import classes from "./WhyUs.module.css"
const stepsImage = "/Assets/Images/CustomizationOverview.svg"

const cardData = [
    "Multi-Label Colors on the Spine",
    "Add Custom Border and Logo",
    "Add Custom Text and choose different styles",
    "Different Leather Colors and 3D Preview",
    "Marble Papers for Deluxe Edition",
    "Gold Border/Logo Embossing"
]

const WhyUs = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <h2>Why us for Customization</h2>
                <p>Craft Your Perfect Book: Customizable Front Covers, Spines, Endpapers, and Gilding</p>
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <h3>{card}</h3>
                    </div>
                ))}
            </div>

            <div className={classes.imageSection}>
                <img src={stepsImage} alt="" />
            </div>

        </div>
    )
}

export default WhyUs