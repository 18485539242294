import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { urls } from "../../Routes/Urls";
import Footer from "../Footer/Footer";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import styles from "./PrivacyPolicy.module.css";

const bannerImg = "/Assets/Images/ourCompnay.jpg";
const PrivacyPolicy = () => {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Privacy Policy", path: urls.PRIVACYPOLICY },
  ];

  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader title={"Privacy Policy"} crumbs={crumbs} bgImage={bannerImg} />

      <div className={styles.policyContent}>

        <h4 className={styles.subHeading}>Privacy Policy for Rare Biblio</h4>
        <p className={styles.paragraph}>
          At Rare Biblio, we are committed to protecting your privacy and ensuring the security of your personal information. This privacy policy outlines how rarebiblio.com collects, uses, and safeguards the personal information you provide while using our website and services.
        </p>


        <h5 className={styles.subHeading}>Personal information collection</h5>
        <p className={styles.paragraph}>
          Rarebiblio.com may collect and use the following kinds of personal
          information: 
          <ul>
            <li>Information about your interactions with the website, such as pages viewed and browsing patterns.</li>
            <li>Information you provide during registration, such as your name, email address, and contact details.</li>
            <li>Information related to transactions conducted through the website, including purchase details and payment information.</li>
            <li>Information provided for subscribing to newsletters, marketing communications, or other website services.</li>
            <li>Any additional information you choose to send to rarebiblio.com.</li>
          </ul>
        </p>

        <h5 className={styles.subHeading}>Use of Personal Information</h5>
        <p className={styles.paragraph}>
          Rare Biblio may use your personal information for the following purposes:

          <ul>
            <li>To administer and personalize your experience on the website.</li>
            <li>To process and deliver products and services you purchase.</li>
            <li>To manage your account, including sending order confirmations, invoices, and payment reminders.</li>
            <li>To send you marketing communications, including promotional emails and newsletters, only if you have provided consent by placing an order or subscribing to marketing services. You may opt out of marketing communications at any time by using the unsubscribe link provided in the email or by contacting us.</li>
            <li>To enhance our services based on customer feedback and usage data.</li>
            <li>To communicate with you regarding customer support inquiries, product updates, or changes to our policies.</li>
          </ul>
        </p>

        <h5 className={styles.subHeading}>Third-Party Disclosure</h5>
        <p className={styles.paragraph}>
        Where Rare Biblio shares personal information with its agents, service providers, or subcontractors (e.g., for order fulfillment, payment processing, or marketing services), they are required to use that information only for the purposes outlined in this privacy policy and to maintain confidentiality and security of the data.
        <br/>
        We may also disclose personal information:

          <ul>
            <li>When required by law or in connection with legal proceedings.</li>
            <li>To protect our legal rights or to defend against legal claims.</li>
            <li>As part of a business transaction, such as a merger or acquisition, where personal information may be transferred to the new owner.</li>
          </ul>
        </p>

        <h5 className={styles.subHeading}>Data Security</h5>
        <p className={styles.paragraph}>
          Rare Biblio implements reasonable technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. All personal data is stored securely, and sensitive data related to electronic transactions is encrypted using industry-standard encryption technologies.
        </p>

        <h5 className={styles.subHeading}>Cross-Border Data Transfers</h5>
        <p className={styles.paragraph}>
          Rare Biblio may store and process your personal information in different countries where we operate. By using this website and providing your personal data, you consent to such cross-border transfers in accordance with this privacy policy.
        </p>

        <h5 className={styles.subHeading}>Cookies and Tracking Technologies</h5>
        <p className={styles.paragraph}>
          We may use cookies and other tracking technologies to collect information about your interactions with the website to improve your experience, provide personalized content, and track marketing performance. You may choose to disable cookies in your browser settings, although this may affect your ability to fully use some website features.
        </p>

        <h5 className={styles.subHeading}>Updating Your Information</h5>
        <p className={styles.paragraph}>
          You have the right to access, update, or delete your personal information at any time. Please contact us at info@rarebiblio.com if you wish to review or amend your information.
        </p>

        <h5 className={styles.subHeading}>Privacy Policy Updates</h5>
        <p className={styles.paragraph}>
          Rare Biblio may update this privacy policy from time to time by posting an updated version on our website. You are encouraged to review this page periodically to stay informed about any changes.
        </p>

        <h5 className={styles.subHeading}>Third-Party Websites</h5>
        <p className={styles.paragraph}>
          Our website may contain links to other websites. Rare Biblio is not responsible for the privacy practices or content of third-party websites. We encourage you to read their privacy policies before providing any personal information.
        </p>

        <h5 className={styles.subHeading}>Contact Us</h5>
        <p className={styles.paragraph}>
          If you have any questions about this privacy policy or how Rare Biblio handles your personal information, please contact us at info@rarebiblio.com.
        </p>

       
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
