import React from "react";
import classes from "./BreadCrumb.module.css";
import Each from "../Each/Each";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getClasses } from "../../Util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
const backIcon = "/Assets/Icons/icon-chevron-left.svg";

function BreadCrumb({ isBack, crumbs, onBack, className }) {
  const history = useHistory();

  return (
    <div className={classes.main}>
      <ul className={classes.breadCrumb}>
        {isBack && (
          <li
            className={getClasses(className, classes.crumb)}
            onClick={onBack ? onBack : () => history.goBack()}
          >
            <img src={backIcon} alt="back" />
          </li>
        )}
        <Each
          of={crumbs}
          render={(crumb, index) => (
            <React.Fragment key={crumb.path}>
              <li
                className={getClasses(className, classes.crumb)}
                onClick={() => history.push(crumb.path)}
              >
                {crumb.title}
              </li>
              {index < crumbs.length - 1 && (
                <li className={classes.separator}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </li>
              )}
            </React.Fragment>
          )}
        />
      </ul>
    </div>
  );
}

export default BreadCrumb;
