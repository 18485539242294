import axios from "../../Axios";

const BASE_URL = "/api";
const FOILING_COLOR_URL = BASE_URL + "/foiling-colors";
const TEXT_COLOR_URL = BASE_URL + "/text-colors";
const CUSTOMIZATION_FONT_URL = BASE_URL + "/customization-fonts";
const GILDING_COLOR_URL = BASE_URL + "/gilding-colors";
const BORDER_DESIGN_URL = BASE_URL + "/border-designs";
const BORDER_DESIGN_CREATE_URL = BORDER_DESIGN_URL + "/create";
const END_PAPER_URL = BASE_URL + "/endpapers";
const END_PAPER_CREATE_URL = END_PAPER_URL + "/create";
const RIB_DESIGN_URL = BASE_URL + "/rib-designs";
const LOGOS_URL = BASE_URL + "/logos";

export function addFoilingColor(data) {
  return axios({
    url: FOILING_COLOR_URL,
    method: "post",
    data: data,
  });
}

export function updateFoilingColor(data, id) {
  return axios({
    url: `${FOILING_COLOR_URL}/${id}`,
    method: "put",
    data: data,
  });
}

export function getFoilingColor(filter) {
  return axios({
    url: `${FOILING_COLOR_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}

export function getFontColor(filter) {
  return axios({
    url: `${TEXT_COLOR_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}

export function getFontData(filter) {
  return axios({
    url: `${CUSTOMIZATION_FONT_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}

export function removeFoilingColor(id) {
  return axios({
    url: `${FOILING_COLOR_URL}/${id}`,
    method: "delete",
  });
}

export function getRibDesign(filter) {
  return axios({
    url: `${RIB_DESIGN_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}

export function createBorderDesign(formData) {
  return axios({
    url: BORDER_DESIGN_CREATE_URL,
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getBorderDesigns(filter) {
  return axios({
    url: `${BORDER_DESIGN_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}

export function updateBorderDesign(formData, id) {
  return axios({
    url: `${BORDER_DESIGN_URL}/${id}`,
    method: "put",
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function deleteBorderDesign(id) {
  return axios({
    url: `${BORDER_DESIGN_URL}/${id}`,
    method: "delete",
  });
}


export function addGildingColor(data) {
  return axios({
    url: GILDING_COLOR_URL,
    method: "post",
    data: data,
  });
}

export function updateGildingColor(data, id) {
  return axios({
    url: `${GILDING_COLOR_URL}/${id}`,
    method: "put",
    data: data,
  });
}

export function getGildingColor(filter) {
  return axios({
    url: `${GILDING_COLOR_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}

export function removeGildingColor(id) {
  return axios({
    url: `${GILDING_COLOR_URL}/${id}`,
    method: "delete",
  });
}

export function addEndPaper(data) {
  return axios({
    url: END_PAPER_CREATE_URL,
    method: "post",
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function updateEndPaper(data, id) {
  return axios({
    url: `${END_PAPER_URL}/${id}`,
    method: "put",
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getEndPaper(filter) {
  return axios({
    url: `${END_PAPER_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}

export function removeEndPaper(id) {
  return axios({
    url: `${END_PAPER_URL}/${id}`,
    method: "delete",
  });
}

export function getLogos(filter) {
  return axios({
    url: `${LOGOS_URL}?filter=${encodeURIComponent(
      JSON.stringify(filter)
    )}`,
    method: "get",
  });
}
