import { createSlice } from "@reduxjs/toolkit";

const BookSlice = createSlice({
    name:"books",
    initialState : {
        books: [],
        book:[]
    },
    reducers : {
        updateBooks(state,action){
            state.books = action.payload
        },
        updateBookById(state,action){
            state.book = action.payload
        }
    }

});

export const BookActions = BookSlice.actions
export default BookSlice.reducer