import classes from "./Customization.module.css";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { Page } from "react-fabric-page";
import MaterialVariantSelector from "./MaterialVariantSelector/MaterialVariantSelector";
import { createContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleQuestion,
  faUndo,
  faRedo,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "./Components/Sidebar/Sidebar";
import Editor from "../Editor/Editor";
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import PreviewModal from "./Components/PreviewModal/PreviewModal";
import FrontCover from "./FrontCover/FrontCover";
import Spine from "./Spine/Spine";
import Paper from "./Paper/Paper";
import Endpaper from "./Endpaper/Endpaper";
import useScreenSize from "../../Hooks/UseScreenSize";
import FrontCoverDeluxe from "./FrontCoverDeluxe/FrontCoverDeluxe";
import AdditionalRequest from "./AdditionalRequest/AdditionalRequest";
import { fetchBookById } from "../../Store/actions/BookAction";
import { useDispatch, useSelector } from "react-redux";
import { getEndPaper, getFoilingColor, getFontColor, getFontData } from "../../Store/actions/ColorsAction";
import CustomizationWelcomeScreen from "./WelcomeScreen/CustomizationWelcomeScreen";
import { fetchAllCustomizationPrices } from "../../Store/actions/CustomizationPricingAction";
import { calculateCustomizationCharges, saveNewDesign, updateCustomizationDesign } from "../../Store/actions/CustomizationAction";
import { faCheckCircle, faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { addCartItem } from "../../Store/actions/CartAction";
import { urls } from "../../Routes/Urls";
import Modal, { ModalBody, ModalFooter } from "../../Components/Modal/Modal";
import PrimaryButton from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import ButtonLight from "../../Components/Buttons/ButtonLight/ButtonLight";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import UnavailableModal from "./Components/UnavailableModal/UnavailableModal";

export const CustomizationContext = createContext();

const Customization = () => {

  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const bookData = useSelector((state) => state.books.book[0]);

  const location = useLocation();
  const usedDesign = location.state?.design; 


  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Shopping", path: "/listing" },
    { title: "Customization", path: `/customization/${bookData?.id}` },
  ];

  useEffect(() => {
    fetchBookById(id, dispatch);
    fetchAllCustomizationPrices(dispatch)
  }, [id]);


  const [warningOpen,setWarningOpen] = useState(false);
  const [allSavedDesigns,setAllSavedDesigns] = useState([])
  const [selectedDesigns,setSelectedDesigns] = useState([]);
  const [showSaveMessage,setShowSaveMessage] = useState(false);

  const [bookQty,setBookQty] = useState(1)
  const [steps, setSteps] = useState(usedDesign ? 0 : -1);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [bookVariant, setBookVariant] = useState("deluxe");
  const [bookLeather, setBookLeather] = useState("realLeather");
  const [frontCoverEditor, setFrontCoverEditor] = useState();
  const [spineEditor, setSpineEditor] = useState();
  const [paperEditor, setPaperEditor] = useState();
  const [endPaperEditor, setEndPaperEditor] = useState();
  const [backCoverEditor, setBackCoverEditor] = useState();
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [designJSON,setDesignJSON] = useState([]);
  const [designSaveInitiated,setDesignSaveInitiated] = useState(false);
  const [designSaveCompleted,setDesignSaveCompleted] = useState(false);

  const [numberOfSections, setNumberOfSections] = useState(0);

  const [fontData,setFontData] = useState([]);
  const [allTextColors, setAllTextColors] = useState([]);
  const [allFoilingColors, setAllFoilingColors] = useState([]);
  const [allEndpapers, setAllEndpapers] = useState([]);

  const [frontCoverColor, setFrontCoverColor] = useState(null);
  const [spineColors, setSpineColors] = useState({});

  const [selectedOuterBorderType, setSelectedOuterBorderType] =
    useState("none");
  const [selectedOuterBorder, setSelectedOuterBorder] = useState(null);

  const [selectedInnerBorderType, setSelectedInnerBorderType] =
    useState("none");
  const [selectedInnerBorder, setSelectedInnerBorder] = useState(null);

  const [outerBorderFoilingColor, setOuterBorderFoilingColor] = useState(null);
  const [InnerBorderFoilingColor, setInnerBorderFoilingColor] = useState(null);

  const [userOuterBorder, setUserOuterBorder] = useState(null);
  const [userInnerBorder, setUserInnerBorder] = useState(null);

  const [frontCoverText,setFrontCoverText] = useState([])
  const [frontCoverTextColor, setFrontCoverTextColor] = useState();
  const [frontCoverFontFamily, setFrontCoverFontFamily] = useState(null);
  const [frontCoverFontStyle, setFrontCoverFontStyle] = useState(null);
  const [frontCoverFontSize,setFrontCoverFontSize] = useState(8);
  const [frontCoverLogo, setFrontCoverLogo] = useState(null);
  const [frontCoverLogoColor, setFrontCoverLogoColor] = useState(null);
  const [frontCoverTextAlign,setFrontCoverTextAlign] = useState("center");

  const [frontMarblePaper, setFrontMarblePaper] = useState(null);

  const [isSpineLoaded, setIsSpineLoaded] = useState(false);
  const [activeSectionNumber, setActiveSection] = useState(-1);
  const [spineText,setSpineText] = useState([])
  const [spineRibDesignColor, setSpineRibDesignColor] = useState(null);
  const [spineRibDesign, setSpineRibDesign] = useState(null);
  const [spineFontColor, setSpineFontColor] = useState(null);
  const [spineFontFamily, setSpineFontFamily] = useState(null);
  const [spineFontStyle, setSpineFontStyle] = useState(null);
  const [spineLogoType, setSpineLogoType] = useState([]);
  const [spineLogo, setSpineLogo] = useState([]);
  const [spineLogoColor, setSpineLogoColor] = useState([]);
  const [spineCustomLogo,setSpineCustomLogo] = useState([])
  const [spineCoverTextAlign,setSpineCoverTextAlign] = useState("center");
  const [spineFontSize,setSpineFontSize] = useState(9);

  const [selectedGildingColor, setSelectedGildingColor] = useState(null);

  const [wantEndpaper, setWantEndpaper] = useState(false);
  const [selectedEndpaper, setSelectedEndpaper] = useState(null);
  const [wantDedicationPage, setWantDedicationPage] = useState(false);
  const [dedicationImage,setDedicationImage] = useState(null)
  const [dedicationText,setDedicationText] = useState("")
  const [dedicationTextColor, setDedicationTextColor] = useState();
  const [dedicationFontFamily, setDedicationFontFamily] = useState(null);
  const [dedicationFontStyle, setDedicationFontStyle] = useState(null);
  const [dedicationTextAlign,setDedicationTextAlign] = useState("left");
  const [dedicationFontSize,setDedicationFontSize] = useState(8);

  const [additionalRequestText,setAdditionalRewquestText] = useState("")
  const [additionalRequestImage,setAdditionalRequestImage] = useState(null)

  const [bookCustomization, setBookCustomization] = useState({});

  const [customizationCharges,setCustomizationCharges] = useState([]);

  const [selectedOption,setSelectedOption] = useState("Leather Color");

  const [showBackModal, setShowBackModal] = useState(false);
  const [allowBack, setAllowBack] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);

  const [prevFrontCoverText, setPrevFrontCoverText] = useState([]);


  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = 'Are you sure you want to leave? Any unsaved progress will be lost !!';
      return 'Are you sure you want to leave? Any unsaved progress will be lost !!';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);


    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      if (!allowBack) {
        event.preventDefault(); // Prevent default back navigation
        setShowBackModal(true);
        setPendingNavigation(event); // Store the popstate event
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [allowBack, history]);

  const handleConfirm = () => {
    setAllowBack(true); // Allow back navigation
    setShowBackModal(false); // Close the modal
    if (pendingNavigation) {
      // Perform the back navigation once the modal is confirmed
      history.goBack();
      setPendingNavigation(null);
      setAllowBack(false)
    }
  };

  useEffect(() => {
    if(!bookData?.title) {
      setWarningOpen(true)
    } else {
      setWarningOpen(false)
    }
  },[bookData])

  const handleCancel = () => {
    setShowBackModal(false); // Close the modal if canceled
    setAllowBack(false)
    setPendingNavigation(null); // Reset pending navigation
  };

  useEffect(() => {
    const hasTextChanged = frontCoverText.some((textObj, index) => {
      const prevTextObj = prevFrontCoverText[index];
      return prevTextObj?.text !== textObj.text;
    });

    const updateCustomizationCharges = async () => {
      const res = await calculateCustomizationCharges(bookCustomization);
      setCustomizationCharges(res.data.data);
    };

    if (steps >= 1 && !hasTextChanged) {
      updateCustomizationCharges();
    }

    setPrevFrontCoverText([...frontCoverText]);
  }, [steps,bookCustomization]);

  useEffect(() => {
    if(selectedDesigns?.leatherType && selectedDesigns?.variantType) {
      setBookLeather(selectedDesigns?.leatherType);
      setBookVariant(selectedDesigns?.variantType);
    }
  },[selectedDesigns])

  
  useEffect(() => {
    const updatedCustomization = {
      variantType: bookVariant,
      leatherType: bookLeather,
      leatherColor: frontCoverColor?.colorName || null,
      outerBorder: {
        style: selectedOuterBorderType || null,
        designId: selectedOuterBorder?.id || null,
        foilingColor: outerBorderFoilingColor?.colorName || null,
        isCustomDesign: !!userOuterBorder,
        customDesignImage: userOuterBorder || null,
      },
      innerBorder: {
        style: selectedInnerBorderType || null,
        designId: selectedInnerBorder?.id || null,
        foilingColor: InnerBorderFoilingColor?.colorName || null,
        isCustomDesign: !!userInnerBorder,
        customDesignImage: userInnerBorder || null,
      },
      marblePaper: {
        hadEndpaper: !!frontMarblePaper,
        endpaper: frontMarblePaper?.id || null,
      },
      frontCoverTexts: frontCoverText.map(textObj => ({
        text: textObj.text, // Use the text content from the array
        fontFamily: frontCoverFontFamily?.fontFamily,
        fontSize: frontCoverFontSize || null,
        textStyle: {
          isBold: frontCoverFontStyle?.isBold || false,
          isItalics: frontCoverFontStyle?.fontStyle === "italic" ? true : false,
          isUnderline: frontCoverFontStyle?.underline || false,
          textAlign: frontCoverTextAlign
        },
        color: frontCoverTextColor?.id,
        isCustom: true,
      })),
      frontCoverImage: {
        image: frontCoverLogo || null,
        foilingColor: frontCoverLogoColor?.colorName || null,
        isCustomImage: !!frontCoverLogo,
        customImage: frontCoverLogo || null,
      },
      spine: {
        ribDesign: spineRibDesign?.designName || null,
        ribDesignColor: spineRibDesignColor?.colorName || null,
        sections: Array.from({ length: numberOfSections }, (_, index) => {
          const matchingText = spineText.find(textObj => textObj.id === `rib-section-${index + 1}`)
          return {
            color: spineColors[`rib-section-${index + 1}`] || null,
            text: {
              text: matchingText?.text || '',
              fontFamily: spineFontFamily?.fontFamily || null,
              fontSize: spineFontSize || null,
              textStyle: {
                isBold: spineFontStyle?.isBold || false,
                isItalics: spineFontStyle?.italics || false,
                isUnderline: spineFontStyle?.underline || false,
                textAlign: spineCoverTextAlign
              },
              color: spineFontColor?.id || null,
              isCustom: false,
            },
            image: {
              image: !spineCustomLogo[index+1] ? spineLogo[index + 1]?.logoName : null,
              foilingColor: spineLogoColor[index + 1]?.colorName || null,
              isCustomImage: spineCustomLogo[index+1] ? true : false,
              customImage: spineCustomLogo[index+1],
            },
          };
        }),
      },
      gilding: {
        hasGilding: selectedGildingColor !== null,
        gildingColor: selectedGildingColor?.colorName || null,
      },
      endpaper: {
        hadEndpaper: !!wantEndpaper,
        endpaper: selectedEndpaper?.id || null,
      },
      dedication: {
        hasDedication: !!wantDedicationPage,
        text: wantDedicationPage
          ? {
              text: dedicationText || '',
              fontFamily: dedicationFontFamily?.fontFamily || null,
              fontSize: dedicationFontSize || null,
              textStyle: {
                isBold: dedicationFontStyle?.isBold || false,
                isItalics: dedicationFontStyle?.fontStyle === "italic" ? true : false,
                isUnderline: dedicationFontStyle?.underline || false,
                textAlign: dedicationTextAlign
              },
              color: dedicationTextColor?.colorName || null,
              isCustom: true,
            }
          : null,
        image: {
          image: dedicationImage || null,
          foilingColor: null,
          isCustomImage: !!dedicationImage,
          customImage: dedicationImage || null,
        },
      },
      additionalRequest: {
        requestText: additionalRequestText || '',
        customImage: additionalRequestImage || null,
      },
      frontCoverThumbnail:frontCoverEditor?.getThumbnail(),
      bookThumbnail:frontCoverEditor?.canvas?.toDataURL(),
      spineThumbnail: spineEditor?.getThumbnail(),
      gildingThumbnail: paperEditor?.getThumbnail(),
      endpaperThumbnail: endPaperEditor?.getThumbnail()
    };
    
    setBookCustomization(updatedCustomization);
  }, [
    bookVariant,bookLeather,
    frontCoverColor,spineColors,
    selectedOuterBorderType,selectedOuterBorder,
    selectedInnerBorderType,selectedInnerBorder,
    outerBorderFoilingColor,InnerBorderFoilingColor,
    userOuterBorder,userInnerBorder,
    frontCoverTextColor,frontCoverFontFamily,
    frontCoverFontStyle,frontCoverLogo,
    frontCoverLogoColor,isSpineLoaded,
    spineRibDesignColor,spineRibDesign,
    spineFontColor, spineFontFamily,
    spineFontStyle,spineLogoType,
    spineLogo,spineCustomLogo,
    spineLogoColor,selectedGildingColor,
    wantEndpaper,dedicationImage,
    selectedEndpaper,wantDedicationPage,
    dedicationTextColor, dedicationFontFamily,
    dedicationFontStyle, dedicationText,
    frontCoverText, additionalRequestText,
    additionalRequestImage,
    spineText,numberOfSections,frontMarblePaper,selectedDesigns,
    frontCoverTextAlign,spineCoverTextAlign,dedicationTextAlign,
    frontCoverFontSize,spineFontSize,dedicationFontSize
  ]);

  useEffect(() => {
    setSelectedOuterBorderType("none");
    setSelectedOuterBorder(null);
    setSelectedInnerBorderType("none");
    setSelectedInnerBorder(null);
    setUserOuterBorder(null);
    setUserInnerBorder(null);
    if(selectedDesigns?.customization?.frontCoverTexts?.[0]?.fontFamily) {
      const selectedFontFamily = fontData?.find((font) => font?.fontFamily === selectedDesigns?.customization?.frontCoverTexts?.[0]?.fontFamily);
      if(selectedFontFamily) {
        setFrontCoverFontFamily(selectedFontFamily)
      } else {
        setFrontCoverFontFamily(fontData?.[0]);
      }
    } else {
      setFrontCoverFontFamily(fontData?.[0]);
    }
    setFrontCoverLogo(null);
    setFrontCoverLogoColor(null);
    setFrontMarblePaper(null);
  }, [bookLeather, bookVariant]);

  const { isMediumScreen } = useScreenSize();

  const [textureData, setTextureData] = useState([
    { src: null, target: "front" },
    { src: null, target: "back" },
    { src: null, target: "spine" },
    { color: null, target: "all_pages" },
  ]);

  const getColor = async () => {
    let filter = {
      where : {
        disabled:false
      }
    };
    try {
      const res = await getFoilingColor(filter);
      if (res.status === 200) {
        setAllFoilingColors(
          res.data?.data
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getTextColor = async () => {
    let filter = {
      where : {
        disabled: false
      }
    };

    try {
      const res = await getFontColor(filter);
      if (res.status === 200) {
        setAllTextColors(
          res.data?.data
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCustomizationFonts = async () => {
    let filter = {
      where : {
        disabled: false
      }
    };

    try {
      const res = await getFontData(filter);
      if (res.status === 200) {
        setFontData(
          res.data?.data
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getEndpapersData = async () => {
    let filter = {
      where : {
        disabled:false
      }
    };

    try {
      const res = await getEndPaper(filter);
      if (res.status === 200) {
        setAllEndpapers(res.data?.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getColor();
    getTextColor();
    getCustomizationFonts();
    getEndpapersData();
  }, []);

  useEffect(() => {
    if(fontData?.length >0) {
      if(selectedDesigns?.customization?.frontCoverTexts?.[0]?.fontFamily) {
        const selectedFontFamily = fontData?.find((font) => font?.fontFamily === selectedDesigns?.customization?.frontCoverTexts?.[0]?.fontFamily);
        if(selectedFontFamily) {
          setFrontCoverFontFamily(selectedFontFamily)
        } else {
          setFrontCoverFontFamily(fontData?.[0]);
        }
      } else {
        setFrontCoverFontFamily(fontData?.[0]);
      }
      setSpineFontFamily(fontData?.[0]);
      setDedicationFontFamily(fontData?.[0]);
    }
  },[fontData,selectedDesigns]);

  useEffect(() => {
    if (!frontCoverTextColor) {
      if(selectedDesigns?.customization?.frontCoverTexts?.[0]?.color) {
        const selectedColor = allTextColors?.find((color) => color?.id === selectedDesigns?.customization?.frontCoverTexts?.[0]?.color);
        if(selectedColor) {
          setFrontCoverTextColor(selectedColor)
        } else {
          setFrontCoverTextColor(allTextColors?.[0]);
        }
      } else {
        setFrontCoverTextColor(allTextColors?.[0]);
      }
    }

    if(selectedDesigns?.customization?.frontCoverTexts?.[0]?.fontSize) {
      setFrontCoverFontSize(selectedDesigns?.customization?.frontCoverTexts?.[0]?.fontSize)
    }

  }, [allTextColors,selectedDesigns]);

  const getSpineTemplate = () => {
    const bookSize = bookData?.sizeName;
    const bookPages = bookData?.pages;

    if(bookSize && bookPages) {
      if (bookPages >= 0 && bookPages <= 200) {
        return `/template/${bookVariant}/${bookSize}/spine/0-200.json`;
      }
      if (bookPages >= 201 && bookPages <= 400) {
        return `/template/${bookVariant}/${bookSize}/spine/201-400.json`;
      }
      if (bookPages >= 401 && bookPages <= 600) {
        return `/template/${bookVariant}/${bookSize}/spine/401-600.json`;
      }
      if (bookPages >= 601 && bookPages <= 800) {
        return `/template/${bookVariant}/${bookSize}/spine/601-800.json`;
      } else {
        return `/template/${bookVariant}/${bookSize}/spine/800.json`;
      }
    }

  };

  const bookSize = bookData?.sizeName;
  const [pages, setPages] = useState({
    frontCover: null,
    spine: null,
    paper: null,
    endPaper: null,
    backPage: null,
  });
  useEffect(() => {
    const initializePages = async () => {
      if(bookSize) {
        
          if(!selectedDesigns?.customization) {
            try {
            const frontCover = new Page(
              `/template/${bookVariant}/${bookSize}/frontPage.json`
            );
            await frontCover.initialize();
    
            const spine = new Page(getSpineTemplate());
            await spine.initialize();
    
            const paper = new Page(
              `/template/${bookVariant}/${bookSize}/paper.json`
            );
            await paper.initialize();
    
            const endPaper = new Page(
              `/template/${bookVariant}/${bookSize}/endPaper.json`
            );
            await endPaper.initialize();
    
            const backPage = new Page(
              `/template/${bookVariant}/${bookSize}/backPage.json`
            );
            await backPage.initialize();
    
            setPages({
              frontCover,
              spine,
              paper,
              endPaper,
              backPage,
            });
            }
           catch (error) {
            console.error("Error initializing pages:", error);
          }
        } else {
          try {
            const frontCover = new Page(
              selectedDesigns?.frontCoverDesign
            );
            await frontCover.initialize();
    
            const spine = new Page(selectedDesigns?.spineDesign);
            await spine.initialize();
    
            const paper = new Page(
              selectedDesigns?.paperDesign
            );
            await paper.initialize();
    
            const endPaper = new Page(
              selectedDesigns?.endPaperDesign
            );
            await endPaper.initialize();
    
            const backPage = new Page(
              `/template/${bookVariant}/${bookSize}/backPage.json`
            );
            await backPage.initialize();
    
            setPages({
              frontCover,
              spine,
              paper,
              endPaper,
              backPage,
            });
            }
           catch (error) {
            console.error("Error initializing pages:", error);
          }
        }
      }

    };

    initializePages();
  }, [bookVariant, bookData]);

  useEffect(() => {
    setIsSpineLoaded(false);
  }, [bookLeather, bookVariant]);

  const incrementSteps = () => {
    if (steps <= 5) {
      if(selectedDesigns?.leatherType && steps === -1) {
        setSteps(steps + 2)
      } else {
        setSteps(steps + 1);
      }
    }
  };

  useEffect(() => {
    if(usedDesign?.bookId && steps <= 0) {
      setSteps(1)
    }
  },[steps])

  const decrementSteps = () => {
    if (steps >= 0) {
      if(steps === 1 && selectedDesigns?.leatherType) {
        setSteps(steps-2);
      } else {
        setSteps(steps - 1);
      }
    }
  };

  useEffect(() => {
    if (steps > 0) {
      setSidebarActive(true);
    } else {
      setSidebarActive(false);
    }
  }, [steps]);

  const handleThumbnailUpdate = () => {
    const backThumbnail =
      bookVariant === "deluxe"
        ? frontCoverEditor
          ? frontCoverEditor.getThumbnailForBackCover()
          : null
        : backCoverEditor
        ? backCoverEditor.getThumbnail()
        : null;
    const updatedTextureData = [
      {
        src: frontCoverEditor ? frontCoverEditor.getThumbnail() : null,
        target: "front",
      },
      { src: backThumbnail, target: "back" },
      { src: spineEditor ? spineEditor.getThumbnail() : null, target: "spine" },
      {
        color: paperEditor ? paperEditor.canvas._objects[2]?.fill : null,
        target: "all_pages",
      },
    ];

    setTextureData(updatedTextureData);
    setPreviewModal(true);
  };

  function handleUndo() {
    if(steps === 1 && frontCoverEditor.currentStep !== 1) {
      frontCoverEditor.undo();
    } else if(steps === 2 && spineEditor.currentStep !== 1) {
      spineEditor.undo()
    } else if(steps === 3) {
      paperEditor.undo()
    } else if(steps === 4 && endPaperEditor.currentStep !== 1) {
      endPaperEditor.undo()
    }
  };

  function handleRedo () {
    if(steps === 1) {
      frontCoverEditor.redo();
    } else if(steps === 2) {
      spineEditor.redo()
    } else if(steps === 3) {
      paperEditor.redo()
    } else if(steps === 4) {
      endPaperEditor.redo()
    }
  };

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth'});
    }
  }

  const handleAddToCart = () => {
    addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization);
    history.push(urls.CART)
  }

  useEffect(()=>{
    scrollToSection('header')
  },[steps])
  const handleDesignSave = () => {
    if(selectedDesigns?.id) {
      updateCustomizationDesign(bookData?.sizeName,bookData?.pages,selectedDesigns?.id,designJSON,bookCustomization).then((res) => {
        if(res.data.success) {
          setDesignSaveInitiated(false)
          setDesignSaveCompleted(true);
          setShowSaveMessage(true)
          setTimeout(() => setShowSaveMessage(false), 2000);
        }
      })
    } else {
      saveNewDesign(designJSON,bookData?.id,bookData?.title,bookCustomization,bookVariant,bookLeather,bookSize,bookData?.pages).then(res => {
        if(res.data.success) {
          setDesignSaveInitiated(false);
          setShowSaveMessage(true);
          setDesignSaveCompleted(true);
          setTimeout(() => setShowSaveMessage(false), 2000);
        }
      })
    }
  }

  useEffect(() => {
    if(designSaveInitiated && designJSON.length === 4) {
      handleDesignSave()
    } 
  },[designSaveInitiated,designJSON])

  return (
    <CustomizationContext.Provider
      value={{
        steps,
        setSteps,
        bookCustomization,
        bookLeather,
        bookVariant,
        bookData,
        bookSize,
        backCoverEditor,
        frontCoverColor,
        setFrontCoverColor,
        selectedOuterBorder,
        setSelectedOuterBorder,
        selectedOuterBorderType,
        setSelectedOuterBorderType,
        selectedInnerBorder,
        setSelectedInnerBorder,
        selectedInnerBorderType,
        setSelectedInnerBorderType,
        InnerBorderFoilingColor,
        setInnerBorderFoilingColor,
        outerBorderFoilingColor,
        setOuterBorderFoilingColor,
        frontCoverTextColor,
        setFrontCoverTextColor,
        frontCoverFontFamily,
        setFrontCoverFontFamily,
        frontCoverText,
        setFrontCoverText,
        fontData,
        allFoilingColors,
        allTextColors,
        frontCoverLogo,
        setFrontCoverLogo,
        frontCoverLogoColor,
        setFrontCoverLogoColor,
        userOuterBorder,
        setUserOuterBorder,
        userInnerBorder,
        setUserInnerBorder,
        frontCoverFontStyle,
        setFrontCoverFontStyle,
        numberOfSections,
        setNumberOfSections,
        spineColors,
        setSpineColors,
        spineLogoType,
        setSpineLogoType,
        spineLogo,
        setSpineLogo,
        spineLogoColor,
        setSpineLogoColor,
        activeSectionNumber,
        setActiveSection,
        spineRibDesignColor,
        setSpineRibDesignColor,
        spineCustomLogo,setSpineCustomLogo,
        selectedGildingColor,
        setSelectedGildingColor,
        wantEndpaper,
        setWantEndpaper,
        selectedEndpaper,
        setSelectedEndpaper,
        wantDedicationPage,
        setWantDedicationPage,
        dedicationTextColor,
        setDedicationTextColor,
        dedicationFontFamily,
        setDedicationFontFamily,
        dedicationFontStyle,
        setDedicationFontStyle,
        spineRibDesign,
        setSpineRibDesign,
        isSpineLoaded,
        setIsSpineLoaded,
        spineFontColor,
        setSpineFontColor,
        spineFontFamily,
        setSpineFontFamily,
        spineFontStyle,
        setSpineFontStyle,
        frontMarblePaper,
        setFrontMarblePaper,
        dedicationImage,
        setDedicationImage,
        dedicationText,
        setDedicationText,
        additionalRequestText,
        setAdditionalRewquestText,
        additionalRequestImage,
        setAdditionalRequestImage,
        bookQty,
        setBookQty,
        selectedOption,
        setSelectedOption,
        spineText,
        setSpineText,
        designJSON,
        setDesignJSON,
        designSaveInitiated,
        setDesignSaveInitiated,
        designSaveCompleted,
        setDesignSaveCompleted,
        bookCustomization,
        customizationCharges,
        setCustomizationCharges,
        allSavedDesigns,
        setAllSavedDesigns,
        selectedDesigns,
        setSelectedDesigns,
        allEndpapers,
        frontCoverTextAlign,setFrontCoverTextAlign,
        spineCoverTextAlign,setSpineCoverTextAlign,
        dedicationTextAlign,setDedicationTextAlign,
        frontCoverFontSize,setFrontCoverFontSize,
        spineFontSize,setSpineFontSize,
        dedicationFontSize,setDedicationFontSize
      }}
    >
      <div className={classes.mainContainer} id="header">
        {showBackModal && <Modal title={"Confirm Action"}>
          <ModalBody>
            <div className={classes.backModal}>
            <p>Are you sure you want to go back? All progress will be lost.</p>
            </div>
          </ModalBody>
          <ModalFooter>
          <div className={classes.btnContainer}>
                <PrimaryButton onClick={handleConfirm}>
                  Go Back
                </PrimaryButton>

                <ButtonLight onClick={handleCancel}>
                  Cancel
                </ButtonLight>
              </div>
          </ModalFooter>
          </Modal>}
        <PrimaryNav />

        <div className={classes.container}>
          {previewModal && (
            <PreviewModal
              textureData={textureData}
              bookData={bookData}
              onClose={() => setPreviewModal(false)}
            />
          )}

          <div className={classes.mainSection}>
            <div className={classes.headerSection}>
              {!isMediumScreen && (
                <div className={classes.crumb}>
                  <BreadCrumb className={classes.crumb} crumbs={crumbs} />
                </div>
              )}
              {steps !== -1 && (
                <div className={classes.icons}>
                  <div className={classes.previewBtns}>
                    <SecondaryButton
                      onClick={handleThumbnailUpdate}
                      className={classes.preview}
                    >
                      3D Preview
                    </SecondaryButton>
                    <FontAwesomeIcon
                      icon={faUndo}
                      onClick={e => handleUndo()}
                      color={!frontCoverEditor?.canUndo() ?  "#ccc" : "#000"}
                    />
                    <FontAwesomeIcon
                      icon={faRedo}
                      onClick={e => handleRedo()}
                      color={!frontCoverEditor?.canRedo()? "#ccc" : "#000"}
                    />

                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faFloppyDisk}
                      onClick={() => setDesignSaveInitiated((e) => !e)}
                    />

                    {showSaveMessage && (
                      <div className={classes.saveMessage}>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className={classes.checkIcon}
                        />
                        Design Saved
                      </div>
                    )}
                  {isMediumScreen && <FontAwesomeIcon className={classes.cartIcon} icon={faCartShopping} onClick={handleAddToCart} />}
                  </div>

                  <div className={classes.iconContainer}>
                    {/* <FontAwesomeIcon icon={faCircleQuestion} /> */}

                    {/* <img src={shareIcon} alt="" /> */}
                  </div>
                </div>
              )}
            </div>

            <div className={classes.Customization}>
              <div className={classes.customizationOptions}>
                <CustomizationWelcomeScreen
                  changeBookVariant={setBookVariant}
                  bookVariant={bookVariant}
                  changeLeatherType={setBookLeather}
                  BookleatherType={bookLeather}
                  onNext={incrementSteps}
                  onPrev={decrementSteps}
                  visibility={steps === -1}
                  bookData={bookData}
                  editor={backCoverEditor}
                  setEditor={setBackCoverEditor}
                  page={pages.backPage}
                  redirectedDesign={usedDesign}
                />
                <MaterialVariantSelector
                  changeBookVariant={setBookVariant}
                  bookVariant={bookVariant}
                  changeLeatherType={setBookLeather}
                  BookleatherType={bookLeather}
                  onNext={incrementSteps}
                  onPrev={decrementSteps}
                  visibility={steps === 0}
                  bookData={bookData}
                  editor={backCoverEditor}
                  setEditor={setBackCoverEditor}
                  page={pages.backPage}
                />
                <Editor
                  Component={
                    bookVariant === "deluxe" ? FrontCoverDeluxe : FrontCover
                  }
                  SecondaryComponent={AdditionalRequest}
                  visibilitySecondary={steps === 5}
                  editor={frontCoverEditor}
                  setEditor={setFrontCoverEditor}
                  page={pages.frontCover}
                  currentStep={1}
                  onNext={incrementSteps}
                  onPrev={decrementSteps}
                  visibility={steps === 1 || steps === 5}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                />
                <Editor
                  Component={Spine}
                  visibilitySecondary={false}
                  editor={spineEditor}
                  setEditor={setSpineEditor}
                  page={pages.spine}
                  currentStep={2}
                  onNext={incrementSteps}
                  onPrev={decrementSteps}
                  numberOfSections={5}
                  visibility={steps === 2}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                />
                <Editor
                  Component={Paper}
                  visibilitySecondary={false}
                  editor={paperEditor}
                  setEditor={setPaperEditor}
                  page={pages.paper}
                  bookVariant={bookVariant}
                  currentStep={3}
                  onNext={incrementSteps}
                  onPrev={decrementSteps}
                  visibility={steps === 3}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                />
                <Editor
                  Component={Endpaper}
                  visibilitySecondary={false}
                  editor={endPaperEditor}
                  setEditor={setEndPaperEditor}
                  page={pages.endPaper}
                  bookVariant={bookVariant}
                  currentStep={4}
                  onNext={incrementSteps}
                  onPrev={decrementSteps}
                  visibility={steps === 4}
                  drawerOpen={drawerOpen}
                  setDrawerOpen={setDrawerOpen}
                />
              </div>
            </div>
          </div>

          {sidebarActive && !isMediumScreen && (
            <div className={classes.sidebar}>
              <Sidebar
                currentStep={steps}
                onClick={setSteps}
                setDrawerOpen={setDrawerOpen}
              />
            </div>
          )}
        </div>
      </div>

      {warningOpen && <UnavailableModal />}
    </CustomizationContext.Provider>
  );
};

export default Customization;
