import React from 'react';
import classes from './Tabs.module.css';


const Tabs = ({ activeTab, onTabClick,tabs }) => {
  return (
    <div className={classes.tabs}>
      {tabs.map((tab, index) => (
        <div
          key={tab}
          className={`${classes.tab} ${activeTab === index ? classes.active : ''}`}
          onClick={() => onTabClick(index)}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
