import React from 'react';
import classes from './ColorSelector.module.css';

const ColorSelector = ({ 
  availableColors, 
  selectedColor, 
  handleColorChange, 
  title 
}) => {
  return (
    <div className={classes.colorPickerDropdown}>
      {title && <h3>{title}</h3>}
      <div className={classes.colors}>
        {availableColors?.map((color, index) =>
          selectedColor?.colorValue === color?.colorValue ? (
            <div key={index} className={classes.selected}>
              <div
                className={classes.active}
                style={{ background: color?.colorValue }}
              ></div>
              {selectedColor?.price !== undefined && selectedColor?.price !== 0 && <div className={classes.dollarIcon}>
                <span>${selectedColor?.price}</span>
              </div>}
            </div>
          ) : (
            <div
              key={index}
              className={classes.colorPicker}
              style={{ background: color?.colorValue }}
              onClick={(e) => handleColorChange(color, e)}
            >
              {color?.price !== undefined && color?.price !== 0 && <div className={classes.dollarIcon}>
                <span>${color?.price}</span>
              </div>}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ColorSelector;
