import classes from "./OrderCard.module.css";
import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton";
import ButtonLight from "../../../../Components/Buttons/ButtonLight/ButtonLight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import DisputeOrderForm from "../DisputeOrderForm/DisputeOrderForm";
import { useState } from "react";
import ReviewModal from "../../../../Components/ReviewModal/ReviewModal";
import { getClasses, getFormattedDate, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../../Util";
import { useSelector } from "react-redux";
import axios from "axios";
import html2pdf from 'html2pdf.js';
import Modal, { ModalBody } from "../../../../Components/Modal/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../../Routes/Urls";

const iconsuccess = "/Assets/icons/icon-success.svg"

const OrderCard = ({ viewDetails, activeTab, data ,disputedOrderData}) => {
  const allColorsData = useSelector(state => state.bookColor.colors);
  const [disputeModal, setDisputeModal] = useState(false);
  const [isPopupOpen,setIsPopupOpen] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const [selectedBook,setSelectedBook] = useState(null)
  const history = useHistory()


  const getVariantType = (type) => {
    switch (type) {
      case "deluxe":
        return "Deluxe";
      case "superDeluxe":
        return "Super Deluxe";
      default:
        break;
    }
  };

  const getOrderStatusOfItem = (itemId) => {
    let status = "";
    data?.subOrders?.forEach((sub) => {
      if (sub?.cartItemIds.includes(itemId)) {
        status = sub?.orderStatus;
      }
    });

    if (status) {
      return status;
    } else {
      return data?.orderStatus;
    }
  };

  const getSubOrders = (itemId) => {
    let subOrder = {};
    data?.subOrders?.forEach((sub) => {
      if (sub?.cartItemIds.includes(itemId)) {
        subOrder = sub;
      }
    });
    if (subOrder) {
      return subOrder;
    }
  };

  const openReviewModal = (bookData) => {
    const book = bookData?.book ? bookData?.book : bookData?.bestSellerBook;
    book.imageUrl = bookData?.book ? getRandomImageForNormalBook(bookData.book, allColorsData, bookData.color, bookData.leatherType, bookData.variantType)?.image : getRandomImageForBestSellerBook(bookData.bestSellerBook, allColorsData, bookData.color, bookData.leatherType, bookData.variantType)?.image
    setSelectedBook(book)
    setReviewModal(true)
  }

  const handleRedirect = (item) =>{
    item?.bestSellerBook ? 
        history.push({
          pathname: `${urls.BESTSELLERPDP}/${item.bestSellerBook.id}`,
      }) : 
        history.push({
          pathname: `${urls.PRODUCTDETAIL}/${item.book.id}`,
      });
}


  const downloadInvoice = async (orderId) => {
    try {
      const response = await axios({
        url: `/api/orders/order-summary/${orderId}`,
        method: "post",
      });

      if (response.data.success) {
        const html = response.data.data;

        // Create a container to hold the HTML content temporarily
        const container = document.createElement('div');
        container.innerHTML = html;

        // Add CSS to avoid page breaks inside tables
        const style = document.createElement('style');
        style.textContent = `
          table {
            page-break-inside: avoid;
          }
          tr {
            page-break-inside: avoid;
          }
          td, th {
            page-break-inside: avoid;
          }
        `;
        container.appendChild(style);

        // Configure html2pdf options
        const options = {
          margin: 10,
          filename: `Invoice-${orderId}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
        };

        // Generate and download the PDF
        html2pdf().from(container).set(options).save();

        // Optional: Remove the container after PDF generation
        container.remove();
      } else {
        console.error('Failed to fetch invoice:', response.statusText);
      }
    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };


  return (
    <div className={classes.mainContainer}>
      <div className={classes.headerSection}>
        <div className={classes.headerItem}>
          <h2>Order Placed</h2>
          <p>{getFormattedDate(data?.createdAt)}</p>
        </div>
        <div className={classes.headerItem}>
          <h2>Amount Paid</h2>
          <p>${Number(data?.payableAmount).toFixed(2)}</p>
        </div>
        <div className={classes.headerItem}>
          <h2>Order No.</h2>
          <p>{data?.orderNo}</p>
        </div>
        <div className={classes.actions}>
          {(activeTab === "delivered" || activeTab === "disputed") && (
            <div className={`${classes.headerItem} ${classes.invoice}`}>
              <h2>Download Invoice</h2>
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faDownload}
                onClick={() => downloadInvoice(data?.id)}
              />
            </div>
          )}
        </div>
      </div>

      <div className={classes.mainBody}>
        {activeTab !== "disputed" ? data?.cartItems?.map((item, idx) => (
          <div key={idx} className={classes.itemContainer}>
          {activeTab !== "disputed" && (
            <div className={classes.orderDetails}>
              <h2>Expected delivery : {getFormattedDate(item?.shippingCharges?.find((date) => date.shippingSpeed === item?.shippingSpeed)?.shipByDate)}</h2>
            </div>
          )}
            
            {activeTab === "shipped" && (
              <div className={classes.subOrder}>
                {getOrderStatusOfItem(item.id) === "shipped" && (
                  <div className={getClasses(classes.status, classes.transit)}>
                    <p>In Transit</p>
                  </div>
                )}
                {getOrderStatusOfItem(item.id) === "delivered" && (
                  <div
                    className={getClasses(classes.status, classes.completed)}
                  >
                    <p className={classes.delivered}>Delivered</p>
                  </div>
                )}
                {getSubOrders(item.id)?.trackingNo && (
                  <div className={classes.trackingName}>
                    Tracking No:{" "}
                    <span>{getSubOrders(item.id)?.trackingNo}</span>
                  </div>
                )}
                {getSubOrders(item.id)?.courierName && (
                  <div className={classes.trackingName}>
                    Courier Name:{" "}
                    <span>{getSubOrders(item.id)?.courierName}</span>
                  </div>
                )}
              </div>
            )}
            <div className={classes.orderItem}>
              <div className={classes.bookDetailsContainer}>
                <div className={classes.bookDetails}>
                  <div className={classes.bookImage} onClick={!item?.book?.isDeleted || !item?.bestSellerBook?.isDeleted ? () => handleRedirect(item) : () => {} }>
                    <img
                      src={
                        item?.book
                          ? item?.customization?.bookThumbnail ? 
                          item?.customization?.bookThumbnail :
                          getRandomImageForNormalBook(
                              item.book,
                              allColorsData,
                              item.color,
                              item.leatherType,
                              item.variantType
                            )?.image
                          : getRandomImageForBestSellerBook(
                              item.bestSellerBook,
                              allColorsData,
                              item.color,
                              item.leatherType,
                              item.variantType
                            )?.image
                      }
                      alt=""
                    />
                  </div>
                  <div className={classes.bookData}>
                    <h2 onClick={!item?.book?.isDeleted || !item?.bestSellerBook?.isDeleted ? () => handleRedirect(item) : () => {}}>{item?.book?.title || item?.bestSellerBook?.title}</h2>
                    <h3>{getVariantType(item.variantType)}</h3>
                    <p>Qty: {item?.quantity}</p>
                    <p>
                      Ship To:{" "}
                      <span className={classes.address}>{`${
                        item?.shippingAddress?.addressLine1
                      } ${item?.shippingAddress?.addressLine2 || ""}, ${
                        item?.shippingAddress?.city
                      }, ${item?.shippingAddress?.state}, ${
                        item?.shippingAddress?.country
                      }, ${item?.shippingAddress?.zipCode}`}</span>
                    </p>
                  </div>
                </div>
                {activeTab !== "disputed" && (
                  <p className={classes.price}>
                    $ {Number(item?.totalAmount).toFixed(2)}
                  </p>
                )}
                
              </div>

              {idx === 0 && activeTab !=="disputed" && (
                <div className={classes.btnContainer}>
                  <PrimaryButton onClick={viewDetails}>
                    {activeTab === "shipped" ? "Track Order" : "View Order"}
                  </PrimaryButton>
                  <ButtonLight onClick={() => setDisputeModal(true)}>
                    Dispute Order
                  </ButtonLight>
                </div>
              )}
            </div>
            {activeTab !== "disputed" && (
              <p
                className={classes.writeReview}
                onClick={() => 
                  openReviewModal(item)
              }
              >
                Write a Review
              </p>
            )}
            
          </div>
        )) : data?.cartItems.filter((item) => item.id === disputedOrderData.cartItemId).map((item,idx) => (
          <div key={idx} className={classes.itemContainer}>
          {activeTab !== "disputed" && (
            <div className={classes.orderDetails}>
              <h2>Expected deliver : {getFormattedDate(item?.shippingCharges?.find((date) => date.shippingSpeed === item?.shippingSpeed)?.shipByDate)}</h2>
            </div>
          )}
            
            {activeTab === "shipped" && (
              <div className={classes.subOrder}>
                {getOrderStatusOfItem(item.id) === "shipped" && (
                  <div className={getClasses(classes.status, classes.transit)}>
                    <p>In Transit</p>
                  </div>
                )}
                {getOrderStatusOfItem(item.id) === "delivered" && (
                  <div
                    className={getClasses(classes.status, classes.completed)}
                  >
                    <p className={classes.delivered}>Delivered</p>
                  </div>
                )}
                {getSubOrders(item.id)?.trackingNo && (
                  <div className={classes.trackingName}>
                    Tracking No:{" "}
                    <span>{getSubOrders(item.id)?.trackingNo}</span>
                  </div>
                )}
                {getSubOrders(item.id)?.courierName && (
                  <div className={classes.trackingName}>
                    Courier Name:{" "}
                    <span>{getSubOrders(item.id)?.courierName}</span>
                  </div>
                )}
              </div>
            )}
            <div className={getClasses(classes.orderItem,classes.orderItemDispute) }>
              <div className={getClasses(classes.bookDetailsContainer,classes.bookDetailsContainerDispute)}>
                <div className={getClasses(classes.bookDetails,classes.bookDetailsDispute)}>
                  <div className={classes.bookImage} onClick={!item?.book?.isDeleted || !item?.bestSellerBook?.isDeleted ? () => handleRedirect(item) : () => {}}>
                    <img
                      src={
                        item?.book
                          ? item?.customization?.bookThumbnail ? 
                          item?.customization?.bookThumbnail :
                           getRandomImageForNormalBook(
                              item.book,
                              allColorsData,
                              item.color,
                              item.leatherType,
                              item.variantType
                            )?.image
                          : getRandomImageForBestSellerBook(
                              item.bestSellerBook,
                              allColorsData,
                              item.color,
                              item.leatherType,
                              item.variantType
                            )?.image
                      }
                      alt=""
                    />
                  </div>
                  <div className={classes.bookData}>
                    <h2 onClick={!item?.book?.isDeleted || !item?.bestSellerBook?.isDeleted ? () => handleRedirect(item) : () => {}}>{item?.book?.title || item?.bestSellerBook?.title}</h2>
                    <h3>{getVariantType(item.variantType)}</h3>
                    <p>Qty: {item?.quantity}</p>
                    <p>
                      Ship To:{" "}
                      <span className={classes.address}>{`${
                        item?.shippingAddress?.addressLine1
                      } ${item?.shippingAddress?.addressLine2 || ""}, ${
                        item?.shippingAddress?.city
                      }, ${item?.shippingAddress?.state}, ${
                        item?.shippingAddress?.country
                      }, ${item?.shippingAddress?.zipCode}`}</span>
                    </p>
                  </div>
                </div>
                
                  <p className={classes.additionalMessage}>
                      {disputedOrderData.additionalMessage}
                  </p>
                
                
              </div>

              {idx === 0 && activeTab !=="disputed" && (
                <div className={classes.btnContainer}>
                  <PrimaryButton onClick={viewDetails}>
                    {activeTab === "shipped" ? "Track Order" : "View Order"}
                  </PrimaryButton>
                  <ButtonLight onClick={() => setDisputeModal(true)}>
                    Dispute Order
                  </ButtonLight>
                </div>
              )}
            </div>
            {activeTab !== "disputed" && (
              <p
                className={classes.writeReview}
                onClick={() => 
                  openReviewModal(item)
              }
              >
                Write a Review
              </p>
            )}
            
          </div>
        ))}
      </div>
      {disputeModal && (
        <DisputeOrderForm
          data={data}
          activeTab={activeTab}
          onClose={() => setDisputeModal(false)}
          setIsPopupOpen={setIsPopupOpen}
        />
      )}
      {isPopupOpen && (
        <Modal title={"Order Disputed "} onClose={() => setIsPopupOpen(false)} >
          <ModalBody>
            <div className={classes.warningModal}>
              <h2>Your Message is Successfully sent!</h2>
              <img src={iconsuccess} alt="Success" />
              <p>Your can check your disputed Orders in the Disputed Orders Section </p>
            </div>
          </ModalBody>
        </Modal>
      )}
      {reviewModal && <ReviewModal bookData={selectedBook} onClose={() => {setSelectedBook(null);setReviewModal(false)}} />}
    </div>
  );
};

export default OrderCard;
