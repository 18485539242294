import axios from "axios";
import { CartActions } from "../Slices/CartSlice";
import { SERVICE_URL } from "../ServiceUrls";

// Function to fetch all cart items
function getCartItems() {
    return axios({
        url: `${SERVICE_URL.CART}`,
        method: 'get',
        responseType: 'json'
    });
}

export function fetchAllCartItems(dispatch) {
    return getCartItems().then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(CartActions.updateCartItems(data.data?.cartItems));
            dispatch(CartActions.fetchCart(data.data));
        }
    }).catch(function (error) {
        console.log(error);
    });
}

// Function to add a new cart item
export function addCartItem(bookData, quantity,variant,leatherType,color,dispatch,bookCustomization) {
    let formData = new FormData();
    formData.append("bookData", JSON.stringify(bookData));
    formData.append("quantity", quantity);
    formData.append("variantType", variant);
    formData.append("leatherType", leatherType);
    formData.append("color", color);

    const customization = {
        "variantType": "deluxe",
        "leatherType": "realLeather",
        "leatherColor": "BROWN",
        "outerBorder": {
            "style": "none",
            "isCustomDesign": false,
            "customDesignImage": null
        },
        "innerBorder": {
            "style": "none",
            "isCustomDesign": false,
            "customDesignImage": null
        },
        "frontCoverTexts": [
            {
                "text": "",
                "fontFamily": "Lato",
                "fontSize": null,
                "textStyle": {
                    "isBold": false,
                    "isItalics": false,
                    "isUnderline": false
                },
                "color": "Gold",
                "isCustom": true
            }
        ],
        "frontCoverImage": {
            "image": null,
            "foilingColor": null,
            "isCustomImage": false,
            "customImage": null
        },
        "spine": {
            "ribDesign": "Design1",
            "ribDesignColor": "Gold",
            "sections": [
                {
                    "color": "#a86b00",
                    "text": {
                        "text": "",
                        "fontFamily": "Lato",
                        "fontSize": null,
                        "textStyle": {
                            "isBold": false,
                            "isItalics": false,
                            "isUnderline": false
                        },
                        "isCustom": false
                    },
                    "image": {
                        "image": null,
                        "foilingColor": null,
                        "isCustomImage": false,
                        "customImage": null
                    }
                }
            ]
        },
        "gilding": {
            "hasGilding": false
        },
        "endpaper": {
            "hadEndpaper": false
        },
        "dedication": {
            "hasDedication": false,
            "text": null,
            "image": {
                "image": null,
                "foilingColor": null,
                "isCustomImage": true,
                "customImage": null
            }
        },
        "additionalRequest": {
            "requestText": "",
            "customImage": null
        }
    }
    

    if(bookCustomization){
        formData.append("bookCustomization",JSON.stringify(bookCustomization))
    }
    
    return axios({
        url: `${SERVICE_URL.CART}/item`,
        headers: { "content-type": "multipart/form-data" },
        method: "post",
        data: formData,
        responseType: "json",
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(CartActions.addCartItemSuccess(data.data.cartItems));
            fetchAllCartItems(dispatch);
        }
        return data;
    });
}
export function addBestSellerCartItem(bookData, quantity,variant,leather,color,dispatch) {
    let formData = new FormData();
    formData.append("bookData", JSON.stringify(bookData));
    formData.append("quantity", quantity);
    formData.append("variantType", variant);
    formData.append("leatherType", leather);
    formData.append("color", color);
    
    return axios({
        url: `${SERVICE_URL.CART}/add-best-seller`,
        headers: { "content-type": "multipart/form-data" },
        method: "post",
        data: formData,
        responseType: "json",
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(CartActions.addCartItemSuccess(data.data.cartItems));
            fetchAllCartItems(dispatch);
        }
        return data;
    });
}

// Function to update a cart item
export function updateCartItem(itemId, quantity,addressId, dispatch,wantBoxWrapping = false,wantGiftWrapping = false,shippingSpeed) {
    let formData = new FormData();
    formData.append("quantity", quantity);
    if(addressId){
        formData.append("addressId", addressId ? addressId : null);
    }
    formData.append("boxWrapping", wantBoxWrapping);
    formData.append("giftWrapping", wantGiftWrapping);
    if(shippingSpeed){
        formData.append("shippingSpeed",shippingSpeed)
    }
    
    return axios({
        url: `${SERVICE_URL.CART}/item/${itemId}`,
        headers: { "content-type": "multipart/form-data" },
        method: "put",
        data: formData,
        responseType: "json",
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            fetchAllCartItems(dispatch);
        }
        return data;
    });
}

// Function to delete a cart item
export function deleteCartItem(itemId, dispatch) {
    return axios({
        url: `${SERVICE_URL.CART}/item/${itemId}`,
        method: 'delete',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            // dispatch(CartActions.removeCartItem(itemId));
            fetchAllCartItems(dispatch);
        }
        return data;
    });
}

// Function to get cart item details
export function getCartItemDetails(itemId, dispatch) {
    return axios({
        url: `${SERVICE_URL.CART}/item/${itemId}`,
        method: 'get',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(CartActions.addCartItemSuccess(data.data));
        }
        return data;
    });
}

export function applyCoupon(couponCode) {
    return axios({
        url:`${SERVICE_URL.CART}/apply-coupon?couponCode=${couponCode}`,
        method:"get",
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.error(err)
    })
}

export function applyWallet() {
    return axios({
        url:`${SERVICE_URL.CART}/use-wallet-amount`,
        method:"get",
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.error(err)
    })
}


export function removeCoupon() {
    return axios({
        url:`${SERVICE_URL.CART}/remove-coupon`,
        method:"get",
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.error(err)
    })
}

export function removeWallet() {
    return axios({
        url:`${SERVICE_URL.CART}/remove-wallet-amount`,
        method:"get",
    }).then((res) => {
        return res;
    }).catch((err) => {
        console.error(err)
    })
}