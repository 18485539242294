import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { warningActions } from "../../Store/Slices/WarningSlice";
import MessageDialogue from "../MessageDialogue/MessageDialogue";
const errorIcon = "/Assets/Icons/icon-error.svg";

function WarningDialogue() {
  const { showWarning, message } = useSelector((state) => state.warning);
  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(warningActions.removeWarning());
  };
  return (
    <>
      {showWarning && (
        <MessageDialogue
          title={"Error"}
          message={message}
          onClose={closeHandler}
          dialogueIcon={errorIcon}
        />
      )}
    </>
  );
}

export default WarningDialogue;
