import { useSelector } from "react-redux";
import { getClasses, getFormattedDate } from "../../../Util";
import classes from "./Wallet.module.css";
import TableWrapper from "../../../Components/CustTable/TableWrapper";
import CustTable from "../../../Components/CustTable/CustTable";

const walletIcon = "/Assets/Icons/wallet.svg";
const entries = [
  { name: "Referral", value: "referral" },
  { name: "Gift", value: "gift" },
  { name: "Order Placed", value: "usedInOrder" },
  { name: "Admin Deduction", value: "adminDeduction" },
];

const Wallet = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  
  const getEntryType = (entry) => {
    return entries.find((data) => data.value === entry)?.name || "";
  };

  const historyColumns = [
    {
      header: "Transaction",
      accessor: (e) => (
        <div
          className={getClasses(
            classes.transaction,
            e.entryType === "usedInOrder" || e.entryType === "adminDeduction" ? classes.debited : ""
          )}
        >
          {`${e.entryType === "usedInOrder" || e.entryType === "adminDeduction" ? "-" : "+"} ${e?.amount}`}
        </div>
      ),
    },
    {
      header: "Medium",
      accessor: (e) => getEntryType(e.entryType),
      style: { textAlign: "left" },
    },
    {
      header: "Date",
      accessor: (e) => (
        <div className={classes.dateContainer}>
          {getFormattedDate(e.time) || "-"}
        </div>
      ),
      style: { textAlign: "center" },
    },
    {
      header: "",
      accessor: (e) => (e.entryType === "usedInOrder" || e.entryType === "adminDeduction" ? "Debited" : "Credit added"),
    },
  ];

  // Sort wallet entries by date, newest first
  const sortedWalletEntries = currentUser?.walletEntries 
    ? [...currentUser.walletEntries].sort((a, b) => b.time - a.time) 
    : [];

  return (
    <div className={classes.main}>
      <div className={classes.title}>My Wallet</div>
      <div className={classes.container}>
        <div className={classes.balance}>
          <div className={classes.walletIcon}>
            <img src={walletIcon} alt="" />
          </div>
          <div className={classes.totalAvailable}>
            <div>Available Balance</div>
            <div>
              <span>$</span>
              {currentUser?.walletBalance || 0}
            </div>
          </div>
        </div>
        <div className={classes.heading}>View transaction History</div>
        <div className={classes.tableContainer}>
          <TableWrapper >
            <CustTable
              data={sortedWalletEntries}
              columns={historyColumns}
              tableClassName={classes.table}
              containerClass={classes.tableMain}
            />
          </TableWrapper>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
