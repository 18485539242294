export const urls = {
    HOME: "/",
    SIGNIN: "/sign-in",
    SIGNUP: "/signup",
    LOGIN:"/login",
    FORGOTPASSWORD:"/forgot-password",
    CONTACTUS:"/contact-us",
    ACCOUNTOVERVIEW:"/account-overview",
    USERPROFILE:"/my-account",
    FAQ:"/faq",
    LISTING:"/listing",
    BESTSELLER:"/best-seller",
    BLOG:"/blogs",
    VIEWBLOG:"/read",
    PRODUCTDETAIL:"/listing/product",
    BESTSELLERPDP:"/product",
    VIEWER: "/viewer",
    EDITOR: "/editor",
    CUSTOMIZATION:"/customization",
    SERVICES:"/services",
    BOOKRESTORATION:"/bookrestoration",
    PDFPRINTING:"/pdfprinting",
    THESISBINDING:"/thesisBinding",
    LEATHERBINDING:"/leatherbinding",
    CART:"/cart",
    OURCOMPANY:"/our-company",
    RB_EXCLUSIVE:"/rb-exclusive",
    FORPUBLISHERS:"/for-publishers",
    BECOMEPARTNER:"/become-our-partner",
    FORAUTHOR:"/for-author",
    PRINTONDEMAND:"/print-on-demand",
    CORPORATEGIFTS:"/corporate-gifts",
    CHECKOUT:"/checkout",
    CHECKOUT_THANK_YOU:"/payment-status",
    PRIVACYPOLICY:"/privacy-policy",
    LIMITEDEDITION:"/limited-edition",
    RETURNREFUNDS:"/return-refunds"
}