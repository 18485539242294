import React, { useEffect, useState } from "react";
import { getClasses } from "../../Util";
import classes from "./CustTable.module.css";
import Pagination from "./Pagination/Pagination";
import Each from "../Each/Each";

const CustTable = ({
  columns,
  data,
  isPaginated,
  pageNo,
  setPageNo,
  totalPages,
  tableClassName,
  pageSize = 20,
  isCollapse,
  isMutiCollapse,
  CollapseChild,
  collapseRowClass,
  responsiveType = "scroll",
  containerClass
}) => {
  const [activeRow, setActiveRow] = useState([]);
  
  useEffect(() => {
    if (isCollapse) {
      setActiveRow(data.map((e) => false));
    }
  }, [data, isCollapse]);

  const handlePhotoUpload = (e,id) => {
    
  }

  const handleCollapseRow = (index) => {
    if (isCollapse) {
      if (isMutiCollapse) {
        setActiveRow((prev) => prev.map((e, idx) => (idx === index ? !e : e)));
      } else {
        setActiveRow((prev) =>
          prev.map((e, idx) => (idx === index ? !e : false))
        );
      }
    }
  };

  return (
    <div className={classes.main}>
      <div className={getClasses(classes.tableContainer, containerClass)}>
        <table
          className={getClasses(
            tableClassName,
            responsiveType === "card" ? classes.cardTable : ""
          )}
        >
          <thead className={classes.thead}>
            <tr>
              <Each
                of={columns}
                render={(col) => (
                  <th
                    style={{ width: col.width, ...col.style }}
                    className={col.headingClassName}
                  >
                    {col.header}
                  </th>
                )}
              />
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              <Each
                of={data}
                render={(ele, index) => (
                  <>
                    <tr
                      className={getClasses(
                        classes.row,
                        isCollapse ? classes.collapseAbleRow : ""
                      )}
                      onClick={() => handleCollapseRow(index)}
                    >
                      <Each
                        of={columns}
                        render={(col) => (
                          <td data-cell={col.header} style={col.style}>
                            {col.accessor(
                              ele,
                              index,
                              pageNo && (pageNo - 1) * pageSize + index + 1
                            )}
                            {col.header === "mainImageUrl" && (<div className={classes.bookImageContainer}>
                              <input type="file" id="photoUpload" accept="image/*" onChange={(e) => handlePhotoUpload(e,ele.id)} title="Select Photos" className={classes.photoUpload} />
                              <label htmlFor="photoUpload" className={classes.photoUploadButton}>Upload Photo</label>
                              </div>)}
                          </td>
                        )}
                      />
                    </tr>
                    {isCollapse && activeRow[index] && CollapseChild && (
                      <tr className={collapseRowClass}>
                        <td
                          colSpan={columns?.length}
                          className={classes.collapseCell}
                        >
                          <CollapseChild data={ele} index={index} />
                        </td>
                      </tr>
                    )}
                  </>
                )}
              />
            ) : (<></>
              // <tr className={classes.errorRow}>
              //   <td colSpan={columns?.length || 1}>No data found.</td>
              // </tr>
            )}
          </tbody>
        </table>
      </div>
      {isPaginated && pageNo >= 0 && totalPages >= 0 && data?.length > 0 && data?.length >= pageSize   && (
        <Pagination
          totalPages={totalPages}
          currentPage={pageNo}
          setCurrentPage={setPageNo}
        />
      )}
    </div>
  );
};

export default CustTable;
