import { createSlice } from "@reduxjs/toolkit";

const WishlistSlice = createSlice({
    name: "wishlist",
    initialState: {
        wishlistItems: [],
        loading: false,
        error: null,
    },
    reducers: {
        addWishlistItemRequest(state) {
            state.loading = true;
            state.error = null;
        },
        addWishlistItemSuccess(state, action) {
            state.loading = false;
            state.wishlistItems = action.payload;
        },
        addWishlistItemFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateWishlistItems(state, action) {
            state.wishlistItems = action.payload;
        },
        removeWishlistItem(state, action) {
            state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
        },
    }
});

export const WishlistActions = WishlistSlice.actions;
export default WishlistSlice.reducer;
