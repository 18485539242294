import classes from "./OrderSummary.module.css"

import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton"

const OrderSummary = ({ disabled, onClick}) => {
    const {cartItems, cart} = useSelector(state => state.cart);

    const updatedCartItems = cartItems?.filter((item) => item?.book?.isDeleted === false || item?.bestSellerBook?.isDeleted === false);

    const getShippingPrice = () => {
        let shippingSpeed = 0;
        updatedCartItems?.forEach(ele => {
          const price = ele.shippingCharges.find((e) => e.shippingSpeed === ele.shippingSpeed)?.totalAmount;
           if(price){
              shippingSpeed += price
           }
        });

        return shippingSpeed;
    }

    const subTotal = updatedCartItems?.reduce((acc, ele) => {
        return acc + ((ele.itemPrice + ele.customizationCharges) * ele.quantity)
    },0)

    const boxWrappingPrice = updatedCartItems?.reduce((acc, ele) => {
        let boxWrappingPrice = ele.boxWrapping ? 15 : 0;
        return acc + (boxWrappingPrice)
    },0);

    const giftWrappingPrice = updatedCartItems?.reduce((acc, ele) => {
        let giftWrappingPrice = ele.giftWrapping ? 15 : 0;
        return acc + (giftWrappingPrice)
    },0);
    
    const getTotal = () => {
        const itemTotal = updatedCartItems?.reduce((acc, ele) => {
            return acc + ((ele.itemPrice + ele.customizationCharges) * ele.quantity  )
        },0);

        let totalWithDiscount = itemTotal - cart.totalDiscount;
        if(totalWithDiscount <= 0){
            return Number(boxWrappingPrice + giftWrappingPrice + getShippingPrice()).toFixed(2)
        } else {
            return Number(totalWithDiscount + boxWrappingPrice + giftWrappingPrice + getShippingPrice()).toFixed(2)
        }
    }

    return(
        <div className={classes.mainContainer}>
            <h1>Order Summary</h1>

            <div className={classes.summarySection}>
                <div className={classes.overview}>
                    <h2>{cartItems?.length} Total {cartItems?.length === 1 ? "Item" : "Items"}</h2>
                    {/* <p>$1234.00</p> */}
                </div>

                {/* <div className={classes.addOn}>
                    <h2 className={classes.addOnHeader}>Add on</h2>
                    <OptionButton option={"Box Wrapping"} price={15} />
                    <OptionButton option={"Gift Wrapping"} price={15} />
                </div> */}

                <div className={classes.priceSection}>
                    <div className={classes.subTotal}>
                        <h2>Sub-total</h2>
                        <p>${(subTotal).toFixed(2)}</p>
                    </div>
                    {boxWrappingPrice > 0  ? <div className={classes.shipping}>
                        <h2>Box-Wrapping</h2>
                        <p>${Number(boxWrappingPrice).toFixed(2)}</p>
                    </div> : ""}
                    {giftWrappingPrice > 0  ? <div className={classes.shipping}>
                        <h2>Gift-Wrapping</h2>
                        <p>${Number(giftWrappingPrice).toFixed(2)}</p>
                    </div> : ""}
                    <div className={classes.shipping}>
                        <h2>Shipping</h2>
                        <p>${Number(getShippingPrice()).toFixed(2)}</p>
                    </div>
                    <div className={classes.Discount}>
                        <h2>Discount</h2>
                        <p>${Number(cart.totalDiscount).toFixed(2)}</p>
                    </div>
                </div>

                <div className={classes.total}>
                    <h2>Total</h2>
                    <p>${getTotal()}</p>
                </div> 

                {/* <div className={classes.couponSection}>
                    <h2>Have a coupon?</h2>
                    <p>Add your code for an instant cart discount</p>

                    <div className={classes.couponButton}>
                        <div>
                            <img src={couponIcon} alt="" />
                            <input type="text" name="" id="" placeholder="Coupon Code" />
                        </div>
                        <button>Apply</button>
                    </div>


                </div>  */}

            </div>  
                    <PrimaryButton disabled={disabled} onClick={onClick}>
                        Proceed to Payment <span style={{ marginLeft: '8px' }}>&#10145;</span>
                    </PrimaryButton>
        </div>

    )
}

export default  OrderSummary