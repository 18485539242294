import React, { useContext, useEffect } from "react";
import { FabricContainer, useFabricCanvas, useFabricCanvasHandler } from "react-fabric-page";
import classes from "./Editor.module.css";
import ProductDetails from "../Customization/Components/ProductDetails/ProductDetails";
import { FabricCanvas } from "./FabricCanvas";
import { getClasses } from "../../Util";
import useScreenSize from "../../Hooks/UseScreenSize";
import SwipeableEdgeDrawer from "../Customization/Components/SwipeableDrawer/swipableEdgeDrawer";
import ProductDetailSmall from "../Customization/Components/ProductDetailSmall/ProductDetailSmall";
import { CustomizationContext } from "../Customization/Customization";

function Editor({ editor, setEditor,Component, onNext, onPrev, page, visibility,drawerOpen,setDrawerOpen,currentStep,SecondaryComponent,visibilitySecondary}) {
    const {numberOfSections,activeSectionNumber, setActiveSection,bookData,bookLeather,bookVariant,steps} = useContext(CustomizationContext);

    let canvas = useFabricCanvas()

    useEffect(() => {
        window.canvasEditor = editor
    },[editor])

    useFabricCanvasHandler(canvas => ({
        "canvas:x-ready": () =>{
            canvas.isReady = true
        }
    }));

    const handleSectionClick = (index) => {
        if( activeSectionNumber !== index+1 ) {
            setActiveSection(index+1)
        } else if(index + 1 === activeSectionNumber) {
            setActiveSection(-1)
        }
    }

    const {isMediumScreen} = useScreenSize()
    return (
        <FabricContainer value={editor}>
            <div className={getClasses(classes.container, !visibility ? classes.inVisible : "")}>
                <div className={classes.mainContainer}>
                    <div className={!isMediumScreen ? classes.editorOption : classes.editorOption}>
                        {isMediumScreen && <SwipeableEdgeDrawer Component={Component} SecondaryComponent={SecondaryComponent} visibilitySecondary={visibilitySecondary} onNext={onNext} onPrev={onPrev} activeSectionNumber={activeSectionNumber} numberOfSections={numberOfSections} setActiveSection={setActiveSection} visibility={visibility} open={drawerOpen} setOpen={setDrawerOpen}  currentStep={currentStep}/>}
                        <div className={visibilitySecondary ? classes.inVisible : ""}>
                            {!isMediumScreen && <Component onNext={onNext} onPrev={onPrev} activeSectionNumber={activeSectionNumber} numberOfSections={numberOfSections} setActiveSection={setActiveSection}/>}
                        </div>
                        <div className={!visibilitySecondary ? classes.inVisible : ""}>
                            {!isMediumScreen && SecondaryComponent && <SecondaryComponent onNext={onNext} onPrev={onPrev} activeSectionNumber={activeSectionNumber} numberOfSections={numberOfSections} setActiveSection={setActiveSection}/>}
                        </div>
                    </div>
                    <div className={classes.editorSection}>
                        {isMediumScreen && <div className={classes.bookDetailsSmall}>
                            <ProductDetailSmall />
                        </div>}
                        <div className={`${classes.bookSection} ${visibilitySecondary ? classes.disabledCanvas : ""}`}>
                            {currentStep === 2 && (
                                <div className={classes.spineSections}>
                                    {Array.from({ length: numberOfSections }, (_, index) => (
                                        <div key={index} className={activeSectionNumber === index+1 ? classes.activeSection : classes.sections} onClick={() => handleSectionClick(index)}>Section {index + 1}</div>
                                    ))}
                                </div>
                            )}
                            <FabricCanvas onShow={setEditor} page={page} onPageMount={e => {
                                                editor.urStack = [];
	                                            editor.currentStep = -1;
                                                editor.saveCurrentState();
                                            }} />
                        </div>
                        {!isMediumScreen && <div className={classes.bookDetails}>
                            <ProductDetails leatherType={bookLeather} variantType={bookVariant} bookData={bookData} />
                        </div>}
                    </div>
                </div>
            </div>
        </FabricContainer>
    );
}

export default Editor;
