import classes from "./CustomizationSteps.module.css"

const book = "/Assets/Images/book2.svg"
const book2 = "/Assets/Images/bookStep3.svg"
const book3 = "/Assets/Images/bookStep4.svg"

const stepData = [
    {
        steps:"01",
        image:book,
        title:"Choose Material",
        desc:"Your journey into the world of customization begins with the choice between our Deluxe and Super Deluxe variants. Both offer exceptional craftsmanship, but the Super Deluxe variant takes luxury to new heights with additional features."
    },
    {
        steps:"02",
        image:book,
        title:"Customize FrontCover",
        desc:"You can select leather color, outer and inner border from  captivating array of options. You can add text and logo and you can upload your own font and design as per your choice ! "
    },
    {
        steps:"03",
        image:book2,
        title:"Customize Spine",
        desc:"Make your mark on your book by adding custom texts on the spine .Whether it's your name, a meaningful quote, or a special date, this personalization adds a touch of significance to your literary treasure."
    },
    {
        steps:"04",
        image:book3,
        title:"Customize Paper Gold Edges",
        desc:"The finishing touch that speaks volumes - gilded pages. Transform your book into a work of art with the shimmering elegance of gold-edged pages."
    },
    {
        steps:"05",
        image:book,
        title:"Customize Endpaper ",
        desc:"Every detail matters, including the endpapers. Select from a range of endpaper options to complement your book's theme and style."
    },
]

const CustomizationSteps = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <h2>How to customize book ?</h2>
                <p>Craft Your Perfect Book: Customizable Front Covers, Spines, Endpapers, and Gilding</p>
        </div>

        <div className={classes.stepsSection}>
            {stepData.map((steps,index) => (
                <div key={index} className={classes.stepsCard}>
                    <div className={classes.stepDetails}>
                        <h3>STEP {steps.steps}</h3>
                        <h2>{steps.title}</h2>   
                        <p>{steps.desc}</p>                   
                    </div>
                    <div className={classes.stepImage}>
                        <img src={steps.image} alt="" />
                    </div>
                </div>
            ))}
        </div>

        
        </div>
    )
}

export default CustomizationSteps