import React from "react";
import classes from "./ExploreAuthors.module.css"

import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";

const ExploreAuthors = () => {
  const history = useHistory()
  return (
    <div className={classes.ctaContainer}>
      <div className={classes.textContent}>
        <h2>Can't Find Your Book? Let Us Help!</h2>
        <p>Discover Your Book with Our Guided Search and search-in-search feature.</p>
      </div>
      <div className={classes.ctaButton}>
        <SecondaryButton onClick={() => history.push(urls.LISTING)}>Explore More Authors</SecondaryButton>
      </div>
    </div>
  );
};

export default ExploreAuthors
