import React from "react";
import classes from "./Community.module.css"
import SearchComponent from "../SearchComponent/SearchComponent";

const Community = ({
  query,
  setQuery,
  onSearch,
  minYear,maxYear,
  selectedGenres
}) => {
  return (
    <div className={classes.ctaContainer}>
      <div className={classes.textContent}>
        <h2>One of the largest book databases with 15 million plus books.</h2>
        <p>Find your next book here</p>
      </div>
      <div className={classes.ctaButton}>
        <SearchComponent query={query} setQuery={setQuery} onSearch={onSearch} minYear = {minYear} maxYear = {maxYear} selectedGenres={selectedGenres}/>
      </div>
    </div>
  );
};

export default Community;
