import axios from "axios";
import { SERVICE_URL } from "../ServiceUrls";
import { bookReviewActions } from "../Slices/BookReviewSlice";

export function getBookReviews(bookId,dispatch) {
    return axios({
        url:`${SERVICE_URL.BOOKREVIEWS}/${bookId}`,
        method:"get"
    }).then((res) => {
        if(res.data.success){
            dispatch(bookReviewActions.getReviews(res.data.data))
        }
    }).catch(err => {
        console.error(err)
    })
}

export function addBookReview(bookData,dispatch){
    return axios({
        url:`${SERVICE_URL.BOOKREVIEWS}/addReview`,
        method:"post",
        data:bookData
    }).then(res => {
        if(res.data.success){
            dispatch(bookReviewActions.addReviews(res.data.data))
        }
    }).catch(err => {
        console.error(err)
    })
}