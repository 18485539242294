import React, { useEffect, useState } from 'react';
import classes from "./EnvelopeCard.module.css"
import { getClasses } from '../../../../Util';


const EnvelopeCard = ({onClick,isOpen}) => {
const [buttonClicked, setButtonClicked] = useState(false);

const envelopOpenAndButton = () => {
  handleButtonClick()
  onClick()
}

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
      }, 2000); // Adjust this timeout to match the envelope opening animation duration
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleButtonClick = () => {
    setButtonClicked(true);
    setTimeout(() => {
      setButtonClicked(false);
    }, 2000); // Adjust this timeout to match the wobble animation duration
  };

  return (
    <div className={classes.envelopeWrapper}  onClick={envelopOpenAndButton}>
    <div className={getClasses(classes.envelope, isOpen ? classes.open : classes.close)}>
        <div className={getClasses(classes.front, classes.flap)} onClick={onClick}></div>
        <div className={getClasses(classes.front, classes.leftFlap)} />
        <div className={getClasses(classes.front, classes.rightFlap)} />
        <div className={getClasses(classes.front, classes.pocket)}></div>
    
        <div className={classes.letter}>
            <div className={getClasses(classes.words, classes.line1)}></div>
            <div className={getClasses(classes.words, classes.line2)}></div>
            <div className={getClasses(classes.words, classes.line3)}></div>
            <div className={getClasses(classes.words, classes.line4)}></div>
        </div>
    </div>
      <div className={getClasses(classes.btnContainer, buttonClicked ? classes.animateBtn : '')} onClick={handleButtonClick}>
        <div className={classes.backgroundBtn}>
          <button className={classes.RBBtn} onClick={onClick}>RB</button>
        </div>
      </div>
</div>
  );
};

export default EnvelopeCard;
