import classes from "./WhyUs.module.css"

const image1 = "/Assets/Images/customization/HistoricalImportance.png"
const image2 = "/Assets/Images/customization/EnduringQuality.png"
const image3 = "/Assets/Images/customization/craftmenship.png"

const cardData = [
    {
        title: "Revive the Tale, Preserve the Scale",
        desc: "Leather-bound books have a timeless grace. Restoring them brings back their historical charm, they bring out a sense of joy in your antique book while maintaining their old significance. ",
        image: image1
    },
    {
        title: "Durable Delight",
        desc: "In addition to offering durability and elegance, leather-bound book covers shield your books from ordinary use and maintain their perfect state.",
        image: image2
    },
    {
        title: "Exclusive Craftsmanship",
        desc: "Customize your old book restoration to your exact specifications. Choose from a wide range of leather options, colors, and accessories to create a genuinely remarkable and one-of-a-kind piece.",
        image: image3
    },
]

const WhyUs = () => {
    return (
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>Why do you need Leather-bound book restoration?
                </h2>
                <p>If you're a book enthusiast, your collection is a priceless store of knowledge, tales, and memories rather than just a stack of pages.

                    Even the best-preserved books may eventually have wear indicators such as weakened bindings, fading covers, and broken spines.

                    Investing in leather-bound book restoration is an act of love for your books, preserving their legacy and allowing them to continue being a source of inspiration, learning, and joy. Therefore get them restored from us today.
                </p>
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card, index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default WhyUs