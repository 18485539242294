
import packageJson from '../../package.json';

const homepage = packageJson.homepage ? `/${packageJson.homepage}` : ''

const Consts = {
    SENT_FOR_REVEIW: "sent_for_review",
    IN_PROGRESS: "in_progress",
    APPROVE: 'approve',
    MESSAAGE_DIALOG_WIDTH: '19rem',
    APP_VERSION: '1.0.0.11',
    BASE_URL: homepage,
    SERVER_URL: "/api"
}
export default Consts;