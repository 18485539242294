import React, { useEffect, useState } from 'react';
import classes from "./SearchComponent.module.css"
import Input from '../../../Components/Inputs/Input/Input';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchBook } from '../../../Store/actions/BestSellerBookAction';
const SearchIcon = "/Assets/Icons/icon-search.svg"

const SearchComponent = ({query, onChange,setQuery,minYear,maxYear,selectedGenres,allowedMinPublishingYear,allowedMaxPublishingYear, onSearch}) => {

  const handleSearchOnKeyPress = (e) => {
    if(e.key === "Enter"){
      onSearch();
    } else {
      const disallowedCharacters = ["(", "{", "[","]","}",")"];
      if (disallowedCharacters.includes(e.key)) {
        e.preventDefault();
      }
    }
  }

  const handleInputPaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const sanitizedText = pastedText.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
    e.preventDefault(); // Prevent the default paste action
    onChange({ target: { value: query + sanitizedText } }); // Append sanitized text to the current query
  };
  

  const [allRecommendations,setAllRecommendations] = useState([]);

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const handleRecommendationClick = (recommendation) => {
    const words = query.trim().split(" ");
    
    if (words.length > 1) {
      words[words.length - 1] = recommendation;
      setQuery(words.join(" "));
    } else {
      setQuery(recommendation);
    }
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };

  
  const getFilter = () => {
    let filter = {
      limit: 10,
      offset:0,
      where: {
        $and: [],
      },
    };

    if (query) {
        filter.where.$and.push({
          $or: [
            { author: `*${query}*` },
            { title: `*${query}*` },
            { language: `*${query}*` },
          ],
        });
      }

    if (
      (minYear || maxYear) &&
      !(
        minYear === allowedMinPublishingYear &&
        maxYear === allowedMaxPublishingYear
      )
    ) {
      const publishingYearCondition = {
        publishingYear: `[${
          minYear === allowedMinPublishingYear
            ? "*"
            : minYear || "*"
        } TO ${
          maxYear === allowedMaxPublishingYear
            ? "*"
            : maxYear || "*"
        }]`,
      };
      filter.where.$and.push(publishingYearCondition);
    }

    if (selectedGenres?.length > 0) {
      filter.where.$and.push({ genres: selectedGenres.join(",") });
    }
    return filter;
  };

  function getSet(obj) {
    return Array.from(
      Object.keys(obj).reduce((prev, curr) => {
        obj[curr].forEach(e => {
          prev.add(e.value);
        });
        return prev;
      }, new Set())
    );
  }

  
  useEffect(() => {
    if(recommendationClicked) {
      onSearch();
      setRecommendationClicked(false);
    }
  },[recommendationClicked])
  
  useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => {
        fetchPageData();
      },500);
    
       return () => clearTimeout(timeout);
    }
  }, [query]);
  
  useEffect(() => {
    if (query) {
      const words = query.trim().split(/\s+/);
      setSearchRecommedations(
        allRecommendations
      );
    } else {
      setSearchRecommedations([]);
    }
  }, [query, allRecommendations]);

  const handleFocus = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout); 
    }
    setFocus(true);
  };

  const handleBlur = () => {
    const timeout = setTimeout(() => setFocus(false), 1000);
    setBlurTimeout(timeout);
  };

  const fetchPageData = async () => {
    if (query !== "") {
      const filter = getFilter()
        const data = await fetchBook(
         null,
         filter
        );

        const filteredRecommendations = data?.data?.map((book) => {
          if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
            return book.author;
          } else if (book?.title?.toLowerCase().includes(query.toLowerCase())) {
            return book.title
          }
          return null;
        }).filter((item) => item !== null); // Remove null values
    
        setAllRecommendations(filteredRecommendations);
      }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchContainer}>
        <img className={classes.searchIcon} src={SearchIcon} alt="" />
        <Input
          type="text"
          className={classes.searchInput}
          placeholder="Search via Author, Book name"
          value={query}
          onChange={onChange}
          onKeyDown={handleSearchOnKeyPress}
          onFocus={handleFocus}  
          onBlur={handleBlur} 
          onPaste={handleInputPaste}
        />
        <button className={classes.searchButton} onClick={onSearch}>
          Search Now
        </button>
      </div>
      {/* Dropdown with Recommendations */}
      {searchRecommendations.length > 0 && focus && (
        <div className={classes.dropdown}>
        {searchRecommendations.slice(0,10).map((recommendation, index) => (
          <div
            key={index}
            className={classes.dropdownItem}
            onClick={() => handleRecommendationClick(recommendation)}
          >
            <FontAwesomeIcon icon={faSearchengin}/>
            {recommendation?.length > 70
                ? `${recommendation.slice(0, 70)}...`
                : recommendation}
          </div>
        ))}
        </div>
      )}

    </div>
  );
};

export default SearchComponent;
