import { useState } from "react";
import classes from "./Sidebar.module.css";
import Dropdown from "./Dropdown/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faBurger,
  faHamburger,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "./Slider/Slider";

const Sidebar = ({allGenres,allowedMaxYear,allowedMinYear, maxYear, setMaxYear, minYear, setMinYear, selectedGenres, setSelectedGenres, sidebarTop }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className={classes.sidebarWrapper}>
      <button
        // style={{ top: `${sidebarTop}px` }}
        className={`${classes.hamburger} ${isOpen ? classes.open : ""}`}
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div
      //  style={{ top: `${sidebarTop}px` }}
        className={`${classes.sidebarContainer} ${isOpen ? classes.open : ""}`}
      >
        <h2>Filter By</h2>
        <div className={classes.authors}>
          <Slider
            max={maxYear}
            setMax={setMaxYear}
            min={minYear}
            setMin={setMinYear}
            title={"Publishing Year"}
            allowedMin={allowedMinYear}
            allowedMax={allowedMaxYear}
          />
        </div>
        <div className={classes.authors}>
          <Dropdown selectedOptions={selectedGenres} setSelectedOptions={setSelectedGenres} categoryData={allGenres} title={"Category/Genres"} />
        </div>
        {/* <div className={classes.authors}>
                    <Slider max={maxPrice} setMax={setMaxPrice} min={minPrice} setMin={setMinPrice} title={"Price"} />
                </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
