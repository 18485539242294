import classes from "./Checkbox.module.css";
import { getClasses } from "../../Util";


const Checkbox = ({
  id,
  checked,
  label,
  className = "",
  style,
  onChange,
  disabled
}) => {
  return (
    <div className={getClasses(classes.checkbox, className, disabled ? classes.disabled : "")} style={style}>
      <input
        type='checkbox'
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

export default Checkbox;
