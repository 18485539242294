import classes from "./AboutLeatherBinding.module.css"

const image1 = "/Assets/Images/customization/LeatherMatrerialIcon.png"
const image2 = "/Assets/Images/customization/handcraftedExcellence.png"
const image3 = "/Assets/Images/customization/customization.png"

const cardData = [
    {
        title:"Dedicated to Tradition",
        desc:"Despite the development of automation, we are devoted to the artistic abilities of our master craftsmen, using traditional methods that have been passed down through the years.",
        image:image1
    },
    {
        title:"Exquisite Handiwork",
        desc:"Our leather binding procedure is a labor of love that involves hand-stitching each page and cover with precision and selecting only the best leather skins.",
        image:image2
    },
    {
        title:"Excellent Handmade Work",
        desc:"We choose the human touch over automation because we think that true character and charm can only be achieved via individual talent and craftsmanship when producing leather-bound books.",
        image:image3
    },
    {
        title:"Artisan Enthusiasm",
        desc:"Our skilled craftsmen carefully and passionately create each book, creating unique works of art that are a reflection of both quality and uniqueness.",
        image:image3
    },
    {
        title:"Unwavering Tradition",
        desc:"Each leather-bound book produced at Rare Biblio is a tribute to our timeless workmanship and a piece of beauty, thanks to our constant commitment to traditional bookbinding processes.",
        image:image3
    },
]

const AboutLeatherBinding = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>What Sets Us Apart?
                                    </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div className={classes.card} key={index}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutLeatherBinding