import React, { useEffect, useState } from "react";
import Review from "./Review/Review";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import classes from "./Reviews.module.css";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import ReviewModal from "../../../Components/ReviewModal/ReviewModal";
import { useDispatch, useSelector } from "react-redux";
import { getBookReviews } from "../../../Store/actions/BookReviewAction";

const Reviews = ({ bookData }) => {
  const dispatch = useDispatch();
  const bookReviews = useSelector((state) => state?.bookReviews?.reviews);
  const [open, setOpen] = useState(false);
  const [averageRating,setAverageRating] = useState(0)
  
  const calculateAverageRating = () => {
    let totalRating = 0;
    let totalCount = 0;
    bookReviews?.forEach((reviewData) => {
      if (reviewData?.review?.rating) {
        totalRating = totalRating + reviewData?.review?.rating;
        totalCount++;
      }
    });

    if (totalCount !== 0) {
     setAverageRating((totalRating / totalCount)?.toFixed(1));
    } else {
      setAverageRating(0)
    }
  };
  
  useEffect(() => {
    getBookReviews(bookData?.id, dispatch);
  }, [bookData]);

  useEffect(() => {
    calculateAverageRating()
  },[bookReviews])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.reviewsContainer}>
      <div className={classes.header}>
        <div className={classes.headerDiv}>
          <h1 className={classes.ratingValue}>
            {averageRating || "Write first review"}
          </h1>
          <div className={classes.details}>
            <Stack spacing={1}>
              <Rating
                name="rating"
                value={averageRating}
                precision={0.5}
                readOnly
                sx={{
                  "& .MuiRating-iconFilled": { color: "#FCC418" }, // Color for filled stars
                  "& .MuiRating-iconEmpty": { color: "gray" }, // Color for empty stars
                }}
              />
            </Stack>

            <p className={classes.reviewCount}>
              Based on {bookReviews?.length} reviews
            </p>
          </div>
        </div>
        <SecondaryButton className={classes.reviewBtn} onClick={handleOpen}>
          Write a Review
        </SecondaryButton>
      </div>
      {bookReviews?.map((review, index) => (
        <Review key={index} review={review} />
      ))}

      {open && (
        <div className={classes.backdrop} onClick={handleClose}>
          <div
            className={classes.modalContent}
            tabIndex="-1"
            onClick={(e) => e.stopPropagation()}
          >
            <ReviewModal bookData={bookData} onClose={handleClose} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Reviews;
