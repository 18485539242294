import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allBlogs: [],
    paginatedBlogs:[],
    categories:[]
}

const blogsSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
        fetchAllBlog: (state, action) => {
            state.allBlogs = action.payload
        },
        fetchAllPaginatedBlogs:(state,action) => {
            state.paginatedBlogs = action.payload
        },
        fetchAllCategories: (state,action) => {
            state.categories = action.payload
        },
        addNewBlog: (state, action) => {
            state.allBlogs.unshift(action.payload.blogs)
        },
        deleteBlog: (state, action) => {
            state.allBlogs = state.allBlogs?.filter(blog => blog.id !== action.payload?.id)
        },
        updateBlog: (state, action) => {
            const updatedBlog = action.payload.blogs;
            const idx = state.allBlogs?.findIndex(blog => blog.id === updatedBlog.id)
            if (idx > -1) {
                state.allBlogs[idx] = updatedBlog;
            }
        }

    }
});

export const blogsAction = blogsSlice.actions;
export default blogsSlice.reducer;