import classes from "./AddText.module.css"
import { useState,useEffect, useContext } from "react";

import { useFabricCanvas } from "react-fabric-page";
import { CustomizationContext } from "../../../Customization";
import ColorSelector from "../../../Components/ColorSelector/ColorSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignCenter, faAlignLeft, faAlignRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
const dropdownIcon = "/Assets/Icons/caret-down-solid.svg"
  

const AddText = () => {

  const {dedicationTextColor,setDedicationTextColor,
    dedicationFontFamily,setDedicationFontFamily,
    dedicationFontStyle,setDedicationFontStyle,fontData,allTextColors,setDedicationText,dedicationTextAlign,setDedicationTextAlign,dedicationFontSize,setDedicationFontSize} = useContext(CustomizationContext);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [colors,setColors] = useState([]);
    const [tempFontSize,setTempFontSize] = useState(dedicationFontSize);

    let canvas = useFabricCanvas()
  
    useEffect(() => {
      setColors(allTextColors)
    },[allTextColors])

    useEffect(() => {
      setDedicationTextColor(colors?.[0])
    },[colors])

    useEffect(() => {
      const handleTextChange = () => {
        const contentsLayer = canvas.getObjects().find(o => o.id === "contents");
        
        if (contentsLayer) {
          const textBox = contentsLayer.getObjects().find(o => o.type === "textArea");
          
          if (textBox) {
            setDedicationText(textBox.text);
          }
        }

        canvas.renderAll()
      };
    
      canvas.on('text:changed', handleTextChange);
    
      return () => {
        canvas.off('text:changed', handleTextChange);
      };
    }, [canvas]);
    

      const handleColorChange = (color) => {
        setDedicationTextColor(color);
      };
    
    
      const handleFontChange = (font) => {
        setDedicationFontFamily(font);
        setIsDropdownOpen(false);
      };

      const handleTextStyleChange = (style) => {
        setDedicationFontStyle((prevStyle) => {
          const newStyle = { ...prevStyle };
      
          switch (style) {
            case 'italic':
              newStyle.fontStyle = newStyle.fontStyle === 'italic' ? 'normal' : 'italic';
              break;
            case 'underline':
              newStyle.underline = !newStyle.underline;
              break;
            default:
              break;
          }
      
          return newStyle;
        });
      };

      const handleDecrease = () => {
        setTempFontSize(prevFontSize => Math.max(6, prevFontSize - 1));
        setDedicationFontSize(prevFontSize => Math.max(6, prevFontSize - 1));
      };
      
      const handleIncrease = () => {
        setTempFontSize(prevFontSize => Math.min(26, prevFontSize + 1));
        setDedicationFontSize(prevFontSize => Math.min(26, prevFontSize + 1));
      };
      
      const handleFontSizeChange = (e) => {
        const value = Number(e.target.value);
        if(value <= 26){
          setTempFontSize(value);
        }  else if(value >= 6 ){
          setTempFontSize(26)
        }
        // handleInputDebouncedChange(maxValue, false)
      };
    
      const handleClick = (e) => {
        if(e.key === "Enter"){
          if(tempFontSize >= 6 && tempFontSize <= 26) {
              setDedicationFontSize(tempFontSize)
          } else {
              setTempFontSize(6);
              setDedicationFontSize(6);
          }
       }
      }

  
    return (
      <div className={classes.mainContainer}>
        <div
          className={classes.colorPickerDropdown}
          onClick={(e) => e.stopPropagation()}
        >
          <h3>Select font for front cover</h3>

          <div className={classes.selectColor}>
            <h2>Font family: {dedicationFontFamily?.fontFamily}</h2>
            <div className={classes.customDropdown}>
              <div
                className={classes.selectedOption}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <div className={classes.dropdownBar}>
                  <div className={classes.dropdownText}>
                    <span>{dedicationFontFamily?.fontFamily}</span>
                    <span>
                      {dedicationFontFamily?.fontPrice > 0 &&
                        `+$${dedicationFontFamily?.fontPrice}`}
                    </span>
                  </div>
                  <div className={classes.dropdownIcon}>
                    <img src={dropdownIcon} alt="" />
                  </div>
                </div>
              </div>
              {isDropdownOpen && (
                <div className={classes.dropdownOptions}>
                  {fontData?.map((font) => (
                    <div
                      key={font?.fontFamily}
                      className={classes.option}
                      onClick={() => handleFontChange(font)}
                    >
                      <span>{font?.fontFamily}</span>
                      <span>
                        {font?.fontPrice > 0 && `+$${font?.fontPrice}`}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={classes.textStylingOptions}>
                <h3>Select Text Styling</h3>
                <div className={classes.fontStyleDiv}>
                  <div className={classes.stylingButtons}>
                    <button
                      className={`${classes.stylingButton} ${
                        dedicationFontStyle?.fontStyle === "italic"
                          ? classes.activeStylingButton
                          : ""
                      }`}
                      onClick={() => handleTextStyleChange("italic")}
                    >
                      <i>I</i>
                    </button>
                    <button
                      className={`${classes.stylingButton} ${
                        dedicationFontStyle?.underline === true
                          ? classes.activeStylingButton
                          : ""
                      }`}
                      onClick={() => handleTextStyleChange("underline")}
                    >
                      <u>U</u>
                    </button>
                  </div>

                  <div className={classes.fontSizeOptions}>
                    <FontAwesomeIcon icon={faMinus} style={{cursor:"pointer"}} onClick={handleDecrease}/>
                    <input type="number" max={26} min={6} name="" id="" value={tempFontSize} onKeyDown={handleClick} onChange={handleFontSizeChange} />
                    <FontAwesomeIcon icon={faPlus} style={{cursor:"pointer"}} onClick={handleIncrease}/>
                  </div>
                </div>
              </div>

          <div className={classes.textStylingOptions}>
            <h3>Text Align</h3>
            <div className={classes.stylingButtons}>
              <button
                className={`${classes.stylingButton} ${
                  dedicationTextAlign === "left"
                    ? classes.activeStylingButton
                    : ""
                }`}
                onClick={() => setDedicationTextAlign("left")}
              >
                <FontAwesomeIcon icon={faAlignLeft} />
              </button>
              <button
                className={`${classes.stylingButton} ${
                  dedicationTextAlign === "center"
                    ? classes.activeStylingButton
                    : ""
                }`}
                onClick={() => setDedicationTextAlign("center")}
              >
                <FontAwesomeIcon icon={faAlignCenter} />
              </button>
              <button
                className={`${classes.stylingButton} ${
                  dedicationTextAlign === "right"
                    ? classes.activeStylingButton
                    : ""
                }`}
                onClick={() => setDedicationTextAlign("right")}
              >
                <FontAwesomeIcon icon={faAlignRight} />
              </button>
            </div>
          </div>

          <ColorSelector
            availableColors={colors}
            selectedColor={dedicationTextColor}
            handleColorChange={handleColorChange}
          />
        </div>
      </div>
    );
}

export default AddText