import React from "react";
import styles from "./FeatureCard.module.css";

import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import history from "../../../../history";
import { urls } from "../../../../Routes/Urls";

const FeatureCard = ({ image, title, btnText, description, altText }) => {
  return (
    <div className={styles.banner} style={{ backgroundImage: `url(${image})` } } aria-label={altText}>
      <img src={image} alt={altText} style={{ display: "none" }} />{" "}
      {/* Hidden but accessible */}
      <div className={styles.overlay}>
        <div className={styles.textContainer}>
          <p className={styles.subHeading}>WELCOME TO RARE BIBLIO</p>
          <h1 className={styles.heading}>{title}</h1>
          <p className={styles.description}>{description}</p>
          <SecondaryButton
            className={styles.button}
            onClick={() => history.push(urls.LISTING)}
          >
            {btnText}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
