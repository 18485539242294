import classes from "./CompanyValues.module.css"

const image1 = "/Assets/Images/ServicePages/AboutUsVision.png"
const image2 = "/Assets/Images/ServicePages/AboutUsMission.png"
const image3 = "/Assets/Images/ServicePages/AboutUsValues.png"

const stepData = [
    {
        image:image1,
        title:"Vision",
        desc:"Our vision is to be the go-to destination for bibliophiles and book lovers in search of meticulously handcrafted rare books, vintage books, and personalized leather-bound books Our unwavering commitment is to safeguard and elevate the profound passion for literature while fostering a vibrant community of book collectors."
    },
    {
        image:image2,
        title:"Mission",
        desc:"At Rare Biblio, we are on a mission to resurrect the lost art of leather binding and preserve the world of literature. In an era dominated by mass production and digital media, we value nostalgia and tradition, and we are dedicated to preserving the beauty and authenticity of leather-bound books. We respect the importance of preserving rare books for future generations. Our goal is to provide exceptional rare and vintage books, as well as create personalized, luxurious leather-bound editions. Join us in honoring the legacy of literature and making every book a unique experience."
    },
    {
        image:image3,
        title:"Value",
        desc:"At Rare Biblio, we hold dear the values that guide our mission. We're passionate about literary excellence, preserving literary heritage, and offering personalized, handcrafted leather-bound books. We foster a vibrant community, curate a unique selection, and prioritize exceptional craftsmanship. Customer satisfaction, accessibility, and innovation are the cornerstones of our commitment. Join us in celebrating these values as we bring you exceptional rare and vintage books, ensuring that your literary journey is enriched with the timeless beauty of handcrafted leather-bound editions."
    },
]

const CompanyValues = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <h2>Our Foundation</h2>
                {/* <p>Craft Your Perfect Book: Customizable Front Covers, Spines, Endpapers, and Gilding</p> */}
            </div>

        <div className={classes.stepsSection}>
            {stepData.map((steps,index) => (
                <div key={index} className={classes.stepsCard}>
                    <div className={classes.stepDetails}>
                        <h2>{steps.title}</h2>   
                        <p>{steps.desc}</p>                   
                    </div>
                    <div className={classes.stepImage}>
                        <img src={steps.image} alt="" />
                    </div>
                </div>
            ))}
        </div>

        
        </div>
    )
}

export default CompanyValues