import React from "react";
import classes from "./FAQRedirect.module.css"

import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";


const FAQRedirect = () => {
  const history = useHistory()
  return (
    <div className={classes.ctaContainer}>
      <div className={classes.textContent}>
        <h2>Your Questions, Answered
        </h2>
        <p>Find solutions to common queries about our services and products.
        </p>
      </div>
      <div className={classes.ctaButton}>
        <SecondaryButton onClick={() => history.push(urls.FAQ)}>Read our FAQ</SecondaryButton>
      </div>
    </div>
  );
};

export default FAQRedirect
