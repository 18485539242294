import { useState } from "react";


import classes from "./FAQCard.module.css";

const rightArrow = "/Assets/Icons/icon-right.svg"


function FAQCard({ques,answer}){

    const [isOpen,setIsOpen] = useState(false);

    const toggleIsOpen = () =>{
        setIsOpen(!isOpen)
    }

    return(
        <div className={isOpen ? classes.openContainer : classes.closedContainer} onClick={toggleIsOpen}>
            <div className={classes.details} >
                <h2>{ques}</h2>
                <div className={classes.toggleImage}>
                 <img src={rightArrow} className={isOpen ? classes.toggleDown : classes.toggleRight} alt="" />
                    {/* {isOpen && <FontAwesomeIcon className={classes.toggleDown} style={{color:"#fff"}} size="2xs" icon={faAngleDown}/>} */}
                </div>
                
            </div>
            { isOpen && <div className={classes.text} onClick={(e) => e.stopPropagation()}>
                <p>{answer}</p>
            </div>}
        </div>
    )
}

export default FAQCard;