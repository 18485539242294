import axios from "axios";
import { blogsAction } from "../Slices/BlogsSlice";
import { SERVICE_URL } from "../ServiceUrls";

function getBlogs(filter) {
    return axios({
        url: `${SERVICE_URL.BLOG}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: 'get',
        responseType: 'json'
    });
}

export function fetchBlogs(query, page, limit) {
    var filter = {};
    if (query === "") {
      filter = { sortBy: "id DESC" };
    } else {
      filter = {
        where: {
            $or:[
                {authorName: { $regex: query, $options: "i" }},
                {title: { $regex: query, $options: "i" }},
                {category:{$regex: query, $options: "i"}}
            ]
        },
        limit: limit,
        offset: (page - 1) * limit || 0
      };
    }
    return getBlogs(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
            return data.data;
        }
    }).catch(function (error) {
        console.log(error)
    });
}

export function fetchAllBlogs(dispatch) {
    let filter = {
      sortBy: "id DESC",
    };

    return getBlogs(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(blogsAction.fetchAllBlog(data.data));
        }
    }).catch(function (error) {
        console.log(error);
    });
}
export function fetchAllPaginatedBlogs(page, limit, dispatch) {
    let filter = {
      sortBy: "id DESC",
      limit: limit,
      offset: (page - 1) * limit + 4 || 0
    };
    
    return getBlogs(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(blogsAction.fetchAllPaginatedBlogs(data.data));
        }
    }).catch(function (error) {
        console.log(error);
    });
}


export function fetchBlogsByCategory(category) {
    var filter = {};
    if (category === "") {
      filter = { sortBy: "id DESC" };
    } else {
      filter = {
        where: {
            $or:[
                {category:{$regex: category, $options: "i"}}
            ]
        },
      };
    }
    return getBlogs(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
            return data.data;
        }
    }).catch(function (error) {
        console.log(error)
    })
}





export function updateBlog(item,content,imageFile,id,dispatch) {
    let formData = new FormData();
    formData.append("blogDeatils", JSON.stringify(item));
    formData.append("content", new Blob([content], { type: "text/plain" }));
    formData.append("photo", imageFile);
    return axios({
      url: `${SERVICE_URL.BLOG}/${id}`,
      headers: { "content-type": "multipart/form-data" },
      method: "put",
      data: formData,
      responseType: "json",
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(blogsAction.updateBlog({ blogs: data.data}))
        }
        return data;
    })
}

export function getBlogCategories(dispatch){
    return axios({
        url:"/api/blogs/categories",
        method:"get"
    }).then(res => {
        if(res.data.success){
            dispatch(blogsAction.fetchAllCategories(res.data.data))
        }
    }).catch(err => {
        console.error(err)
    })
}



export function addNewBlog(item,content,imageFile,dispatch) {
    let formData = new FormData();
    formData.append("blogDetails", JSON.stringify(item));
    formData.append("content", new Blob([content], { type: "text/plain" }));
    formData.append("photo", imageFile);
    return axios({
      url: `${SERVICE_URL.BLOG}`,
      headers: { "content-type": "multipart/form-data" },
      method: "post",
      data: formData,
      responseType: "json",
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(blogsAction.addNewBlog({ blogs: data.data || [] }))
        }
        return data;
    })
}

export function deleteBlog(blogId, dispatch) {
    return axios({
        url: `${SERVICE_URL.BLOG}/${blogId}`,
        method: 'delete',
        responseType: 'json'
    }).then(function (response){
        let data =response.data;
        if(data.success) {
            dispatch(blogsAction.deleteBlog({id: blogId}))
        }    
    })
}

export function getBlogsCount(query){
        let where = {
            $or:[
                {authorName: { $regex: query, $options: "i" }},
                {title: { $regex: query, $options: "i" }},
            ]
        }
    
    return axios({
        url:`/api/blogs/count?where=${encodeURIComponent(JSON.stringify(where))}`,
        method:"get"
    }).then((res) => {
        if(res.data.success){
            return res.data.data
        }
    }).catch(err => {
        console.log(err)
    })
}

export function getContent(contentDataPath){
    return axios({
        url: contentDataPath,
        method: 'get',
    }).then(response => response.data)

}