import React from 'react';
import classes from './PreviewCard.module.css';

const PreviewCard = ({ image, altText, isActive, handleClick, design,className }) => {
  const activeClass = typeof isActive === 'function' 
    ? isActive(design) 
      ? classes.activePreviewCard 
      : '' 
    : isActive 
      ? classes.activePreviewCard 
      : '';

    const cardClass = className ? classes[className] : "";

  return (
    <div
      className={`${classes.previewCard} ${activeClass} ${cardClass}`}
      onClick={() => handleClick(design)}
    >
      <img src={image} alt={altText || 'Preview'} />
    </div>
  );
};


export default PreviewCard;
