import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    videos:[]
}

const ServiceVideoSlice = createSlice({
    name:"videos",
    initialState,
    reducers:{
        fetchAllVideos:(state,action) => {
            state.videos = action.payload;
        },
    }

})

export const ServiceVideoAction = ServiceVideoSlice.actions;
export default ServiceVideoSlice.reducer;