import React from "react";
import classes from "./Input.module.css";
import { useState } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import {getClasses} from "../../../Util";

const Input = forwardRef(function Input(props, ref) {
  const [msg, setMsg] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      message: msg,
      isValid: (e) => !msg,
    }),
    [msg]
  );

  return (
    <>
      <input
        {...props}
        className={getClasses(classes.input, props.className)}
        type={props.type || "text"}
        onBlur={props.onBlur}
        onChange={(e) => {
          try {
            props.onChange?.(e);
          } finally {
            setMsg("");
          }
        }}
        onPaste={props.onPaste}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
        value={props.value}
        disabled={props.disabled}
        name={props.name}
        defaultValue={props.defaultValue}
      />
      {msg && (
        <div
          style={{ color: "red", marginBottom: "0" }}
          className={props.msgClassname}
        >
          {msg}
        </div>
      )}
    </>
  );
});

export default Input;

/*
onBlur={async e => {
                try {
                    props.onBlur?.(e);
                    await props.validate?.(e);
                } catch(err) {
                    setMsg(err?.message || "Unknown");
                }
            }}
*/
