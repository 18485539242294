import axios from "axios";
import { WishlistActions } from "../Slices/WishlistSlice";
import { SERVICE_URL } from "../ServiceUrls";

// Function to fetch all cart items
function getWishlistItems(filter) {
    return axios({
        url: `${SERVICE_URL.WISHLIST}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: 'get',
        responseType: 'json'
    });
}

export function fetchAllWishlistItems(dispatch) {
    let filter = { sortBy: "id DESC" };
    return getWishlistItems(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
            // console.log(data.data.cartItems)
            dispatch(WishlistActions.updateWishlistItems(data.data.wishlistItems));
        }
    }).catch(function (error) {
        console.log(error);
    });
}

// Function to add a new cart item
export function addWishlistItem(bookData, quantity, dispatch,isBestSeller=false) {
    let formData = new FormData();
    formData.append("bookData", JSON.stringify(bookData));
    formData.append("quantity", quantity);
    formData.append("isBestSeller",isBestSeller);
    
    return axios({
        url: `${SERVICE_URL.WISHLIST}/item`,
        headers: { "content-type": "multipart/form-data" },
        method: "post",
        data: formData,
        responseType: "json",
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(WishlistActions.addWishlistItemSuccess(data.data.wishlistItems));
        }
        return data;
    }).catch((err) => {});
}

// Function to update a cart item
export function updateWishlistItem(itemId, quantity, dispatch) {
    let formData = new FormData();
    formData.append("quantity", quantity);
    
    return axios({
        url: `${SERVICE_URL.WISHLIST}/item/${itemId}`,
        headers: { "content-type": "multipart/form-data" },
        method: "put",
        data: formData,
        responseType: "json",
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            fetchAllWishlistItems(dispatch);
        }
        return data;
    });
}

// Function to delete a cart item
export function deleteWishlistItem(itemId, dispatch) {
    return axios({
        url: `${SERVICE_URL.WISHLIST}/item/${itemId}`,
        method: 'delete',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(WishlistActions.removeWishlistItem(itemId));
        }
        return data;
    });
}

// Function to get cart item details
export function getWishlistItemDetails(itemId, dispatch) {
    return axios({
        url: `${SERVICE_URL.WISHLIST}/item/${itemId}`,
        method: 'get',
        responseType: 'json'
    }).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(WishlistActions.addWishlistItemSuccess(data.data));
        }
        return data;
    });
}
