import classes from "./AboutThesisBinding.module.css"

const AboutThesisBinding = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>Protected Privacy and Secure Data
                </h2>
                <p>At Rare Biblio, we respect strict regulations to preserve your confidentiality and guarantee the safety of your thesis. Your work is bound just for you, ensuring that it stays safe and never falls into the wrong hands.
                </p>
            </div>

            {/* <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
 */}

        </div>
    )
}

export default AboutThesisBinding