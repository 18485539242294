import { createSlice } from "@reduxjs/toolkit";

const BannerSlice = createSlice({
    name:"banners",
    initialState:{
        banners:[],
    },
    reducers:{
        updateBanners: (state,action) => {
            state.banners = action.payload;
        },
    }

});

export const BannerAction = BannerSlice.actions
export default BannerSlice.reducer