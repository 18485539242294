import classes from "./ColorPicker.module.css"
import { useContext, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown,faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useFabricCanvas,useFabricCanvasHandler,useFabricEditor } from "react-fabric-page";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ColorPickerSmall from "./ColorPickerSmall";
import { CustomizationContext } from "../../Customization";
import { useSelector } from "react-redux";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import ToolTip from "../../../../Components/ToolTip/ToolTip";

const ColorPicker = ({onClick,toggleDropdown,dropdownOpen,currentDropdown}) => {

     const {bookLeather,bookVariant,frontCoverColor,setFrontCoverColor,selectedDesigns} = useContext(CustomizationContext);

    const allColorsData = useSelector((state) => state.bookColor.colors);
    const allowedColors = allColorsData?.filter((color) => color.disabled === false && color.isBestSeller === false && color.variantType === bookVariant && color?.leatherType === bookLeather)

    const {isMediumScreen} = useScreenSize()
    const canvas = useFabricCanvas()
    const editor = useFabricEditor()

    useEffect(() => {
        if(selectedDesigns?.customization) {
            const selectedColor = allowedColors?.find(color => color.colorName === selectedDesigns?.customization?.leatherColor);
            if(selectedColor) {
                setFrontCoverColor(selectedColor);
                editor?.saveCurrentState()
            } else {
                setFrontCoverColor(allowedColors?.[0])
                editor?.saveCurrentState()
            }
        } else {
            setFrontCoverColor(allowedColors?.[0])
            editor?.saveCurrentState()
        }
    },[allColorsData,bookVariant,bookLeather])

    const handleColorChange = (color,e) => {
        e.stopPropagation()
        setFrontCoverColor(color)
    }

    const handleClickAndSelect = () => {
        onClick()
        if(dropdownOpen){
            toggleDropdown(false)
        }
        else{
            toggleDropdown(true)
        }
    }

    const changeCoverColor = (colorCode) => {

        const obj1 = canvas?.getObjects()?.find(o => o.id === "left-rectangle");
        const obj2 = canvas?.getObjects()?.find(o => o.id === "bottom-right-triangle");
        const obj3 = canvas?.getObjects()?.find(o => o.id === "top-right-triangle");
          if (obj1 && obj2 && obj3) {
            obj1.set("fill", colorCode);
            obj2.set("fill", colorCode);
            obj3.set("fill", colorCode);
            editor.leatherColor = colorCode;
            obj1.dirty = true;
            obj2.dirty = true;
            obj3.dirty = true;
            canvas.renderAll();
        }
    }

    changeCoverColor(frontCoverColor?.colorValue)

    useFabricCanvasHandler({
        'canvas:x-ready': () => {
            const obj1 = canvas?.getObjects()?.find(o => o.id === "left-rectangle");
            const obj2 = canvas?.getObjects()?.find(o => o.id === "bottom-right-triangle");
            const obj3 = canvas?.getObjects()?.find(o => o.id === "top-right-triangle");
            if (obj1 && obj2 && obj3) {
              const frontCoverColor = obj1.fill
              const appliedColor = allowedColors?.find((color) => color?.colorValue === frontCoverColor);
              if(appliedColor) {
                setFrontCoverColor(appliedColor)
              }
            }
        },
      },[allowedColors]);

    useEffect(() => {
        changeCoverColor(frontCoverColor?.colorValue)
        if(editor) {
            editor.saveCurrentState();
        }
    },[frontCoverColor])

    useFabricCanvasHandler({
        'canvas:x-ready': () => {
            const obj1 = canvas?.getObjects()?.find(o => o.id === "left-rectangle");
            if (obj1) {
                const selectedColor = allowedColors?.find((color) => color?.colorValue === obj1?.fill);
                if(selectedColor) {
                    setFrontCoverColor(selectedColor)
                }
            }
        },
      });

    return (
        <div>
            {isMediumScreen ? <ColorPickerSmall allowedColors={allowedColors} selectedColor={frontCoverColor} handleColorChange={handleColorChange}/> : (
                    
            <div className={classes.ColorOption} onClick={() => handleClickAndSelect()}>
            <div className={classes.colorHeader}>
                <div className={classes.headerText}>
                    <h2>Leather Color</h2>
                    <ToolTip tooltipText={"Customize front cover leather color"}/>
                </div>

                <div className={classes.dropDownImage} onClick={toggleDropdown}>
                    {dropdownOpen && currentDropdown === "Leather Color" ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
            </div>

            {dropdownOpen && currentDropdown === "Leather Color" ? (
                <div className={`${classes.colorPickerDropdown} ${classes.open}`} onClick={(e) => e.stopPropagation()}>
                    <h3>Select leather cover for front color</h3>

                    <div className={classes.selectColor}>
                        <h2>Color : {frontCoverColor?.colorName}</h2>
                        {/* <div className={classes.colors}>
                            {allowedColors?.map((color, index) => (
                                selectedColor?.colorValue == color.colorValue ? (
                                    <div key={index} className={classes.selected}>
                                        <div className={classes.active} style={{ background: color.colorValue }} >
                                        </div>
                                    </div>
                                ) : (

                                    <div key={index} className={classes.colorPicker} style={{ background: color.colorValue }} onClick={(e) => handleColorChange(color,e)}> </div>
                                )
                            ))}
                        </div> */}
                        <ColorSelector availableColors={allowedColors} selectedColor={frontCoverColor} handleColorChange={handleColorChange} />
                    </div>
                </div>
            ) : (
                <p>{frontCoverColor?.colorName}</p>
            )}

        </div>
            )}

        </div>
    )   
}

export default ColorPicker;