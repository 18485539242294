import classes from "./OuterBorder.module.css";
import { useState, useEffect, useContext } from "react";
import { fabric, useFabricCanvasHandler, useFabricEditor } from "react-fabric-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleUp,
  faAngleDown,
  faPlusCircle,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { addImageToCanvas } from "../../../../objectUtils";
import Slider from "react-slick";

import { useFabricCanvas } from "react-fabric-page";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { CustomizationContext } from "../../Customization";
import {
  getBorderDesigns,
} from "../../../../Store/actions/ColorsAction";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { useSelector } from "react-redux";

const outerBorderTabs = [
  {
    title: "None",
    value: "none",
  },
  {
    title: "Embossing",
    value: "embossing",
  },
  {
    title: "Foiling",
    value: "foiling",
  },
];

const getBounds = (canvas) => {
  const layer = canvas?.getObjects()?.find((o) => o.id === "decoration")?.clipPath;
  if (layer) {
    return {
      left: layer.left,
      top: layer.top,
      width: layer.width,
      height: layer.height - layer.top,
    };
  }
  return null;
};

function changeCoverBorder(border, canvas, bookSize) {
  let imageUrl = "";
  switch (bookSize) {
    case "F1":
      imageUrl = border?.designImage1;
      break;
    case "F2":
      imageUrl = border?.designImage2;
      break;
    case "F3":
      imageUrl = border?.designImage3;
      break;
    case "F4":
      imageUrl = border?.designImage4;
      break;
    default:
      break;
  }

  if(imageUrl){
    return addImageToCanvas({ filepath: imageUrl, fileType: "image" }, 1, () =>
      getBounds(canvas),null,false,false
    ).then((img) => {
      const layer = canvas?.getObjects()?.find((o) => o?.id === "decoration");
      if (layer) {
        layer._objects = layer._objects.filter((o) => o.id !== "outerBorder");
        layer.dirty = true;
        img.imageUrl = border?.paletteImage;
        layer.add(img);
        img.id = "outerBorder";
        img.hasControls = false;
        layer.dirty = true;
        canvas.renderAll();
      }
    });
  }
}

const OuterBorder = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
}) => {
  const {
    bookSize,
    selectedOuterBorder,
    setSelectedOuterBorder,
    selectedOuterBorderType,
    setSelectedOuterBorderType,
    bookVariant,allFoilingColors,
    outerBorderFoilingColor,
    setOuterBorderFoilingColor,
    userOuterBorder,
    setUserOuterBorder,
    selectedDesigns
  } = useContext(CustomizationContext);

  const { isMediumScreen } = useScreenSize();

  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const [allBorderDesigns, setAllBorderDesigns] = useState([]);
  const [borderDesigns, setBorderDesigns] = useState([]);
  const [FoilingColors, setFoilingColor] = useState([]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0]; 
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const base64Data = event.target.result; 
        if(base64Data) {
          setUserOuterBorder(base64Data); // Set the file to state
        }
      };

      reader.readAsDataURL(file);
    }
  }

  const handleClick = () => {
    document.getElementById("fileInput").click();
  };

  const canvas = useFabricCanvas();
  const editor = useFabricEditor();

  const settings = {
    slidesToShow: borderDesigns?.length > 1 ? 2.5 : 1,
    swipeToSlide: true,
    infinite: false,
    centerPadding: "10px",
  };

  const fetchBorderDesigns = async () => {
    try {
      const res = await getBorderDesigns({});
      if (res.status === 200) {
        setAllBorderDesigns(res.data.data);
      }
    } catch (err) {}
  };

  useEffect(() => {
    fetchBorderDesigns();
  }, []);

  useEffect(() => {
    const allowedBorders = allBorderDesigns?.filter(
      (border) =>
        border.borderType === "outer" &&
        border.borderStyle === selectedOuterBorderType &&
        border.variantType === bookVariant &&
        border.disabled === false
    );
    setBorderDesigns(allowedBorders);
  }, [selectedOuterBorderType, allBorderDesigns, bookVariant]);

  useEffect(() => {
    const allowedFoilingColors = allFoilingColors?.filter(
      (color) => color.disabled === false
    );
    setFoilingColor(allowedFoilingColors);
  }, [allFoilingColors]);

  const handleClickAndSelect = () => {
    onClick();
    toggleDropdown(!dropdownOpen);
  };

  const changeFoilingColor = () => {
    if (canvas) {
      const layer = canvas?.getObjects()?.find((o) => o.id === "decoration");
      if (layer) {
        const activeObject = layer?._objects?.filter(
          (o) => o.id === "outerBorder"
        )?.[0];
        if (activeObject && outerBorderFoilingColor) {
          activeObject.filters[0] = new fabric.Image.filters.PatternColor({
            color: outerBorderFoilingColor?.colorValue,
            convert: true,
            webgl:false
          });
          activeObject.applyFilters();
          layer.dirty = true;
        }
      }
      canvas.renderAll();
    }
  };

  useEffect(() => {
    if (selectedOuterBorderType === "foiling") {
      changeFoilingColor();
    }
  }, [outerBorderFoilingColor, selectedOuterBorderType, selectedOuterBorder]);

  useEffect(() => {
    if (selectedOuterBorderType === "none") {
      setSelectedOuterBorder(null);
      setSelectedOuterBorderType("none");
      setOuterBorderFoilingColor(null);
      const layer = canvas?.getObjects()?.find((o) => o.id === "decoration");
      if (layer) {
        layer._objects = layer._objects.filter((o) => o.id !== "outerBorder");
        layer.dirty = true;
        canvas.renderAll();
      }
    } else {
      if(selectedDesigns?.customization?.outerBorder?.designId) {
        const appliedBorder = allBorderDesigns?.find(border => border?.id === selectedDesigns?.customization?.outerBorder?.designId && border.disabled === false);
        if(appliedBorder) {
          setSelectedOuterBorder(appliedBorder)
        }
      } else {
        (async function (params) {
          if (borderDesigns.length > 0) {
            await changeCoverBorder(borderDesigns[0], canvas, bookSize);
            setSelectedOuterBorder(borderDesigns[0]);
            if (selectedOuterBorderType === "foiling" && FoilingColors.length > 0) {
              setOuterBorderFoilingColor(FoilingColors[0]);
              changeFoilingColor();
            }
          }
  
        })();
      }

    }
    if(editor) {
      editor.saveCurrentState()
    }
  }, [selectedOuterBorderType, borderDesigns, FoilingColors]);


  useFabricCanvasHandler({
    'canvas:x-ready': () => {
         const layer = canvas?.getObjects()?.find((o) => o?.id === "decoration");
         if (layer) {
          const outerBorderObj = layer._objects.find((o) => o.id === "outerBorder");
          if(outerBorderObj) {
            const outerBorderImage = allBorderDesigns?.find((border) => border?.paletteImage === outerBorderObj.imageUrl);
            if(outerBorderImage) {
              setSelectedOuterBorderType(outerBorderImage.borderStyle);
              setSelectedOuterBorder(outerBorderImage);
              if(outerBorderImage.borderStyle === outerBorderTabs[2].value) {
                  const previousFoilColor = FoilingColors?.find((color) => color.colorValue === outerBorderImage?.filters?.[0]?.color);
                  if(previousFoilColor) {
                    setOuterBorderFoilingColor(previousFoilColor)
                  }
              } 
            }
          } else {
            setSelectedOuterBorderType(outerBorderTabs[0].value)
          }
        }
    },
  },[borderDesigns,FoilingColors]);


  useEffect(() => {
    if (selectedOuterBorderType === "foiling" && FoilingColors?.length > 0) {
      if(selectedDesigns?.customization?.outerBorder?.foilingColor) {
        const selectedFoilColor = FoilingColors?.find((color) => color?.colorName === selectedDesigns?.customization?.outerBorder?.foilingColor);
        if(selectedFoilColor) {
          setOuterBorderFoilingColor(selectedFoilColor)
        } else {
          setOuterBorderFoilingColor(FoilingColors?.[0])
        }
      } else {
        setOuterBorderFoilingColor(FoilingColors[0]);
        changeFoilingColor(FoilingColors[0]);
      }
    }
  }, [selectedOuterBorderType, FoilingColors]);

  const handleChangeBorder = (border) => {
    if (selectedOuterBorder?.paletteImage !== border?.paletteImage) {
      (async function (params) {
          await changeCoverBorder(border, canvas, bookSize);
          setSelectedOuterBorder(border);
          if (selectedOuterBorderType === "foiling" && outerBorderFoilingColor) {
            changeFoilingColor();
          }
        }

      )();
      if(editor) {
        editor.saveCurrentState()
      }
    }

  };

  const handleColorChange = (color, e) => {
    e.stopPropagation();
    setOuterBorderFoilingColor(color);
  };

  useEffect(() => {
    if(selectedDesigns?.customization?.outerBorder?.style){
      setSelectedOuterBorderType(selectedDesigns?.customization?.outerBorder?.style);
    }
  },[selectedDesigns,FoilingColors])

  return (
    <div>
      {isMediumScreen ? (
            <div onClick={(e) => e.stopPropagation()}>
            <div className={classes.outerBorderTypes}>
              <h2>Select type of outer border</h2>
              <div className={classes.outerBorderTypePreview}>
                <div className={classes.outerBorderType}>
                  {outerBorderTabs.map((option) => (
                    <OptionCapsules
                      key={option?.value}
                      option={option.title}
                      active={selectedOuterBorderType === option.value}
                      onClick={() => setSelectedOuterBorderType(option.value)}
                    />
                  ))}
                </div>

                {selectedOuterBorderType !== "none" && (
                  <div className={classes.previewContainer}>
                    <Slider {...settings}>
                      {borderDesigns?.map((border) => (
                        <div
                          key={border?.designName}
                          className={`${classes.previewCard} ${
                            selectedOuterBorder?.designName ===
                            border?.designName
                              ? classes.activePreviewCard
                              : ""
                          }`}
                          onClick={() => handleChangeBorder(border)}
                        >
                          <img src={border?.paletteImage} alt="" />
                        </div>
                      ))}
                    </Slider>
                  </div>
                )}

                {selectedOuterBorderType === "foiling" && (
                  <div className={classes.foilingColorSection}>
                    <h2>Select Foiling Color</h2>
                    <ColorSelector
                      availableColors={FoilingColors}
                      selectedColor={outerBorderFoilingColor}
                      handleColorChange={handleColorChange}
                    />
                  </div>
                )}
              </div>

              <div className={classes.uploadBtn} onClick={handleClick}>
                <div className={classes.addLogo}>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    className={classes.addLogoIcon}
                  />
                  <p>Add your own design</p>
                </div>
                <div className={classes.logoPrice}>+${customizationPricing?.superDeluxeOuterBorderPrice}</div>
              </div>

              {/* Hidden file input to select an image */}
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileSelect} // Trigger on file selection
              />
              {/* Optional: Image preview (if image is selected) */}
              {userOuterBorder && (
                <div className={classes.imagePreview}>
                  <img
                    src={userOuterBorder} // Convert file to URL for preview
                    alt="Selected Design"
                  />
                  <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setUserOuterBorder(null)} />
                </div>
              )}
              <p className={classes.disclaimer}>
                Disclaimer : uploaded design won’t be available for live
                preview
              </p>
            </div>
          </div>
      ) : (
        <div
          className={classes.outerBorder}
          onClick={() => handleClickAndSelect()}
        >
          <div className={classes.colorHeader}>
            <div className={classes.headerText}>
              <h2>Outer Border</h2>
              <ToolTip tooltipText={"Add Outer Border on front cover"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Outer Border" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Outer Border" ? (
            <div onClick={(e) => e.stopPropagation()}>
              <div className={classes.outerBorderTypes}>
                <div className={classes.outerBorderTypePreview}>
                  <div className={classes.outerBorderType}>
                    {outerBorderTabs.map((option) => (
                      <OptionCapsules
                        key={option?.value}
                        option={option.title}
                        active={selectedOuterBorderType === option.value}
                        onClick={() => setSelectedOuterBorderType(option.value)}
                      />
                    ))}
                  </div>

                  {selectedOuterBorderType !== "none" && (
                    <div className={classes.previewContainer}>
                      <Slider {...settings}>
                        {borderDesigns?.map((border) => (
                          <div
                            key={border?.designName}
                            className={`${classes.previewCard} ${
                              selectedOuterBorder?.designName ===
                              border?.designName
                                ? classes.activePreviewCard
                                : ""
                            }`}
                            onClick={() => handleChangeBorder(border)}
                          >
                            <img src={border?.paletteImage} alt="" />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}

                  {selectedOuterBorderType === "foiling" && (
                    <div className={classes.foilingColorSection}>
                      <h2>Select Foiling Color</h2>
                      <ColorSelector
                        availableColors={FoilingColors}
                        selectedColor={outerBorderFoilingColor}
                        handleColorChange={handleColorChange}
                      />
                    </div>
                  )}
                </div>

                <div className={classes.uploadBtn} onClick={handleClick}>
                  <div className={classes.addLogo}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={classes.addLogoIcon}
                    />
                    <p>Add your own design</p>
                  </div>
                  <div className={classes.logoPrice}>+${customizationPricing?.superDeluxeOuterBorderPrice}</div>
                </div>

                {/* Hidden file input to select an image */}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelect} // Trigger on file selection
                />
                {/* Optional: Image preview (if image is selected) */}
                {userOuterBorder && (
                  <div className={classes.imagePreview}>
                    <img
                      src={userOuterBorder} // Convert file to URL for preview
                      alt="Selected Design"
                    />
                    <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setUserOuterBorder(null)} />
                  </div>
                )}
                <p className={classes.disclaimer}>
                  Disclaimer : uploaded design won’t be available for live
                  preview
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </div>
  );
};

export default OuterBorder;
