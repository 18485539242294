import classes from "./BestSellerPDP.module.css"
import Header from "../../Header/Header"
import BreadCrumb from "../../../Components/BreadCrumb/BreadCrumb"
import { useEffect, useState, useRef } from "react"
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton"
import FeatureCard from "./../FeatureCard/FeaturerCard"
import AboutBook from "./../AboutBook/AboutBook"
import Reviews from "./../Reviews/Reviews"
import { addBestSellerCartItem } from "../../../Store/actions/CartAction";
import Recommendations from "./../Recommendation/Recommendations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchBookById } from "../../../Store/actions/BestSellerBookAction";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Footer/Footer";
import { urls } from "../../../Routes/Urls";
import LeatherType from "./../LeatherType/LeatherType";
import VariantType from "./../VariantType/VariantType"
import Metatag from "../../../Components/Metatag"
import useScrollToTop from "../../../Hooks/useScrollToTop"
import { addWishlistItem } from "../../../Store/actions/WishlistAction"
import ButtonLight from "../../../Components/Buttons/ButtonLight/ButtonLight"
import { generateUUID } from "three/src/math/MathUtils.js"
import { CartActions } from "../../../Store/Slices/CartSlice"
import { setIsOpen } from "../../../Store/Slices/ToggleSlice"

const sewn = "/Assets/Images/pdp/sewn.png"
const notSprayed = "/Assets/Images/pdp/notSprayed.png"
const acidFree = "/Assets/Images/pdp/acidfree.png"
const goldPrinting = "/Assets/Images/pdp/goldFoil.png"
const goldEmbossing = "/Assets/Images/pdp/goldEmbossing.png"
const ribbonBookmark = "/Assets/Images/pdp/ribbonBookmark.png"
const marbleEndpaper = "/Assets/Images/pdp/marbleEnpaper.png"
const bestSellerRibbon = "/Assets/Images/pdp/bestSellerRibbon.svg"

const tabsData = ["About the book", "Reviews"]

const features = [
    {
        image: sewn,
        title: "Sewn Pages",
        desc: "Pages sewn not glued"
    },
    {
        image: acidFree,
        title: "Acid Free",
        desc: "Acid free natural shade paper"
    },
    {
        image: goldPrinting,
        title: "Gold Foil Printing",
        desc: "Gold Foil Printing on hubbed spine"
    },
    {
        image: goldEmbossing,
        title: "Gold Foil Embossing",
        desc: "Gold Foil embossing on frontcover"
    },
    {
        image: notSprayed,
        title: "Not Sprayed",
        desc: "Gold Foil gliding of edges are not sprayed"
    },
    {
        image: ribbonBookmark,
        title: "Ribbon Bookmark",
        desc: "leather bound book with ribbon bookmark"
    },
    {
        image: marbleEndpaper,
        title: "Marble Endpapers",
        desc: "Colored Marbled Endpapers"
    },
]

const VariantTabs = [
    {
        name: "Super Deluxe Edition",
        value: "superDeluxe"
    },
    {
        name: "Deluxe Edition",
        value: "deluxe"
    },
]

const LeatherTabs = [
    {
        name: "Genuine Leather",
        value: "realLeather"
    },
    {
        name: "Vegan Leather",
        value: "veganLeather"
    },
]

const BestSellerPDP = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory()
    const bookData = useSelector(state => state.bestSellerBooks.book)
    const currentUser = useSelector(state => state.user.currentUser)
    const [showLeatherType, setShowLeatherType] = useState(false)
    const [showVariantType, setShowVariantType] = useState(false)

    useEffect(() => {
        fetchBookById(id, dispatch);
    }, [id])

    const crumbs = [
        {
            title: "Home",
            path: "/"
        },
        {
            title: "Best Sellers",
            path: urls.BESTSELLER
        },
        {
            title: bookData?.title,
            path: `${urls.BESTSELLERPDP}/${id}`
        },
    ]

    const allColorsData = useSelector(state => state.bookColor.colors);
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
    const activeVariant = VariantTabs[0];
    const [activeLeather, setActiveLeather] = useState(LeatherTabs[0])
    const [qty, setQty] = useState(1);
    const [activeTab, setActiveTab] = useState(0);

    const allowedColors = allColorsData?.filter(color => color.leatherType === activeLeather.value && color.isBestSeller === true)
    
    const [selectedColor, setSelectedColor] = useState(allowedColors[0])

    const imgRef = useRef(null);
    const [ribbonStyle, setRibbonStyle] = useState({});

    useEffect(() => {
        const defaultColor = allColorsData?.filter(color => color.leatherType === activeLeather.value && color.isBestSeller === true )
        setSelectedColor(defaultColor[0])
    }, [allColorsData])

        // Initialize images as an empty array
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (images.length > 0) {
            setCurrentPhoto(images[currentPhotoIndex]);
        }
    }, [activeLeather, activeVariant, selectedColor]);

    const [currentPhoto, setCurrentPhoto] = useState(bookData?.imageLink ? bookData.imageLink[currentPhotoIndex] : "");

    useEffect(() => {
        if (bookData && bookData?.imageLink) {
            const baseImag = bookData.imageLink[0]
            setCurrentPhoto(baseImag)
        }
    }, [bookData])

    useEffect(() => {
        if (bookData && bookData?.imageLink) {
            setCurrentPhoto(bookData.imageLink[currentPhotoIndex])
        }
    }, [currentPhotoIndex])

    const handleLeftArrowClick = () => {
        if (currentPhotoIndex === 0) {
            setCurrentPhotoIndex(3)
        }
        else {
            setCurrentPhotoIndex(currentPhotoIndex - 1)
        }
    }

    useEffect(() => {
        setCurrentPhoto(allowedColors[0]?.baseImg);
        setSelectedColor(allowedColors[0])
    }, [activeLeather, activeVariant])

    const handleSetActiveLeather = (type) => {
        setActiveLeather(type)
        setCurrentPhoto(allowedColors[0]?.baseImg);
        setSelectedColor(allowedColors[0])
    }

    const handleRightArrowClick = () => {
        if (currentPhotoIndex === 3) {
            setCurrentPhotoIndex(0)
        }
        else {
            setCurrentPhotoIndex(currentPhotoIndex + 1)
        }
    }

    const handleQtyDecrease = () => {
        if (qty > 1) {
            setQty(qty - 1)
        }
    }
    const handleQtyIncrease = () => {
        setQty(qty + 1)
    }

    const getBookPrice = (bookData) => {
        if(activeLeather.value === LeatherTabs[0].value){
            return Number(bookData?.priceGenuine).toFixed(2);
        }
        return Number(bookData?.priceVegan).toFixed(2);
    }

    const getVariant = () => {
        if(bookData?.variantType === "Super Deluxe"){
            return "superDeluxe"
        }
        else{
            return "deluxe"
        }
    }

    const getItemPrice = (bookData,leatherType) => {
        if(bookData && leatherType) {
            if(leatherType === "veganLeather") {
              return bookData?.priceVegan
            } else {
              return bookData?.priceGenuine
            }
        }
      }

    const addToCart = () => {
        const cartItem = {
            id:generateUUID(),
            bestSellerBook: bookData, 
            quantity: qty,
            variantType: getVariant(),
            leatherType: activeLeather.value,
            color: selectedColor?.colorName || "",
            itemPrice:getItemPrice(bookData,activeLeather.value)
          };
        if (currentUser?.id) {
            addBestSellerCartItem(bookData, qty,getVariant(),activeLeather.value,selectedColor?.colorName,dispatch)
            dispatch(setIsOpen(true));
        }
        else {
            // history.push(urls.LOGIN, { from: history.location.pathname + history.location.search });
            const cart = JSON.parse(localStorage.getItem("guestCart")) || [];
            if (cart?.length > 0) {
              const isBookInCart = cart?.some(
                (item) =>
                  item?.bestSellerBook?.sku === bookData?.sku &&
                  item?.leatherType === activeLeather.value && 
                  item?.color === selectedColor?.colorName
              );
    
              if (isBookInCart) {
                return; // Do nothing if the book is already in the cart
              }
            }
            cart.push(cartItem);
            localStorage.setItem("guestCart", JSON.stringify(cart));
    
            dispatch(CartActions.addCartItemSuccess(JSON.parse(localStorage.getItem("guestCart"))));
            dispatch(setIsOpen(true));
          }
        };

    const scrollRef = useScrollToTop();

  
    const updateImagesBasedOnBookData = () => {
      let newImages = []; // Create an empty array
  
      // Populate the array with the default selectedColor images
      if (selectedColor) {
        newImages = [
          selectedColor?.baseImg,
          selectedColor?.sideImg1,
          selectedColor?.sideImg2,
          selectedColor?.sideImg3,
          selectedColor?.sideImg4,
        ];
      }
  
      // If bookData has images and the key exists, update the images based on the key
      if (bookData && bookData.images && selectedColor && activeLeather) {
        const key = `${selectedColor?.colorName}_${activeLeather?.value}`;
  
        if (doesKeyExist(bookData.images, key)) {

            const imageObj = JSON.parse(bookData?.images);
            const matchedKey = Object.keys(imageObj).find(
                (existingKey) => existingKey.toLowerCase() === key.toLowerCase()
              );
          const bookImages = JSON.parse(bookData?.images)[matchedKey]?.links;
  
          // Update the newImages array with images from bookData
          newImages = newImages.map((img, index) =>
            bookImages[index] !== "" ? bookImages[index] : img
          );
        }
      }
  
      // Finally, update the state with the populated images array
      setImages(newImages);
      // Set the current photo to the first image
      if (newImages.length > 0) {
        setCurrentPhoto(newImages[0]);
        setCurrentPhotoIndex(0); // Reset photo index to 0 when images update
      }
    };
  
    useEffect(() => {
      if (bookData) {
        updateImagesBasedOnBookData();
      }
    }, [bookData, selectedColor, activeLeather, activeVariant]);
  
    // Update the current photo whenever the currentPhotoIndex changes
    useEffect(() => {
      if (images.length > 0) {
        setCurrentPhoto(images[currentPhotoIndex]);
      }
    }, [currentPhotoIndex, images]);
  
    function doesKeyExist(jsonString, keyToCheck) {
        if (jsonString) {
          const imagesObj = JSON.parse(jsonString);
          const lowerCaseKeyToCheck = keyToCheck.toLowerCase();
          return Object.keys(imagesObj).some(
            (key) => key.toLowerCase() === lowerCaseKeyToCheck
          );
        }
        return false;
      }

      const handleImageLoad = () => {
        const img = imgRef.current;

        if (img) {
            const { naturalWidth, naturalHeight } = img;
            const container = img.parentElement;
            const containerWidth = container.offsetWidth;
            const containerHeight = container.offsetHeight;

            const widthScale = containerWidth / naturalWidth;
            const heightScale = containerHeight / naturalHeight;
            const scale = Math.min(widthScale, heightScale);

            const imgWidth = naturalWidth * scale;
            const imgHeight = naturalHeight * scale;

            const leftOffset = (containerWidth - imgWidth) / 2;
            const topOffset = (containerHeight - imgHeight) / 2;

            // Position the ribbon relative to the image
            setRibbonStyle({
                top: `${topOffset + 0.05 * imgHeight}px`, // 5% from top of the image
                left: `${leftOffset-4}px`, // Align to image start
            });
        }
    };

    const handleWishListAdd = () => {
        if(currentUser?.id){
          addWishlistItem(bookData,1,dispatch,true)
        }
        else{
            history.push(urls.LOGIN, { from: history.location.pathname + history.location.search });
        }
        
      }

    
    return (
        <div className={classes.container} ref={scrollRef}>
            <Header />
            {bookData && (
                <>
                    {bookData?.metaTitle && <Metatag name="title" content={bookData.metaTitle} />}
                    {bookData?.metaDescription && <Metatag name="description" content={bookData.metaDescription} />}
                    {bookData?.metaKeywords && <Metatag name="keywords" content={bookData.metaKeywords} />}
                </>
            )}
            <div className={classes.mainContainer}>
                <div className={classes.crumb}>
                    <BreadCrumb className={classes.crumb} crumbs={crumbs} />
                </div>

                <div className={classes.productDetails}>
                    <div className={classes.imageSection}>
                        <div className={classes.mainImage}>
                            <img ref={imgRef} src={currentPhoto} alt="" onLoad={handleImageLoad}/>
                            <div className={classes.arrowLeft} onClick={handleLeftArrowClick} >
                                <FontAwesomeIcon icon={faAngleLeft} className={classes.leftIcon} />
                            </div>
                            <div className={classes.arrowRight} onClick={handleRightArrowClick}>
                                <FontAwesomeIcon icon={faAngleRight} className={classes.rigthIcon} />
                            </div>
                            <div className={classes.ribbon} style={ribbonStyle}>
                                <img src={bestSellerRibbon} alt="" />
                            </div>
                        </div>
                        <div className={classes.images}>
                            {images?.filter(image => image && image !== "")?.map((image, index) => (
                                <img key={index} src={image} alt="" onClick={() => setCurrentPhoto(image)} className={currentPhoto === image ? classes.activeImage : ""} />
                            ))}
                        </div>
                    </div>
                    <div className={classes.details}>
                        <h1>{bookData?.title}</h1>
                        <div className={classes.authors}>
                                <h2>{bookData?.author}</h2>
                        </div>

                        <h3>${getBookPrice(bookData)}</h3>
                        
                        <div className={classes.varientTypes}>
                            <div className={classes.varientHeader}>
                                <h2>Leather Types</h2>
                                <p style={{cursor:"pointer"}} onClick={() => setShowLeatherType(true)} >Know about Leather &gt;</p>
                            </div>
                            <div className={classes.leatherTypeBtn}>
                                {LeatherTabs.map((type, index) => (
                                    <div
                                        className={`${classes.leatherBtn} ${activeLeather.name === type.name ? classes.activeleatherBtn : ""}`}
                                        onClick={() => handleSetActiveLeather(type)}
                                        key={index}
                                    >
                                        <p>{type.name}</p>
                                    </div>

                                ))}
                            </div>
                        </div>

                        <div className={classes.selectColor}>
                            <h2>Color : {selectedColor?.colorName}</h2>
                            <div className={classes.colors}>
                                {allowedColors?.map((color, index) => (
                                    <div key={index} className={`${classes.colorPicker} ${selectedColor?.colorName === color.colorName ? classes.active : ""}`} onClick={() => setSelectedColor(color)}>
                                        <img src={color.paletteImage} alt="" />
                                        {/* <p>{color.colorName}</p> */}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={classes.quantityContainer}>
                            <h2>Quantity</h2>
                            <div className={classes.quantity}>
                                <div className={classes.selectQty}>
                                    <div className={classes.qtyButton} onClick={handleQtyDecrease}><p>-</p></div>
                                    <h2>{qty}</h2>
                                    <div className={classes.qtyButton} onClick={handleQtyIncrease}><p>+</p></div>
                                </div>
                                <SecondaryButton className={classes.addToCart} onClick={addToCart}>Add to Cart</SecondaryButton>
                                <ButtonLight className={classes.addToCart} onClick={handleWishListAdd}>Add to Wishlist</ButtonLight>
                            </div>
                        </div>

                    </div>
                </div>

                <div className={classes.bindingFeatures}>
                    <h2 className={classes.featuresHeading}>About the Binding</h2>
                    <div className={classes.featureSection}>
                        {features.map((data, index) => (
                            <div key={index} className={classes.features}>
                                <FeatureCard key={index} image={data.image} title={data.title} />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={classes.mainBody}>
                    <div className={classes.tabs}>
                        {tabsData.map((tab, index) => (
                            <div
                                key={tab}
                                className={`${classes.tab} ${activeTab === index ? classes.activeTab : ''}`}
                                onClick={() => setActiveTab(index)}
                            >
                                <p>{tab}</p>
                            </div>
                        ))}
                    </div>
                    {activeTab === 0 ? (
                        <AboutBook author={bookData?.author} publicationDate={bookData?.publishingYear} language={bookData?.language} title={bookData.title} desc={bookData?.description} />
                    ) : (
                        <div>
                            <Reviews bookData={{...bookData,imageUrl:currentPhoto}}/>
                        </div>
                    )}
                </div>

                <div className={classes.recommendation}>
                    <Recommendations />
                </div>


            </div>
            <Footer />

            {showLeatherType && <LeatherType onClose={() => setShowLeatherType(false)} />}
            {showVariantType && <VariantType onClose={() => setShowVariantType(false)} />}
        </div>
    )
}

export default BestSellerPDP;
