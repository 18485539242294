import { useEffect, useState } from "react";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import classes from "./ManageAddress.module.css";
import AddressCard from "./AddressCard";
import Modal from "../../../Components/Modal/Modal";
import AddressForm from "./AddressForm/AddressForm";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddress } from "../../../Store/actions/UsersActions";

const tabs = [
    { value: "Shipping Address", type: "shipping" },
    { value: "Billing Address", type: "billing" }
];

const ManageAddress = () => {
    const userData = useSelector(state => state.user.currentUser);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(tabs[0]);
    const [addressData, setAddressData] = useState(userData.shippingAddresses);
    const [modalOpen, setModalOpen] = useState(false);
    const [editingAddress, setEditingAddress] = useState(null);

    useEffect(() => {
        if (activeTab.type === "shipping") {
            setAddressData(userData.shippingAddresses);
        } else {
            setAddressData(userData.billingAddresses);
        }
    }, [activeTab, userData]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleDeleteItem = (addressId, addressType) => {
        deleteAddress(addressId, addressType, dispatch);
    };

    const handleEditItem = (address) => {
        setEditingAddress(address);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setEditingAddress(null);
        setModalOpen(false);
    };

    return (
        <div className={classes.mainContainer}>
            <div className={classes.title}>
                <div>Manage Address</div>
                <SecondaryButton className={classes.headerBtn} disabled={activeTab.type === "billing" && addressData.length >= 1} onClick={() => {
                    setEditingAddress(null);
                    setModalOpen(true);
                }}
                dis
                
                >
                    Add Address
                </SecondaryButton>
            </div>

            <div className={classes.tabs}>
                {tabs.map((tab) => (
                    <div
                        key={tab.value}
                        className={`${classes.tab} ${activeTab.value === tab.value ? classes.activeTab : ""}`}
                        onClick={() => handleTabChange(tab)}
                    >
                        {tab.value}
                    </div>
                ))}
            </div>

            <div className={classes.addressSection}>
                {addressData?.map((address) => (
                    <AddressCard
                        key={address.addressId}
                        userName={address.fullName}
                        mobileNumber={address.phoneNo}
                        addressLine1={address.addressLine1}
                        addressLine2={address.addressLine2}
                        country={address.country}
                        state={address.state}
                        city={address.city}
                        zipCode={address.zipCode}
                        onDelete={() => handleDeleteItem(address.addressId, activeTab.type)}
                        onEdit={() => handleEditItem(address)}
                    />
                ))}
            </div>

            {modalOpen && (
                <Modal title={editingAddress ? "Update Address" : "Add Address"} onClose={handleModalClose}>
                    <AddressForm
                        type={activeTab.type}
                        onClose={handleModalClose}
                        initialData={editingAddress}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ManageAddress;
