import { useState } from "react"
import classes from "./BestSeller.module.css"
import { useSelector } from "react-redux"
import Card from "../../BestSeller/Card/Card2"
import { getRandomImageForBestSellerBook } from "../../../Util"

const tabsData = ["All","Horror","Thriller","Comedy","Fiction"]


const BestSeller = () => {
    const allColorsData = useSelector(state => state.bookColor.colors);
    const featuredCollection = useSelector(state => state.featured.featuredCollection);
    const wishlist = useSelector((state) => state.wishlist.wishlistItems);

    const [activeTab,setActiveTab] = useState(0)

    const allBestSellingBooks = featuredCollection?.filter((data) => data.category === "bestSelling")?.[0]?.books || [];
    const books = tabsData[activeTab]==="All" ? allBestSellingBooks : allBestSellingBooks?.filter((book) => book.genres?.includes(tabsData[activeTab]));
    const onTabClick = (index) => {
        setActiveTab(index);
    }

    const isWishListItem = (bookData) => {
        const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title)
        if(bookOpt) {
          return {isPresent : true, data: bookOpt}
        } else {
          return {isPresent : false, data:null}
        }
      }

    return (
        <div className={classes.container}>
            <h2>Best Seller Books</h2>

            <div className={classes.tabs}>
                {tabsData.map((tab, index) => (
                    <div
                        key={tab}
                        className={`${classes.tab} ${activeTab === index ? classes.active : ''}`}
                        onClick={() => onTabClick(index)}
                    >
                    {tab}
                    </div>
                ))}
             </div>

             <div className={classes.books}>
                {books.map((book,index) => (
                    <Card image={getRandomImageForBestSellerBook(book,allColorsData)?.image} altText={getRandomImageForBestSellerBook(book,allColorsData)?.altText} key={index} price={book?.priceGenuine} bookData={book} isWishListItem={isWishListItem(book).isPresent} wishlistItemId={isWishListItem(book)?.data?.id}/>
                ))}
             </div>

        </div>
    )
}

export default BestSeller