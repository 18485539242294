import React from 'react';
import classes from './StatisticsBanner.module.css';

const statistics = [
    { count: '250+', label: 'Happy customers' },
    { count: '600+', label: 'Book customization' },
    { count: '600+', label: 'Book restoration' },
    { count: '15k+', label: 'Largest database' }
];

const StatisticsBanner = () => {
    return (
        <div className={classes.banner}>
            <h2>One of the largest book databases with 15 million antique books.</h2>
            <p>We let you customize your rare and vintage books.</p>
            <div className={classes.statistics}>
                {statistics.map((stat, index) => (
                    <div key={index} className={classes.stat}>
                        <h3>{stat.count}</h3>
                        <div className={classes.statLabel}>
                            <p>{stat.label}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StatisticsBanner;
