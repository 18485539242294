import React,{useState,useEffect} from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleLeft,faAngleRight} from "@fortawesome/free-solid-svg-icons"

import classes from "./CustomerReview.module.css"

import CustomerCard from "../../../Components/Card/CustomerCard/CustomerCard";
import { fetchTestimonials } from "../../../Store/actions/TestimonialActions";
import { useDispatch, useSelector } from "react-redux";


function CustomerReview() {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);
    const [slideDirection, setSlideDirection] = useState("");

    const testimonialData = useSelector((state) => state.testimonial.testimonial);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchTestimonials(dispatch);
    }, [dispatch]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileOrTablet(window.innerWidth <= 768);
        };
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const handlePrev = () => {
        setSlideDirection("slide-right");
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonialData.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setSlideDirection("slide-left");
        setCurrentIndex((nextIndex) => (nextIndex === testimonialData.length - 1 ? 0 : nextIndex + 1));
    };

    const getVisibleCards = () => {
        if (!testimonialData.length) return [];
        if (isMobileOrTablet) {
            return [testimonialData[currentIndex]];
        } else {
            const visibleCards = [];
            for (let i = 0; i < 3; i++) {
                visibleCards.push(
                    testimonialData[(currentIndex + i - 1 + testimonialData.length) % testimonialData.length]
                );
            }
            return visibleCards;
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.headerSection}>
                <div className={classes.header}>
                    <h2>Voices of Satisfaction</h2>
                </div>
                <div className={classes.description}>
                    <p>Customer Experiences: Stories of Delight</p>
                </div>
            </div>

            <div className={classes.corouselSection}>
                <div className={`${classes.carousel} ${classes[slideDirection]}`}>
                    <div className={classes.reviewCards}>
                        {getVisibleCards().map((details, index) => {
                            const cardClass = index === 1 ? classes.middleCard : classes.card;
                            return (
                                <div key={index} className={cardClass}>
                                    <CustomerCard name={details?.authorName} desc={details?.description} image={details?.imageUrl} />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={classes.navButtons}>
                    <button className={classes.navButton} onClick={handlePrev}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <button className={classes.navButton} onClick={handleNext}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CustomerReview;
