import classes from "./ColorSelection.module.css";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import {
  useFabricCanvas,
  useFabricCanvasHandler,
  useFabricEditor,
} from "react-fabric-page";
import { changeAllSectionColors } from "../../../../spineUtils";
import useScreenSize from "../../../../Hooks/UseScreenSize";

import ToolTip from "../../../../Components/ToolTip/ToolTip";
import { CustomizationContext } from "../../Customization";
const SmapleImage = "/Assets/Images/customization/Image.svg";

const ColorSelection = ({
  onClick,
  toggleDropdown,
  dropdownOpen,
  currentDropdown,
  selectedColor,
  setSelectedColor,
  isCanvasReady
}) => {
  const {
    bookLeather,
    bookVariant,
    activeSectionNumber,
    numberOfSections,
    spineColors,
    setSpineColors,
    selectedDesigns
  } = useContext(CustomizationContext);
  const canvas = useFabricCanvas();
  const editor = useFabricEditor();
  const allColorsData = useSelector((state) => state.bookColor.colors);
  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const allowedColors = allColorsData?.filter(
    (color) =>
      color.disabled === false &&
      color.isBestSeller === false &&
      color.variantType === bookVariant &&
      color?.leatherType === bookLeather
  );

  const { isMediumScreen } = useScreenSize();

  const [designedApplied,setDesignApplied] = useState(false);


  useEffect(() => {
    if(selectedDesigns?.spine?.sections?.[0]?.color) {
      const selectedSpineColor = allowedColors?.filter((color) => color?.colorName === selectedDesigns?.spine?.sections?.[0]?.color);
      if(selectedSpineColor) {
        setSelectedColor(selectedSpineColor);
      } else {
        setSelectedColor(allowedColors?.[0]);
      }
      setSelectedColor()
    }
    else {
      setSelectedColor(allowedColors?.[0]);
    }
  },[bookLeather,bookVariant,canvas])

  useEffect(() => {
    window.spineCanvas = canvas;
  }, [canvas]);

  if (editor) {
    editor.leatherColor = selectedColor?.colorValue;
  }

  const handleColorChange = (color, e) => {
    e.stopPropagation();
    setSelectedColor(color);
  };

  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen) {
      toggleDropdown(false);
    } else {
      toggleDropdown(true);
    }
  };

  useEffect(() => {
    if (!selectedColor && allowedColors?.length > 0) {
      setSelectedColor(allowedColors[0]);
    }
  }, [allowedColors, selectedColor]);
  


  const changeCoverColor = (colorCode, colorName) => {
    if (!canvas || !colorCode) return;
  
    if (activeSectionNumber === -1) {
      // Handle "all sections" case
      changeAllSectionColors(canvas, colorCode);
      canvas.renderAll();
  
      const allSections = canvas
        .getObjects()
        .filter((obj) => obj.id && obj.id.startsWith("rib-section-"));
  
      const updatedColors = {};
      let colorChanged = false;
  
      allSections.forEach((section) => {
        if (spineColors[section.id] !== colorName) {
          updatedColors[section.id] = colorName;
          colorChanged = true;
        }
      });
  
      if (colorChanged) {
        setSpineColors((prevColors) => ({ ...prevColors, ...updatedColors }));
      }
    } else {
      // Handle individual section case
      const section = canvas
        .getObjects()
        .find((obj) => obj.id === `rib-section-${activeSectionNumber}`);
  
      if (section && spineColors[`rib-section-${activeSectionNumber}`] !== colorCode) {
        section.getObjects().forEach((obj) => {
          if (obj.type === "rect") {
            obj.set({ fill: colorCode });
          }
        });
        section.dirty = true;
  
        setSpineColors((prevColors) => ({
          ...prevColors,
          [`rib-section-${activeSectionNumber}`]: colorName,
        }));
  
        // Handle adjacent sections if needed
        if (activeSectionNumber === 1) {
          // Top section
          const topSection = canvas.getObjects().find((obj) => obj.id === "rib-section-top");
          if (topSection && spineColors["rib-section-top"] !== colorCode) {
            topSection.getObjects().forEach((obj) => {
              if (obj.type === "rect") {
                obj.set({ fill: colorCode });
              }
            });
            topSection.dirty = true;
  
            setSpineColors((prevColors) => ({
              ...prevColors,
              "rib-section-top": colorName,
            }));
          }
        }
  
        if (activeSectionNumber === numberOfSections) {
          // Bottom and between-lines sections
          const bottomSection = canvas.getObjects().find((obj) => obj.id === "rib-section-bottom");
          const betweenLinesSection = canvas.getObjects().find((obj) => obj.id === "rib-section-between-lines");
  
          if (betweenLinesSection && spineColors["rib-section-between-lines"] !== colorCode) {
            betweenLinesSection.getObjects().forEach((obj) => {
              if (obj.type === "rect") {
                obj.set({ fill: colorCode });
              }
            });
            betweenLinesSection.dirty = true;
  
            setSpineColors((prevColors) => ({
              ...prevColors,
              "rib-section-between-lines": colorName,
            }));
          }
  
          if (bottomSection && spineColors["rib-section-bottom"] !== colorCode) {
            bottomSection.getObjects().forEach((obj) => {
              if (obj.type === "rect") {
                obj.set({ fill: colorCode });
              }
            });
            bottomSection.dirty = true;
  
            setSpineColors((prevColors) => ({
              ...prevColors,
              "rib-section-bottom": colorName,
            }));
          }
        }
  
        canvas.renderAll();
      }
    }
    editor.saveCurrentState();
  };
  
  useEffect(() => {
    if (selectedColor?.colorValue) {
      changeCoverColor(selectedColor.colorValue, selectedColor.colorName);
    }
  }, [selectedColor, activeSectionNumber,isCanvasReady]);

  useEffect(() => {
    if(selectedDesigns?.customization?.spine) {
      selectedDesigns?.customization?.spine?.sections?.forEach((section,index) => {
        setSpineColors((prevColors) => ({
          ...prevColors,
          [`rib-section-${index}`]: section?.color,
        }));
      })
    }

  },[selectedDesigns])

  useFabricCanvasHandler({
    'canvas:x-ready': () => {
      const allSections = canvas
      .getObjects()
      .filter((obj) => obj.id && obj.id.startsWith("rib-section-"));
      
      allSections.forEach((section) => {
        const selectedSectionColor = allowedColors?.find((color) => color?.colorValue === section?._objects?.[0]?.fill);
        if(selectedSectionColor) {
          setSpineColors((prevColors) => ({
            ...prevColors,
            [section?.id]: selectedSectionColor?.colorName,
          }));

          if (activeSectionNumber === -1 || section?.id === `rib-section-${activeSectionNumber}`) {
            setSelectedColor(selectedSectionColor);
          }
        }
      });
    },
  },[allowedColors]);

  return (
    <div>
      {isMediumScreen ? (
            <div
            className={`${classes.colorPickerDropdown} ${classes.open}`}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>Select leather color for spine section</h3>

            {/* <div className={classes.message}>
              <h2>Color different from front cover will cost</h2>
              <p>+40$</p>
            </div> */}

            <div className={classes.selectColor}>
              {/* Display active section number */}
              <h2>
                {activeSectionNumber === -1
                  ? "Color for All Sections"
                  : `Color for Section: ${activeSectionNumber}`}
              </h2>

              {/* Display selected color name */}
              <h3>Selected Color: {selectedColor?.colorName}</h3>

              {/* Color selection boxes */}
              <div className={classes.colors}>
                {allowedColors?.map((color, index) =>
                  selectedColor?.colorValue === color?.colorValue ? (
                    <div key={index} className={classes.selected}>
                      <div
                        className={classes.active}
                        style={{ background: color?.colorValue }}
                      ></div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className={classes.colorPicker}
                      style={{ background: color?.colorValue }}
                      onClick={(e) => handleColorChange(color, e)}
                    ></div>
                  )
                )}
              </div>
            </div>

            <div className={classes.message2}>
              <FontAwesomeIcon icon={faCircleInfo} />
              <p>We recommend Color same as front cover as given image</p>
            </div>

            {/* <div className={classes.imageSection}>
              <div className={classes.tag}>
                <p>Recommended</p>
              </div>
              <img src={SmapleImage} alt="" />
            </div> */}
          </div>
      ) : (
        <div
          className={classes.ColorOption}
          onClick={() => handleClickAndSelect()}
        >
          <div className={classes.colorHeader}>
            <div className={classes.HeaderText}>
              <h2>Choose Spine color</h2>
              <ToolTip tooltipText={"Select Spine Color"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Choose Spine color" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Choose Spine color" ? (
            <div
              className={`${classes.colorPickerDropdown} ${classes.open}`}
              onClick={(e) => e.stopPropagation()}
            >
              <h3>Select leather color for spine section</h3>

              <div className={classes.message}>
                <h2>Color different from front cover will cost</h2>
                <p>+${customizationPricing?.spineSectionColorPrice}</p>
              </div>

              <div className={classes.selectColor}>
                {/* Display active section number */}
                <h2>
                  {activeSectionNumber === -1
                    ? "Color for All Sections"
                    : `Color for Section: ${activeSectionNumber}`}
                </h2>

                {/* Display selected color name */}
                <h3>Selected Color: {selectedColor?.colorName}</h3>

                {/* Color selection boxes */}
                <div className={classes.colors}>
                  {allowedColors?.map((color, index) =>
                    selectedColor?.colorValue === color?.colorValue ? (
                      <div key={index} className={classes.selected}>
                        <div
                          className={classes.active}
                          style={{ background: color?.colorValue }}
                        ></div>
                      </div>
                    ) : (
                      <div
                        key={index}
                        className={classes.colorPicker}
                        style={{ background: color?.colorValue }}
                        onClick={(e) => handleColorChange(color, e)}
                      ></div>
                    )
                  )}
                </div>
              </div>

              <div className={classes.message2}>
                <FontAwesomeIcon icon={faCircleInfo} />
                <p>We recommend Color same as front cover as given image</p>
              </div>

              <div className={classes.imageSection}>
                <div className={classes.tag}>
                  <p>Recommended</p>
                </div>
                <img src={SmapleImage} alt="" />
              </div>
            </div>
          ) : (
            <p>{selectedColor?.colorName}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ColorSelection;
