import React from "react";
import classes from "./MessageDialogue.module.css";
import Modal from "../Modal/Modal";
const closeIcon = "/Assets/Icons/icon-x.svg";
const successIcon = "/Assets/Icons/icon-success.svg";

function MessageDialogue({
  onClose,
  title = "Success",
  message,
  dialogueIcon = successIcon,
}) {
  return (
    <Modal className={classes.modal}>
      <div className={classes.main}>
        <div className={classes.closeIcon} onClick={onClose} >
          <img src={closeIcon} alt="close"/>
        </div>
        <div className={classes.referIcon}>
          <img src={dialogueIcon} alt="success" />
        </div>
        <div className={classes.messageWrapper}>
          <div className={classes.title}>{title}</div>
          <div className={classes.messageContainer}>{message}</div>
        </div>
      </div>
    </Modal>
  );
}

export default MessageDialogue;
