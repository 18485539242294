import axios from "../../Axios";
import Consts from "../../Consts";

export const ContactUsAction = (data) => {
    return axios({
        url:`${Consts.SERVER_URL}/contact-us`,
        data:data,
        method:"post",
        responseType:"json"
    })
    .then((response) => {
        return response
    })
    .catch((error) => {
        console.error(error)
    })
}

export const DisputeOrderAction = (data) => {
    return axios({
        url:`${Consts.SERVER_URL}/contact-us/dispute-order`,
        data:data,
        method:"post",
        responseType:"json"
    })
    .then((response) => {
        return response
    })
    .catch((error) => {
        console.error(error)
    })
}

export const subscribe = (data) => {
    return axios({
        url:`${Consts.SERVER_URL}/contact-us/subscribe`,
        data:data,
        method:"post",
        responseType:"json"
    })
    .then((response) => {
        return response
    })
    .catch((error) => {
        console.error(error)
    })
}