import classes from "./MyCart.module.css"

import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb"
import { urls } from "../../Routes/Urls"
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav"
import Cart from "./Cart/Cart"
import OrderSummary from "./OrderSummary/OrderSummary"
import Footer from "../Footer/Footer"
import Recommendations from "../ProductDetail/Recommendation/Recommendations"
import { useSelector } from "react-redux"
import useScrollToTop from "../../Hooks/useScrollToTop";

const MyCart = () => {

    const crumbs = [
        {title:"Home",path:"/"},
        {title:"Collection",path:urls.LISTING},
        {title:"My Cart",path:urls.CART},
    ]

    const cartItems = useSelector((state) => state.cart.cartItems)

    const scrollRef = useScrollToTop();
    return(
        <div ref={scrollRef}>
            <PrimaryNav />
            <div className={classes.mainContainer}>
                <BreadCrumb className={classes.breadCrumbs} crumbs={crumbs} />  
                <div className={classes.header}>
                    <h2>My Cart</h2>
                    <p>Please don't hesitate to reach out to us at your convenience. We'll respond promptly!</p>
                </div>
                <div className={classes.cartSection}>
                    <div className={classes.cart}>
                        <Cart cartItems={cartItems}/>
                    </div>
                    <div className={classes.summary}>
                        <OrderSummary />
                    </div>
                </div>
            </div>

            <div className={classes.recommendations}>
                <Recommendations />
            </div>
            <Footer />

        </div>
    )
}

export default MyCart;