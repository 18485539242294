import classes from "./BlogPostCard.module.css"

const flagPlaceholder = "/Assets/Images/flag_placeholder.png"

function BlogPostCard({label,date,readTime,title,description,author,authorRole,countryCode,onClick,isTrending}){
    return(
        <div className={classes.container} onClick={onClick}>
            <div className={classes.headerDetails}>
                <div className={classes.label}>
                    <p>{label}</p>
                </div>
                <p>{date} | {readTime}</p>
            </div>

            <div className={classes.blogBody}>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>

            <div className={classes.authorDetails}>
                {/* <img src={authorImage} alt={author} /> */}
                <div className = {classes.author}>
                    <img src={flagPlaceholder} alt={author} className={` ${classes.flag} ${classes[`flag-${countryCode}`]}`} />
                    <div className={classes.authorName}>
                        <h3>{author}</h3>
                        <p>{authorRole}</p>
                    </div>
                </div>
                <div className={classes.trending}>
                    {isTrending && 
                        <div className={classes.isTrending}>Trending</div>
                        }
                </div>
            </div>

        </div>
    )
}


export default BlogPostCard;