import {ActiveObject,fabric} from 'react-fabric-page';

export function addTextObject(canvas, newValue, props, isCurved) {
  let contentsLayer = canvas.getLayerById('contents');
  let textObject = new fabric[isCurved ? 'CurvedText' : 'ShapedText'](newValue, {
    left: contentsLayer.width / 2,
    top: contentsLayer.height / 2,
    fontSize: 28,
    scaleX: 0.8,
    scaleY: 0.8,
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlignment: 'center',
    ...props
  });
  if (contentsLayer) {
    contentsLayer.add(textObject);
    canvas.setActiveObject(textObject);
  }
}

export function addTextboxObject(canvas, options) {
  let position = {
    "left": 49.1,
    "top": 100.5,
  }
    const contentsAddLayer = canvas.getObjects()?.find(o => o.id === 'contents')

  const IText = fabric.TextArea;

  let textbox = new IText('', {
    ...position,
    fontSize: 12,
    width: 190,
    scaleX: 0.665168,
    scaleY: 0.665168,
    hasControls:false,
    ...options
  });
  contentsAddLayer.add(textbox);
  canvas.setActiveObject(textbox);
  textbox.enterEditing();
  textbox.onInput();
}

export function addImageToCanvas(imageData, scaling = 0.3, getBounds, range, selectable = true, evented = true) {
  return new Promise(function (resolve, reject) {
    if (imageData.fileType === 'svg') {
      fabric.loadSVGFromURL(imageData.filePath, function (objects, options) {
        if (!objects || objects.length === 0) {
          reject();
          return;
        }

        var group = fabric.util.groupSVGElements(objects, options);
        var bounds = getBounds?.();
        if (bounds) {
          var position = {
            x: bounds.left,
            y: bounds.top
          };

          var scale = 1.0,
              scaleX = 1.0,
              scaleY = 1.0;

          scaleX = (bounds.width / group.width) * scaling;
          scaleY = (bounds.height / group.height) * scaling;
          scale = Math.min(scaleX, scaleY);

          // position.y += (bounds.height - scale * group.height) / 2;
          position.y += (bounds.height)
          position.x += (bounds.width - scale * group.width) / 2;

          group.scale(scale, scale);
          group.set({
            left: position.x,
            top: position.y,
            ...range,
            selectable: selectable,  // Use the function argument
            evented: evented       // Use the function argument
          });
        }
        resolve(group);
      });
    } else {
      fabric.Image.fromURL(imageData.filepath, function (img) {
        var bounds = getBounds?.();
        if (bounds) {
          var position = {
            x: bounds.left,
            y: bounds.top
          };

          var scaleX = bounds.width / img.width;
          var scaleY = bounds.height / img.height;
          var scale = Math.max(scaleX, scaleY) * scaling;

          // position.y += (bounds.height - scale * img.height) / 2;
          // position.y += (bounds.height);
          position.x += (bounds.width - scale * img.width) / 2;

          img.set({
            left: position.x,
            top: position.y,
            ...range,
            selectable: selectable,  // Use the function argument
            evented: evented       // Use the function argument
          }).scale(scale);
        }
        resolve(img);
      });
    }
  });
}

export function addImageToEndpaper(canvas, imageData, scaling = 1.0) {
  return new Promise((resolve, reject) => {
    const existingEndpaper = canvas.getObjects().find(obj => obj.id === 'background-design-image');
    if (existingEndpaper) {
      canvas.remove(existingEndpaper);
    }

    if (imageData.fileType === 'svg') {
      fabric.loadSVGFromURL(imageData.filePath, function (objects, options) {
        if (!objects || objects.length === 0) {
          reject(new Error('Failed to load SVG image.'));
          return;
        }

        const group = fabric.util.groupSVGElements(objects, options);
        const canvasWidth = canvas.pageWidth
        const canvasHeight = canvas.pageHeight

        const scaleX = canvasWidth / group.width;
        const scaleY = canvasHeight / group.height;
        const scale = Math.max(scaleX, scaleY) * scaling;

        group.scale(scale);
        group.set({
          left: 0,
          top: 0,  
          selectable: false,
          evented: false,
          id: 'background-design-image'
        });

        canvas.add(group);
        canvas.sendToBack(group);
        canvas.renderAll();
        resolve(group);
      });
    } else {
      fabric.Image.fromURL(imageData.filepath, function (img) {
        const canvasWidth = canvas.pageWidth
        const canvasHeight = canvas.pageHeight

        const scaleX = canvasWidth / img.width;
        const scaleY = canvasHeight / img.height;
        const scale = Math.max(scaleX, scaleY) * scaling; 

        img.set({
          left: 0, 
          top: 0,
          selectable: false,
          evented: false,
          id: 'background-design-image'
        }).scale(scale);

        canvas.add(img);
        canvas.sendToBack(img); 
        canvas.renderAll();
        resolve(img);
      });
    }
  });
}


export async function placeBackground(canvas, img, getBounds) {
  let background = canvas.getLayerById('background');
  if (background) {
    background._objects = [];
    let obj = await addImageToCanvas(img, 1, getBounds);
    obj.set({
      selectable: false,
      evented: false
    });
    background.add(obj);
    canvas.renderAll();
  }
}



export class ActiveTextObject extends ActiveObject {
  constructor(obj) {
    super(obj);
    this._activeObjects = this._activeObjects.filter(o => o.get('type') === 'shaped-text' || o.get('type') === 'curved-text');
  }
}

const rangeProps = new Set(['fontSize', 'fontWeight', 'fontStyle', 'fill', 'stroke', 'fontFamily', 'underline']);
export class ActiveTextboxObject extends ActiveObject {
  constructor(obj) {
    super(obj);
    const Textbox = window.fabric.IText;
    this._activeObjects = this._activeObjects.filter(o => o instanceof Textbox);
  }
  getLinedPosition(textLines, position) {
    let lineNum = 0;
    while (position > 0) {
      const len = textLines[lineNum].length + 1;
      if (len > position) {
        break;
      }
      position -= len;
      ++lineNum;
    }
    return { position, lineNum };
  }
  iterateSelectedChars(o, callback, startIndex, endIndex) {
    const textLines = o.text.split('\n');
    startIndex = startIndex ??  o.selectionStart;
    endIndex = endIndex ?? o.selectionEnd;
    let { position, lineNum } = this.getLinedPosition(textLines, startIndex);
    let lineLen = textLines[lineNum].length;
    for (let i = 0, len = endIndex - startIndex; i < len; ++i) {
      if (position > lineLen || lineLen === 0) {
        position = 0;
        lineLen = textLines[++lineNum].length;
      }
      if (lineLen > 0) {
        if (callback(lineNum, position)) {
          break;
        }
        position++;
      }
    }
  }
  updateStyles(o, style, startIndex, endIndex) {
    const styles = o.styles || {};
    this.iterateSelectedChars(o, (lineNum, position) => {
      styles[lineNum] = styles[lineNum] || {};
      styles[lineNum][position] = styles[lineNum][position] || {};
      Object.assign(styles[lineNum][position], style);
    }, startIndex, endIndex);
    return styles;
  }
  _getValue(o, key) {
    const styles = o.styles || {};
    let val = o.get(key);
    if (rangeProps.has(key) && o.isEditing) {
      if (o.selectionStart !== o.selectionEnd) {
        let res = null;
        this.iterateSelectedChars(o, (lineNum, position) => {
          const v = styles[lineNum]?.[position]?.[key];
          if (res === null) {
            res = v;
          }
          if (res !== v) {
            res = val;
            return true;
          }
        });
        return res ? res : val;
      } else {
        let styleCache = o.styleCache;
        if (styleCache?.key !== o.selectionStart) {
          let { position, lineNum } = this.getLinedPosition(o.text.split('\n'), o.selectionStart - 1);
          styleCache = {
            key: o.selectionStart,
            lineNum,
            style: JSON.parse(JSON.stringify(
              styles[lineNum]?.[position] ||
              styleCache?.style ||
              {}
            ))
          }
          styles[lineNum] = styles[lineNum] || {};
        }
        o.styleCache = styleCache;
        const v = styleCache.style?.[key];
        if (v) {
          return v;
        }
      }
    }
    return val;
  }
  _setValue(o, key, value) {
    o.dirty = true;
    if (rangeProps.has(key)) {
      if(o.isEditing){
        if (o.selectionStart !== o.selectionEnd) {
          o.set('styles', this.updateStyles(o, { [key]: value }));
          if (o.selectionStart === o.styleCache?.key || o.selectionEnd === o.styleCache?.key) {
            o.styleCache.style[key] = value;
          }
        } else if (o.selectionStart === o.styleCache?.key) {
          o.styleCache.style[key] = value;
        } else {
          o.set(key, value);
        }
      } else {
        o.set('styles', this.updateStyles(o, { [key]: value }, 0, o.text.length - 1));
        o.set(key, value);
      }
    } else {
      o.set(key, value);
    }
    if (key === 'enableTransliteration') {
      o.initHiddenTextarea();
      o.hiddenTextarea.focus();
      o.hiddenTextarea.value = o.text;
      o._updateTextarea();
    }
  }
  set(key, value) {
    const targets = this.getTargetObjects();
    const canvas = targets[0].canvas;
    for (let o of targets) {
      this._setValue(o, key, value);
    }
    canvas.fire('object:modified', { target: targets[0] });
    canvas.renderAll();
  }
  setProps(object) {
    const targets = this.getTargetObjects();
    const canvas = targets[0].canvas;
    for (let key in object) {
      for (let o of targets) {
        this._setValue(o, key, object[key]);
      }
    }
    canvas.fire('object:modified', { target: targets[0] });
    canvas.renderAll();
  }
}

export const getLineLengthAndAngle = (line) => {
  const dx = line.x2 - line.x1;
  const dy = line.y2 - line.y1;
  const length = Math.sqrt(dx * dx + dy * dy);  // Line length
  const angle = (Math.atan2(dy, dx) * 180) / Math.PI;  // Line angle in degrees
  return { length, angle };
};

export const addImageToLine = (canvas, layer, line, img, id) => {
  return new Promise((resolve) => {
    const { length, angle } = getLineLengthAndAngle(line);
    const widthScale = length / img.width;  // Scale factor for the width to match the line's length
    const heightScale = line.strokeWidth / img.height;  // Scale factor for height to match strokeWidth

    // Clone the img to prevent modifying the same instance multiple times
    img.clone((clonedImg) => {
      let leftOffset = 0;
      let topOffset = 0;

      if (angle === 90) {
        leftOffset = line.strokeWidth;  // Shift left for 90° line
      } else if (angle === 45) {
        leftOffset = line.strokeWidth;
        topOffset = -line.strokeWidth/4;  // Shift left and down for 45° line
      } else if (angle === 135) {
        leftOffset = line.strokeWidth;
        topOffset = line.strokeWidth;  // Shift left and down for 135° line
      }

      clonedImg.set({
        scaleX: widthScale,
        scaleY: heightScale,
        angle: angle,
        left: line.x1 + leftOffset,
        top: line.y1 + topOffset,
        originX: 'left',
        originY: 'top',
        id: id,
      });

      layer.add(clonedImg);
      layer.dirty = true;

      resolve(clonedImg);
    });
  });
};

