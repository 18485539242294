import React from 'react';
import classes from './BlogCardLarge.module.css'
const flagPlaceholder = "/Assets/Images/flag_placeholder.png"

function BlogCardLarge({ image, date, title, authorName, onClick, category,desc,countryCode }) {
  return (
    <div className={classes.cardContainer} onClick={onClick}>
      <div className={classes.imageContainer}>
        <img src={image} alt="Article" className={classes.image} />
        <div className={classes.categoryTag}>{category}</div>
      </div>
      <div className={classes.cardContent}>
        <p className={classes.date}>{date}</p>
        <h3 className={classes.title}>{title}</h3>
        <p className={classes.desc}>{desc}</p>
        <div className={classes.authorSection}>
          <img src={flagPlaceholder} alt={authorName} className={`${classes.authorImage} ${classes.flag} ${classes[`flag-${countryCode}`]}`} />
          <div className={classes.authorNameDetails}>
            <h2 className={classes.authorName}>{authorName}</h2>  
            <p>Writer</p>
          </div>
        </div>
      </div>
    </div>
      );
}

export default BlogCardLarge;
