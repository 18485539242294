import { useState } from "react"
import Modal, { ModalBody } from "../../../Components/Modal/Modal"
import classes from "./VariantType.module.css"

const deluxe = "../../../Assets/Images/Leather-Binding-Deluxe.png"
const superdeluxe = "../../../Assets/Images/Leather-Binding-Super Deluxe.png"

const hexagon = "../../../Assets/Icons/hexagon.svg"

const tabsData = [
    {
        name: "Deluxe Edition",
        value: "deluxe"
    },
    {
        name: "Super Deluxe Edition",
        value: "superDeluxe"
    }
]

const tabContent = [
    {
        type : "deluxe",
        value : "This half-leather binding seamlessly blends premium leather with handcrafted marbled paper, offering a fusion of elegance and artistry. Leather covers approximately 50% of the book’s exterior, including the spine and corners, providing both durability and luxury. The remaining surfaces are adorned with meticulously crafted marbled paper, harmonized to complement the leather’s color, creating a cohesive and sophisticated aesthetic. This edition not only enhances the book’s visual appeal but also elevates it into a timeless collector’s piece.", 
        image : deluxe,
        features : ["Premium Half-Leather Binding","Artisanal Aesthetics - Marble Paper Covers","Cost Effective"]
    },
    {
        type : "superDeluxe",
        value : "This premium edition is expertly crafted entirely from fine leather, reflecting luxury and elegance. Designed for discerning collectors, it offers a perfect blend of sophistication and durability, making it an ideal centerpiece for any personal library or displayshelf. With its impeccable craftsmanship and luxurious materials, the Super Deluxe Edition is the ultimate choice for those seeking an extraordinary addition to their book collection.",
        image : superdeluxe,
        features : ["Premium Full-Leather Craftsmanship","Enhanced Durability with Reinforced Binding","Collector’s Quality with Customization Options"]
    }
]

const VariantType = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState(tabsData[0])

    return (
        <Modal title={"Know about Variant type"} onClose={onClose}>
            <ModalBody>
                <div className={classes.container} style={{width:"50vw",padding: "2.5rem 0",borderTop:"1px solid #D0D4D7" } }>
                    <div className={classes.tabs}>
                        {tabsData.map((tab) => (
                            <div className={`${classes.tab} ${activeTab.value === tab.value ? classes.tabActive : ""}`} onClick={() => setActiveTab(tab)}>
                                {tab.name}
                            </div>
                        ))}
                    </div>

                    <div className={classes.detailSection}>
                        <div className={classes.image}>
                            <img src={tabContent.find((content) => content.type === activeTab.value).image} alt="" />
                        </div>

                        <div className={classes.details}>
                            <h2>{activeTab.name}</h2>
                            <p>
                                {tabContent.find((content) => content.type === activeTab.value).value}
                            </p>
                        </div>
                    </div>

                    <div className={classes.featureSection}>
                    {tabContent.find((content) => content.type === activeTab.value)?.features.map( (content) => (
                        <div className={classes.features}>
                            <div className={classes.image}>
                                <img src={hexagon} alt="" />
                            </div>
                            <div className={classes.description}>
                                <h2>{content}</h2>
                                {/* <p>{content}</p> */}
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default VariantType;