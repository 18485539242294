import classes from "./Accordion.module.css"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown,faAngleUp } from "@fortawesome/free-solid-svg-icons"
import ToolTip from "../../../../Components/ToolTip/ToolTip"

const Accordion = ({onClick,title,currentDropdown,dropdownName,toggleDropdown,dropdownOpen=false,toolText}) => {

    return (
            <div className={classes.colorHeader}>
            <div className={classes.headerText}>
                <h2>{title}</h2>
                {toolText && <ToolTip tooltipText={toolText} />}
            </div>
                <div className={classes.dropDownImage} onClick={toggleDropdown}>
                    {dropdownOpen && dropdownName === currentDropdown ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                </div>
            </div>
    )
}

export default Accordion