import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./PreviewModal.module.css";
import { faCircleInfo, faXmark } from "@fortawesome/free-solid-svg-icons";
import Viewer from "../../../WebGLViewer/Viewer";

const PreviewModal = ({ textureData,bookData, onClose }) => {
    return (
        <div className={classes.mainContainer}>
            <div className={classes.modalContent}>
                <div className={classes.header}>
                    <h2>3D Preview</h2>
                    <div onClick={onClose} className={classes.cancelBtn}>
                        <FontAwesomeIcon icon={faXmark}  className={classes.closeButton} />
                    </div>
                </div>
                <div className={classes.info}>
                <p >
                <FontAwesomeIcon icon={faCircleInfo} /> Click the book to rotate and enjoy a full 360-degree view.</p>
                </div>
                <div className={classes.viewer}>
                    <Viewer textureData={textureData} bookData={bookData} />
                </div>
            </div>
        </div>
    );
};

export default PreviewModal;
