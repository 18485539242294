import React from "react";
import classes from "./Community.module.css"

import SearchComponent from "../SearchComponent/SearchComponent";


const Community = () => {
  
  return (
    <div className={classes.ctaContainer}>
      <div className={classes.textContent}>
        <h2>Join our Community</h2>
        <p>Connect with fellow bibliophiles. Join our community of rare book enthusiasts and collectors.</p>
      </div>
      <div className={classes.ctaButton}>
        <SearchComponent/>
      </div>
    </div>
  );
};

export default Community;
