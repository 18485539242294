import classes from "./AdditionalRequest.module.css"
import {useContext } from "react"
import PrimaryTextArea from "../../../Components/Inputs/PrimaryTextArea/PrimaryTextArea"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleXmark, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { CustomizationContext } from "../Customization"
import useScreenSize from "../../../Hooks/UseScreenSize"

// const bookSections = ["Front Cover", "Spine", "Back Cover", "Paper", "End Paper"]

const AdditionalRequest = () => {

    const {setAdditionalRewquestText,
        additionalRequestImage,setAdditionalRequestImage} = useContext(CustomizationContext);

    const {isMediumScreen} = useScreenSize()

    const handleFileSelect = (event) => {
        const file = event.target.files[0]; // Get the first selected file
        if (file) {
            setAdditionalRequestImage(file); // Set the file to state
        }
    };
        
    const handleClick = () => {
        document.getElementById("fileInput").click();
    };

    return (
        <div className={classes.mainContainer}>
            <div className={classes.details}>
                <div className={classes.headerSection}>
                   {!isMediumScreen && <h2>Do you have any Additional Request (5/5)</h2>}

                    <p>Place your request for any additional customizations and our team will reach out to you! </p>
                </div>
                {/* <div className={classes.bookSection}>
                    <h2>Select Book Section</h2>
                    <div className={classes.customDropdown}>
                        <div
                            className={classes.selectedOption}
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                        >
                            <div className={classes.dropdownBar}>
                                <div className={classes.dropdownText}>
                                    <span>{selectedSection}</span>
                                    <span>{"+$40"}</span>
                                </div>
                                <div className={classes.dropdownIcon}>
                                    <img src={dropDownIcon} alt="" />
                                </div>
                            </div>
                        </div>
                        {isDropdownOpen && (
                            <div className={classes.dropdownOptions}>
                                {bookSections.map((section,index) => (
                                    <div
                                        key={index}
                                        className={classes.option}
                                        onClick={() => setSelectedSection(section)}
                                    >
                                        <span>{section}</span>
                                        <span>{"+$40"}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div> */}
                <div className={classes.inputSection}> 
                    <PrimaryTextArea onChange={(e) => setAdditionalRewquestText(e.target.value) } />
                </div>

                <div className={classes.uploadBtn} onClick={handleClick}>
                  <div className={classes.addLogo}>
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className={classes.addLogoIcon}
                    />
                    <p>Upload File</p>
                  </div>
                  {/* <div className={classes.logoPrice}>+$40</div> */}
                </div>

                {/* Hidden file input to select an image */}
                <input
                  type="file"
                  id="fileInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileSelect} // Trigger on file selection
                />
                {/* Optional: Image preview (if image is selected) */}
                {additionalRequestImage && (
                  <div className={classes.imagePreview}>
                    <img
                      src={URL.createObjectURL(additionalRequestImage)} // Convert file to URL for preview
                      alt="Selected Design"
                    />
                    <FontAwesomeIcon className={classes.deleteImage} icon={faCircleXmark} onClick={() => setAdditionalRequestImage(null)} />
                  </div>
                )}

                <div className={classes.disclaimer}>
                    <p>Disclaimer : uploaded design won’t be available for live preview
                    We will contact you on your registered email / mobile to understand your needs more precisely.</p>
                </div>

            </div>



        </div>
    )
}

export default AdditionalRequest