import { useSelector } from "react-redux"
import VideoPlayer from "../../../Components/VideoPlayer/VideoPlayer"
import { Card } from "./Card/Card"
import classes from "./FeatureVideo.module.css"
const data = [
    {
        text:"View Live Preview while customizing book"
    },
    {
        text:"Luxury Finishes: Custom Endpapers and Gilded Edges"
    },
    {
        text:"Add individuality with text, colors, and Logo"
    }
    ]

export const FeatureVideo = () => {
    const video = useSelector((state) => state.serviceVideo.videos?.servicesVideo);

    return(
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <h2>"Your Book, Your Way: Customize Every Aspect from Cover to Gilding"</h2>
                <p>Craft Your Perfect Book: Customizable Front Covers, Spines, Endpapers, and Gilding</p>
            </div>

            <div className={classes.cardContainer}>
               
                {data.map((card,index) => (
                    <Card key={index} index={index+1} data={card.text} />
                ))}
            </div>

            <div className={classes.videoContainer}>
                <VideoPlayer videoSrc={video} button={true}/>
                {/* <PrimaryButton>Explore Our Collection</PrimaryButton> */}
            </div>
        </div>


    )
}