import axios from 'axios';

const CUSTOMIZATION_URL = "/api/customization-designs"
const CUSTOMIZATION_PRICING = "/api/customization-pricing"

export function getAllUserDesigns(userid,bookId,currentPage,pageSize){
    const filter = {
        where : {
            $and : [
                {userId : userid},
            ]
        }
    }
    if(bookId) {
        filter.where.$and.push({bookId : bookId})
    }
    if(currentPage && pageSize) {
        filter.offset = (currentPage - 1) * pageSize;
        filter.limit = pageSize
    }


    return axios({
        url:`${CUSTOMIZATION_URL}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method:"get"
    })
}

export function saveNewDesign(JSONLinks,bookId,bookTitle,bookCustomization,variant,leather,sizeName,pages){
    const formData = new FormData();

    JSONLinks.forEach((blob, index) => {
        formData.append(`files`, blob, `file${index}.json`);
    });
    formData.append('bookCustomization', JSON.stringify(bookCustomization));
    formData.append('variant', variant);
    formData.append('leatherType', leather);
    formData.append('sizeName',sizeName)
    formData.append('pages',pages);
    formData.append('bookId',bookId);
    formData.append("bookName",bookTitle)

    return axios({
        url:`${CUSTOMIZATION_URL}/create`,
        data:formData,
        method:"post"
    })
}

export function updateCustomizationDesign(sizeName,pages,designId,JSONLinks,bookCustomization){
    console.log(sizeName)
    const formData = new FormData();

    JSONLinks.forEach((blob, index) => {
        formData.append(`files`, blob, `file${index}.json`);
    });

    formData.append("bookCustomization",JSON.stringify(bookCustomization));
    formData.append("sizeName",sizeName);
    formData.append("pages",pages);
   
    return axios({
        url:`${CUSTOMIZATION_URL}/update/${designId}`,
        data:formData,
        method:"put"
    })
}

export function countCustomizationDesign(filter){
    return axios({
        url:`${CUSTOMIZATION_URL}/count?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method:"get"
    })
}

export function deleteCustomizationDesign(designId){
    return axios({
        url:`${CUSTOMIZATION_URL}/delete/${designId}`,
        method:"delete"
    })
}

export function calculateCustomizationCharges(bookCustomization) {
    return axios({
        url:`${CUSTOMIZATION_PRICING}/calculate`,
        method:"post",
        data:bookCustomization
    })
}


