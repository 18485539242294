import { createSlice } from "@reduxjs/toolkit";


const FeaturedCollectionSlice = createSlice({
    name:"featured",
    initialState:{
        featuredCollection:[]
    },
    reducers:{
        updateFeaturedCollection: (state,action) => {
            state.featuredCollection = action.payload;
        }
    }
})

export const featuredCollectionAction = FeaturedCollectionSlice.actions
export default FeaturedCollectionSlice.reducer