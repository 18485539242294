import classes from "./AddTextLogo.module.css"
import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { addTextToSection } from "../../../../spineUtils";
import { useFabricCanvas } from "react-fabric-page";
import EditText from "./EditText/EditText";
import Logo from "./Logo/Logo";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import OptionCapsules from "../../../../Components/OptionCapsules/OptionCapsules";
import { CustomizationContext } from "../../Customization";


const optionTabs = [
    {
        name:"Logo",
        value:"logo"
    },
    {
        name:"Text",
        value:"text"
    },
    {
        name:"None",
        value:"none"
    },
]

const AddTextLogo = ({ onClick, toggleDropdown, dropdownOpen, currentDropdown, activeSectionNumber, isCanvasReady }) => {
    const [selectedOption,setSelectedOption] = useState(optionTabs[2].value);
    const {numberOfSections,setSpineLogo,setSpineText} = useContext(CustomizationContext);

    const { isMediumScreen } = useScreenSize()

    const canvas = useFabricCanvas();

    const handleDesignChange = () => {
        if (selectedOption === optionTabs[2].value) { // Logo selected
            if (canvas) {
                const layer = canvas.getObjects().find(o => o.id === `rib-section-${activeSectionNumber}`);
                if (layer) {
                    layer._objects = layer._objects.filter(o => o.type !== "image" && o.type !== "textArea");
                    layer.dirty = true;
                    canvas.renderAll();
                }
                setSpineLogo(prev => {
                    const newLogo = { ...prev };
                    delete newLogo[activeSectionNumber];
                    return newLogo;
                });
                // Remove the text from spineText when "Logo" is selected
                setSpineText(prev => prev.filter(textObj => textObj.id !== `rib-section-${activeSectionNumber}`));
            }
        }
        else if (selectedOption === optionTabs[1].value) { // Add Text/Logo selected
            if (canvas) {
                const layer = canvas.getObjects().find(o => o.id === `rib-section-${activeSectionNumber}`);
                if (layer) {
                    if (numberOfSections !== 1) {
                        layer._objects = layer._objects.filter(o => o.type !== "image");
                    } else {
                        const imageObj = layer._objects.filter(o => o.type !== "image");
                        if (imageObj?.length > 1) {
                            const latestImage = imageObj[imageObj.length - 1];
                            layer._objects = layer._objects.filter(o => o !== latestImage);
                        }
                    }
                    layer.dirty = true;
                }
                addTextArea();
            }
        }
        else if (selectedOption === optionTabs[0].value) { // None selected
            if (canvas) {
                const layer = canvas.getObjects().find(o => o.id === `rib-section-${activeSectionNumber}`);
                if (layer) {
                    if (numberOfSections !== 1) {
                        layer._objects = layer._objects.filter(o => o.type !== "textArea");
                    } else {
                        const textObj = layer._objects.filter(o => o.type !== "textArea");
                        if (textObj?.length > 1) {
                            const latestImage = textObj[textObj.length - 1];
                            layer._objects = layer._objects.filter(o => o !== latestImage);
                        }
                    }
                    layer.dirty = true;
                    canvas.renderAll();
                }
            }
            // Remove the text from spineText when "None" is selected
            setSpineText(prev => prev.filter(textObj => textObj.id !== `rib-section-${activeSectionNumber}`));
        }
    };
    
    useEffect(() => {
        handleDesignChange()
    },[selectedOption,activeSectionNumber])

    const addTextArea = () => {
        if (canvas) {
            const layer = canvas.getObjects().find(obj => obj.id === `rib-section-${activeSectionNumber}`)
            if (layer) {
                const textBox = layer?.getObjects().filter((o) => o.type === 'textArea');
                if(textBox?.length < 1){
                    addTextToSection(layer)
                    canvas.dirty = true;
                    canvas.renderAll()
                }
            }
        }
    }

    const handleClickAndSelect = () => {
        onClick()
        if (dropdownOpen) {
            toggleDropdown(false)
        }
        else {
            toggleDropdown(true)
        }
    }


    useEffect(() => {
        if (canvas) {
            const layer = canvas.getObjects().find(obj => obj.id === `rib-section-${activeSectionNumber}`)
            if (layer) {
                layer.set({ renderClip: true })
                layer.dirty = true
                canvas.renderAll()
            }
        }
        return () => {
            if (canvas) {
                const layer = canvas.getObjects().find(obj => obj.id === `rib-section-${activeSectionNumber}`)
                if (layer) {
                    layer.set({ renderClip: false });
                    layer.dirty = true;
                    canvas.renderAll()
                }
            }
        }
    }, [activeSectionNumber, canvas]);

    const handleSpineTextOrLogo = (option) => {
        setSelectedOption(option)
    }

    return (
        <div>
            {isMediumScreen ? (
                                     <div onClick={(e) => e.stopPropagation()}>
                                     <div className={classes.outerBorderOptions}>
                                         <p>You can either add logo or text in the spine of the book</p>
                                         <div className={classes.spineOptions}>
                                             {optionTabs.map((tab) => (
                                                 <OptionCapsules key={tab.value} option={tab.name} onClick={() => handleSpineTextOrLogo(tab.value)} active={selectedOption === tab.value} />
                                             ))}
                                         </div>
                                     </div>
         
                                     {selectedOption === optionTabs[1].value && <div className={classes.outerBorderTypes}>
         
                                         <div className={classes.outerBorderTypePreview}>
                                             <p>Select design for rib design</p>
         
                                             <EditText />
         
                                             <div className={classes.previewContainer}>
                                                 {/* <div className={classes.previewCard} onClick={() => changeCoverBorder(bookBorder)}></div>
                                         <div className={classes.previewCard} onClick={() => changeCoverBorder(bookBorder)}></div> */}
                                             </div>
         
                                         </div>
                                         <p className={classes.disclaimer}>Disclaimer : uploaded design won’t be available for live preview</p>
                                     </div>}
                                     {selectedOption === optionTabs[0].value && <div className={classes.outerBorderTypes}>
         
                                         <div className={classes.outerBorderTypePreview}>
                                             <p>Select type of Logo you want </p>
         
                                             <Logo isCanvasReady={isCanvasReady} selectedOption={selectedOption} activeSectionNumber={activeSectionNumber} />
         
                                         </div>
                                         <p className={classes.disclaimer}>Disclaimer : uploaded design won’t be available for live preview</p>
                                     </div>}
         
                                 </div>
            ) : (

                <div className={classes.outerBorder} onClick={() => handleClickAndSelect()}>
                    <div className={classes.colorHeader}>
                        <div className={classes.headerText}>
                            <h2>Add Text/Logo</h2>
                            <ToolTip tooltipText={"Add Logo or text to selected section"} />
                        </div>
                        <div className={classes.dropDownImage} onClick={toggleDropdown}>
                            {dropdownOpen && currentDropdown === "Add Text/Logo" ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
                        </div>
                    </div>

                    {dropdownOpen && currentDropdown === "Add Text/Logo" ? (
                        <div onClick={(e) => e.stopPropagation()}>
                            <div className={classes.outerBorderOptions}>
                                <p>You can either add logo or text in the spine of the book</p>
                                <div className={classes.spineOptions}>
                                    {optionTabs.map((tab) => (
                                        <OptionCapsules key={tab.value} option={tab.name} onClick={() => handleSpineTextOrLogo(tab.value)} active={selectedOption === tab.value} />
                                    ))}
                                </div>
                            </div>

                            {selectedOption === optionTabs[1].value && <div className={classes.outerBorderTypes}>

                                <div className={classes.outerBorderTypePreview}>
                                    <p>Select design for rib design</p>

                                    <EditText />

                                    <div className={classes.previewContainer}>
                                        {/* <div className={classes.previewCard} onClick={() => changeCoverBorder(bookBorder)}></div>
                                <div className={classes.previewCard} onClick={() => changeCoverBorder(bookBorder)}></div> */}
                                    </div>

                                </div>
                                <p className={classes.disclaimer}>Disclaimer : uploaded design won’t be available for live preview</p>
                            </div>}
                            {selectedOption === optionTabs[0].value && <div className={classes.outerBorderTypes}>

                                <div className={classes.outerBorderTypePreview}>
                                    <p>Select type of Logo you want </p>

                                    <Logo isCanvasReady={isCanvasReady} selectedOption={selectedOption} activeSectionNumber={activeSectionNumber} />

                                </div>
                                <p className={classes.disclaimer}>Disclaimer : uploaded design won’t be available for live preview</p>
                            </div>}

                        </div>
                    ) : (
                        <div></div>
                    )
                    }
                </div>
            )}

        </div>
    )
}

export default AddTextLogo