import { useDispatch, useSelector } from "react-redux";
import classes from "./Wishlist.module.css";
import { useEffect } from "react";
import { deleteWishlistItem, fetchAllWishlistItems } from "../../../Store/actions/WishlistAction";
import WishlistCard from "./WishlistCard"
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";
import { getBookPrice, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../Util";


const Wishlist = () => {
  const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  const dispatch = useDispatch();
  const history = useHistory()
    useEffect(() => {
      fetchAllWishlistItems(dispatch);
    }, []);

    const handleRemove = (itemId) => {
        deleteWishlistItem(itemId,dispatch)
    }

    const allColorsData = useSelector((state) => state.bookColor.colors);
  return (
    <div className={classes.mainContainer}>
      <div className={classes.title}>Wishlist</div>
      {wishlist?.length === 0 && <div className={classes.text}>
        <h2>Your wishlist is empty</h2>
        <p>Save items that you like in your wishlist. Review them anytime and eaisly move them to bag</p>

        <SecondaryButton className={classes.textBtn} onClick={() => history.push(urls.LISTING)}>Shop Now</SecondaryButton>
      </div>}
      {wishlist && <div className={classes.bookSection}>
        {wishlist.map((wishlistItem, idx) => (
          <WishlistCard
            key={idx}
            isUnavailable={wishlistItem?.book?.isDeleted || wishlistItem?.bestSellerBook?.isDeleted}
            author={wishlistItem.book.author}
            bookName={wishlistItem.book.title}
            price={wishlistItem.book?.priceGenuine ? wishlistItem?.book?.priceGenuine : wishlistItem?.book?.deluxePriceGenuine?.toFixed(2) }
            image={wishlistItem.book?.priceGenuine ?getRandomImageForBestSellerBook(wishlistItem.book,allColorsData)?.image : getRandomImageForNormalBook(wishlistItem.book,allColorsData)?.image}
            altText={wishlistItem.book?.priceGenuine ?getRandomImageForBestSellerBook(wishlistItem.book,allColorsData)?.altText : getRandomImageForNormalBook(wishlistItem.book,allColorsData)?.altText}
            onRemove={() => handleRemove(wishlistItem.id)}
            onClick={ () => !wishlistItem.book?.priceGenuine ? history.push({
              pathname: `${urls.PRODUCTDETAIL}/${wishlistItem.book.id}`,
          }) : history.push({
            pathname : `${urls.BESTSELLERPDP}/${wishlistItem.book.id}`,
          })}
          />
        ))}
      </div>}
    </div>
  );
};

export default Wishlist;
