import classes from "./ColorPicker.module.css" 
import ColorSelector from "../../Components/ColorSelector/ColorSelector"
import { useContext } from "react"
import { CustomizationContext } from "../../Customization"

const ColorPickerSmall = ({allowedColors,handleColorChange}) => {
    const {frontCoverColor} = useContext(CustomizationContext)
    return (
        <div className={`${classes.colorPickerDropdown} ${classes.open}`} onClick={(e) => e.stopPropagation()}>
        <h3>Select leather cover for front color</h3>

        <div className={classes.selectColor}>
            <h2>Color : {frontCoverColor?.colorName}</h2>
            {/* <div className={classes.colors}>
                {allowedColors?.map((color, index) => (
                    selectedColor?.colorValue == color.colorValue ? (
                        <div key={index} className={classes.selected}>
                            <div className={classes.active} style={{ background: color.colorValue }} >
                            </div>
                        </div>
                    ) : (

                        <div key={index} className={classes.colorPicker} style={{ background: color.colorValue }} onClick={(e) => handleColorChange(color,e)}> </div>
                    )
                ))}
            </div> */}
            <ColorSelector availableColors={allowedColors} selectedColor={frontCoverColor} handleColorChange={handleColorChange} />
        </div>
    </div>
    )
}

export default ColorPickerSmall