import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen:false,
};

const toggleSlice = createSlice({
    name:'toggle',
    initialState,
    reducers:{
        toggleIsOpen:(state) => {
            state.isOpen = !state.isOpen
        },
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
    },
});

export const {toggleIsOpen,setIsOpen} = toggleSlice.actions;
export default toggleSlice.reducer