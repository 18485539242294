import { useDispatch } from "react-redux"
import ToolTip from "../../../../Components/ToolTip/ToolTip"
import { updateCartItem } from "../../../../Store/actions/CartAction"
import classes from "./OptionButton.module.css"
import { useState } from "react"

const OptionButton = ({option, id, price, cartItem,tooltipText,setWarningOpen}) => {
    const dispatch = useDispatch()
    const [boxWrapping, setBoxWrapping] =  useState(cartItem?.boxWrapping ? cartItem?.boxWrapping : false);
    const [giftWrapping, setGiftWrapping] =  useState(cartItem?.giftWrapping ? cartItem?.giftWrapping : false);

    const isChecked = () => {
        if(option === "Gift Wrapping"){
            return giftWrapping
        }
        else if(option === "Box Wrapping"){
            return boxWrapping
        }
    }

    const handleSetChecked = () => {
        if(!cartItem.shippingAddress){
            setWarningOpen(true)
            return;
        }
        
        if (option === "Gift Wrapping") {
            const newGiftWrapping = !giftWrapping;
            setGiftWrapping(newGiftWrapping);
            updateCartItem(cartItem?.id, cartItem?.quantity, cartItem?.shippingAddress?.addressId, dispatch, cartItem?.boxWrapping, newGiftWrapping);
        } else if (option === "Box Wrapping") {
            const newBoxWrapping = !boxWrapping;
            setBoxWrapping(newBoxWrapping);
            updateCartItem(cartItem?.id, cartItem?.quantity, cartItem?.shippingAddress?.addressId, dispatch, newBoxWrapping, cartItem?.giftWrapping);
        }
    };
    
    return(
        <div className={classes.mainContainer}>
            <div className={classes.details}>
                <input id={id} type="checkbox" checked={isChecked()} onChange={handleSetChecked} name={option} />
                <label htmlFor={id}>{option}</label>
                <ToolTip tooltipText={tooltipText ? tooltipText : "This text will be displayed"} />
            </div>
            <div className={classes.price}>
                <p>+${price}</p>
            </div>
        </div>
    )
}

export default OptionButton