import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";
import classes from "./InfoNav.module.css";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
// import CountryDropdown from "./CountryDropdown";
// import CountryCodeList from "../../SignUp/CountryCodeList.json"


function InfoNav() {
  const history = useHistory();

  return (
    <nav className={classes.main}>
      <div className={classes.container}>
        <p className={classes.description}>
          Explore world of exclusive customization unique design and super
          premium craftsmanship!
        </p>
        <div className={classes.rightSection}>
          <SecondaryButton className={classes.exploreButton} onClick={() => history.push(urls.RB_EXCLUSIVE)}>
            Explore RB Exclusive
          </SecondaryButton>
          {/* <CountryDropdown CountryCodeList={CountryCodeList} /> */}
        </div>
      </div>
    </nav>
  );
}

export default InfoNav;
