import { useDispatch, useSelector } from "react-redux";
import classes from "./MultipleAddress.module.css";
import CartMini from "./Cart/CartMini";

const MultipleAddress = ({ isActive, setActive, selectedAddress, setSelectedAddress, shippingAddress, shippingSpeed, setShippingSpeed }) => {

    return (
                <div className={classes.mainContainer}>
                    <div className={classes.headerSection}>
                        <h2>1 | Shipping Address</h2>
                        <p>Choose delivery address for each item</p>
                    </div>
                    <CartMini setSelectedAddresses={setSelectedAddress} setSelectedShippingSpeeds={setShippingSpeed} selectedAddresses={selectedAddress} selectedShippingSpeeds={shippingSpeed} />

                    {/* <div className={classes.btnContainer}>
                        <PrimaryButton disabled={selectedAddress.length === 0} onClick={handleNext}>
                            Deliver to This Address
                        </PrimaryButton>
                    </div> */}
                </div>
    );
};

export default MultipleAddress;
