import axios from "../../Axios";
import { bookColorActions } from "../Slices/BookColorSlice";

// Fetch all book colors
export const fetchAllBookColors = (dispatch) => {
    return axios({
        url: "/api/book-colors",
        method: "get",
        responseType: "json"
    })
    .then((response) => {
        const data = response.data;
        if (data.success) {
            dispatch(bookColorActions.setColors(data.data));
        }
    })
    .catch((error) => {
        console.error("Failed to fetch book colors:", error);
    });
};

// Create a new book color
export const createBookColor = (dispatch,bookColor,paletteImage, baseImage, sideImage1, sideImage2, sideImage3) => {
    const formData = new FormData();
    formData.append("bookColor", JSON.stringify(bookColor));
    if(paletteImage) formData.append("paletteImage",paletteImage)
    if (baseImage) formData.append("baseImage", baseImage);
    if (sideImage1) formData.append("sideImage1", sideImage1);
    if (sideImage2) formData.append("sideImage2", sideImage2);
    if (sideImage3) formData.append("sideImage3", sideImage3);

    return axios({
        url: "/api/book-colors/create",
        method: "post",
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: "json"
    })
    .then((response) => {
        const data = response.data;
        if (data.success) {
            dispatch(bookColorActions.addColor(data.data));
        }
    })
    .catch((error) => {
        console.error("Failed to create new book color:", error);
    });
};

// Update an existing book color
export const updateBookColor = (dispatch,colorId, updatedBookColor,paletteImage,baseImage, sideImage1, sideImage2, sideImage3) => {
    const formData = new FormData();
    formData.append("bookColor", JSON.stringify(updatedBookColor));
    if(paletteImage) formData.append("paletteImage",paletteImage);
    if (baseImage) formData.append("baseImage", baseImage);
    if (sideImage1) formData.append("sideImage1", sideImage1);
    if (sideImage2) formData.append("sideImage2", sideImage2);
    if (sideImage3) formData.append("sideImage3", sideImage3);

    return axios({
        url: `/api/book-colors/update/${colorId}`,
        method: "put",
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
        responseType: "json"
    })
    .then((response) => {
        const data = response.data;
        if (data.success) {
            dispatch(bookColorActions.updateColors(data.data));
        }
    })
    .catch((error) => {
        console.error("Failed to update book color:", error);
    });
};

// Delete a book color
export const deleteBookColor = (colorId, dispatch) => {
    return axios({
        url: `/api/book-colors/${colorId}`,
        method: "delete",
        responseType: "json"
    })
    .then((response) => {
        if (response.data.success) {
            dispatch(bookColorActions.deleteColor(colorId));
        }
    })
    .catch((error) => {
        console.error("Failed to delete book color:", error);
    });
};
