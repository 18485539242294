import classes from "./EditText.module.css";

import { useState, useEffect, useContext } from "react";
import { CustomizationContext } from "../../../Customization";
import ColorSelector from "../../../Components/ColorSelector/ColorSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignCenter, faAlignLeft, faAlignRight, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
const dropDownIcon = "/Assets/Icons/caret-down-solid.svg";

const EditText = () => {
  const { fontData, spineFontColor, setSpineFontColor, setSpineFontFamily,spineCoverTextAlign,setSpineCoverTextAlign, spineFontStyle, setSpineFontStyle, allTextColors,spineFontSize,setSpineFontSize } = useContext(CustomizationContext);
  const [selectedFont, setSelectedFont] = useState(fontData[0]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [tempFontSize,setTempFontSize] = useState(spineFontSize)

  // Set spineFontColor to the first allowed color on first render
  useEffect(() => {
    if (allTextColors?.length > 0 && !spineFontColor) {
      setSpineFontColor(allTextColors[0]); // Ensure to set the colorValue
    }
  }, [allTextColors]);

  const handleColorChange = (color) => {
    setSpineFontColor(color); // Directly update spineFontColor
  };

  const handleFontChange = (font) => {
    setSelectedFont(font);
    setSpineFontFamily(font);
    setIsDropdownOpen(false);
  };

  const handleTextStyleChange = (style) => {
    setSpineFontStyle((prevStyle) => {
      const newStyle = { ...prevStyle };

      switch (style) {
        case 'italic':
          newStyle.fontStyle = newStyle.fontStyle === 'italic' ? 'normal' : 'italic';
          break;
        case 'underline':
          newStyle.underline = !newStyle.underline;
          break;
        default:
          break;
      }

      return newStyle;
    });
  };

  const handleDecrease = () => {
    setTempFontSize(prevFontSize => Math.max(6, prevFontSize - 1));
    setSpineFontSize(prevFontSize => Math.max(6, prevFontSize - 1));
  };
  
  const handleIncrease = () => {
    setTempFontSize(prevFontSize => Math.min(26, prevFontSize + 1));
    setSpineFontSize(prevFontSize => Math.min(26, prevFontSize + 1));
  };
  
  const handleFontSizeChange = (e) => {
    const value = Number(e.target.value);
    if(value <= 26){
      setTempFontSize(value);
    }  else if(value >= 6 ){
      setTempFontSize(26)
    }
    // handleInputDebouncedChange(maxValue, false)
  };

  const handleClick = (e) => {
    if(e.key === "Enter"){
      if(tempFontSize >= 6 && tempFontSize <= 26) {
          setSpineFontSize(tempFontSize)
      } else {
          setTempFontSize(6);
          setSpineFontSize(6)
      }
   }
  }

  return (
    <div
      className={classes.colorPickerDropdown}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.selectColor}>
        <h2>Font family: {selectedFont?.fontFamily}</h2>
        <div className={classes.customDropdown}>
          <div
            className={classes.selectedOption}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className={classes.dropdownBar}>
              <div className={classes.dropdownText}>
                <span>{selectedFont?.fontFamily}</span>
                <span>
                  {selectedFont?.fontPrice > 0 &&
                    `+$${selectedFont?.fontPrice}`}
                </span>
              </div>
              <div className={classes.dropdownIcon}>
                <img src={dropDownIcon} alt="" />
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <div className={classes.dropdownOptions}>
              {fontData.map((font) => (
                <div
                  key={font.fontFamily}
                  className={classes.option}
                  onClick={() => handleFontChange(font)}
                >
                  <span>{font?.fontFamily}</span>
                  <span>{font?.fontPrice > 0 && `+$${font?.fontPrice}`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={classes.textStylingOptions}>
        <h3>Select Text Styling</h3>
        <div className={classes.fontStyleDiv}>
          <div className={classes.stylingButtons}>
            <button
              className={`${classes.stylingButton} ${
                spineFontStyle?.fontStyle === "italic"
                  ? classes.activeStylingButton
                  : ""
              }`}
              onClick={() => handleTextStyleChange("italic")}
            >
              <i>I</i>
            </button>
            <button
              className={`${classes.stylingButton} ${
                spineFontStyle?.underline === true
                  ? classes.activeStylingButton
                  : ""
              }`}
              onClick={() => handleTextStyleChange("underline")}
            >
              <u>U</u>
            </button>
          </div>

          <div className={classes.fontSizeOptions}>
            <FontAwesomeIcon
              icon={faMinus}
              style={{ cursor: "pointer" }}
              onClick={handleDecrease}
            />
            <input
              type="number"
              max={26}
              min={6}
              name=""
              id=""
              value={tempFontSize}
              onKeyDown={handleClick}
              onChange={handleFontSizeChange}
            />
            <FontAwesomeIcon
              icon={faPlus}
              style={{ cursor: "pointer" }}
              onClick={handleIncrease}
            />
          </div>
        </div>
      </div>

      <div className={classes.textStylingOptions}>
        <h3>Text Align</h3>
        <div className={classes.stylingButtons}>
          <button
            className={`${classes.stylingButton} ${
              spineCoverTextAlign === "left" ? classes.activeStylingButton : ""
            }`}
            onClick={() => setSpineCoverTextAlign("left")}
          >
            <FontAwesomeIcon icon={faAlignLeft} />
          </button>
          <button
            className={`${classes.stylingButton} ${
              spineCoverTextAlign === "center"
                ? classes.activeStylingButton
                : ""
            }`}
            onClick={() => setSpineCoverTextAlign("center")}
          >
            <FontAwesomeIcon icon={faAlignCenter} />
          </button>
          <button
            className={`${classes.stylingButton} ${
              spineCoverTextAlign === "right" ? classes.activeStylingButton : ""
            }`}
            onClick={() => setSpineCoverTextAlign("right")}
          >
            <FontAwesomeIcon icon={faAlignRight} />
          </button>
        </div>
      </div>

      <div className={classes.selectColor}>
        <h2>Color: {spineFontColor?.colorName}</h2>{" "}
        <ColorSelector
          availableColors={allTextColors}
          selectedColor={spineFontColor}
          handleColorChange={handleColorChange}
        />
      </div>
    </div>
  );
};

export default EditText;
