import React from "react";
import classes from "./ConfirmationModal.module.css";

const closeIcon = "/Assets/Icons/icon-x.svg"; 
const emailIcon = "/Assets/Icons/confirmEmailIcon.svg"

const ConfirmationModal = ({ email, onResend, onEditEmail, onClose }) => {
  return (
    <div className={classes.modal}>
      <div className={classes.modalContent}>
            <img
            src={closeIcon}
            alt="Close"
            className={classes.closeIcon}
            onClick={onClose}
            />
        <div className={classes.iconContainer}>
            <div className={classes.emailIcon}>
            <img
                src={emailIcon}
                alt="Email Icon"
            />
            </div>
        </div>
        <h2 className={classes.title}>Verify Your Email</h2>
        <p className={classes.message}>
          Almost there! We have sent a verification link to your email{" "}
          <strong>{email}</strong>.
          <br />
          You need to verify your email to complete your account set-up.
        </p>
        <div className={classes.actions}>
          <button className={classes.resendButton} onClick={onResend}>
            Resend Email
          </button>
          <button className={classes.editButton} onClick={onClose}>
            Edit your email
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
