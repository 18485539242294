import { createSlice } from "@reduxjs/toolkit";

const BestAuthorSlice = createSlice({
    name:"bestSellingAuthors",
    initialState:{
        authors:[],
        topFiveAuthors:[]
    },
    reducers:{
        updateBestAuthors: (state,action) => {
            state.authors = action.payload;
        },
        updateTop5Authors: (state,action) => {
            state.topFiveAuthors = action.payload;
        }
    }

});

export const BestSellingAuthorActions = BestAuthorSlice.actions
export default BestAuthorSlice.reducer