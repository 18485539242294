import React, { useState } from 'react';
import classes from './CouponCard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { copyTextToClipboard } from '../../../../Util';

const couponIcon = "/Assets/Icons/couponIconDark.svg"
const CouponCard = ({
    discountAmount,
    minItemQuantity,
    minOrderValue,
    maxDiscount,
    authorName,
    couponCode,
    endDate,
    discountType,
    couponType
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const copyHandler = (copyText) => {
        copyTextToClipboard(copyText).then(() => {
            setShowPopup(true);
            setTimeout(() => {
                setShowPopup(false);
            }, 1000);
        })
    };

    

    const renderDiscountText = () => {
        if(couponType === "minOrderAmount"){
            return <p>{`FOR ORDERS ABOVE ${minOrderValue}`}</p>
        }
        else if(couponType === "itemQty"){
            return <p>{`MIN ${minItemQuantity} ITEMS REQUIRED IN CART `}</p>
        }
        else{
            return  <p>{`VALID FOR BOOKS BY ${authorName}`}</p>
        }
    }

    const formattedEndDate = new Date(endDate).toLocaleDateString();

    return (
        <div className={classes.couponCard}>
            <div className={classes.leftSection}>
                <div className={classes.circle1}></div>
                <div className={classes.circle2}></div>
                <h2>
                    {discountType === "fixedDiscount" ? `$${discountAmount} OFF` : `${discountAmount}% OFF upto $${maxDiscount}`}
                </h2>
                {/* <p>{`FOR ORDERS ABOVE ${orderCondition}`}</p> */}
                {renderDiscountText()}
                <div className={classes.codeSection}>
                    <span className={classes.codeLabel}>Code: </span>
                    <span className={classes.code}>{couponCode}</span>
                    <FontAwesomeIcon className={classes.copyIcon} icon={faCopy} onClick={() => copyHandler(couponCode)} />
                    {showPopup && <div className={classes.copied}>Copied</div>}
                </div>
                <p className={classes.endDate}>Expiry Date: <strong>{formattedEndDate}</strong></p>
            </div>
            <div className={classes.rightSection}>
                <div className={classes.iconWrapper}>
                    <img
                        src={couponIcon}
                        alt="Discount Icon"
                        className={classes.icon}
                    />
                </div>
                <p className={classes.discountText}> {discountType === "fixedDiscount" ? `$${discountAmount} OFF` : `${discountAmount}% OFF`}</p>
            </div>
        </div>
    );
};

export default CouponCard;
