import { createSlice } from "@reduxjs/toolkit";

function getSet(obj) {
    return Array.from(
      Object.keys(obj).reduce((prev, curr) => {
        obj[curr].forEach(e => {
          prev.add(e.value);
        });
        return prev;
      }, new Set())
    );
  }

const SearchRecommendationSlice = createSlice({
    name: "recommendation",
    initialState: {
        recommendations: [],
    },
    reducers: {
        setRecommendations: (state, action) => {
            state.recommendations = getSet(action.payload);
        },
    }
})

export const SearchRecommendationAction = SearchRecommendationSlice.actions;
export default SearchRecommendationSlice.reducer;