import React, { useEffect, useState } from 'react'
import { getContent } from '../../Store/actions/BlogsAction';

const HtmlViewer = ({ src, data }) => {
  const [blogContent, setBlogContent] = useState();
  useEffect(() => {
    if (src) {
      getContent(src).then(setBlogContent);
    } else if (data) {
      setBlogContent(data);
    }
  }, [src, data]);

  return (
    <div className={"ql-editor"} dangerouslySetInnerHTML={{ __html: blogContent }} style={{lineHeight:"32px", letterSpacing:"0.5px"}} />
  )
}

export default HtmlViewer