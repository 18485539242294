import React, {  } from 'react';
import classes from './ButtonLink.module.css'

function ButtonLink({ onClick, title, children, style}) {
  return (
    <button onClick={onClick} style={style} className={classes.buttonLink}>
      {children || title}
    </button>
  );
}

export default ButtonLink