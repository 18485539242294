import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../../../Components/Inputs/Input/Input";
import classes from "./Orders.module.css";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import OrderCard from "./OrderCard/OrderCard";
import OrderDetails from "./OrderDetails/OrderDetails";
import { fetchDisputedOrders, fetchOrdersData } from "../../../Store/actions/OrdersActions";

const tabData = [
  {
    title: "In Process Orders",
    value: "pending"
  },
  {
    title: "In Transit Orders",
    value: "shipped"
  },
  {
    title: "Delivered Orders",
    value: "delivered"
  },
  {
    title: "Dispute Orders",
    value: "disputed"
  },
];

const Orders = () => {
  const [activeTab, setActiveTab] = useState(tabData[0]);
  const [orderDetails, setOrderDetails] = useState({show: false, data: {}});
  const [orders, setOrders] = useState([]);
  const [disputedOrders,setDisputedOrders] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBooks, setFilteredBooks] = useState([]);
  const [timeRange,setTimeRange] = useState("3Month")

  // Handle the search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  };

  // Handle the time range change
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
  };

  // Check if a timestamp falls within the given time range
  const isWithinTimeRange = (timestamp, range) => {
    const currentDate = new Date();
    const givenDate = new Date(timestamp);
    const diffInMs = currentDate - givenDate;
    const diffInMonths = diffInMs / (1000 * 60 * 60 * 24 * 30); // Convert milliseconds to months

    switch (range) {
      case "3Month":
        return diffInMonths <= 3;
      case "6Month":
        return diffInMonths <= 6;
      case "12Month":
        return diffInMonths <= 12;
      case "all":
        return true;
      default:
        return false;
    }
  };

  // Filter books based on search term
  useEffect(() => {
    if (searchTerm.trim() === "") {
      let filtered = ""
      if(activeTab.value === tabData[3].value){
        filtered = disputedOrders.filter((order) => {
          return isWithinTimeRange(order.Order.createdAt,timeRange)
        })
      }
      else {
        filtered = orders.filter((order) => {
          return isWithinTimeRange(order.createdAt,timeRange) && order.orderStatus === activeTab.value
        })
      }
      setFilteredBooks(filtered)
    } else {
        let filtered=[];
        if(activeTab.value === tabData[3].value){
          filtered = disputedOrders.filter((order) => {
            const isInRange = isWithinTimeRange(order.Order.createdAt,timeRange)
            return order.Order.cartItems.some((cartItem) => {
              const title = cartItem?.book?.title || cartItem?.bestSellerBook?.title;
              const orderNo = order.Order.orderNo;
              
              return isInRange && ((title && title.toLowerCase().includes(searchTerm.toLowerCase())) || (orderNo && orderNo.toLowerCase().includes(searchTerm.toLowerCase())));
            })  
          })
        }
        else {
          filtered = orders.filter( (order) => {
            const isInRange = isWithinTimeRange(order.createdAt,timeRange)
            return order.cartItems.some((cartItem) => {
              const title = cartItem?.book?.title || cartItem?.bestSellerBook?.title;
              const orderNo = order.orderNo;

              return order.orderStatus === activeTab.value && isInRange && ((title && title.toLowerCase().includes(searchTerm.toLowerCase())) || (orderNo && orderNo.toLowerCase().includes(searchTerm.toLowerCase())));
            })
          })
        }
        setFilteredBooks(filtered); 
      }
  }, [searchTerm, orders,activeTab,timeRange]); // Re-run filtering when the search term or orders change  

  const getOrders = () => {
      fetchOrdersData() 
        .then((res) => {
          setOrders(res.data.data);
        })
        .catch((err) => console.error("Error fetching orders:", err));
  };

  const getDisputedOrders = () => {
    fetchDisputedOrders()
    .then((res) => {
      setDisputedOrders(res.data.data);
    })
    .catch();
  }

  useEffect(() => {
    getOrders();
    getDisputedOrders();
  }, [activeTab]);

  return (
    <>
      {!orderDetails.show ? (
        <div>
          <div className={classes.headerSection}>
            <h2>My Orders</h2>
            <div className={classes.filters}>
              <div className={classes.searchBar}>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <Input 
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>

              <select name="" id="" onChange={handleTimeRangeChange}>
                <option value="3Month">Past 3 Months</option>
                <option value="6Month">Past 6 Months</option>
                <option value="12Month">Past 12 Months</option>
                <option value="all">All Time</option>
              </select>
            </div>
          </div>

          <div className={classes.tabs}>
            {tabData.map((tabs, idx) => (
              <div
                className={`${classes.tab} ${
                  activeTab.title === tabs.title ? classes.active : ""
                } `}
                onClick={() => setActiveTab(tabs)}
                key={idx}
              >
                {tabs.title}
              </div>
            ))}
          </div>

          {filteredBooks?.map((order) => (
            <div className={classes.orders}
            key={order?.id}>
              <OrderCard
                key={order?.id}
                activeTab={activeTab.value}
                viewDetails={() => setOrderDetails({show: true, data: activeTab?.value === tabData[3].value ? order?.Order : order})}
                data={activeTab?.value === tabData[3].value ? order?.Order : order}
                disputedOrderData={activeTab?.value === tabData[3].value ? order?.DisputedOrder : ""}
              />
            </div>
          ))}
        
        </div>
      ) : (
        <OrderDetails data={orderDetails.data} onBack={() => setOrderDetails({show: false, data: {}})} />
      )}
    </>
  );
};

export default Orders;
