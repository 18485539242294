import React,{useState,useEffect} from 'react'

import classes from './Banner.module.css';

import Slider from 'react-slick'; // Import Slider from react-slick
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import FeatureCard from './FeatureCard/FeatureCard';
import SearchComponent from './SearchComponent/SearchComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBanners, getAllOffers } from '../../../Store/actions/BannerAction';
import { isUserLoggedIn } from '../../../Util';
import { fetchAllCartItems } from '../../../Store/actions/CartAction';
import OfferCard from '../../../Components/Card/BookCard/OfferCard';

function Banner(){

  const bannerData = useSelector((state) => state.banners?.banners);
  const currentUser = useSelector((state) => state.user.currentUser)

  const [offers,setOffers] = useState([]);
 
  const [currentIndex, setCurrentIndex] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllOffers().then((res) => {
      if(res?.data?.success) {
        const filteredData = res.data?.data?.filter((offer) => offer?.isDisabled === false);
        setOffers(filteredData);
      }
    })
  },[]);

  useEffect(() => {
    getAllBanners(dispatch)
    if(currentUser?.id){
        fetchAllCartItems(dispatch);
    }
  },[currentUser])

  useEffect(() => {
    if (bannerData?.length > 1) {
      const interval = setInterval(() => {
        if (currentIndex === bannerData.length - 1) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex(currentIndex + 1);
        }
      }, 4000);
  
      // Clear the interval when the component unmounts
      return () => clearInterval(interval);
    }
  }, [bannerData, currentIndex]);
  

    const settingsRight = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        autoplay:true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        slidesToScroll: 1
      };

    return (
      <div className={classes.main_banner}>
        <div className={`${classes.bannersContainer} ${offers.length == 0 ? classes.singleBanner : '' }`}>
          <div className={classes.leftBanner}>
            <div
              className={classes.carouselWrapper}
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {bannerData?.map((data, index) => (
                <div key={index} className={classes.carousel}>
                  <FeatureCard
                    image={data?.imageUrl}
                    title={data?.title}
                    description={data?.description}
                    altText={data?.altText}
                    btnText={"Explore our collection"}
                  />
                </div>
              ))}
            </div>
          </div>
          {offers?.length > 0 && <div className={classes.rightBanner}>
          <div className={classes.top_text}>
                <h1>Limited Time Offers</h1>
            </div>
            <div className={classes.imageContainerRight}>
              {/* Carousel for Book Cards */}
              <Slider {...settingsRight}>
                {offers.map((data, index) => (
                  <div key={index}>
                    {/* <div className={classes.top_text}>
                      <h1>{data?.offerTitle}</h1>
                    </div> */}
                    <OfferCard
                      key={index}
                      offerTitle={data?.offerTitle}
                      image={data.image}
                      title={"Coupon Code"}
                      couponCode={data?.couponCode}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>}
        </div>

        <div className={classes.section}>
          <SearchComponent />
        </div>
      </div>
    );
}


export default Banner