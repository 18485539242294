import classes from "./SectionController.module.css"
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton"

const SectionController = ({next,prev,onNext,onPrev}) => {
    return(
        <div className={classes.mainContainer}>
            <SecondaryButton onClick={onPrev}>Prev : {prev}</SecondaryButton>
            <SecondaryButton onClick={onNext}>Next : {next}</SecondaryButton>
        </div>
    )
}

export default SectionController;