import { useState } from "react";
import PrimaryButton from "../../Components/Buttons/PrimaryButton/PrimaryButton";
import Modal, { ModalBody, ModalFooter } from "../../Components/Modal/Modal";
import classes from "./PaymentOptionCard.module.css";

const paypal = "paypal";
const stripe = "stripe";

const PaymentOptionCard = ({onSelect, onClose }) => {
  const [method, setMethod] = useState(paypal);

  const continueToPayment = async() => {
      onSelect?.(method);
      onClose();
  }

  return (
    <Modal className={classes.modal} title="Choose your payment method" onClose={onClose}>
      <ModalBody>
        <div className={classes.main}>
          <div className={classes.radio}>
            <input
              type="radio"
              name=""
              id="paypal"
              value={method}
              checked={method === paypal}
              onChange={() => setMethod(paypal)}
            />
            <label htmlFor="paypal">PayPal</label>
          </div>
          <div className={classes.radio}>
            <input
              type="radio"
              name=""
              id="card-payment"
              value={method}
              checked={method === stripe}
              onChange={() => setMethod(stripe)}
            />
            <label htmlFor="card-payment">Credit/Debit Card/NetBanking</label>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={classes.actions}>
          <PrimaryButton onClick={continueToPayment}>Continue</PrimaryButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentOptionCard;
