import axios from "axios";
import { BannerAction } from "../Slices/BannerSlice";

export function getAllBanners(dispatch){
    let filter = {sortBy: "id DESC"}
    return axios({
        url:`/api/banners?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method:"get",
        responseType:"json"
    }).then((response) => {
        const data = response.data;
        if(data.success){
            dispatch(BannerAction.updateBanners(data.data))
        }
    }).catch((error) => {
        console.error(error)
    })
}

export function getAllOffers() {
    return axios({
        url:`/api/offers`,
        method:"get"
    })
 }