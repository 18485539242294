import React, { useState,useRef } from 'react';
import styles from './VideoPlayer.module.css';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { urls } from '../../Routes/Urls';

const VideoPlayer = ({ videoSrc,videoId,button=false}) => {
  const history = useHistory()
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlayButton, setShowPlayButton] = useState(true);

  const videoRef = useRef(null);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsPlaying(true);
    }
    setShowPlayButton(false);
  };

  return (
    <div className={styles.videoFrame}>
        <video ref = {videoRef} id="video" src={videoSrc} onClick={handlePlayPause} />
        {showPlayButton && (
          <div className={styles.playButton} onClick={handlePlayPause}>
            <div className={styles.playIcon} />
          </div>
        )}

        {button && <PrimaryButton className={styles.btn} onClick={() => history.push(urls.LISTING)}>Explore Our Collection</PrimaryButton>}
    </div>
  );
};

export default VideoPlayer;
