import React from "react";
import InfoNav from "./InfoNav/InfoNav";
import PrimaryNav from "./PrimaryNav/PrimaryNav";
import MiniCart from "../MyCart/MiniCart/MiniCart";

function Header() {
  return (
    <>
      <InfoNav />
      <PrimaryNav />
      <MiniCart />
      {/* {!isSmallScreen && <ItemsNav />} */}
    </>
  );
}

export default Header;
