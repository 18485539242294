import axios from "axios";
import { FaqAction } from "../Slices/FaqSlice";

export function getAllCategories(dispatch) {
  return axios({
    url: `/api/faqs/categories`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      const data = response.data;
      if (data.success) {
        dispatch(FaqAction.fetchAllCategories(data.data));
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function getAllFaq(field, query, searchQuery, dispatch) {
  var filter = {};
  if (query === "") {
    filter = { sortBy: "id DESC" };
  } else {
    filter = {
      where: {
        [field]: { $regex: query, $options: "i" },
      },
    };
  }

  if (searchQuery) {
    filter.where.question = { $regex: searchQuery, $options: "i" };
  }

  return axios({
    url: `/api/faqs?filter=${encodeURIComponent(JSON.stringify(filter))}`,
    method: "get",
    responseType: "json",
  })
    .then((response) => {
      const data = response.data;
      if (data.success) {
        dispatch(FaqAction.fetchAllFaq(data.data));
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

export function addNewFaq(faqData, dispatch) {
  const formData = new FormData();

  formData.append("faqDetails", JSON.stringify(faqData));

  return axios({
    url: "/api/faqs",
    method: "post",
    data: faqData,
    responseType: "json",
  })
    .then((res) => {
      const data = res.data;
      if (data.success) {
        dispatch(FaqAction.addNewFaq(data.data));
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function updateFaq(faqId, faqData, dispatch) {
  return axios({
    url: `/api/faqs/${faqId}`,
    method: "put",
    data: faqData,
    responseType: "json",
  })
    .then((res) => {
      const data = res.data;
      if (data.success) {
        dispatch(FaqAction.updateFaq(data.data));
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export function deleteFaq(faqId, dispatch) {
  return axios({
    url: `/api/faqs/${faqId}`,
    method: "delete",
    responseType: "json",
  })
    .then((res) => {
      const data = res.data;
      if (data.success) {
        dispatch(FaqAction.deleteFaq({ id: faqId }));
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
