import { useContext } from "react";
import Modal, { ModalBody } from "../../../Components/Modal/Modal";
import classes from "./CustomizationDetails.module.css";
import { CustomizationContext } from "../../Customization/Customization";
import { getBookPrice, getRandomImageForNormalBook } from "../../../Util";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { calculateCustomizationCharges } from "../../../Store/actions/CustomizationAction";

const CustomizationDetails = ({ onClose,bookLeather, bookVariant, bookData, frontCoverColor,customization,bookQty,image}) => {
  const allColorsData = useSelector((state) => state?.bookColor?.colors);
  const [customizationCharges,setCustomizationCharges]  = useState([])
  
  useEffect(()=>{
    const calculateCustomization = async () => {
      const res = await calculateCustomizationCharges(customization);
        setCustomizationCharges(res.data.data)
    }
    calculateCustomization();
  },[customization])
  const bookImage = getRandomImageForNormalBook(
    bookData,
    allColorsData,
    frontCoverColor?.colorName,
    bookLeather,
    bookVariant
  )?.image;
  const imageAlt = getRandomImageForNormalBook(
    bookData,
    allColorsData,
    frontCoverColor?.colorName,
    bookLeather,
    bookVariant
  )?.altText;

  return (
    <Modal onClose={onClose} title={"Review and Confirm"}>
      <ModalBody>
        <div className={classes.container}>
          <div className={classes.viewDetails}>
            <div className={classes.bookDetails}>
              <div className={classes.bookImage}>
                <img src={image || bookImage} alt={imageAlt} />
              </div>
              <div className={classes.bookInfo}>
                <h2 className={classes.bookTitle}>{bookData?.title}</h2>
                <p className={classes.authorName}>{bookData?.author}</p>
                <p className={classes.bookPrice}>${getBookPrice(bookData, bookLeather, bookVariant)}</p>
              </div>
            </div>

            <div className={classes.customizationCharges}>
              <h3>Add-on Customizations</h3>
              <div className={classes.customizationList}>
                {customizationCharges?.frontCoverCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Front Cover</h4>
                    {customizationCharges.frontCover.outerBorderCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Outer Border</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.outerBorderCharges.toFixed(2)}</p>
                      </div>
                    )}
                    {customizationCharges.frontCover.innerBorderCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Inner Border</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.innerBorderCharges.toFixed(2)}</p>
                      </div>
                    )}
                    {customizationCharges.frontCover.marblePaperCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Marble Paper</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.marblePaperCharges.toFixed(2)}</p>
                      </div>
                    )}
                    {customizationCharges.frontCover.fontCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Custom Font</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.fontCharges.toFixed(2)}</p>
                      </div>
                    )}
                        {customizationCharges.frontCover.textColorCharges > 0 && (
                          <div className={classes.customizationItemDetail}>
                            <p>Text Color Charges</p>
                            <p className={classes.optionPrice}>${customizationCharges.frontCover.textColorCharges.toFixed(2)}</p>
                          </div>
                        )}
                    {customizationCharges.frontCover.logoCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Front Cover Logo</p>
                        <p className={classes.optionPrice}>${customizationCharges.frontCover.logoCharges.toFixed(2)}</p>
                      </div>
                    )}
                   
                  </div>
                )}

                {customizationCharges?.spineCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Spine</h4>
                    {customizationCharges?.spine?.sectionColorCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Spine Section Color</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.sectionColorCharges.toFixed(2)}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.logoCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Custom Logo Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.logoCharges.toFixed(2)}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.fontCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Font Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.fontCharges.toFixed(2)}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.textCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>TextBox Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.textCharges.toFixed(2)}</p>
                      </div>
                    )}
                    {customizationCharges?.spine?.textColorCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Text Color Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.spine?.textColorCharges.toFixed(2)}</p>
                      </div>
                    )}
                  </div>
                )}

                {customizationCharges?.gildingCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Gilding</h4>
                    {customizationCharges.gildingCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Gilding Color</p>
                        <p className={classes.optionPrice}>${customizationCharges?.gildingCharges.toFixed(2)}</p>
                      </div>
                    )}
                  </div>
                )}
                {customizationCharges?.endpaperCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Endpaper</h4>
                    {customizationCharges.endpaperCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Endpaper Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.endpaperCharges.toFixed(2)}</p>
                      </div>
                    )}
                  </div>
                )}

                {customizationCharges?.dedicationPageCharges > 0 && (
                  <div className={classes.customizationItem}>
                    <h4>Dedication Page</h4>
                    {customizationCharges.dedicationPageCharges > 0 && (
                      <div className={classes.customizationItemDetail}>
                        <p>Dedication Page Charges</p>
                        <p className={classes.optionPrice}>${customizationCharges?.dedicationPageCharges.toFixed(2)}</p>
                      </div>
                    )}
                  </div>
                )}

              </div>
            </div>
          </div>

          <div className={classes.orderSummary}>
            <h3 className={classes.orderSummaryTitle}>Order Summary</h3>
            <div className={classes.summaryItem}>
              <span>Book Price:</span>
              <span>${getBookPrice(bookData, bookLeather, bookVariant)}</span>
            </div>
            <div className={classes.summaryItem}>
              <span>Customization Price:</span>
              <span>${customizationCharges.totalCharges?.toFixed(2)}</span>
            </div>
            <div className={classes.summaryItem}>
              <span>Total Quantity:</span>
              <span>{bookQty}</span>
            </div>
            <hr className={classes.divider} />
            <div className={classes.summaryTotal}>
              <span>Total:</span>
              <span>${(getBookPrice(bookData, bookLeather, bookVariant) * bookQty) + (customizationCharges.totalCharges?.toFixed(2) * bookQty)}</span>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CustomizationDetails;