import classes from "./WhoAreWe.module.css"

import { useState } from "react";

import Tabs from "./Tabs/Tabs"
import Content from "../Content/Content"
import { urls } from "../../../Routes/Urls";
import { Link } from "react-router-dom/cjs/react-router-dom";
const footerEnd = "/Assets/Images/sectionEnd.svg"

  
const tabs = [
    "Customization of books",
    "Leather Binding",
    "Book Restoration",
    "PDF Transformation",
    "Thesis Binding"
  ];

  const contents = [
    {
      title: "Your Vision, Our Craft, Endless Possibilities",
      text: "Immerse yourself in the art of bespoke bookbinding. Our one-of-a-kind customization tool empowers you to bring your literary vision to life, offering unparalleled control over every aspect of your book's leather binding. From selecting premium colors to choosing intricate embossing patterns, we ensure each volume becomes a unique masterpiece reflecting your personal taste and style.",
      image: "customization_of_book.svg",
      alt: "Soul by Olivia Wilson"
    },
    {
      title: "Timeless Elegance, Bound by Hand",
      text: "Experience the pinnacle of bookbinding craftsmanship. Our master artisans, drawing on generations of expertise, meticulously handcrafted each leather-bound book. Using only the finest materials - from high quality leathers to acid-free papers - we create exquisite volumes that are not just books, but works of art designed to be treasured for lifetimes.",
      image: "customization_of_book.svg",
      alt: "Leather Binding"
    },
    {
      title: "Reviving Literary Treasures",
      text: "Entrust your precious volumes to our expert care. Our restoration specialists combine time-honored techniques with modern conservation methods to breathe new life into aged or damaged books. We meticulously repair, clean, and preserve each page and binding, ensuring that your literary treasures retain their integrity and continue to tell their stories for generations to come.",
      image: "customization_of_book.svg",
      alt: "Book Restoration"
    },
    {
      title: "Digital to Tangible",
      text: "Elevate your digital works to tangible treasures. We transform your PDFs into beautifully leather bound physical books worthy of any distinguished collection. Our artisans carefully select materials and binding styles that complement your content, seamlessly merging digital-age efficiency with time-honored bookmaking craftsmanship.",
      image: "customization_of_book.svg",
      alt: "PDF Transformation"
    },
    {
      title: "Academic Excellence, Beautifully Preserved",
      text: "Give your academic achievements the presentation they deserve. We offer custom binding services tailored to scholarly works, combining durability with elegance. Whether you prefer classic leather binding or modern alternatives, we ensure your thesis or dissertation is beautifully preserved, ready to take its place among the great works in your field.",
      image: "customization_of_book.svg",
      alt: "Thesis Binding"
    }
  ];

export function WhoAreWe(){
    const [activeTab, setActiveTab] = useState(0);
    const activeTabData = contents[activeTab];

    return (
    <div className={classes.tabSection}>
        
        <div className={classes.aboutSection}>

                <div className={classes.aboutImage}>
                    <img src={'/Assets/Icons/whoWeAreIcon.svg'} alt="whoWeAre"  />
                </div>

                <div className={classes.whoAreWe}>
                    <h2>Who Are We?</h2>
                    <p>Trusted by thousands of bibliophiles around the globe, Rare Biblio has emerged as a global leader in designing and developing fine leather-bound books with exclusive customization options.<br/>
                      Welcome to Rare Biblio, where book lovers unite! Our community of passionate readers from all corners of the globe trusts us to create beautiful, leather-bound books. We pour our hearts into every design, offering exclusive customization options to make each book uniquely yours. Join us in celebrating the timeless joy of reading and collecting fine books.<br/>
                      As guardians of literary heritage, we unite bibliophiles with rare gems. Our professional artisans craft exquisite leather-bound books, preserving stories for generations to come.
                    </p>
                    <Link className={classes.knowMoreLink} to={urls.OURCOMPANY}>Learn More About Us</Link>
                </div>

        </div>
        <Tabs activeTab={activeTab} onTabClick={setActiveTab} tabs={tabs}/>
        <div className={classes.tabContent}>
          <Content tab={activeTab} data={activeTabData} activeTab={activeTab} />
        </div>

        <div className={classes.footerImage}>
            <img src={footerEnd} alt="" />
        </div>
    </div>
    )
}