import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPortal } from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

import { usersActions } from "../../Store/Slices/UsersSlice";
import { validateRBExclusiveAccess } from "../../Store/actions/UsersActions";
import classes from "./CodeCard.module.css";

const container = document.querySelector("#root");

const CodeCard = ({setHasExclusiveAccess, onClose }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.currentUser);

  const [code, setCode] = useState("");

  const addExclusiveCode = () => {
    if (!code) {
      return;
    }
    validateRBExclusiveAccess(code).then((res) => {
      if (res.data.success) {
        setHasExclusiveAccess(true)
        dispatch(
          usersActions.updateCurrentUser({
            ...userData,
            hasExclusiveAccess: true,
          })
        );
      }
    });
  };

  return createPortal(
    <>
      <div className={classes.modal}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={faXmarkCircle}
          onClick={onClose}
        />
        <div className={classes.title}>Please Enter Code</div>
        <div className={classes.description}>
          <div>Please enter your exclusive invite code to</div>
          <div>begin your journey into the world of Rare Biblio</div>
        </div>
        <div className={classes.container}>
          <div className={classes.actions}>
            <input
              type="text"
              placeholder="Enter Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
            <button onClick={addExclusiveCode}>Let's Go</button>
          </div>
        </div>
      </div>
    </>,
    container
  );
};

export default CodeCard;
