import React from "react";
import classes from "./PrimaryTextArea.module.css";
import { getClasses } from "../../../Util";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

function PrimaryTextArea({
  rows = "10",
  className,
  placeholder,
  onChange,
  errorMsg,
}) {
  return (
    <div className={classes.main}>
      <textarea
        rows={rows}
        placeholder={placeholder}
        className={getClasses(classes.primaryTextArea, className)}
        onChange={onChange}
      ></textarea>
      {errorMsg && <ErrorMessage error={errorMsg} />}
    </div>
  );
}

export default PrimaryTextArea;
