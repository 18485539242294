import React, { useState } from 'react';
import classes from './OfferCard.module.css';
import { copyTextToClipboard } from '../../../Util';

const OfferCard = ({ image, title, offerTitle, couponCode }) => {
    const [copied, setCopied] = useState(false);

    const handleCopyCode = () => {
        copyTextToClipboard(couponCode)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); 
            })
            .catch(err => console.error('Failed to copy text: ', err));
    };

    return (
        <div className={classes.card}>
            <div className={classes.offerTitle}>
                <h2 className={classes.cardtitle}>{offerTitle}</h2>
            </div>
            <div className={classes.offerImage}>
                <img src={image} alt="Offer Image" />
            </div>
            <div className={classes.bottomSection}>
                <h2 className={classes.cardtitle}>{title}</h2>
                <div className={classes.pricesection} onClick= {() => handleCopyCode()}>
                    <span title={couponCode} className={classes.couponCode}>{couponCode}</span>
                    {copied && <span className={classes.copiedMessage}>Copied</span>}
                </div>
            </div>
        </div>
    );
};


export default OfferCard;
