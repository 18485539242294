import BannerHeader from "../../../Components/BannerHeader/BannerHeader";
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav";
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo";
import classes from "./PDFPrinting.module.css";

import Timeline from "../Components/Timeline/Timeline";
import Gallery from "../../Body/Gallery/Gallery";
import CustomerReview from "../../Body/CustomerReview/CutomerReview";
import { ContactUs } from "../Components/ContactUs/ContactUs";
import Footer from "../../Footer/Footer";
import FAQ from "../FAQ/FAQ";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllFaq } from "../../../Store/actions/FaqAction";
import { urls } from "../../../Routes/Urls";
import useScrollToTop from "../../../Hooks/useScrollToTop";

const step1 = "/Assets/Images/PDF/Quick-PDF-Submission.png";
const step2 = "/Assets/Images/PDF/Expert-Binding-Insights.png";
const step3 = "/Assets/Images/PDF/Personalized-Customization-Creation.png";
const step4 = "/Assets/Images/PDF/Fair-Pricing.png";
const step5 = "/Assets/Images/PDF/Your-Exquisite-Leather-Bound-Treasue.png";

const steps = [
  {
    number: "01",
    title: "Quick PDF Submission",
    description:
      "Starting with the basic step of submitting the PDF. We’ve made it very easy for you and can be done in a jiffy, all you have to do is submit the pdf that you want to get transformed at info@rarebiblio.com or you can start by submitting a user-friendly form below that is going to make your word hassle-free.",
    image: step1, 
    align: "left",
  },
  {
    number: "02",
    title: "Expert Binding Insights",
    description:
      "Our binding experts make an entry for this step, our skilled craftsmen make sure they get into every detail of the PDFs that are submitted, and through their expert analysis and insights an optimal binding size and style is decided that will be in sync with your written digital document.",
    image: step2, 
    align: "right",
  },
  {
    number: "03",
    title: "Personalized Customization Creation",
    description:
      "Rare Biblio understands that your document is an emotion for you and therefore we make sure to bring this feature of personalized customization for you. You get a chance to select from leather type to vibrant leather colors, custom border and logo embossing, golden-edged pages i.e. gilding, and many more that will make your book a luxurious masterpiece that you wouldn’t have even imagined.Don’t worry, to make you understand this process better our team of experts will walk you through it and guide you in every possible way to help you bring your vision to life.",
    image: step3, 
    align: "left",
  },
  {
    number: "04",
    title: "Fair Pricing",
    description:
      "We do not intend to exploit our customers in any way, therefore, we maintain pure transparency and provide you with a fair pricing quote. You're going to know exactly how much it will cost to turn your PDF into a lavish, leather-bound book",
    image: step4, 
    align: "right",
  },
  {
    number: "05",
    title: "Your Exquisite Leather-Bound Treasure",
    description:
      "Now, comes the final step that you must have been waiting for, this is when your digital document starts getting its shape, and the leathery aesthetics are being applied to it.Our expert craftsmen begin to work on your book and very precisely they give a new life to your document by using the finest of the finest materials, adding perfectly hand-stitched binding to carefully selected leather covers, everything is flawlessly assembled.So, are you ready to turn your digital documents into timeless leather-bound treasures? Fill out the form below to start your journey with Rare Biblio. Learn about the grace and toughness of finely bound books that will be treasured for many years. ",
    image: step5, 
    align: "left",
  },
];
const bgImage = "Assets/Images/ServicePages/PdfPrinting.png"

const PDFPrinting = () => {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "PDF Printing", path: urls.PDFPRINTING },
  ];

  const video = useSelector(
    (state) => state.serviceVideo?.videos?.pdfPrintingVideo
  );
  const allFaq = useSelector((state) => state.Faq.questions);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllFaq("category", "PDF Transformation", "", dispatch);
  }, []);

  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />

      <BannerHeader
        bgImage={bgImage}
        crumbs={crumbs}
        title={"Transform Your PDF into a Beautiful Printed Book"}
        desc={
          "Our skilled artisans specialize in the meticulous art of leather-bound book restoration. This process not only enhances the aesthetic appeal of your vintage books but also safeguards their structural integrity."
        }
      />

      <div className={classes.mainContainer}>
        <ServiceVideo
          videoSrc={video}
          title={" Let’s Print A Book From PDF"}
          desc={
            "Your digital creations must have taken days, weeks, or even years to complete; now it's time to feel them in your hands as an exquisite leather-bound masterpiece, wrapped in vibrant colors of leather of your choice and shimmery golden-gilded pages adding royal finish to your book."
          }
        />
      </div>

      {/* <WhyUs /> */}

      <div className={classes.printingSteps}>
        <h2 className={classes.title}>How it works ?</h2>
        <p className={classes.subtitle}>
          The premier destination for bibliophiles and book lovers who
          appreciate the art of bookmaking.As a rare books store. we specialize
          in following things:
        </p>
        <Timeline steps={steps} />
      </div>

      <Gallery />

      <CustomerReview />

      <FAQ data={allFaq} />

      <div className={classes.contactUs}>
        <ContactUs
          desc={
            "Get in touch with us right now to see the superior craftsmanship that makes us stand out!"
          }
        />
      </div>

      <Footer />
    </div>
  );
};

export default PDFPrinting;
