import { Children } from "react";

function Each({ render, of }) {
  if (!of?.length) {
    return <></>;
  }
  return Children.toArray(of.map((item, index) => render(item, index)));
}

export default Each;
