import axios from "axios";
import { CouponActions } from "../Slices/CouponSlice";
import { SERVICE_URL } from "../ServiceUrls";
import { store } from "../Store";



export function fetchAllCoupons(dispatch, filter) {

    return axios({
        url: `${SERVICE_URL.COUPONS}?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method:"get",
        responseType:"json"
    }).then((res) => {
        if(res.data.success){
            const currUser = store.getState().user.currentUser;
            const allCoupons = res.data?.data.filter((coupon) => currUser?.id === coupon?.issuedTo || !coupon?.issuedTo )
            dispatch(CouponActions.setAllCoupons(allCoupons))
        }
    }).catch((err) => {
        console.error(err)
    })
}

export function fetchUserCoupons(userId,dispatch) {
    return axios({
        url:`${SERVICE_URL.COUPONS}/my-coupons`,
        method:"get",
        responseType:"json"
    }).then((res) => {
        if(res.data.success){
            const coupons = res.data?.data.filter((coupon) => coupon?.expires >= new Date().getTime())
            dispatch(CouponActions.setUserCoupons(coupons))
        }
    }).catch((err) => {
        console.error(err)
    })
}
