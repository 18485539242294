import {React, useEffect, useState} from "react";
import { Canvas } from "@react-three/fiber";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { CameraControls } from "@react-three/drei";
import { TextureLoader } from "three";

const textureLoader = new TextureLoader();
const objLoader = new OBJLoader();
function OBJModel(props) {
  const [obj, setObj] = useState();
  const {path, textureData} = props;
  useEffect(() => {
    if (path) {
      objLoader.load(path, setObj);
    }
  }, [path]);

  useEffect(() => {
    if (obj && textureData?.length){
      let map = new Map();
      for(let texture of textureData) {
        map.set(texture.target, texture);
      }
      for(let mesh of obj.children) {
        const {name, material} = mesh;
        if (map.has(name)) {
          material.origMap = material.map;
          const tex = map.get(name);
          if (tex.src) {
            material.map = textureLoader.load(tex.src);
          } else if (tex.color) {
            material.color.set(tex.color);
          }
          
        } else if(material.origMap){
          material.map = material.origMap;
          delete material.origMap;
        }
        material.needsUpdate = true;
      }
    }
  }, [obj, textureData]);

  return obj && <primitive {...props} object={obj} />
}

function Viewer({textureData,bookData}) {

  const getBookModel = () => {
    const bookSize = bookData?.sizeName;
    const bookPages = bookData?.pages;

    if(bookPages >= 0 && bookPages <= 200){
      return `/models/${bookSize}/0-200.obj`
    }
    if(bookPages >= 201 && bookPages <= 400){
      return `/models/${bookSize}/201-400.obj`
    }
    if(bookPages >= 401 && bookPages <= 600){
      return `/models/${bookSize}/401-600.obj`
    }
    if(bookPages >= 601 && bookPages <= 800){
      return `/models/${bookSize}/601-800.obj`
    }
    else{
      return `/models/${bookSize}/800.obj`
    }
  }

  return <Canvas>
    <ambientLight position={[1, 1, 1]}/>
    <OBJModel path={getBookModel()} scale={0.15} textureData={textureData} position={[0, 0.5, 0]}/>
    <CameraControls makeDefault minAzimuthAngle={-2 * Math.PI} maxAzimuthAngle={2 * Math.PI} minPolarAngle={-2 * Math.PI} maxPolarAngle={2 * Math.PI} /> 
  </Canvas>;
}

export default Viewer;



