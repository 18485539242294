import classes from "./Footer.module.css"
import FooterItems from "./FooterItems/FooterItems"
import Community from "./Community/Community"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faLinkedinIn, faPinterest, faXTwitter } from "@fortawesome/free-brands-svg-icons"

const logo = "/Assets/Icons/FooterLogo.svg"

const india = "/Assets/Icons/india.png"
const uk  = "/Assets/Icons/uk.png"
const america  = "/Assets/Icons/america.png"
const  canada  = "/Assets/Icons/canada.png"
const france   = "/Assets/Icons/france.png"

const footerData = [
    {
        "title":"Company",
        "items": ["About Us","Privacy Policy","FAQs","Return/Refunds","Blogs"]
    },
    {
        "title":"Shop",
        "items": ["Best Seller","Limited Edition","Customization"]
    },
    {
        "title":"Contact Us",
        "items": ["Email: info@rarebiblio.com"]
    },
]

const handleIconClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer"); 
  };

export default function Footer(){
    return (
        <div className={classes.footerContainer}>
            <div className={classes.communityContainer}>
                <Community />
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.icon}>
                    <img src={logo} alt="" />
                </div>
                    <div className={classes.itemContainer}>
                        {footerData.map((footer,index) => (
                            <FooterItems key={index} title={footer.title} items={footer.items} />
                        ))}

                    </div>

                <div className={classes.footerSocial}>
                    <div>
                        <h2>Let's do it!</h2>
                        <div className={classes.socialIcons}>
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.facebook.com/Rarebiblio")} className={classes.footerIcons} icon={faFacebook} />
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.instagram.com/Rarebiblio")} className={classes.footerIcons} icon={faInstagram} />
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.twitter.com/Rarebiblio")} className={classes.footerIcons} icon={faXTwitter} />
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.linkedin.com/company/Rarebiblio")} className={classes.footerIcons} icon={faLinkedinIn} />
                            <FontAwesomeIcon onClick={()=> handleIconClick("https://www.pinterest.com/rare_biblio")} className={classes.footerIcons} icon={faPinterest} />

                        </div>
                    </div>

                    <div className={classes.locatedSection}>
                        <h2>Located in</h2>

                        <div className={classes.iconContainer}>
                                <div className={classes.icons}><img src={canada} alt="" /></div>
                                <div className={classes.icons}><img src={uk} alt="" /></div>
                                <div className={classes.icons}><img src={america} alt="" /></div>
                                <div className={classes.icons}><img src={france} alt="" /></div>
                                <div className={classes.icons}><img src={india} alt="" /></div>
                        </div>

                    </div>

                </div>  
            </div>
            {/* <div className={classes.footerLinks}>
                <FooterLinks />
            </div> */}

        </div>

    )
}