import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
    name: "user",
    initialState: {
        currentUser: {}
    },
    reducers: {
        updateCurrentUser: (state, action) => {
            state.currentUser = action.payload;
            localStorage.setItem("isLoggedIn","true")
        },

        logoutUser: (state) => {
            state.currentUser = {}
            localStorage.removeItem("isLoggedIn")
        }
    },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
