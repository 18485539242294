import React, { useState } from 'react';
import classes from "./SearchComponent.module.css"
import { subscribe } from '../../../Store/actions/ContactUsAction';

const SearchComponent = ({onClick}) => {
  const [userEmail,setUserEmail] = useState("")

  const handleInputChange = (e) => {
    setUserEmail(e.target.value);
  };

  const clearForm = () => {
      setUserEmail("")
  }

  const handleFormSubmission = (e) => {
      e.preventDefault()
      const data = {
          "email":userEmail.toLowerCase(),
      }
      
      subscribe(data).then((response) => {
          if(response.data.success === true){
              clearForm();
          }

      })
  }


  return (
    <div className={classes.search}>
        {/* <div className={classes.searchBar}>
          <input type="text" placeholder="Search via author, keywords, book name, ISBN" />
          <button>Search Now</button>
        </div> */}
           <div className={classes.searchContainer}>
      {/* <FaSearch className={classes.searchIcon} /> */}
      <input
        type="email"
        className={classes.searchInput}
        placeholder="Enter your Email Address"
        value={userEmail}
        onChange={handleInputChange}
      />
      <button className={classes.searchButton} onClick={handleFormSubmission}>
        Subscribe 
      </button>
    </div>

        </div>
  );
};

export default SearchComponent;
