import classes from "./Timeline.module.css"

const Timeline = ({steps}) => {
    return(
        <div className={classes.stepsContainer}>
        {steps.map((step, index) => (
          <div key={index} className={classes.step}>
            <div className={classes.stepNumberContainer}>
              <div className={classes.stepNumber}>{step.number}</div>
              {index < steps.length - 1 && <div className={classes.dottedLine}></div>}
            </div>
            <div className={`${classes.stepContent} ${step.align === 'left' ? classes.left : classes.right}`}>
              <div className={classes.stepInfo}>
                <h2 className={classes.stepTitle}>Step {step.number}</h2>
                <h3 className={classes.stepName}>{step.title}</h3>
                <p className={classes.stepDescription}>{step.description}</p>
              </div>
              <div className={classes.stepImage}>
                <img src={step.image} alt={`Step ${step.number}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
    )
}

export default Timeline