import classes from "./AddressCard.module.css";

const AddressCard = ({ userName, mobileNumber, addressLine1, addressLine2, country, city, state, zipCode, onDelete, onEdit }) => {
    const formattedAddress = `
        ${addressLine1}
        ${addressLine2 ? addressLine2 + ', ' : ''}
        ${city ? city + ', ' : ''}
        ${state ? state + ', ' : ''}
        ${country ? country + ' ' : ''}
        ${zipCode ? zipCode : ''}
    `.trim();

    return (
        <div className={classes.addressCard}>
            <h2>{userName}</h2>
            <p>{mobileNumber}</p>
            <p>{formattedAddress}</p>

            <div className={classes.options}>
                <p className={classes.remove} onClick={onDelete}>Remove</p>
                <p className={classes.edit} onClick={onEdit}>Edit</p>
            </div>
        </div>
    );
};

export default AddressCard;
