import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { urls } from "../../../Routes/Urls";
import {
  applyCoupon,
  applyWallet,
  fetchAllCartItems,
  removeCoupon,
  removeWallet,
} from "../../../Store/actions/CartAction";
import Modal, { ModalBody } from "../../../Components/Modal/Modal";
import classes from "./OrderSummary.module.css";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton";
import Coupons from "../Coupons/Coupons";
import { useEffect } from "react";

const couponIcon = "/Assets/Icons/VectorCoupon.svg";

const OrderSummary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { cart, cartItems } = useSelector((state) => state.cart);
  const currentUser = useSelector((state) => state.user.currentUser);

  const [modalOpen, setModalOpen] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [isApplicable, setIsApplicable] = useState(false);

  const subtotal = cartItems?.reduce(
    (acc, item) =>
      acc + ((item?.book?.isDeleted || item?.bestSellerBook?.isDeleted) 
        ? 0 
        : (item?.itemPrice + (item?.customizationCharges || 0)) * item.quantity),
    0
  );

  const getCartItemsQuantity = () => {
    const updatedCart = cartItems?.filter((item) => item?.book?.isDeleted === false || item?.bestSellerBook?.isDeleted === false )
    return updatedCart?.length
  }
  

  const applyCouponCode = async () => {
    if(currentUser?.id) {
    try {
        const res = await applyCoupon(couponCode);

        if (res.data.success) {
          fetchAllCartItems(dispatch);
        } else {
          setIsApplicable(true);
        }
    } catch (err) {}
  } else {
    history.push(urls.LOGIN)
  }
  };

  const applyWalletAmount = async () => {
    try {
      const res = await applyWallet();

      if (res.data.success) {
        fetchAllCartItems(dispatch);
      } else {
        setIsApplicable(true);
      }
    } catch (err) {}
  };

  const removeCouponCode = async () => {
    try {
      const res = await removeCoupon();

      if (res.data.success) {
        fetchAllCartItems(dispatch);
      } else {
        setIsApplicable(false);
      }
    } catch (err) {}
  };

  const removeWalletAmount = async () => {
    try {
      const res = await removeWallet();

      if (res.data.success) {
        fetchAllCartItems(dispatch);
      } else {
        setIsApplicable(false);
      }
    } catch (err) {}
  };

  const subTotal = () => {
    if(subtotal) {
        if(cart?.totalDiscount &&  (Number(subtotal) - Number(cart?.totalDiscount)) > 0){
            return Number(Number(subtotal) - Number(cart?.totalDiscount)).toFixed(2)
        } else {
            return Number(subtotal).toFixed(2)
        }
    } else {
        return (0).toFixed(2)
    }
  };

  useEffect(()=>{
    setTimeout(() => {
      setIsApplicable(false)
    },4000)
  },[isApplicable])

  return (
    <div className={classes.mainContainer}>
      <h1>Order Summary</h1>
      <div className={classes.summarySection}>
        <div className={classes.overview}>
          <h2>
            {getCartItemsQuantity()} Total{" "}
            {cartItems?.length === 1 ? "Item" : "Items"}
          </h2>
        </div>
        <div className={classes.couponSection}>
          <h2>Have a coupon?</h2>
          <p>Add your code for an instant cart discount</p>
          <p
            className={classes.browseCoupons}
            onClick={() => {currentUser?.id ? setModalOpen(true) : history.push(urls.LOGIN)}}
          >
            Browse Coupons
          </p>

          <div className={classes.couponButton}>
            <div>
              <img src={couponIcon} alt="" />
              <input
                type="text"
                name=""
                id=""
                placeholder="Coupon Code"
                value={couponCode}
                onChange={(e) => {
                  setCouponCode(e.target.value);
                  setIsApplicable(false);
                }}
              />
            </div>
            <button  disabled={!couponCode} onClick={applyCouponCode}>
              Apply
            </button>
          </div>
          {isApplicable && (
            <div className={classes.notApplicable}>
             Sorry, this coupon is not valid.
            </div>
          )}
        </div>

        {cart?.couponCode && (
          <div className={classes.appliedCoupon}>
            <div className={classes.couponHeader}>
              <h4>Coupon Applied</h4>
            </div>
            <div className={classes.couponBody}>
              <h3>{cart?.couponCode}</h3>
              <p onClick={removeCouponCode}>Remove</p>
            </div>
          </div>
        )}

        {currentUser?.walletBalance > 0 && (
          <div className={classes.walletSection}>
            <div className={classes.walletInfo}>
              <h2>Wallet Balance</h2>
              <p className={cart?.walletUsed ? classes.usedWalletAmount : classes.walletAmount}>
                ${cart?.walletUsed ? Number(currentUser.walletBalance - cart?.walletDiscount).toFixed(2) : Number(currentUser.walletBalance).toFixed(2)}
              </p>
            </div>
            {cart?.walletUsed ? (<PrimaryButton
              className={classes.walletButton}
              onClick={removeWalletAmount}
            >
              Remove
            </PrimaryButton>) : (<PrimaryButton
              className={classes.walletButton}
              onClick={applyWalletAmount}
            >
              Use Wallet
            </PrimaryButton>)}
          </div>
        )}

        {cart?.totalDiscount > 0 ? (
          <div className={classes.discount}>
            <p>Discount</p>
            <p>${Number(cart.totalDiscount).toFixed(2)}</p>
          </div>
        ) : (
          ""
        )}
        <div className={classes.total}>
          <h2>Sub-Total</h2>
          <p>${subTotal()}</p>
        </div>
      </div>
      <PrimaryButton
        disabled={cartItems?.length === 0 ? true : false}
        onClick={() => history.push(urls.CHECKOUT)}
      >
        Proceed to Checkout <span style={{ marginLeft: "8px" }}>&#10145;</span>
      </PrimaryButton>

      {modalOpen && (
        <Modal title={"All Coupons"} onClose={() => setModalOpen(false)}>
          <ModalBody>
            <div className={classes.couponModal}>
              <Coupons />
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default OrderSummary;
