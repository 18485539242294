import BannerHeader from "../../../Components/BannerHeader/BannerHeader";
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav";
import classes from "./BookRestoration.module.css"
import { useEffect } from "react";
import WhyUs from "./WhyUs/WhyUs";
import RestorationSteps from "./RestorationSteps/RestorationSteps";
import Gallery from "../../Body/Gallery/Gallery";
import CustomerReview from "../../Body/CustomerReview/CutomerReview";
import FAQ from "../FAQ/FAQ";
import { ContactUs } from "../Components/ContactUs/ContactUs";
import Footer from "../../Footer/Footer";
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo";
import { useDispatch, useSelector } from "react-redux";
import { getAllFaq } from "../../../Store/actions/FaqAction";
import { urls } from "../../../Routes/Urls";
import useScrollToTop from "../../../Hooks/useScrollToTop";

const banner = "/Assets/Images/ServicePages/BookRestoration.png"
const BookRestoration = () => {
    const crumbs = [
        {title:"Home",path:("/")},
        {title:"Book Restoration",path:urls.BOOKRESTORATION},
    ]

    const allFaqs = useSelector(state => state.Faq.questions)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const video = useSelector((state) => state.serviceVideo?.videos?.bookRestorationVideo)

    const dispatch = useDispatch()

    useEffect(() => {
        getAllFaq("category", "Book Restoration", "", dispatch);
    },[])

    const scrollRef = useScrollToTop();

    return(
        <div ref={scrollRef}>
            <PrimaryNav />
            <BannerHeader
                title={'Renewing Classics, Preserving Memories'}
                crumbs={crumbs}
                bgImage={banner} 
            />

            <ServiceVideo videoSrc={video} 
            title={"Restoration of old books"} 
            desc={"Your old and worn-out books are meant to be given a second chance through our restoration process. Our talented artisans are experts and specialize in the fine craft of leather-bound book restoration and can bring your old book to life. Rare Biblio makes sure to bring out the beauty while keeping your cherished volumes safe for years to come."}/>

            <WhyUs />

            <RestorationSteps />

            <Gallery />

            <CustomerReview />

            
            <FAQ data={allFaqs} category={"Book Restoration"}/>

            <div className={classes.ContactUs}>
                <ContactUs />
            </div>

            <Footer />

        </div>
    )
}

export default BookRestoration;