import BannerHeader from "../../../Components/BannerHeader/BannerHeader";
import PrimaryNav from "../../Header/PrimaryNav/PrimaryNav";
import ServiceVideo from "../Components/ServiceVideo/ServiceVideo";
import classes from "./ThesisBinding.module.css";
import AboutThesisBinding from "./AboutThesisBinding/AboutThesisBinding";
import CustomerReview from "../../Body/CustomerReview/CutomerReview";
import Timeline from "../Components/Timeline/Timeline";
import Gallery from "../../Body/Gallery/Gallery";
import FAQ from "../FAQ/FAQ";
import { ContactUs } from "../Components/ContactUs/ContactUs";
import Footer from "../../Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllFaq } from "../../../Store/actions/FaqAction";
import { urls } from "../../../Routes/Urls";
import useScrollToTop from "../../../Hooks/useScrollToTop";

const image = "/Assets/Images/CustomizationOverview.svg";
const steps = [
  {
    number: "01",
    title: "PhD Thesis Submission",
    description:
      "Let’s begin with the initial step that involves you submitting your PhD thesis to us via mail i.e. info@rarebiblio.co, or you can submit it by filling out a basic form given below.",
    image: image, // replace with image path if needed
    align: "left",
  },
  {
    number: "02",
    title: "Expert Analysis",
    description:
      "Our team of expert craftsmen will analyze your work completely and according to their genius insights come up with an appropriate binding size.",
    image: image, // replace with image path if needed
    align: "right",
  },
  {
    number: "03",
    title: "Customization & Personalization",
    description:
      "As we are trying to craft your hard work we want you to have the right to give your inputs and preferences. We give you multiple options from selecting the colorful leather to adding your custom logo with precise embossing or designing appealing golden borders, our team will be in touch with you throughout to get your approvals and make you feel connected in this amazing craftsmanship of your hard work.",
    image: image, // replace with image path if needed
    align: "left",
  },
  {
    number: "04",
    title: "Honest Pricing",
    description:
      "We work hard to give you an affordable price after a careful study, all the while maintaining the transparency you deserve. We aim to make sure that you get outstanding value at an affordable price, which reflects our commitment to honesty and fairness.",
    image: image, // replace with image path if needed
    align: "right",
  },
  {
    number: "05",
    title: "Thesis Printing and Binding",
    description:
      "Our skilled artisans start work as soon as you confirm the design and the cost, carefully handcrafting each book with great attention to detail. Your revised thesis, which will highlight your academic accomplishments and make a valuable contribution to your professional library, will arrive soon.At Rare Biblio we transform dissertations and theses into works of art worthy of your achievements, not just bind them. With Rare Biblio, you may embrace the eternal craft of leather binding and let us transform your academic work into an enduring masterpiece.",
    image: image, // replace with image path if needed
    align: "left",
  },
];
const bgImage = "Assets/Images/ServicePages/PdfThesisBinding.png"

const ThesisBinding = () => {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Thesis binding", path: urls.THESISBINDING },
  ];
  const video = useSelector(
    (state) => state.serviceVideo?.videos?.thesisBindingVideo
  );
  const allFaq = useSelector((state) => state.Faq?.questions);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllFaq("category", "Thesis Binding", "", dispatch);
  }, []);

  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />

      <BannerHeader
      bgImage={bgImage}
        crumbs={crumbs}
        title={"Professional PhD Thesis Binding Services."}
        desc={
          "“A thesis is the key that unlocks the door to deeper knowledge, transforming questions into compelling answers"
        }
      />

      <ServiceVideo
        title={"PhD Thesis Binding Service"}
        desc={
          "At Rare Biblio we truly appreciate the value of your Ph.D thesis and the rigorous commitment that you would’ve put in to achieve it. Rare Biblio acknowledges the years of hard work, sleepless nights, and mental exertion that you have devoted to the completion of your thesis. Your academic success is deserving of the best preservation possible.Thus, Rare Biblio won’t let any of your efforts go in vain and is here to offer you a special feature to get your Ph.D thesis perfectly leather-bound into royalty and luxury,  along with an exclusive discount intended just for intellectuals like you.Our mission is to preserve your academic journey with the classic style of leather, making sure that your thesis will always be a testament to your commitment and diligence."
        }
        videoSrc={video}
      />

      <AboutThesisBinding />

      <div className={classes.ThesisBindingSteps}>
        <h2 className={classes.title}>
          How To Get Started With Thesis Binding
        </h2>
        <p className={classes.subtitle}>
           the premier destination for bibliophiles and book lovers who
          appreciate the art of bookmaking. As a rare books store. we specialize
          in following things:
        </p>
        <Timeline steps={steps} />
      </div>

      <Gallery />

      <CustomerReview />

      <FAQ data={allFaq} />

      <div className={classes.contactUs}>
        <ContactUs
          title={
            "Get started with your PhD thesis binding by submitting the form below."
          }
        />
      </div>

      <Footer />
    </div>
  );
};

export default ThesisBinding;
