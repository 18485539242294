import classes from "./ButtonLight.module.css"

import { getClasses } from "../../../Util"

function ButtonLight({children,onClick,className,toolTip}){
    return (
        <button className={getClasses(className,classes.lightButton)}
            onClick={onClick}
            title={toolTip}
        >
            {children}
        </button>
    )
}

export default ButtonLight;