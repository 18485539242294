import classes from "./ButtonUtility.module.css"
import { getClasses } from "../../../Util";

function ButtonUtility({icon, children, onClick, className, isActive, toolTip }) {
    return (
        <div className={getClasses(classes.buttonContainer,className)} onClick={onClick} >
           {icon && <div className={classes.icon}>
                <img src={icon} alt="icon" />
            </div>}
            <div className={getClasses(classes.label,  isActive ? classes.active : "")}>
                {children}
            </div>
        </div>
    );
}

export default ButtonUtility;