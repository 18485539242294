import React, {useState } from "react";

import classes from "./FeaturedCollection.module.css";

import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import Card from "../../../Components/Card/Card2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";
import { useSelector } from "react-redux";
import { getRandomImageForBestSellerBook } from "../../../Util";
import useScreenSize from "../../../Hooks/UseScreenSize";
const footerImage = "/Assets/Images/sectionEnd.svg";

const tabs = [
  {
    name: "Newly Crafted",
    value: "newlyCrafted",
  },
  {
    name: "Best Sellers",
    value: "bestSelling",
  },
  {
    name: "Gifts",
    value: "gifts",
  },
];

function FeaturedCollection() {
  const featuredCollectionBooks = useSelector(
    (state) => state.featured.featuredCollection
  );
  const allColorsData = useSelector(state => state.bookColor.colors);

  const wishlist = useSelector((state) => state.wishlist.wishlistItems);

  const screenSize = useScreenSize();
  const history = useHistory()
  
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const featuredCollection = featuredCollectionBooks?.find((data) => data.category === activeTab)?.books || [];

  const isWishListItem = (bookData) => {
    const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title)
    if(bookOpt) {
      return {isPresent : true, data: bookOpt}
    } else {
      return {isPresent : false, data:null}
    }
  }

  return (
    <div className={classes.featuredContainer}>
      <div className={classes.headerSection}>
        <div className={classes.headerh2}>
          <h2>Featured Collection</h2>
        </div>
        <div className={classes.description}>
          <p>
            Explore our bestsellers, newly crafted designs, and perfect gifts
            for the discerning bibliophile.
          </p>
        </div>
      </div>

      <div className={classes.tabs}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${classes.tab} ${
              activeTab === tab.value ? classes.active : ""
            }`}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.name}
          </div>
        ))}
      </div>

      <div className={classes.cardGrid}>
        {featuredCollection.slice(0, screenSize.isSmallScreen ? 2 : 4).map((card, index) => (
          <Card
            key={index}
            altText={getRandomImageForBestSellerBook(card, allColorsData)?.altText}
            image={card.imageUrl}
            title={card?.title}
            author={card?.author}
            price={card?.priceGenuine}
            bookData={card}
            isWishListItem={isWishListItem(card).isPresent}
            wishlistItemId={isWishListItem(card).data?.id}
          />
        ))}
      </div>

      <div className={classes.buttonContainer}>
        <SecondaryButton onClick={() => history.push(urls.BESTSELLER)}>Explore Our Collection</SecondaryButton>
      </div>

      <div className={classes.footerImage}>
        <img src={footerImage} alt="" />
      </div>
    </div>
  );
}

export default FeaturedCollection;
