import axios from "../../Axios";
import Consts from "../../Consts";

import { BestSellingAuthorActions } from "../Slices/BestAuthors";

export const fetchAllAuthors = (dispatch) => {
    return axios({
        url:`${Consts.SERVER_URL}/best-selling-authors`,
        params:{
            filter : "{}"
        },
        method:"get",
        responseType:"json"
    })
    .then((response) =>{
        if(response.data.success){
            dispatch(BestSellingAuthorActions.updateBestAuthors(response.data.data));
        }
    }) 
    .catch((error) => {
        console.error(error)
    })
}

export const fetchTop5Authors = (dispatch) => {
    const filter = JSON.stringify({
            "sortBy":"sales desc",
            "limit":5
           
    })

    return axios({
        url:`${Consts.SERVER_URL}/best-selling-authors`,
        params:{
            filter:filter
        },
        method:"get",
        responseType:"json"
    })
    .then((response) => {
        if(response.data.success){
            dispatch(BestSellingAuthorActions.updateTop5Authors(response.data.data))
        }
    })
    .catch((error) => {
        console.error(error)
    })
    
}