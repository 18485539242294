import axios from "../../Axios";
import { ProcessingCostActions } from "../Slices/ProcessingCostSlice";

// Fetch all processing costs
export const fetchAllProcessingCosts = (dispatch) => {
    return axios({
        url: "/api/processing-costs",
        method: "get",
        responseType: "json"
    })
    .then((response) => {
        dispatch(ProcessingCostActions.setCost(response.data));
    })
    .catch((error) => {
        console.error("Failed to fetch processing costs:", error);
    });
};

// Create a new processing cost
export const createProcessingCost = (processingCost, dispatch) => {
    return axios({
        url: "/api/processing-costs",
        method: "post",
        data: processingCost,
        responseType: "json"
    })
    .then((response) => {
        const data = response.data;
        if (data.success) {
            dispatch(ProcessingCostActions.addCost(data.data));
        }
    })
    .catch((error) => {
        console.error("Failed to create processing cost:", error);
    });
};

// Update an existing processing cost
export const updateProcessingCost = (costId, updatedProcessingCost, dispatch) => {
    return axios({
        url: `/api/processing-costs/${costId}`,
        method: "put",
        data: updatedProcessingCost,
        responseType: "json"
    })
    .then((response) => {
        const data = response.data;
        if (data.success) {
            dispatch(ProcessingCostActions.updateCost(data.data));
        }
    })
    .catch((error) => {
        console.error("Failed to update processing cost:", error);
    });
};

// Delete a processing cost
export const deleteProcessingCost = (costId, dispatch) => {
    return axios({
        url: `/api/processing-costs/${costId}`,
        method: "delete",
        responseType: "json"
    })
    .then((response) => {
        if (response.data.success) {
            dispatch(ProcessingCostActions.deleteCost(costId));
        }
    })
    .catch((error) => {
        console.error("Failed to delete processing cost:", error);
    });
};
