import classes from "./Card.module.css"

export const Card = ({index,data}) => {
    return(
        <div className={classes.cardContainer}>
            <div className={classes.key}>
                <p>{index}</p>
            </div>
            <div className={classes.data}>
                <p>{data}</p>
            </div>
        </div>
    )
}