import classes from "./Paper.module.css"
import { useState, useContext, useEffect } from "react";
import Gilding from "./Gilding/Gilding";
import SectionController from "../Components/SectionController/SectionController";
import useScreenSize from "../../../Hooks/UseScreenSize";
import { CustomizationContext } from "../Customization";
import { useFabricCanvas, useFabricEditor } from "react-fabric-page";

const options = ["Gilding"]

const Paper = ({onNext,onPrev}) => {

    const [currentDropdownId, setCurrentDropdownId] = useState(0);
    const [currentDropdown, setCurrentDropdown] = useState(options[currentDropdownId]);
    const [dropdownOpen, setDropDownOpen] = useState(true);
    const {isMediumScreen} = useScreenSize()
    const {selectedOption,setDesignJSON,designSaveInitiated,steps} = useContext(CustomizationContext)

    const editor = useFabricEditor()
    const canvas = useFabricCanvas()

    useEffect(() => {
      window.paperEditor = editor
    },[editor])

    useEffect(() => {
          const timeout = setTimeout(() => {
          if (canvas) {
              canvas.renderAll();
          }
      }, 300);
      return () => clearTimeout(timeout);
  },[steps,canvas])

    const toggleDropdown = () => {
        setDropDownOpen(!dropdownOpen);
    };

    const handleOptionClick = (id) => {
        if (id === currentDropdownId) {
          setDropDownOpen(!dropdownOpen);
        } else {
          setCurrentDropdownId(id);
          setCurrentDropdown(options[id]);
          setDropDownOpen(true);
        }
    
      };

    const nextSection = (id) => {
        if (currentDropdownId < 0) {
            setCurrentDropdownId(currentDropdownId + 1);
            setCurrentDropdown(options[id + 1]);
            setDropDownOpen(true)
        }
        else{
            onNext()
        }
    }

    const prevSection = (id) => {
        if (currentDropdownId >= 1) {
            setCurrentDropdownId(currentDropdownId - 1);
            setCurrentDropdown(options[id - 1]);
            setDropDownOpen(true)
        }
        else{
            onPrev()
        }
    }

    useEffect(() => {
      if(designSaveInitiated && editor) {
        const paperJSONString = editor?.getJSON();
        const paperJSONBlob = new Blob([paperJSONString], { type: "application/json" });
        setDesignJSON(prevArray => {
          const updatedArray = [...prevArray];
          updatedArray[2] = paperJSONBlob;
          return updatedArray;
        });
      }
    },[designSaveInitiated])

    return (
      <div className={classes.mainContainer}>
        <div className={classes.details}>
            
      {   !isMediumScreen && <div className={classes.headerSection}>
              <h2>Let's customize Paper of the book (3/5) </h2>
              <p>
                Paper: Select the perfect paper for your book, considering both
                its look and feel.
              </p>
            </div>}
          

          {!isMediumScreen && (
            <div className={classes.customizationBody}>
              <div onClick={() => handleOptionClick(0)}>
                <Gilding
                  currentDropdown={currentDropdown}
                  dropdownOpen={dropdownOpen}
                  toggleDropdown={toggleDropdown}
                  onClick={() => handleOptionClick(0)}
                />
              </div>
              <div className={classes.sectionController}>
                <SectionController
                  prev={
                    currentDropdownId === 0
                      ? "Spine"
                      : options[currentDropdownId - 1]
                  }
                  next={
                    currentDropdownId === 0
                      ? "Endpaper"
                      : options[currentDropdownId + 1]
                  }
                  onNext={() => nextSection(currentDropdownId)}
                  onPrev={() => prevSection(currentDropdownId)}
                />
              </div>
            </div>
          )}
          {isMediumScreen && (
            <div className={classes.customizationBody}>
              <div
                className={
                  selectedOption === options[0] ? "" : classes.inVisible
                }
                onClick={() => handleOptionClick(0)}
              >
                <Gilding
                  currentDropdown={currentDropdown}
                  dropdownOpen={dropdownOpen}
                  toggleDropdown={toggleDropdown}
                  onClick={() => handleOptionClick(0)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
}

export default Paper