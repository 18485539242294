import axios from "../../Axios";
import Consts from "../../Consts";
import { getRandomImageForNormalBook } from "../../Util";
import { GalleryActions } from "../Slices/GallerySlice";
import { store } from "../Store";

export const fetchGalleryByType = (dispatch) => {
    return axios({
        url:`${Consts.SERVER_URL}/galleries`,
        method:"get",
        responseType:"json"
    })
    .then((response) => {
        if(response.status === 200){
            const booksData = response.data;
            const allColorsData = store.getState().bookColor.colors;
            let addRandomImagesToBookData = [];
            booksData.forEach((book) => {
              const newData =  book.books.map((data) => (
                     {
                      ...data,
                      imageUrl: getRandomImageForNormalBook(data, allColorsData)?.image
                     }
                ))
              if(newData){
                addRandomImagesToBookData.push({...book,  books: newData})
              }
            })
          
            dispatch(GalleryActions.updateGallery(addRandomImagesToBookData))
        }
    })
}