import { createSlice } from "@reduxjs/toolkit";
import {} from "../../Util";



const TestimonialSlice = createSlice({
    name: "testimonial",
    initialState: {
        testimonial: []
    },
    reducers: {
        updateTestimonial: (state, action) => {
            state.testimonial = action.payload;
        },
    },
});


export const testimonialActions = TestimonialSlice.actions;
export default TestimonialSlice.reducer;

