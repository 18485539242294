import { createSlice } from "@reduxjs/toolkit";

const CartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: [],
        loading: false,
        error: null,
        cart: {},
    },
    reducers: {
        addCartItemRequest(state) {
            state.loading = true;
            state.error = null;
        },
        addCartItemSuccess(state, action) {
            state.loading = false;
            state.cartItems = action.payload;
        },
        addCartItemFailure(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updateCartItems(state, action) {
            state.cartItems = action.payload;
        },
        removeCartItem(state, action) {
            state.cartItems = state.cartItems.filter(item => item.id !== action.payload);
        },
        fetchCart(state, action) {
            state.cart = action.payload;
        },
    }
});

export const CartActions = CartSlice.actions;
export default CartSlice.reducer;
