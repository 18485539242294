import axios from "../../Axios";
import Consts from "../../Consts";
import { getRandomImageForBestSellerBook } from "../../Util";

import { featuredCollectionAction } from "../Slices/FeaturedCollectionSlice";
import { store } from "../Store";

export const fetchFeaturedCollection = (dispatch) => {
  return axios({
    url: `${Consts.SERVER_URL}/featured-collections`,
    method: "get",
    responseType: "json",
  }).then((response) => {
    if (response.status === 200) {
      const booksData = response.data;
      const allColorsData = store.getState().bookColor.colors;
      let addRandomImagesToBookData = [];
      booksData?.forEach((book) => {
        const newData =  book?.books?.map((data) => (
               {
                ...data,
                imageUrl: getRandomImageForBestSellerBook(data, allColorsData)?.image
               }
          ))
        if(newData){
          addRandomImagesToBookData.push({...book,  books: newData})
        }
      })

      dispatch(
        featuredCollectionAction.updateFeaturedCollection(
          addRandomImagesToBookData
        )
      );
    }
    return response;
  });
};
