import React, { useState} from "react";

import classes from './RBExclusive.module.css'

import Card from "./Card/Card"

import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";

import EnvelopeCard from "./Envelope/EnvelopeCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";

const image1 = "/Assets/Images/Rbbook1.svg"
const image2 = "/Assets/Images/RBbook2.svg";
const book1 = '/Assets/Images/book1.svg'
const book2 = '/Assets/Images/book2.svg'
const book3 = '/Assets/Images/book3.svg'
const book4 = '/Assets/Images/book4.svg'
const book5 = '/Assets/Images/book5.svg'

const cardData = [

  {
    text:"One of kind design",
    image:image1
  },
  {
    text:"Handcrafted excellence",
    image:image1
  },
  {
    text:"Access only via invite",
    image:image2
  },

]
function RBExclusive(){
    const history = useHistory();
      
      const [isOpen, setIsOpen] = useState(false);
      const [cardOpened,setCardOpened] = useState(false)

      const handleMouseLeave = () => {
        setCardOpened(false)
        setIsOpen(false)
      }

      const handleCardClick = () => {
        setTimeout(() => {
          setIsOpen(true)
        },1000)
        setTimeout(() => {
          setCardOpened(true)
        },2000)
      }
      
    return(
// Dummy data for books
    <div className={classes.container}>
            <div className={classes.topBottomMargin}></div>
      <div className={classes.bottomMargin}></div>
      <div className={classes.leftRightMargin}></div>
      <div className={classes.rightMargin}></div>
    <div className={classes.secondaryContainer}>

      <h1 className={classes.heading}>RB exclusive</h1>
      <p className={classes.description}>
      Experience unparalleled designs and premium leathers in our members-only collection.
      </p>
      <div className={classes.icons}>
        {cardData.map((card,idx) => (
          <Card key={idx} image={card.image} data={card.text}/>
        ))}
      </div>
      <div className={classes.books}>
          {cardOpened ? (
            <div className={classes.openLetter} onMouseLeave={handleMouseLeave}>
            <h2>Our Collection</h2>
            <div className={classes.bookContainer}>
              <div className={classes.lastbooks} >
                <img src={book1} alt="" />
              </div>
              <div className={classes.secondLastbooks} >
                <img src={book2} alt="" />
              </div>
              <div className={classes.middleBook} >
                <img src={book3} alt=""/>
              </div>
              <div className={classes.secondLastbooks} >
                <img src={book4} alt=""/>
              </div>
              <div className={classes.lastbooks} >
                <img src={book5} alt=""/>
              </div>
            </div>

            <SecondaryButton className={classes.letterButton} onClick={() => history.push(urls.RB_EXCLUSIVE) }>
              Explore RB Exclusive
            </SecondaryButton>
          </div>
          ) : (
            <div className={classes.envelopeContainer}>
              <h2 className={classes.envelopeHEader}>Click on the Envelope</h2>
              <FontAwesomeIcon icon={faAngleDoubleDown} className={classes.envelopeIcon} />
              <EnvelopeCard isOpen={isOpen} onClick = {handleCardClick}/>
            </div>
          )}
      </div>
      </div>
    </div>
  );
};

export default RBExclusive;
