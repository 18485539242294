import { createSlice } from "@reduxjs/toolkit";

const bookReviewSlice = createSlice({
    name: "bookReviews",
    initialState: {
        reviews: [],
    },
    reducers: {
        addReviews: (state, action) => {
            state.reviews.push(action.payload[0]);
        },
        getReviews: (state, action) => {
            state.reviews = action.payload;
        },
    }
});

export const bookReviewActions = bookReviewSlice.actions;
export default bookReviewSlice.reducer;
