import classes from "./AboutForPublishers.module.css"

const image1 = "/Assets/Images/customization/LeatherMatrerialIcon.png"
const image2 = "/Assets/Images/customization/handcraftedExcellence.png"
const image3 = "/Assets/Images/customization/customization.png"

const cardData = [
    {
        title: "High-profit margins",
        desc: " Time to boost your revenue! By collaborating, you can provide your authors with books of the finest and premium quality, drawing in more customers and boosting revenue. Authors can now receive higher royalties thanks to this additional feature, which is advantageous for your bookstore your authors, and us.",
        image: image1
    },
    {
        title: "Exclusive Selection of Books",
        desc: "We provide you with a unique selection of luxurious books with leather bindings for your customers. For book lovers and collectors, our collection offers a wide range of unique editions, genres, and styles.",
        image: image2
    },
    {
        title: "Superior Quality Hand-crafted Books:",
        desc: "Premium materials are used in the meticulous attention to detail that goes into creating each of our books by talented craftsmen. Our books are made to last and leave an eternal mark, starting with the finest leathers and hand-stitched bindings.",
        image: image3
    },
    {
        title: "Customizable Offerings",
        desc: "We provide personalization options so that customers may choose the type and color of leather, along with aesthetic marble papers and even customized embossing of logos and borders on their books. All of this while getting a 3D preview of their designed book. This difference makes you stand apart from other book dealers.",
        image: image3
    },
]

const AboutForPublishers = () => {
    return (
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                    Why Partner with Us?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card, index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutForPublishers