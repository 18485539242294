import classes from "./FeatureCard.module.css"

const FeatureCard = ({image,title,desc}) => {
    return(
        <div className={classes.container}>
            <div className={classes.image}>
                <img src={image} alt="" />
            </div>
            <div className={classes.details}>
                <h2>{title}</h2>
                <p>{desc}</p>
            </div>
        </div>
    )
}

export default FeatureCard