import classes from "./AboutForPublishers.module.css"

const image1 = "/Assets/Images/FeatureIcons/ForPublishersF1.png"
const image2 = "/Assets/Images/FeatureIcons/ForPublishersF2.png"
const image3 = "/Assets/Images/FeatureIcons/ForPublishersF3.png"
const image4 = "/Assets/Images/FeatureIcons/ForPublishersF4.png"
const image5 = "/Assets/Images/FeatureIcons/ForPublishersF5.png"

const cardData = [
    {
        title:"Higher-monetary benefits",
        desc:"Partnering with us will allow you to improve your services and boost revenue in several ways",
        image:image1
    },
    {
        title:"Exclusive Access to Premium Leather Bound Books",
        desc:"Provide your writers with premium options, such as rich-in-quality marble papers, leather-bound volumes, golden-gilded endpapers, and many more. Our books are made to be as unique and remarkable as the tales they hold.",
        image:image2
    },
    {
        title:"Handcrafted craftsmanship and customizable options",
        desc:"Every book that is made benefits from the years of experience and commitment of our talented artisans. To guarantee that every book is a work of art, we only utilize the best supplies and traditional methods that are purely hand-stitched with exquisite leather.Along with that, we give your authors the option to design their personalized books and get a real-time 3D preview of their design.",
        image:image3
    },
    {
        title:"Dedicated Assistance",
        desc:"As part of our collaboration, your team will have access to a dedicated account manager who will work directly with you to answer any problems, speed up the process, and guarantee that everything runs smoothly from beginning to end.",
        image:image4
    },
    {
        title:"Variable Volume Selections",
        desc:"We provide various buying choices to fit your demands without putting you under pressure from big minimum orders, whether you need a single special edition book or a batch of volumes bound to order.",
        image:image5
    }
]

const AboutForPublishers = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                Why Partner with Us?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutForPublishers