import BannerHeader from "../../Components/BannerHeader/BannerHeader"
import { urls } from "../../Routes/Urls"
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav"
import classes from "./OurCompany.module.css"

import CompanyValues from "./CompanyValues/CompanyValues"
import Footer from "../Footer/Footer"
import useScrollToTop from "../../Hooks/useScrollToTop"

const bannerImg = "/Assets/Images/Banners/AboutUsBanner.png"
const OurCompany = () => {

    const crumbs = [
        { title: "Home", path: ("/") },
        { title: "About Us", path: urls.OURCOMPANY }
    ]

    const scrollRef = useScrollToTop();

    return (
        <div ref={scrollRef}>
            <PrimaryNav />
            <BannerHeader title={"About Us"} crumbs={crumbs} bgImage={bannerImg} />

            <div className={classes.mainContainer}>
                <div className={classes.intro}>
                    <h2>Welcome to Rare Biblio, the premier destination for bibliophiles and book lovers who appreciate the art of bookmaking. As a rare books store, we specialize in handcrafted leather-bound books, personalization & customization services, and restoration of valuable old books, antiquarian books, and manuscripts.</h2>
                    <p>At Rare Biblio, we understand the importance of books as both works of art and cultural artifacts. We believe that books are more than just objects; they are vessels of knowledge, beauty, and history. That's why we offer a wide range of personalization services, including leather binding, gold embossing, and gilding to transform your favorite books into one-of-a-kind treasures. Our expert bookbinders use traditional techniques and high-quality leather to handcraft these leather-bound books that are both durable and beautiful. We take pride in offering personalized books with unique designs, fonts, and styles, making them truly one-of-a-kind. If you're looking for leather-bound books, Rare Biblio is your go-to destination.

                        In addition to customization, we also provide restoration services for old and cherished books that have seen better days. Our restoration service offers a new lease of life for antique books and manuscripts. Our team of experts will work to bring your cherished book back to its former glory, fulfilling your expectations. Our restoration services include cleaning, mending, and restoration of bindings, among others.

                        If you're an author looking to publish your work, Rare Biblio offers a range of services to help you realize your vision. From typesetting and design to printing and distribution, our team of professionals will work with you to create a finished product that meets your specific needs.

                        Finally, our print-on-demand service allows you to print any quantity of books, from a single copy to bulk orders. With options for decorative binding, different sizes, and designs, we cater to all your printing needs. We offer printing services for rare and antique books that have limited copies in the world, making them available to a larger audience.
                    </p>
                </div>
            </div>

            <CompanyValues />

            <Footer/>
        </div>
    )
}

export default OurCompany