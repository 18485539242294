import classes from "./OptionCapsules.module.css"

const OptionCapsules = ({option,active,onClick}) => {
    return(
        <div className={`${classes.mainContainer} ${active ? classes.active : ""}`} onClick={onClick}>
            {option}
        </div>
    )
}

export default OptionCapsules;