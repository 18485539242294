import axios from "axios";
import { CustomizationPricingAction } from "../Slices/CustomizationPricingSlice";

export function fetchAllCustomizationPrices(dispatch) {
    return axios({
        url:"/api/customization-pricing",
        method:"get"
    }).then((res) => {
        if(res.data.success) {
            dispatch(CustomizationPricingAction.setAllPrices(res.data.data))
        }
    })
}