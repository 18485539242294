import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { urls } from "../../Routes/Urls";
import Footer from "../Footer/Footer";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import styles from "./ReturnRefunds.module.css";

const bannerImg = "/Assets/Images/ourCompnay.jpg";
const ReturnRefunds = () => {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Return/Refunds", path: urls.RETURNREFUNDS },
  ];

  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader title={"Return/Refunds"} crumbs={crumbs} bgImage={bannerImg} />

      <div className={styles.policyContent}>
        <h4 className={styles.subHeading}>Return and Refund Policy for Rare Biblio</h4>
        <p className={styles.paragraph}>
            At Rare Biblio, we are dedicated to providing high-quality, customized products to our customers. If you are not fully satisfied with your purchase, please review our return and refund policy outlined below.
        </p>

        <h5 className={styles.subHeading}>Eligibility for Returns</h5>
        <p className={styles.paragraph}>
            Since many of our products are custom-made and personalized (such as customized leather-bound books), returns are only accepted under the following conditions:
          <ul>
            <li>The product arrives damaged or defective.</li>
            <li>The product you receive is significantly different from what was ordered (e.g., wrong book or incorrect customizations).</li>
          </ul>
          To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in its original packaging.
        </p>

        <h5 className={styles.subHeading}>Non-Returnable Items</h5>
        <p className={styles.paragraph}>
        Please note that the following items are not eligible for return:
          <ul>
            <li>Custom-made products that meet the specifications and personalization details provided by you.</li>
            <li>Gift cards and vouchers.</li>
          </ul>
        </p>

        <h5 className={styles.subHeading}>Return Process</h5>
        <p className={styles.paragraph}>
        If you believe your order qualifies for a return, please follow these steps:
          <ol>
            <li>Contact us at info@rarebiblio.com within 7 days of receiving your order. Include your order number, a description of the issue, and photos of any defects or damage.</li>
            <li>Our customer support team will review your request and provide you with instructions for returning the item if it is eligible for a return.</li>
            <li>Once the returned item is received and inspected, we will notify you of the approval or rejection of your refund.</li>
          </ol>
        </p>

        <h5 className={styles.subHeading}>Return Shipping</h5>
        <p className={styles.paragraph}>
          <ul>
            <li>You are responsible for covering the shipping costs for returning the item unless the return is due to an error on our part (e.g., incorrect or defective product).</li>
            <li>We recommend using a trackable shipping service and purchasing shipping insurance, as we cannot guarantee that we will receive your returned item.</li>
          </ul>
        </p>

        <h5 className={styles.subHeading}>Refunds</h5>
        <p className={styles.paragraph}>
            Once your return is received and inspected, we will process your refund. If approved, a credit will automatically be applied to your original method of payment within a certain number of days, depending on your payment provider’s processing time.
        </p>

        <h5 className={styles.subHeading}>Late or Missing Refunds</h5>
        <p className={styles.paragraph}>
            If you haven’t received your refund yet:
            <ol>
                <li>First, check your bank account or credit card statement again.</li>
                <li>Then, contact your credit card company or bank, as it may take some time before your refund is officially posted.</li>
                <li>If you’ve done all of this and you still have not received your refund, please contact us at info@rarebiblio.com.</li>
            </ol>
        </p>

        <h5 className={styles.subHeading}>Exchanges</h5>
        <p className={styles.paragraph}>
            We only replace items if they are defective or damaged. If you need to exchange it for the same item, please contact us at info@rarebiblio.com for further instructions.
        </p>

        <h5 className={styles.subHeading}>Cancellations</h5>
        <p className={styles.paragraph}>
            Cancellations are allowed within 24 hours of placing the order. After this period, since many of our products are custom-made, cancellations may not be possible if the customization process has already started. Please contact us as soon as possible at info@rarebiblio.com to inquire about your specific order.
        </p>

        <h5 className={styles.subHeading}>Contact Us</h5>
        <p className={styles.paragraph}>
            If you have any questions about our return and refund policy, please reach out to us at info@rarebiblio.com. We are happy to assist you.
        </p>

       
      </div>
      <Footer />
    </div>
  );
};

export default ReturnRefunds;
