import axios from "axios";
import { ServiceVideoAction } from "../Slices/ServiceVideoSlice";

function getVideo(filter) {
    return axios({
        url: `/api/service-videos?filter=${encodeURIComponent(JSON.stringify(filter))}`,
        method: 'get',
        responseType: 'json'
    });
}

export function fetchAllVideos(dispatch) {
    let filter = {sortBy: "id DESC"}
    return getVideo(filter).then(function (response) {
        let data = response.data;
        if (data.success) {
            dispatch(ServiceVideoAction.fetchAllVideos(data.data[0]))
        }
    }).catch(function (error) {
        console.log(error)
    })
}

function uploadVideosRequest(formData) {
    return axios({
        url: '/api/service-videos/upload',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function uploadVideos(formData, dispatch) {
    return uploadVideosRequest(formData)
        .then(function (response) {
            let data = response.data;
            if (data.success) {
                // Dispatch the action to update the state with the uploaded videos
                dispatch(ServiceVideoAction.fetchAllVideos(data.data));
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

