import classes from "./Faq.module.css"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../Header/Header";
import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import FAQCard from "./FAQCard/FAQCard";

import { useDispatch } from "react-redux";
import { getAllCategories, getAllFaq } from "../../Store/actions/FaqAction";
import { urls } from "../../Routes/Urls";
import useScrollToTop from "../../Hooks/useScrollToTop";
import Footer from "../Footer/Footer";

const FAQ = () => {

    const dispatch = useDispatch()
    const faqs = useSelector((state) => state.Faq.questions);
    const categories = useSelector((state) => state.Faq.categories);
    const [activeTab,setActiveTab] = useState(categories[0] || "");
    const [selectedCategory,setSelectedCategory] = useState("")
    const onTabClick = (tab) => {
        setActiveTab(tab)
        setSelectedCategory(tab)
    }

    const crumbs  = [
        {title:"Home",path:"/"},
        {title:"FAQ",path:urls.FAQ},
    ];

    useEffect(() => {
        getAllFaq("category", selectedCategory, "", dispatch);
        getAllCategories(dispatch);
    },[activeTab])

    const scrollRef = useScrollToTop();
    return(
        <div className={classes.mainContainer} ref={scrollRef}>
            <Header/>
            <BannerHeader title={"Frequently Asked Questions"} crumbs={crumbs} />
            <div className={classes.tabs}>
                {categories.map((tab, index) => (
                    <div
                        key={tab}
                        className={`${classes.tab} ${activeTab === tab ? classes.active : ''}`}
                        onClick={() => onTabClick(tab)}
                    >
                    {tab}
                    </div>
                ))}
             </div>
            <div className={classes.faqContainer}>

                <div className={classes.cardContainer}>
                    {faqs?.map((faq,index) => (
                        <FAQCard key={index} ques={faq.question} answer={faq.answer}/>
                    ))}
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default FAQ