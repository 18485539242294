import React,{useContext, useState} from 'react';
import classes from './LogoModal.module.css';
import { useFabricCanvas } from 'react-fabric-page';
import { addLogoToSection } from '../../../../../spineUtils';
import { createPortal } from 'react-dom';
import SecondaryButton from '../../../../../Components/Buttons/SecondaryButton/SecondaryButton';
import { CustomizationContext } from '../../../Customization';
const hexagon = "/Assets/Icons/hexagon.svg"

const Modal = ({ isOpen, onClose,activeSectionNumber}) => {
  const canvas = useFabricCanvas();
  const [imageURL,setImageURL] = useState("");
  const {spineCustomLogo,setSpineCustomLogo} = useContext(CustomizationContext)


  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        const base64Data = event.target.result; 
        if(base64Data) {
          setImageURL(base64Data);
          setSpineCustomLogo((prev) => ({
            ...prev,
            [activeSectionNumber]: base64Data,
          }));
          onClose()
        }
      };

      reader.readAsDataURL(file);
    }
  };
  
  const updateImageURL = () => {
    const layer = canvas
    ?.getObjects()
    .find((o) => o.id === `rib-section-${activeSectionNumber}`);
    if (layer) {
            addLogoToSection(canvas,layer,imageURL)
            layer.dirty = true;
            canvas.renderAll()
            onClose()
        }
  }

  const container = document.querySelector("#root");
  if (!isOpen) return null;

  return createPortal (
    <>
    <div className={classes.modalOverlay} onClick={onClose}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <h2>Upload your own design</h2>
        <div className={classes.uploadArea}>
          <div className={classes.uploadBox}>
            <input type="file" id="fileUpload" accept="image/*" onChange={(e) => handlePhotoUpload(e)} className={classes.fileInput} />
            <label htmlFor="fileUpload" className={classes.uploadLabel}>
              <div className={classes.uploadIcon}>&#128194;</div>
              <div className={classes.uploadText}>Click or drag file to this area to upload</div>
            </label>
          </div>
          <p>Formats accepted are <span>jpg</span> and <span>jpeg</span></p>
        </div>
        <div className={classes.sampleImageSection}>
          <div className={classes.sampleImageHeader}>
            <span className={classes.sampleImage}>Sample Image</span>
            <button className={classes.recommendedButton}>Recommended</button>
          </div>
          <div className={classes.sampleDetails}>
            <div className={classes.lineArt}>
              <div className={classes.circle}>
                <img src={hexagon} alt="" />
              </div>
              <div>
                <h3>Line Art</h3>
                <p>Please try to submit your design in line art format to ensure seamless embossing/foiling.</p>
                <p>The image above illustrates what we mean by line art/line drawing.</p>
              </div>
            </div>
            <div className={classes.fileFormat}>
              <div className={classes.circle}>
              <img src={hexagon} alt="" />
              </div>
              <div>
                <h3>File Format</h3>
                <p>Please note that we only accept files in PDF, Word, JPEG, and PNG formats.</p>
              </div>
            </div>
            <div className={classes.fileSize}>
              <div className={classes.circle}>
              <img src={hexagon} alt="" />
              </div>
              <div>
                <h3>File Size</h3>
                <p>Please ensure your files do not exceed the maximum size limit. We accept files up to {10} MB to ensure efficient processing.</p>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.disclaimer}>
            <p>Disclaimer : uploaded design won't be available for live preview</p>
          </div>
          <div className={classes.buttons}>
            <SecondaryButton onClick={(e) => updateImageURL(canvas,imageURL)} className={classes.uploadButton}>Upload File</SecondaryButton>
            <button className={classes.cancelButton} onClick={() => {onClose();setSpineCustomLogo(null)}}>Cancel</button>
          </div>
        </div>
      </div>
    </div>
    </>,
      container
  );
};

export default Modal;
