const BASE_URL = "/api"

export const SERVICE_URL = {
    LOGIN: `${BASE_URL}/login`,
    LOGOUT: `${BASE_URL}/logout`,
    SIGNUP: `${BASE_URL}/users/create`,
    BOOK:`${BASE_URL}/books`,
    BLOG:`${BASE_URL}/blogs`,
    CART:`${BASE_URL}/carts`,
    WISHLIST:`${BASE_URL}/wishlists`,
    COUPONS:`${BASE_URL}/coupons`,
    BESTSELLERBOOK:`${BASE_URL}/best-selling-books`,
    BOOKREVIEWS:`${BASE_URL}/book-reviews`,
    ORDERS:`${BASE_URL}/orders`,
}