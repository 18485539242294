import classes from "./Spine.module.css"
import { useState, useEffect, useContext } from "react";

import RibDesign from "./RibDesign/RibDesign";
import SectionController from "../Components/SectionController/SectionController";
import ColorSelection from "./ColorSelection/ColorSelection";
import AddTextLogo from "./AddTextLogo/AddTextLogo";
import useScreenSize from "../../../Hooks/UseScreenSize";
import MessageDialog from "../../../Components/MessageDialogue/MessageDialogue";
import Consts from "../../../Consts";
import { useFabricCanvas, useFabricCanvasHandler, useFabricEditor } from "react-fabric-page";
import { CustomizationContext } from "../Customization";
import { addTextureToBackground, changeAllSectionColors, createSpineSections } from "../../../spineUtils";
import { useSelector } from "react-redux";
// const gildingIcon = "/Assets/Icons/customization/gildingIcon.svg";
// const endpaperIcon = "/Assets/Icons/customization/endpaperIcon.svg";
const failIcon = "/Assets/Icons/icon-error.svg"

const options = ["RibDesign", "Choose Spine color", "Add Text/Logo"];

const Spine = ({ onNext, onPrev }) => {

    const {bookLeather,bookVariant,spineFontColor,
        spineFontFamily,
        spineFontStyle,selectedOption,setSpineText,selectedDesigns,
        spineFontSize} = useContext(CustomizationContext)

    const allColorsData = useSelector((state) => state.bookColor.colors);
    const allowedColors = allColorsData?.filter(
      (color) =>
        color.disabled === false &&
        color.isBestSeller === false &&
        color.variantType === bookVariant &&
        color?.leatherType === bookLeather
    );

    const [isCanvasReady,setIsCanvasReady] = useState(false)

    const [selectedColor, setSelectedColor] = useState(allowedColors?.[0]);
    const {setNumberOfSections,activeSectionNumber,spineCoverTextAlign,isSpineLoaded,setIsSpineLoaded,setDesignJSON,designSaveInitiated,steps} = useContext(CustomizationContext)

    const [currentDropdownId, setCurrentDropdownId] = useState(0);
    const [currentDropdown, setCurrentDropdown] = useState(options[currentDropdownId]);
    const [dropdownOpen, setDropDownOpen] = useState(true);
    const [showMessageDialog, setShowMessageDialog] = useState(false)

    let canvas = useFabricCanvas();
    let editor = useFabricEditor();

    useEffect(() => {
        window.spineCanvas = canvas
        window.spineEditor = editor
    },[canvas])

    useEffect(() => {
          const timeout = setTimeout(() => {
          if (canvas) {
              canvas.renderAll();
          }
      }, 300); // Adjust delay as needed
      return () => clearTimeout(timeout);
  },[steps,canvas])

    const { isMediumScreen } = useScreenSize()
    const [currentOption, setCurrentOption] = useState("")

    useEffect(() => {
        if (!canvas) return;
      
        const applyTextProperties = () => {
          // Get all layer objects that start with "rib-section"
          const allLayerObjects = canvas?.getObjects().filter(obj => obj.id && obj.id.startsWith("rib-section"));
      
          if (allLayerObjects.length > 0) {
            allLayerObjects.forEach(layer => {
              // Get text area objects within each layer
              const textAreaObjects = layer.getObjects().filter(obj => obj.type === "textArea");
      
              textAreaObjects.forEach((obj) => {
                // Set properties for each text area object
                obj.set("fill", spineFontColor?.colorValue);
                obj.set("fontFamily", spineFontFamily?.fontFamily);
                if (spineFontStyle?.fontStyle) {
                  obj.set("fontStyle", spineFontStyle?.fontStyle);
                }
                if (spineFontStyle?.underline !== null) {
                  obj.set("underline", spineFontStyle?.underline);
                }
                if(spineCoverTextAlign) {
                  obj.set('textAlign', spineCoverTextAlign);
                }
                if(spineFontSize >=6) {
                  obj.set("fontSize",spineFontSize)
                }
                obj.dirty = true; // Mark the object as dirty
              });
      
              layer.dirty = true; // Mark the layer as dirty
            });
      
            canvas.renderAll(); // Render the changes on the canvas
          }
        };
      
        // Apply text properties on initial load and when dependencies change
        applyTextProperties();
      
        // Listen for the addition of new objects (e.g., text boxes)
        canvas.on('object:added', applyTextProperties);
      
        // Clean up the event listener when the component unmounts
        return () => {
          canvas.off('object:added', applyTextProperties);
        };
      }, [canvas, spineFontColor, spineFontFamily, spineFontStyle,spineCoverTextAlign ,isSpineLoaded,spineFontSize, activeSectionNumber]);
      

    useEffect(() => {
        setIsCanvasReady(false)
    },[bookLeather,bookVariant])

    useEffect(() => {
        if (!canvas) return;
      
        const handleTextChange = () => {
          const ribSections = canvas.getObjects().filter((o) => o.id && o.id.startsWith('rib-section'));
      
          const updatedTextArray = ribSections.map((section, index) => {
            const textAreas = section.getObjects().filter((obj) => obj.type === 'textArea');
      
            return textAreas.map((textArea) => ({
              id: `rib-section-${index}`,
              text: textArea.text,
            }));
          }).flat();
      
          setSpineText(updatedTextArray);
          canvas.renderAll()
        };
      
        // Listen for text changes on the canvas
        canvas.on('text:changed', handleTextChange);
      
        // Clean up the event listener on unmount
        return () => {
          canvas.off('text:changed', handleTextChange);
        };
      }, [canvas, setSpineText]);
      
    
    function onReady() {
        if(!selectedDesigns?.id) {
          if(!isSpineLoaded) {
            createSpineSections(canvas).then(() => setIsSpineLoaded(true))
            const sectionObjects = canvas?.getObjects()?.filter(obj => obj.id && obj.id.startsWith("rib-section"))
            if(sectionObjects && sectionObjects?.length > 0){
                setNumberOfSections(sectionObjects?.length > 3 ? sectionObjects?.length -3 : 1);
            }
            addTextureToBackground(canvas, '/images/leatherTexture.png');
            changeAllSectionColors(canvas,allowedColors?.[0]?.colorValue);

            setIsCanvasReady(true)
          }
          }
    }

    useFabricCanvasHandler(canvas => ({
        "canvas:x-ready": onReady
    }),[allowedColors,bookLeather,bookVariant,canvas]);

    useEffect(() => {
        if (canvas?.isReady) {
            onReady()
        }
    }, [canvas])

    const toggleDropdown = () => {
        setDropDownOpen(!dropdownOpen);
    };

    const handleOptionClick = (id) => {
        if (id === currentDropdownId) {
            toggleDropdown()
        }
        else {
            setCurrentDropdownId(id);
            setCurrentDropdown(options[id]);
            setDropDownOpen(true);
        }
    };

    const nextSection = (id) => {
        if (currentDropdownId < 2) {
            setCurrentDropdownId(currentDropdownId + 1);
            setCurrentDropdown(options[id + 1]);
            setCurrentOption(options[id + 1])
            setDropDownOpen(true)
        }
        else {
            onNext()
        }
    }

    const prevSection = (id) => {
        if (currentDropdownId >= 1) {
            setCurrentDropdownId(currentDropdownId - 1);
            setCurrentDropdown(options[id - 1]);
            setCurrentOption(options[id - 1])
            setDropDownOpen(true)
        }
        else {
            onPrev()
        }
    }

    useEffect(() => {
      if(designSaveInitiated && editor) {
        const spineJSONString = editor?.getJSON();
        const spineJSONBlob = new Blob([spineJSONString], { type: "application/json" });
        setDesignJSON(prevArray => {
          const updatedArray = [...prevArray];
          updatedArray[1] = spineJSONBlob;
          return updatedArray;
        });
      }
    },[designSaveInitiated])

    return (
      <div className={classes.mainContainer}>
        {showMessageDialog && (
          <div>
            <MessageDialog            
              onClose={setShowMessageDialog(false)}
              closeText="Close"
              width={Consts.MESSAAGE_DIALOG_WIDTH}
              dialogueIcon={failIcon}
            />
          </div>
        )}
        <div
          className={
            isMediumScreen && currentOption !== ""
              ? classes.detailsSmall
              : classes.details
          }
        >
          {isMediumScreen ? (
            <>
            </>
          ) : (
            <div className={classes.headerSection}>
              <h2>Let's customize Spine of the book (2/5) </h2>
              <p>
                Spine: The spine is the edge of the book where the pages are
                bound together. It often displays the book’s title and author.
              </p>
            </div>
          )}

          {!isMediumScreen && (
            <div className={classes.customizationBody}>
              <div onClick={() => handleOptionClick(0)}>
                <RibDesign
                  currentDropdown={currentDropdown}
                  dropdownOpen={dropdownOpen}
                  toggleDropdown={toggleDropdown}
                  onClick={() => handleOptionClick(0)}
                />
              </div>
              <div onClick={() => handleOptionClick(1)}>
                <ColorSelection
                  currentDropdown={currentDropdown}
                  dropdownOpen={dropdownOpen}
                  toggleDropdown={toggleDropdown}
                  onClick={() => handleOptionClick(1)}
                  allowedColors={allowedColors}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  isCanvasReady = {isCanvasReady}
                />
              </div>
              <div onClick={() => handleOptionClick(2)}>
                <AddTextLogo
                  activeSectionNumber={activeSectionNumber}
                  currentDropdown={currentDropdown}
                  dropdownOpen={dropdownOpen}
                  toggleDropdown={toggleDropdown}
                  onClick={() => handleOptionClick(2)}
                  isCanvasReady={isCanvasReady}
                />
              </div>
              <div className={classes.sectionController}>
                <SectionController
                  prev={
                    currentDropdownId === 0
                      ? "Front Cover"
                      : options[currentDropdownId - 1]
                  }
                  next={
                    currentDropdownId === 2
                      ? "Paper"
                      : options[currentDropdownId + 1]
                  }
                  onNext={() => nextSection(currentDropdownId)}
                  onPrev={() => prevSection(currentDropdownId)}
                />
              </div>
            </div>
          )}

          {isMediumScreen && (
          <div className={classes.customizationBody}>
          <div
            className={
              selectedOption === options[0] ? "" : classes.inVisible
            }
            onClick={() => handleOptionClick(0)}
          >
            <RibDesign
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                activeSectionNumber={activeSectionNumber}
                onClick={() => handleOptionClick(0)}
            />
          </div>
          <div
            className={
              selectedOption === options[1] ? "" : classes.inVisible
            }
            onClick={() => handleOptionClick(1)}
          >
            <ColorSelection
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                activeSectionNumber={activeSectionNumber}
                allowedColors = {allowedColors}
                selectedColor = {selectedColor}
                setSelectedColor = {setSelectedColor}
                onClick={() => handleOptionClick(1)}
                isCanvasReady = {isCanvasReady}
            />
          </div>
          <div
            className={
              selectedOption === options[2] ? "" : classes.inVisible
            }
            onClick={() => handleOptionClick(2)}
          >
           <AddTextLogo
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(3)}
                activeSectionNumber={activeSectionNumber}
                isCanvasReady = {isCanvasReady}
            />
          </div>
        </div>
          )}
        </div>
      </div>
    );
}

export default Spine