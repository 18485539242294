import axios from "../../Axios";
import Consts from "../../Consts";
import { testimonialActions } from "../Slices/TestimonialSlice";

export const fetchTestimonials = (dispatch) => {
    return axios({
        url:`${Consts.SERVER_URL}/testimonials`,
        params :
        {
            filter: "{}"
        },
        method:"get",
        responseType:"json"
    })
    .then((response) => {
        if(response.data.success){
            dispatch(testimonialActions.updateTestimonial(response.data.data))
        }
    })
}