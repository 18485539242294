import classes from "./Card.module.css"

const Card = ({image,data}) => {
    return(
        <div className={classes.cardContainer}>
            <div className={classes.image}>
                <img src={image} alt="" />
            </div>
            <div className={classes.data}>
                <p>{data}</p>
            </div>
        </div>
    )
}

export default Card