import { createSlice } from "@reduxjs/toolkit";

const ProcessingCostSlice = createSlice({
    name: "processingCost",
    initialState: {
        costs: [],
    },
    reducers: {
        addCost: (state, action) => {
            state.costs.push(action.payload);
        },
        setCost: (state, action) => {
            state.costs = action.payload;
        },
        deleteCost: (state, action) => {
            state.costs = state.costs.filter(cost => cost.id !== action.payload);
        },
        updateCost: (state, action) => {
            const index = state.costs.findIndex(cost => cost.id === action.payload.id);
            if (index !== -1) {
                state.costs[index] = action.payload;
            }
        },
    }
});

export const ProcessingCostActions = ProcessingCostSlice.actions;
export default ProcessingCostSlice.reducer;
