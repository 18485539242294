import React from "react";
import Modal, { ModalBody, ModalFooter } from "../Modal/Modal";
import classes from "./MessageDialog.module.css";
import PrimaryButton from "../Buttons/PrimaryButton/PrimaryButton";
import ButtonLight from "../Buttons/ButtonLight/ButtonLight";

function MessageDialog({
  title,
  message,
  isCancel,
  onCancel,
  onAccept,
  closeText = "Cancel",
  acceptBtnText = "OK"
}) {
  return (
    <Modal title={title}>
      <ModalBody>
        <div className={classes.message}>
          <p>{message}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className={classes.actions}>
          {isCancel && (
            <ButtonLight className={classes.btn} onClick={onCancel} negative>
              {closeText}
            </ButtonLight>
          )}
          {onAccept && (
            <PrimaryButton className={classes.btn} onClick={onAccept} positive>
               {acceptBtnText}
            </PrimaryButton>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default MessageDialog;
