import classes from "./FAQ.module.css"

import FAQCard from "../../FAQ/FAQCard/FAQCard"

const FAQ = ({data,category}) => {
    
    return(
        <div className={classes.mainContainer}>
            <div className={classes.header}>
                <h2>FAQs</h2>
                <p>{category}</p>
            </div>

            <div className={classes.faqSection}>
                {data?.map((faq, index) => (
                    <FAQCard key={index} ques={faq.question} answer={faq.answer} />
                ))}
            </div>
        </div>
    )
}

export default FAQ