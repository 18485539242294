import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import useScrollToTop from "../../Hooks/useScrollToTop";
import { urls } from "../../Routes/Urls";
import Footer from "../Footer/Footer";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import styles from "./LimitedEdition.module.css";

const bannerImg = "/Assets/Images/ourCompnay.jpg";
const LimitedEdition = () => {
  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Limited Edition", path: urls.LIMITEDEDITION },
  ];

  const scrollRef = useScrollToTop();

  return (
    <div ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader
        title={"Limited Edition"}
        crumbs={crumbs}
        bgImage={bannerImg}
      />
      <div className={styles.mainContainer}>
        <div className={styles.textContainer}>
          <h3>Limited Quantity Books</h3>
          <p>
            Limited quantity books are a rare and precious commodity in the
            literary world. These books are produced in small print runs,
            typically limited to a few hundred or thousand copies, and are often
            highly sought after by collectors and book enthusiasts. They may be
            rare first editions, special editions, or limited signed copies, and
            can come in a range of genres from classic literature to modern
            non-fiction. Owning a limited quantity book can be a source of pride
            and satisfaction for a true book lover, as it represents not only a
            valuable possession but also a connection to the history and culture
            of the book. It is a tangible piece of art that has been crafted
            with care and precision, often with exquisite design and materials,
            and is an item that can be passed down through generations. While
            limited quantity books can be difficult to acquire due to their
            scarcity, they can offer a unique and enriching reading experience.
            They can also provide an opportunity for readers to connect with the
            author and the publishing process, as they often come with
            additional content such as author notes, illustrations, or
            behind-the-scenes information. In conclusion, limited quantity books
            are a treasured and rare gem in the world of literature, and owning
            one can be a truly rewarding experience for any book enthusiast.
            What makes us unique is the fact that we offer many budget-friendly
            binding and designing options to cater to people with all kinds of
            budget. All of this comes with the promise of quality and assurance
            of material used in binding. So, choosing an affordable leather
            binding doesn’t translate to poor quality.
          </p>
        </div>
        <div className={styles.imageContainer}>
          <img src="/Assets/Images/limited-edition.png" alt="Limited Edition" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LimitedEdition;
