import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from "./Cart.module.css"
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { updateCartItem, deleteCartItem } from "../../../Store/actions/CartAction"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { urls } from "../../../Routes/Urls"
import { getEnum, getRandomImageForBestSellerBook, getRandomImageForNormalBook } from "../../../Util"
import { useState } from "react"
import CustomizationDetails from "../CustomizationDetails/CustomizationDetails"
import { CartActions } from "../../../Store/Slices/CartSlice"

const Cart = ({cartItems}) => {
    const allColorsData = useSelector(state => state.bookColor.colors);
    const currentUser = useSelector((state) => state.user.currentUser)
    const dispatch = useDispatch()
    const history = useHistory()
    // const cartItems = useSelector((state) => state.cart.cartItems)

    const [isOpen,setIsOpen] = useState(false);
    const [selectedItem,setSelctedItem] = useState(null);

    const handleQtyIncrement = (cartItemId, qty) => {
      if (currentUser?.id) {
        updateCartItem(cartItemId, qty + 1, null, dispatch);
      } else {
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        const updatedCart = guestCart.map((item) =>
          item.id === cartItemId
            ? { ...item, quantity: item.quantity + 1 } // Increase quantity for the matching item
            : item
        );
        localStorage.setItem("guestCart", JSON.stringify(updatedCart));
        dispatch(CartActions.addCartItemSuccess(JSON.parse(localStorage.getItem("guestCart"))))
      }
    };
    
    const handleQtyDecrement = (cartItemId, qty) => {
      if (currentUser?.id) {
        if (qty > 1) {
          updateCartItem(cartItemId, qty - 1, null, dispatch);
        }
      } else {
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        const updatedCart = guestCart.map((item) =>
          item.id === cartItemId && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
        localStorage.setItem("guestCart", JSON.stringify(updatedCart));
        dispatch(CartActions.addCartItemSuccess(JSON.parse(localStorage.getItem("guestCart"))))
      }
    };
    

    const handleDeleteItem = (cartItemId) => {
      if (currentUser?.id) {
        deleteCartItem(cartItemId, dispatch);
      } else {
        // User is not logged in, remove the item from local storage
        const guestCart = JSON.parse(localStorage.getItem("guestCart")) || [];
        const updatedCart = guestCart.filter((item) => item.id !== cartItemId); // Use a unique identifier
        localStorage.setItem("guestCart", JSON.stringify(updatedCart));
        dispatch( CartActions.removeCartItem(cartItemId))
      }
    };

    const handleRedirect = (item) =>{
      item?.bestSellerBook ? 
          history.push({
            pathname: `${urls.BESTSELLERPDP}/${item.bestSellerBook.id}`,
        }) : 
          history.push({
            pathname: `${urls.PRODUCTDETAIL}/${item.book.id}`,
        });
    }
    

    const getBookImage = (cartItem) => {

        if(cartItem?.book){
            return getRandomImageForNormalBook(cartItem?.book,allColorsData,cartItem?.color,cartItem?.leatherType,cartItem?.variantType)?.image
        }
        else if(cartItem?.bestSellerBook){
            const link =  getRandomImageForBestSellerBook(cartItem?.bestSellerBook,allColorsData,cartItem?.color,cartItem?.leatherType,cartItem?.variantType)?.image
            return link
        }
        return ""
    } 

    return (
      <>
        <div className={classes.mainContainer}>
          {isOpen && 
            <CustomizationDetails 
              bookData={selectedItem.book} 
              bookVariant={selectedItem.variantType} 
              bookLeather={selectedItem.leatherType} 
              customization={selectedItem.customization}
              frontCoverColor={selectedItem.color}
              bookQty={selectedItem.quantity}
              image={selectedItem?.customization?.bookThumbnail}
              onClose={()=>{setIsOpen(false); setSelctedItem(null)}}
              />
            }
          <div className={classes.itemCount}>
            <h3>{cartItems?.length} Items</h3>
          </div>

          <div className={classes.cartTableWrapper}>
            <table className={classes.cartTable}>
              <thead>
                <tr>
                  <th>Products</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Customization</th>
                  <th colSpan={2}>Sub total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems &&
                  cartItems?.map((item) => (
                    <tr
                      key={item.id}
                      className={
                        item?.book?.isDeleted || item?.bestSellerBook?.isDeleted
                          ? classes.unavailableRow
                          : ""
                      }
                    >
                      <td>
                        <div className={classes.product}>
                          <div className={classes.productImage}>
                            {item?.book?.isDeleted ||
                            item?.bestSellerBook?.isDeleted ? (
                              <span className={classes.unavailableText}>
                                Unavailable
                              </span>
                            ) : (
                              <img src={item?.customization?.bookThumbnail ? item?.customization?.bookThumbnail : getBookImage(item)} onClick={() => handleRedirect(item)} alt="Book" />
                            )}
                          </div>
                          <div className={classes.productDetails} onClick={!item?.book?.isDeleted || !item?.bestSellerBook?.isDeleted ? () => handleRedirect(item) : () => {}}>
                            <h2>
                              {item?.book?.title || item?.bestSellerBook?.title}
                            </h2>
                            <p>
                              Edition: {getEnum(item.variantType)} |{" "}
                              {item?.book
                                ? `Color : ${item.color}`
                                : item?.bestSellerBook
                                ? `Color : ${item.color}`
                                : ""}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className={classes.quantity}>
                        {item.isDeleted ? (
                          <span className={classes.unavailableText}>
                            Unavailable
                          </span>
                        ) : (
                          <div className={classes.qtyController}>
                            <p
                              onClick={() =>
                                handleQtyDecrement(item.id, item.quantity)
                              }
                              className={item?.book?.isDeleted || item?.bestSellerBook?.isDeleted ? classes.disabled : ""}
                            >
                              -
                            </p>
                            <h2>{item.quantity}</h2>
                            <p
                              onClick={() =>
                                handleQtyIncrement(item.id, item.quantity)
                              }
                              className={item?.book?.isDeleted || item?.bestSellerBook?.isDeleted ? classes.disabled : ""}
                            >
                              +
                            </p>
                          </div>
                        )}
                      </td>
                      <td className={classes.price}>
                        <p>
                          {item.isDeleted
                            ? "—"
                            : `$${item?.itemPrice?.toFixed(2)}`}
                        </p>
                      </td>
                      <td className={classes.customization}>
                        <div>
                          <p>
                            {item?.isDeleted
                              ? "—"
                              : item?.customizationCharges ? `$${item?.customizationCharges?.toFixed(2)}` : "$0" }
                          </p>
                          {item.hasCustomization && 
                            <h2 className={classes.customizationDetails} onClick={() => {setIsOpen(true); setSelctedItem(item)}}>View</h2>
                          }
                        </div>
                        
                      </td>
                      <td className={classes.subTotal}>
                        <p>
                          {item.isDeleted
                            ? "—"
                            : `$${((item?.itemPrice + (item?.customizationCharges || 0) )* item.quantity).toFixed(2)}`}
                        </p>
                      </td>
                      <td className={classes.deleteItem}>
                        {!item.isDeleted && (
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => handleDeleteItem(item.id)}
                            className={classes.deleteIcon}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className={classes.backBtn}
        >
          <FontAwesomeIcon icon={faArrowLeft} className={classes.leftArrow} onClick={() => history.push(urls.LISTING)}/>
          <p onClick={() => history.push(urls.LISTING)}>Return To Shop</p>
        </div>
      </>
    );
}

export default Cart
