import { createSlice } from "@reduxjs/toolkit";

const CouponSlice = createSlice({
    name: "coupon",
    initialState: {
        allCoupons: [],
        userCoupons: [],
    },
    reducers: {
        setAllCoupons(state, action) {
            state.allCoupons = action.payload;
        },
        setUserCoupons(state, action) {
            state.userCoupons = action.payload;
        },
    }
});

export const CouponActions = CouponSlice.actions;
export default CouponSlice.reducer;
