import React from "react";

import classes from './Content.module.css'

import './Content.module.css'
import PrimaryButton from "../../../Components/Buttons/PrimaryButton/PrimaryButton";
import ButtonLight from "../../../Components/Buttons/ButtonLight/ButtonLight";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { urls } from "../../../Routes/Urls";


function Content({data,tab}){
      // const {title,text,image,alt} = contents[activeTab];
      const history = useHistory()
      const {title,text,image,alt} = data;

      

      const handleRedirect = () => {
        if(tab === 0){
            history.push(urls.SERVICES)
        }
        else if(tab === 1){
            history.push(urls.LEATHERBINDING)
        }
        else if(tab === 2){
            history.push(urls.BOOKRESTORATION)
        }
        else if(tab === 3){
            history.push(urls.PDFPRINTING)
        }
        else{
            history.push(urls.THESISBINDING)
        }
      }

      return (
        <div className={classes.mainContent}>
            <div className={classes.contentContainer}>


                <div className={classes.customizationContent}>
                    <h2>{title}</h2>
                    <p>{text}</p>
                    <div className={classes.buttons}>
                    <PrimaryButton className={`${classes.button}`} onClick={() => handleRedirect()}>Know More</PrimaryButton>
                    <ButtonLight className={`${classes.button}`}onClick={() => history.push(urls.LISTING)}>Customize</ButtonLight>
                    </div>
                </div>
                <div className={classes.bookCover}>
                    <img src={`/Assets/Images/${image}`} alt={alt} />
                </div>
            </div>
        </div>
      );
}

export default Content