import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ProfileForm.module.css';
import Consts from '../../../Consts';
import MessageDialogue from '../../../Components/MessageDialogue/MessageDialogue';
import Password from '../../../Components/Password/Password';
import { updateUser, updatePassword, fetchUserDetails } from '../../../Store/actions/UsersActions';
import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton';
import PrimaryInput from '../../../Components/Inputs/PrimaryInput/PrimaryInput';

const successIcon = "/Assets/Icons/icon-success.svg"
const ProfileForm = () => {

  const dispatch = useDispatch()

  const handleFormSubmission = (e) => {
    e.preventDefault();

    const data = {}
    //   if(password !== "" && password === confirmPassword){
    //     data["password"] = password
    //   }
    if (firstName !== "" && lastName !== "") {
      data["fullName"] = firstName + " " + lastName
    }
    if (mobileNumber !== "") {
      data["phoneNo"] = mobileNumber
    }
    if (dob !== "") {
      data["dob"] = new Date(dob).getTime()
    }

    if(!(existingPassword !== "" && password !== "" && password === confirmPassword)){
    updateUser(data, null, dispatch).then((response) => {
      if (response && response.data && response.data.success) {
        dispatch(fetchUserDetails())
        setDialogContent(() => {
          return {
            title: "Success",
            message: "User Details Updated Successfully",
          };
        })
        setShowMessageDialog(true)
      }
    });
  }

    if (existingPassword !== "" && password !== "" && password === confirmPassword) {
      const data = {
        "userId": currentUser.userId,
        "existingPassword": existingPassword,
        "password": password
      }
      updatePassword(data, dispatch).then((response) => {
        if (response && response.data && response.data.success) {
          setDialogContent(() => {
            return {
              title: "Success",
              message: "User details and Password Changed Successfully",
            };
          })
        }
      })
    }

    setFirstName("");
    setLastName("");

  }

  const currentUser = useSelector((state) => state.user.currentUser)

  const [existingPassword, setExistingPassword] = useState("")
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState(currentUser?.fullName?.split(" ")[0] || "");

  const [lastName, setLastName] = useState(currentUser?.fullName?.split(" ")[1] || "");
  const [mobileNumber, setMobileNumber] = useState(currentUser.phoneNo || "")
  const [dob, setDob] = useState(currentUser.dob ? new Date(currentUser.dob).toISOString().split("T")[0] : "")
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });

  useEffect(() => {
    if (currentUser?.fullName) {
      const [firstName, lastName] = currentUser.fullName.split(" ");
      setFirstName(firstName || "");
      setLastName(lastName || "");
      setMobileNumber(currentUser?.phoneNo)
    }
},[currentUser])

  const [isEditable, setIsEditable] = useState({
    mobile: false,
    email: false,
    dob: false,
  })


  const handleEdit = (field) => {
    setIsEditable((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  useEffect(() => {
    if (currentUser.dob) {
      setDob(new Date(currentUser.dob).toISOString().split('T')[0]);
    }
  }, [currentUser.dob])

  const handleMobileNumberChange = (e) => {
    const input = e.target.value;
  
    // Check if the first character is a "+" (country code)
    if (input.startsWith("+")) {
      // Allow the "+" character, but restrict the rest to numbers only, and limit to 15 digits
      const countryCodeAndNumber = input.slice(1); // Get everything after the "+"
      if (!/^\d*$/.test(countryCodeAndNumber)) {
        return; // Ignore input if it's not numeric after the "+"
      }
      if (countryCodeAndNumber.length <= 15) {
        setMobileNumber(input); // Allow up to 15 digits including country code
      }
    } else {
      // Without country code, allow only numbers and restrict to 10 digits
      if (/^\d{0,10}$/.test(input)) {
        setMobileNumber(input);
      }
    }
  };
  

  const isDisabled = () => {
    if (existingPassword || password || confirmPassword) {
 // Ensure all password fields are filled and password matches confirmation
      return (
        !existingPassword ||
        !password ||
        !confirmPassword ||
        (existingPassword === password) ||
        (password !== confirmPassword) || 
        !firstName ||
        !lastName ||
        !mobileNumber ||
        !dob
      );
    }
    return (
      !firstName ||
      !lastName ||
      !mobileNumber ||
      !dob||
      (currentUser?.fullName === (firstName+" "+lastName) &&
      currentUser?.phoneNo === mobileNumber &&
      currentUser?.dob === new Date(dob).getTime())
    );
  };
  return (
    <>
      {showMessageDialog && (
        <div >
          <MessageDialogue
            {...dialogContent}
            onClose={() => setShowMessageDialog(false)}
            closeText="Close"
            width={Consts.MESSAAGE_DIALOG_WIDTH}
            dialogueIcon={successIcon}
          />
        </div>

      )}
      <form className={styles.profileForm} autoComplete='off'>
        <div className={styles.row}>
          <div className={`${styles.column} ${styles.name}`}>
            {/* <label>First Name</label> */}
            <PrimaryInput label={"First Name"} maxLength={15} isRequired={true} value={firstName} onChange={(e) => setFirstName(e.target.value)} autoComplete="off" />
          </div>
          <div className={`${styles.column} ${styles.name}`}>
            {/* <label>Last Name</label> */}
            <PrimaryInput label={"Last Name"} isRequired={true} maxLength={15} value={lastName} placeholder={currentUser?.fullName?.split(" ")[1]} onChange={(e) => setLastName(e.target.value)} autoComplete="off" />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.inputWithButton}>
              <PrimaryInput isRequired={true} label={"Mobile Number"} value={mobileNumber} onChange={handleMobileNumberChange} disabled={!isEditable.mobile} />
              <button type='button' className={styles.changeButton} onClick={() => handleEdit("mobile")}>Change</button>
            </div>
          </div>
          <div className={styles.column}>
            {/* <label>Email</label> */}
            <div >
              <PrimaryInput label={"Email"} placeholder={currentUser.userId} disabled={!isEditable.email} />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            {/* <label>Date of Birth</label> */}
            <div className={`${styles.inputWithButton} ${styles.dob}`}>
              {/* <Input placeholder={currentUser.dob} disabled = {!isEditable.dob} autoComplete="off" /> */}
              <PrimaryInput
                label={"Date of Birth"}
                type="date"
                value={dob}
                placeholder={dob}
                onChange={(e) => setDob(e.target.value)}
                disabled={!isEditable.dob}
                className={styles.dateInput}
                max={new Date().toISOString().split('T')[0]}
              />
              <button type='button' className={styles.changeButton} onClick={() => handleEdit("dob")}>Change</button>
            </div>
          </div>
        </div>
        <div>
          <h2 className={styles.changePasswordTitle}>Change password</h2> 
          <div className={`${styles.row} `} >
            <div className={`${styles.column} ${styles.name}`}>
              <label>Existing Password</label>
              <Password
                id='password'
                placeholder="Existing Password"
                togglePasswordVisibility={true}
                onChange={(e) => { setExistingPassword(e.target.value) }}
                autoComplete='new-password'
                onCommit=""
              />
            </div>
            <div className={`${styles.column} ${styles.name}`}>
              <label>New Password</label>
              <Password
                id='password'
                placeholder="New Password"
                togglePasswordVisibility={true}
                onChange={(e) => { setPassword(e.target.value) }}
                autoComplete='off-password'
                onCommit=""
              />
            </div>
            <div className={`${styles.column} ${styles.name}`}>
              <label>Confirm New Password</label>
              <Password
                id='password'
                placeholder="Confirm New Password"
                togglePasswordVisibility={true}
                onChange={(e) => { setConfirmPassword(e.target.value) }}
                autoComplete='off-password'
                onCommit=""
              />
            </div>
            
          </div>
        </div>
        
        <SecondaryButton disabled={isDisabled()} className={styles.submitBtn} onClick={(e) => handleFormSubmission(e)}>Update Profile</SecondaryButton>
      </form>
    </>
  );
};

export default ProfileForm;
