import classes from "./AboutBook.module.css"

const AboutBook = ({title,author,language,publicationDate,desc}) => {
    return(
        <div className={classes.container}>
            <h1>Book Information</h1>
            <div className={classes.detailContainer}>
                <div className={classes.details}>
                    <h2>Book title</h2>
                    <p>{title}</p>
                </div>
                <div className={classes.details}>
                    <h2>Author</h2>
                    <p>{author}</p>
                </div>
                <div className={classes.details}>
                    <h2>Language</h2>
                    <p>{language}</p>
                </div>
                <div className={classes.details}>
                    <h2>Publishing Year</h2>
                    <p>{publicationDate}</p>
                </div>
            </div>

            <div className={classes.description}>
                <h2>Description</h2>
                <p>{desc}</p>
            </div>
        </div>
    )
}

export default AboutBook