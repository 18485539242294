import classes from "./SocialMediaButton.module.css"
import { getClasses } from "../../../Util"
import { useEffect } from "react";

function poptastic(url) {
	window.open (url, "auth", "width=700,height=900");
}

function SocialMediaButton({className, icon, title, url, callback}){
    useEffect(() => {
        const onMessage = e => {
            if (e.origin === window.location.origin && e.source.name === 'auth') {
                callback && callback(e.data);
            }
        }
        window.addEventListener('message', onMessage);
        return () => {
            window.removeEventListener("message", onMessage);
        }
    }, [callback]);
    return(
        <button className={getClasses(className, classes.socialButton)}
            onClick={e => poptastic(url)}
        > 
            <img src={icon} alt="Social Media" />
        </button>
    )
}

export default SocialMediaButton;