import classes from "./AboutPOD.module.css"

const image1 = "/Assets/Images/customization/LeatherMatrerialIcon.png"
const image2 = "/Assets/Images/customization/handcraftedExcellence.png"
const image3 = "/Assets/Images/customization/customization.png"

const cardData = [
    {
        title:"Customized Creations",
        desc:"With our print-on-demand service, you may create and print precisely what you have in mind for your customized books. Your creativity is limitless!",
        image:image1
    },
    {
        title:"High-quality printing",
        desc:" We make sure that every page of your book is printed with brilliant colors and crisp text, giving your work a highly polished look. We do this by utilizing cutting-edge printing technology and premium materials.",
        image:image2
    },
    {
        title:"No Order Minimum",
        desc:"For special orders and limited editions, our print-on-demand service is ideal, whether you need a single copy or a small batch. Eliminate the hassle of large orders and extra inventory.",
        image:image3
    },
    {
        title:"Quick Turnaround",
        desc:"  Our effective production method ensures that you receive your books quickly. We guarantee on-time delivery so you can begin promoting or selling your book right away.",
        image:image3
    },
    {
        title:"Eco-Friendly Printing",
        desc:" Sustainability is important to us. Our eco-friendly printing choices and materials, together with our print-on-demand service, help you cut waste and improve the environment.",
        image:image3
    },
]

const AboutPOD = () => {
    return(
        <div className={classes.mainContainer}>
            <div className={classes.headerSection}>
                <h2>
                    Why Choose Print-on-Demand?
                </h2>
                {/* <p>Our mission is to encapsulate your academic journey in the timeless beauty of leather, ensuring that your thesis remains a testament to your dedication and intellect.</p> */}
            </div>

            <div className={classes.cardSection}>
                {cardData.map((card,index) => (
                    <div key={index} className={classes.card}>
                        <div className={classes.image}>
                            <img src={card.image} alt="" />
                        </div>
                        <div className={classes.title}>
                            <h2>{card.title}</h2>
                            <p>{card.desc}</p>
                        </div>
                    </div>
                ))}
            </div>


        </div>
    )
}

export default AboutPOD