import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./TrendingBooks.module.css";
import Card from "../Card/Card2";
import { getRandomImageForNormalBook } from "../../../Util";

const TrendingBooks = () => {
  const allColorsData = useSelector((state) => state.bookColor.colors);
  const bookData = useSelector((state) => state.gallery.gallery);
  const galleriesData =
    bookData?.find((data) => data.category === "trending")?.books || [];

  const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  

  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardsToShow, setCardsToShow] = useState(3);
  const [disableHover, setDisableHover] = useState(false);

  const totalPages = Math.ceil(galleriesData.length / cardsToShow);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1200) setCardsToShow(3);
    else if (width > 768) setCardsToShow(2);
    else setCardsToShow(1);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const nextSlide = () => {
    if (currentIndex < totalPages - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const startIndex = currentIndex * cardsToShow;
  const visibleBooks = galleriesData?.slice(startIndex, startIndex + cardsToShow);
  const handleMouseEnter = () => setDisableHover(true);
  const handleMouseLeave = () => setDisableHover(false);

  const isWishListItem = (bookData) => {
    const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title)
    if(bookOpt) {
      return {isPresent : true, data: bookOpt}
    } else {
      return {isPresent : false, data:null}
    }
  }

  return (
    <div className={classes.mainContainer}>
      <h2>Trending Books</h2>

      <div className={classes.carouselContainer}>
        <button
          className={`${classes.arrow} ${classes.prev}`}
          onClick={prevSlide}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disabled={currentIndex === 0}
        >
          &#10094;
        </button>

        <div
          className={`${classes.carousel} ${
            disableHover ? classes.disableHover : ""
          }`}
        >
          {visibleBooks?.map((book, index) => (
            <Card
              key={index}
              bookData={book}
              image={book?.imageUrl}
              isBestSeller={false}
              price={
                getRandomImageForNormalBook(book, allColorsData)?.variantType ===
                "deluxe"
                  ? book.deluxePriceGenuine
                  : book.superDeluxePriceGenuine
              }
              altText={getRandomImageForNormalBook(book, allColorsData)?.altText}
              isWishListItem={isWishListItem(book)?.isPresent}
              wishlistItemId={isWishListItem(book)?.data?.id}
            />
          ))}
        </div>

        <button
          className={`${classes.arrow} ${classes.next}`}
          onClick={nextSlide}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          disabled={currentIndex >= totalPages - 1}
        >
          &#10095;
        </button>
      </div>
    </div>
  );
};

export default TrendingBooks;