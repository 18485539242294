import React from 'react';
import classes from './Card2.module.css';
import SecondaryButton from '../../../Components/Buttons/SecondaryButton/SecondaryButton';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from '../../../Routes/Urls';
import { addWishlistItem, deleteWishlistItem } from '../../../Store/actions/WishlistAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
const heartIcon = "/Assets/Icons/heartIcon.svg"

const Card = ({ altText,price,image,bookData, icon,onClick,isWishListItem = false,wishlistItemId }) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.user.currentUser)

  const handleAddCart = () => {
    history.push({
      pathname: `${urls.PRODUCTDETAIL}/${bookData.id}`,
      state: { bookData }
  });
  }

  const handleIconClick = (event) => {
    event.stopPropagation()
    if(currentUser?.id){
      addWishlistItem(bookData,1,dispatch)
    }
    else{
      history.push(urls.LOGIN)
    }
    
  }

  const handleRemove = (e) => {
    e.stopPropagation()
    deleteWishlistItem(wishlistItemId, dispatch);
  };

  return (
    <div className={classes.card} onClick={onClick ? onClick : handleAddCart}>
      <div className={classes.main}>
      <div>
      <div className={classes.imageContainer}>
        <img src={image} alt={altText} className={classes.image} />
        {!icon && isWishListItem ? (
          <FontAwesomeIcon className={classes.filledHeart} icon={faHeart} onClick={(e) => handleRemove(e)}/>
        ) : (
          <img className={classes.heartButton} src={icon || heartIcon} alt="" onClick={handleIconClick}/>
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.titleContainer}>
            <h3 className={classes.title}>{bookData?.title}</h3>
            <p className={classes.author}>{bookData.author}</p>
          </div>
        </div>
      </div>
      </div>
      <div className={classes.actions}>
        <div className={classes.bottomRow}>
            <p className={classes.price}>${price?.toFixed(2)}</p>
          </div>
        <div className={classes.addToCartButton}>
          <SecondaryButton className={classes.cartBtn} onClick={handleAddCart}>Preview</SecondaryButton>
        </div>
      </div>
      </div>
      <div className={classes.cloudLayer}></div>
    </div>
  );
};

export default Card;
