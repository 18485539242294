import React from "react";
import { createPortal } from "react-dom";
import classes from "./Loader.module.css";
import { useSelector } from "react-redux";

const container = document.querySelector("#root");

function Loader() {
  const loading = useSelector((state) => state.loader.loading);

  if (loading < 0) {
    
  }

  return createPortal(
    !!loading && (
      <>
        <div className={classes.container}>
          <div className={classes.loader} />
        </div>
      </>
    ),
    container
  );
}

export default Loader;
