import { createSlice } from "@reduxjs/toolkit";

const bookColorSlice = createSlice({
    name: "bookColors",
    initialState: {
        colors: [],
    },
    reducers: {
        addColor: (state, action) => {
            state.colors.push(action.payload);
        },
        setColors: (state, action) => {
            const filteredColors = action.payload.filter((color) => color.disabled === false);
            state.colors = filteredColors;
        },
        updateColors: (state, action) => {
            const index = state.colors.findIndex(color => color.id === action.payload.id);
            if (index !== -1) {
                state.colors[index] = action.payload;
            }
        },
        deleteColor: (state, action) => {
             state.colors = state.colors.filter(color => color.id !== action.payload);
        },
    }
});

export const bookColorActions = bookColorSlice.actions;
export default bookColorSlice.reducer;
