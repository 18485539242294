import Modal, { ModalBody, ModalFooter } from "../../../../Components/Modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import classes from "./UnavailableModal.module.css";
import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton";
import { urls } from "../../../../Routes/Urls";
import ButtonLight from "../../../../Components/Buttons/ButtonLight/ButtonLight";

const UnavailableModal = () => {
    const redirectToCollection = () => {
        window.location.href = urls.LISTING;
    };
    const redirectToSavedDesigns = () => {
        window.location.href = "/my-account/my-customizations";
    };

    return (
        <Modal>
            <ModalBody>
                <div className={classes.container}>
                    <FontAwesomeIcon 
                        icon={faExclamationTriangle} 
                        className={classes.icon} 
                    />
                    <h2 className={classes.title}>
                        Book Unavailable
                    </h2>
                    <p className={classes.message}>
                        The book you are looking for is currently unavailable. You cannot proceed further.
                    </p>
                </div>
            </ModalBody>

            <ModalFooter>
                <div className={classes.btnContainers}>
                <PrimaryButton className={classes.exploreBtn} onClick={redirectToCollection}>
                    Browse Our Collection 
                </PrimaryButton>

                <ButtonLight className={classes.exploreBtn} onClick={redirectToSavedDesigns}>
                    View Saved Designs
                </ButtonLight>

                </div>
            </ModalFooter>
        </Modal>
    );
};

export default UnavailableModal;
