import React from "react";
import BlogCard from "./BlogCard/BlogCard";
import classes from "./BlogSection.module.css";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { urls } from "../../../Routes/Urls";

function BlogsSection() {
  const history = useHistory();
  const blogData = useSelector((state) => state.blogs.allBlogs);

  const handleOnClick = (slug) => {
    history.push(`${urls.VIEWBLOG}/${slug}`);
  };

  return (
    <div className={classes.blogsSection}>
      <div className={classes.headerSection}>
        <h2 className={classes.header}>Blogs</h2>
        <p className={classes.description}>
          Literary Musings: Reviews, Summaries, and Insights
        </p>
      </div>
      <div className={classes.blogCards}>
        {blogData?.slice(0, 3)?.map((blog, index) => (
          <BlogCard
            key={index}
            image={blog?.bannerImagePath}
            date={blog?.updateTime}
            title={blog?.title}
            authorName={blog?.authorName}
            authorImg={blog?.authorImg}
            countryCode={blog.countryCode}
            category={blog?.category}
            onClick={() => handleOnClick(blog?.urlSlug)}
            className={classes.blogCard}
          />
        ))}
      </div>
      {/* <button className={classes.exploreButton}>Explore Our Blogs</button> */}
      <div className={classes.btnContainer}>
        <div className={classes.moreBtn}>
          <SecondaryButton onClick={() => history.push(urls.BLOG)}>
            {" "}
            Explore our blogs
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}

export default BlogsSection;
