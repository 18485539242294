import React, { useEffect, useRef, useState } from "react";
import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import SearchComponent from "./SearchComponent/SearchComponent";
import Sidebar from "./Sidebar/Sidebar";
import Card from "./Card/Card2";
import Footer from "../Footer/Footer";
import classes from "./BestSeller.module.css";
import {
  fetchBook,
  fetchBookCount,
  getFacets,
} from "../../Store/actions/BestSellerBookAction";
import { useDispatch, useSelector } from "react-redux";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { urls } from "../../Routes/Urls";
import { getRandomImageForBestSellerBook } from "../../Util";

const itemsPerPage = 16;

const BestSeller = () => {
  const dispatch = useDispatch();
  const allColorsData = useSelector((state) => state.bookColor.colors);
  const wishlist = useSelector((state) => state.wishlist.wishlistItems);
  
  const [searchResults, setSearchResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const searchCriteria = "all";
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [allowedMinPublishingYear, setAllowedMinPublishingYear] =
    useState(null);
  const [allowedMaxPublishingYear, setAllowedMaxPublishingYear] =
    useState(null);
  const [minPublishingYear, setMinPublishingYear] = useState(null);
  const [maxPublishingYear, setMaxPublishingYear] = useState(null);
  const [allGenres, setAllGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const totalPages = totalResults > 0 ? Math.ceil(totalResults / itemsPerPage) : 1;
  const [isPageChange, setIsPageChange] = useState(false);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, []);


  const getFilter = () => {
    const currPage = isPageChange ? currentPage : 1;
    let filter = {
      limit: itemsPerPage,
      offset: (currPage - 1) * itemsPerPage,
      where: {
        $and: [],
      },
    };

    if (query) {
      // Add field and query conditions
      if (searchCriteria !== "all") {
        filter.where.$and.push({ [searchCriteria]: `'${query}'` });
      } else {
        filter.where.$and.push({
          $or: [
            { author: `'${query}'` },
            { title: `'${query}'` },
            { language: `'${query}'` },
          ],
        });
      }
    }

    // Add publishingYear range condition in the format "MIN TO MAX"
    if (
      (minPublishingYear || maxPublishingYear) &&
      !(
        minPublishingYear === allowedMinPublishingYear &&
        maxPublishingYear === allowedMaxPublishingYear
      )
    ) {
      const publishingYearCondition = {
        publishingYear: `[${
          minPublishingYear === allowedMinPublishingYear
            ? "*"
            : minPublishingYear || "*"
        } TO ${
          maxPublishingYear === allowedMaxPublishingYear
            ? "*"
            : maxPublishingYear || "*"
        }]`,
      };
      filter.where.$and.push(publishingYearCondition);
    }

    if (selectedGenres?.length > 0) {
      filter.where.$and.push({ genres: selectedGenres.join(",") });
    }

    // Add sorting if provided
    if (sortBy) {
      filter.sortBy = sortBy;
    }

    return filter;
  };

  const fetchPageData = async () => {
    setLoading(true);
    const filter = getFilter();
    try {
      // Fetch books based on criteria, query, and pagination
      const data = await fetchBook(dispatch, filter);
      const total = await fetchBookCount(
        searchCriteria,
        query,
        minPublishingYear === allowedMinPublishingYear
          ? null
          : minPublishingYear,
        maxPublishingYear === allowedMaxPublishingYear
          ? null
          : maxPublishingYear,
        selectedGenres
      ); // Fetch total count
      setSearchResults(data.data);
      if (totalResults !== total) {
        setCurrentPage(1);
      }
      setTotalResults(total);
      setIsPageChange(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch facet data (like publishing year)
  useEffect(() => {
    getFacets().then((data) => {
      setMaxPublishingYear(data?.publishingYear?.max);
      setAllowedMaxPublishingYear(data?.publishingYear?.max);
      setMinPublishingYear(data?.publishingYear?.min);
      setAllowedMinPublishingYear(data?.publishingYear?.min);
      setAllGenres(data?.genres);
    });
  }, []);

  useEffect(() => {
    fetchPageData();
  }, [
    selectedGenres,
    minPublishingYear,
    maxPublishingYear,
    sortBy,
    currentPage,
  ]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
      setIsPageChange(true);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
      setIsPageChange(true);
    }
  };

  const crumbs = [
    { title: "Home", path: "/" },
    { title: "Best Sellers", path: urls.BESTSELLER },
  ];

  const isWishListItem = (bookData) => {
    const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title)
    if(bookOpt) {
      return {isPresent : true, data: bookOpt}
    } else {
      return {isPresent : false, data:null}
    }
  }

  const bgImage = "/Assets/Images/Banners/BestSellerBanner.png"

  return (
    <div className={classes.mainContainer} ref={scrollRef}>
      <PrimaryNav />
      <BannerHeader crumbs={crumbs} title={"Best Sellers"} bgImage={bgImage}/>
      <div className={classes.nav}>
        <div className={classes.headerText}>
          <h2>Explore the Best Sellers</h2>
        </div>
        <div className={classes.SearchComponent}>
          <SearchComponent
            query={query}
            onChange={(e) => setQuery(e.target.value)}
            onSearch={() => {
              fetchPageData();
              setCurrentPage(1);
            }}
            setQuery={setQuery}
            minYear={minPublishingYear}
            maxYear={maxPublishingYear}
            selectedGenres={selectedGenres}
            allowedMaxPublishingYear={allowedMaxPublishingYear}
            allowedMinPublishingYear={allowedMinPublishingYear}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className={classes.sortBtn}>
              <select
                name="sortBy"
                id="sortBy"
                onChange={(e) => setSortBy(e.target.value)}
              >
                <option value="" disabled>
                  Sort By
                </option>
                <option value="title ASC">A-Z</option>
                <option value="title DESC">Z-A</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.mainSection}>
        <div className={classes.sidebar}>
          <Sidebar
            allGenres={allGenres}
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
            allowedMaxYear={allowedMaxPublishingYear}
            allowedMinYear={allowedMinPublishingYear}
            maxYear={maxPublishingYear}
            minYear={minPublishingYear}
            setMaxYear={setMaxPublishingYear}
            setMinYear={setMinPublishingYear}
          />
        </div>
        <div className={classes.content}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <p className={classes.searchCount}>
                Showing {totalResults} results
              </p>
              <div className={classes.cardGrid}>
                {searchResults.map((card, index) => (
                  <Card
                    key={index}
                    image={card.imageUrl}
                    title={card.title}
                    author={card.author}
                    price={card?.priceGenuine}
                    altText={
                      getRandomImageForBestSellerBook(card, allColorsData)
                        ?.altText
                    }
                    bookData={card}
                    isWishListItem = {isWishListItem(card).isPresent}
                    wishlistItemId = {isWishListItem(card)?.data?.id}
                  />
                ))}
              </div>

              {/* Pagination Controls */}
              <div className={classes.paginationControls}>
                <div className={classes.paginatonBtn}>
                  <FontAwesomeIcon icon={faArrowLeft} onClick={handlePrevPage} className={classes.arrowLeft}/>
                  <button onClick={handlePrevPage} disabled={currentPage === 1}>
                    Prev
                  </button>
                </div>

                <span>
                  {currentPage > 2 && (
                    <a
                      onClick={() => {
                        setCurrentPage(1);
                        setIsPageChange(true);
                      }}
                    >
                      1
                    </a>
                  )}
                  {currentPage > 3 && <span className={classes.dots}>...</span>}
                  {currentPage > 1 && (
                    <a
                      onClick={() => {
                        setCurrentPage(currentPage - 1);
                        setIsPageChange(true);
                      }}
                    >
                      {currentPage - 1}
                    </a>
                  )}
                  <span className={`${classes.pageNumber} ${classes.active}`}>
                    {currentPage}
                  </span>
                  {currentPage < totalPages && (
                    <a
                      onClick={() => {
                        setCurrentPage(currentPage + 1);
                        setIsPageChange(true);
                      }}
                    >
                      {currentPage + 1}
                    </a>
                  )}
                  {currentPage < totalPages - 2 && (
                    <span className={classes.dots}>...</span>
                  )}
                  {currentPage < totalPages - 1 && (
                    <a
                      onClick={() => {
                        setCurrentPage(totalPages);
                        setIsPageChange(true);
                      }}
                    >
                      {totalPages}
                    </a>
                  )}
                </span>

                <div className={classes.paginatonBtn}>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                  <FontAwesomeIcon icon={faArrowRight} onClick={handleNextPage} className={classes.arrowRight}/>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BestSeller;
