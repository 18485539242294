import React from "react";
import classes from "./PrimaryInput.module.css";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import { getClasses } from "../../../Util";

function PrimaryInput({
  min,
  max,
  name,
  label,
  value,
  onKeyUp,
  errorMsg,
  onChange,
  onKeyDown,
  isRequired,
  placeholder,
  type = "text",
  disabled,
  className,
  maxLength,
  autoComplete="off"
}) {
  return (
    <div className={classes.formControl}>
      {label && (
        <label htmlFor="" className={classes.label}>
          {label}
          {isRequired && "*"}
        </label>
      )}
      <input
        max={max}
        min={min}
        type={type}
        name={name}
        value={value}
        onKeyUp={onKeyUp}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={getClasses(classes.primaryInput, className)}
        maxLength={maxLength}
        autoComplete="off"
      />
      {errorMsg && <ErrorMessage error={errorMsg} />}
    </div>
  );
}

export default PrimaryInput;
