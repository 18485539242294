import React, { useEffect, useState } from "react";
import classes from "./CustomDropdown.module.css"; // Import CSS module

const flagPlaceholder = "/Assets/Images/flag_placeholder.png";

const CustomDropdown = ({ CountryCodeList,setCountry}) => {
  const [selectedCountry, setSelectedCountry] = useState("Country Code");
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (dial_code) => {
    setSelectedCountry(dial_code);
    setIsOpen(false); // Close the dropdown
  };

  useEffect(() => {
    setCountry(selectedCountry.dial_code)
  },[selectedCountry,setSelectedCountry])

  return (
    <div className={classes.dropdown}>
      <div className="buttonContainer">
        <p
          className={classes.dropdownButton}
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          {selectedCountry?.code ? (
            <img
              src={flagPlaceholder}
              className={` ${classes.flag} ${
                classes[`flag-${selectedCountry.code.toLowerCase()}`]
              }`}
            />
          ) : (
            " "
          )}{" "}
          {selectedCountry?.dial_code? selectedCountry.dial_code : selectedCountry}
        </p>
      </div>
      {isOpen && (
        <div
          className={classes.dropdownMenu}
          onMouseLeave={() => setIsOpen(false)}
        >
          {CountryCodeList?.countryCode.map((item, idx) => (
            <div
              key={idx}
              className={classes.dropdownItem}
              onClick={() => handleSelect(item)}
            >
              <img
                src={flagPlaceholder}
                className={` ${classes.flag} ${
                  classes[`flag-${item.code.toLowerCase()}`]
                }`}
              />
              &nbsp;&nbsp;
              <span> {item.code}</span> &nbsp;&nbsp;{" "}
              <span>{item.dial_code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
