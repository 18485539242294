import { createSlice } from "@reduxjs/toolkit";

const BestSellerBookSlice = createSlice({
    name:"bestSellerBooks",
    initialState : {
        books: [],
        book:[]
    },
    reducers : {
        updateBooks(state,action){
            state.books = action.payload
        },
        updateBookById(state,action){
            state.book = action.payload
        }
    }

});

export const BestSellerBookActions = BestSellerBookSlice.actions
export default BestSellerBookSlice.reducer