import { createSlice } from "@reduxjs/toolkit";
import {} from "../../Util";



const GallerySlice = createSlice({
    name: "gallery",
    initialState: {
        gallery: []
    },
    reducers: {
        updateGallery: (state, action) => {
            state.gallery = action.payload;
        },
    },
});


export const GalleryActions = GallerySlice.actions;
export default GallerySlice.reducer;

