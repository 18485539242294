import React from 'react';
import ReactDOM from 'react-dom';

function Metatag({ name, content }) {
    return ReactDOM.createPortal(
        <meta name={name} content={content} />,
        document.head
    );
}

export default Metatag;