import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  showWarning: false,
  message: "Sorry, there was a problem with your request.",
};

const warning = createSlice({
  name: "warning",
  initialState,
  reducers: {
    showWarning: (state, actions) => {
      const message = actions.payload?.message;
      state.showWarning = true;
      if (message) {
        state.message = message;
      }
    },
    removeWarning: (state) => {
      state.showWarning = false;
      state.message = initialState.message;
    },
  },
});

export const warningActions = warning.actions;
export default warning.reducer;
