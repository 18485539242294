import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classes from "./ProductDetailSmall.module.css"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { useContext, useState } from "react"
import { CustomizationContext } from "../../Customization"
import { getBookPrice } from "../../../../Util"
import CustomizationDetails from "../CustomizationDetails/CustomizationDetails"
import { calculateCustomizationCharges } from "../../../../Store/actions/CustomizationAction"

const ProductDetailSmall = () => {
    const {bookData,bookLeather,bookVariant,bookCustomization,customizationCharges,setCustomizationCharges,bookQty} = useContext(CustomizationContext)

    const [showDetailModal,setShowDetailModal] = useState(false)

    const handleDetailsClick = async () => {
        setShowDetailModal(true);
        const res = await calculateCustomizationCharges(bookCustomization);
        setCustomizationCharges(res.data.data)
    }
    return(
        <div className={classes.mainContainer}>
            <h2>{bookData?.title}</h2>
            <div className={classes.priceSection}>
                <p>$ {(
              getBookPrice(bookData, bookLeather, bookVariant) * bookQty +
              (customizationCharges?.totalCharges
                ? customizationCharges.totalCharges * bookQty
                : 0)
            ).toFixed(2)}</p>
                <FontAwesomeIcon className={classes.icon} icon={faAngleRight} onClick={handleDetailsClick}/>
            </div>

            {showDetailModal && <CustomizationDetails onClose = {() => setShowDetailModal(false)} />}
        </div>
    )
}

export default ProductDetailSmall;