import React, { useContext, useState, useEffect } from "react";
import ColorPicker from "./ColorPicker/ColorPicker";
import OuterBorder from "./OuterBorder/OuterBorder";
import InnerBorder from "./InnerBorder/InnerBorder";
import EditText from "./EditText/EditText";
import AddLogo from "./AddLogo/AddLogo";
import SectionController from "../Components/SectionController/SectionController";
import useScreenSize from "../../../Hooks/UseScreenSize";
import classes from "./FrontCover.module.css"; // Make sure this path is correct
import { CustomizationContext } from "../Customization";
import { useFabricCanvas, useFabricCanvasHandler, useFabricEditor } from "react-fabric-page";
import useScrollToTop from "../../../Hooks/useScrollToTop";

const options = [
  "Leather Color",
  "Outer Border",
  "Inner Border",
  "Text Boxes",
  "Unique Design"
];

const FrontCover = ({ onNext, onPrev }) => {
  const [currentDropdownId, setCurrentDropdownId] = useState(0);
  const [currentDropdown, setCurrentDropdown] = useState(
    options[currentDropdownId]
  );
  const [dropdownOpen, setDropDownOpen] = useState(true);

  const scrollRef = useScrollToTop();

  const {
    bookData,
    frontCoverTextColor,
    frontCoverFontFamily,
    frontCoverFontStyle,
    frontCoverTextAlign,
    setFrontCoverText,
    frontCoverFontSize,
    selectedOption,
    setDesignJSON,designSaveInitiated,
    selectedDesigns,
    steps
  } = useContext(CustomizationContext);

  const [currentOption, setCurrentOption] = useState("");
  const [isCanvasReady,setIsCanvasReady] = useState(false);

  const { isMediumScreen } = useScreenSize();

  let canvas = useFabricCanvas();
  let editor = useFabricEditor();

  useEffect(() => {
    window.canvas = canvas;
    window.frontEditor = editor
  }, [canvas,editor]);

  useEffect(() => {
        const timeout = setTimeout(() => {
        if (canvas) {
            canvas.renderAll();
        }
    }, 300); // Adjust delay as needed
    return () => clearTimeout(timeout);
},[steps,canvas])

  useEffect(() => {
    if(designSaveInitiated && editor) {
      const frontJSONString = editor?.getJSON();
      const frontJSONBlob = new Blob([frontJSONString], { type: "application/json" });
      setDesignJSON(prevArray => {
        const updatedArray = [...prevArray];
        updatedArray[0] = frontJSONBlob;
        return updatedArray;
      });
    }
  },[designSaveInitiated])

  // Effect to set title and author text when canvas loads
  useEffect(() => {
    if (canvas && isCanvasReady) {
      const contentLayer = canvas.getObjects().find((obj) => obj.id === 'contents');
      if (contentLayer) {
        // Update title text area
        const titleTextArea = contentLayer._objects.find(
          (obj) => obj.type === 'textArea' && obj.placeholderText === 'Add Book Title'
        );
        if (titleTextArea) {
          titleTextArea.set('text', bookData?.title || 'Set book title');
          titleTextArea.dirty = true; // Mark as dirty to ensure it renders
        }

        // Update author text area
        const authorTextArea = contentLayer._objects.find(
          (obj) => obj.type === 'textArea' && obj.placeholderText === 'Add Author Name'
        );
        if (authorTextArea) {
          authorTextArea.set('text', bookData?.author || 'Set book authors');
          authorTextArea.dirty = true; // Mark as dirty to ensure it renders
        }

        // Render canvas
        canvas.renderAll();

        // Update the frontCoverText state after initial set
        const updatedTextArray = [
          { id: 0, text: titleTextArea.text, left: titleTextArea.left, top: titleTextArea.top },
          { id: 1, text: authorTextArea.text, left: authorTextArea.left, top: authorTextArea.top },
        ];
        setFrontCoverText(updatedTextArray); 
        editor?.saveCurrentState()
      }
    }
  }, [canvas, bookData, setFrontCoverText, isCanvasReady]);



  useEffect(() => {
    if (!canvas) return;

    const applyStylesToTextAreas = () => {
      const allObjects = canvas?.getObjects().find((obj) => obj.id === 'contents');
      if (allObjects) {
        allObjects._objects.forEach((obj) => {
          if (obj.type === 'textArea') {
            obj.set('fill', frontCoverTextColor?.colorValue);
            obj.set('fontFamily', frontCoverFontFamily?.fontFamily);
            if (frontCoverFontStyle?.fontStyle) {
              obj.set('fontStyle', frontCoverFontStyle?.fontStyle);
            }
            if (frontCoverFontStyle?.underline !== null) {
              obj.set('underline', frontCoverFontStyle?.underline);
            }
            if(frontCoverTextAlign) {
              obj.set('textAlign', frontCoverTextAlign);
            }
            if(frontCoverFontSize >= 6) {
              obj.set("fontSize",frontCoverFontSize)
            }
            obj.dirty = true;
          }
        });
        allObjects.dirty = true;
        canvas.renderAll();
      }
    };
    
    applyStylesToTextAreas();

    canvas.on('object:added', applyStylesToTextAreas);
      
        // Clean up the event listener when the component unmounts
        return () => {
          canvas.off('object:added', applyStylesToTextAreas);
        };
  }, [frontCoverTextColor, frontCoverFontFamily, frontCoverFontStyle,frontCoverTextAlign,frontCoverFontSize, isCanvasReady, canvas,selectedDesigns]);

  
  // Effect to handle text changes on the canvas
  useEffect(() => {
    if (!canvas) return;

    const handleTextChange = () => {
      const contentsLayer = canvas.getObjects().find((o) => o.id === 'contents');
      if (contentsLayer) {
        const textAreas = contentsLayer.getObjects().filter((o) => o.type === 'textArea');
        const updatedTextArray = textAreas.map((textArea, index) => ({
          id: index,
          text: textArea.text,
          left: textArea.left,
          top: textArea.top,
          fontSize: textArea.fontSize,
          fontFamily: textArea.fontFamily,
        }));

        setFrontCoverText(updatedTextArray);
      }
    };

    // Listen for text changes on the canvas
    canvas.on('text:changed', handleTextChange);

    // Clean up the event listener on unmount
    return () => {
      canvas.off('text:changed', handleTextChange);
    };
  }, [canvas, setFrontCoverText]);

  useFabricCanvasHandler({
    'canvas:x-ready': () => {
      setIsCanvasReady(true);
    },
  });

  const toggleDropdown = () => {
    setDropDownOpen(!dropdownOpen);
  };

  const handleOptionClick = (id) => {
    if (id === currentDropdownId) {
      setDropDownOpen(!dropdownOpen);
    } else {
      setCurrentDropdownId(id);
      setCurrentDropdown(options[id]);
      setDropDownOpen(true);
    }
  };

  const nextSection = (id) => {
    if (currentDropdownId < 4) {
      setCurrentDropdownId(currentDropdownId + 1);
      setCurrentDropdown(options[id + 1]);
      setCurrentOption(options[id + 1]);
      setDropDownOpen(true);
    } else {
      onNext();
    }
  };

  const prevSection = (id) => {
    if (currentDropdownId >= 1) {
      setCurrentDropdownId(currentDropdownId - 1);
      setCurrentDropdown(options[id - 1]);
      setDropDownOpen(true);
    } else {
      onPrev();
    }
  };

  return (
    <div className={classes.mainContainer} ref={scrollRef}>
      <div
        className={`${
          isMediumScreen && currentOption !== "" && classes.detailsNew
        } ${classes.details}`}
      >
        {isMediumScreen ? (
          <></>
        ) : (
          <div className={classes.headerSection}>
            <h2>Customize front cover (1/5)</h2>
            <p>
              Front Cover: The front cover is the outside of your book, with the
              title and picture that introduce your book to readers.
            </p>
          </div>
        )}

        {!isMediumScreen && (
          <div className={classes.customizationBody}>
            <div onClick={() => handleOptionClick(0)}>
              <ColorPicker
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(0)}
              />
            </div>
            <div onClick={() => handleOptionClick(1)}>
              <OuterBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(1)}
              />
            </div>
            <div onClick={() => handleOptionClick(2)}>
              <InnerBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(2)}
              />
            </div>
            <div onClick={() => handleOptionClick(3)}>
              <EditText
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(3)}
              />
            </div>
            <div onClick={() => handleOptionClick(4)}>
              <AddLogo
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(4)}
              />
            </div>
            <div className={classes.sectionController}>
              <SectionController
                prev={
                  currentDropdownId === 0
                    ? "Material and Variant"
                    : options[currentDropdownId - 1]
                }
                next={
                  currentDropdownId === 4
                    ? "Spine"
                    : options[currentDropdownId + 1]
                }
                onNext={() => nextSection(currentDropdownId)}
                onPrev={() => prevSection(currentDropdownId)}
              />
            </div>
          </div>
        )}

        {isMediumScreen && (
          <div className={classes.customizationBody}>
            <div
              className={selectedOption === options[0] ? "" : classes.inVisible}
              onClick={() => handleOptionClick(0)}
            >
              <ColorPicker
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(0)}
              />
            </div>
            <div
              className={selectedOption === options[1] ? "" : classes.inVisible}
              onClick={() => handleOptionClick(1)}
            >
              <OuterBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(1)}
              />
            </div>
            <div
              className={selectedOption === options[2] ? "" : classes.inVisible}
              onClick={() => handleOptionClick(2)}
            >
              <InnerBorder
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(2)}
              />
            </div>
            <div
              className={selectedOption === options[3] ? "" : classes.inVisible}
              onClick={() => handleOptionClick(3)}
            >
              <EditText
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(3)}
              />
            </div>
            <div
              className={selectedOption === options[4] ? "" : classes.inVisible}
              onClick={() => handleOptionClick(3)}
            >
              <AddLogo
                currentDropdown={currentDropdown}
                dropdownOpen={dropdownOpen}
                toggleDropdown={toggleDropdown}
                onClick={() => handleOptionClick(4)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FrontCover;
