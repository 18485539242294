import React, { useState, useEffect } from 'react';
import classes from "./Corousel.module.css";

import { addWishlistItem, deleteWishlistItem } from '../../../Store/actions/WishlistAction';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from '../../../Routes/Urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const listingDecoration = '/Assets/Images/listingDecoration.svg'

const getNumberOfVisibleItems = () => {
  if (window.innerWidth >= 1200) return 3.5;
  if (window.innerWidth >= 768) return 2;
  return 1;
};

const Carousel = () => {
  const currentUser = useSelector(state => state.user.currentUser)
  const [current, setCurrent] = useState(0);
  const [visibleItems, setVisibleItems] = useState(getNumberOfVisibleItems());
  const dispatch = useDispatch()

  const history = useHistory()

  const featuredCollection = useSelector(state => state.featured.featuredCollection);
  const wishlist = useSelector((state) => state.wishlist.wishlistItems);

  const books = featuredCollection?.find((data) => data.category === "newlyCrafted")?.books || [];

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  useEffect(() => {
    const handleResize = () => {
      setVisibleItems(getNumberOfVisibleItems());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isWishListItem = (bookData) => {
    const bookOpt = wishlist?.find((item) => item?.book?.sku === bookData.sku && item?.book?.title === bookData?.title)
    if(bookOpt) {
      return {isPresent : true, data: bookOpt}
    } else {
      return {isPresent : false, data:null}
    }
  }

  useEffect(() => {
    const autoScroll = setInterval(() => {
      if(books.length > 0){
      setCurrent((prevCurrent) => (prevCurrent + 1) % books.length);
      }
    }, 3000);

    return () => clearInterval(autoScroll);
  }, []);

  const nextSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent + 1) % books.length);
  };

  const prevSlide = () => {
    setCurrent((prevCurrent) => (prevCurrent - 1 + books.length) % books.length);
  };

  const handleAddCart = (bookData) => {
    history.push(`${urls.BESTSELLERPDP}/${bookData?.id}`);
  }

  const handleWishlistChange = (e,book) => {
      e.stopPropagation()
      if(isEmpty(currentUser)) {
        history.push(urls.LOGIN)
      }
      else{
        addWishlistItem(book,1,dispatch,true)
      }
  }

  const handleRemove = (e,itemId) => {
    e.stopPropagation()
    deleteWishlistItem(itemId, dispatch);
  };

  return (
    <div className={classes.container} >
      <div className={classes.carousel}>
        <div className={classes.header}>
          <div className={classes.newlyHeader}>
            <h2>Newly crafted books</h2>
            <img src={listingDecoration} alt="" />
          </div>
          <div className={classes.carouselBtn}>
            <button
              className={`${classes.carouselControl} ${classes.prev}`}
              onClick={prevSlide}
            >
              &lt;
            </button>
            <button
              className={`${classes.carouselControl} ${classes.next}`}
              onClick={nextSlide}
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
      <div className={classes.mainCarousel}>
        <div
          className={classes.carouselInner}
          style={{
            transform: `translateX(-${current * (100 / visibleItems)}%)`,
          }}
        >
          {books.map((book, index) => (
            <div
              key={index}
              className={`${classes.carouselItem} ${
                current === index ? classes.active : ""
              }`}
              onClick={() => handleAddCart(book)}
            >
              <div className={classes.imageContainer}>
                <img
                  src={book.imageUrl}
                  alt={book.title}
                  className={classes.carouselImage}
                />
                <div className={classes.cloudyOverlay}></div>
                <div className={classes.bookDetail}>
                  <h2>{book?.title}</h2>
                  <h3>{book.author?.slice(0, 4)}</h3>
                  <p>${book?.priceGenuine}</p>
                </div>
                <button
                  className={classes.addToCartBtn}
                  onClick={() => handleAddCart(book)}
                >
                  Preview
                </button>
                {isWishListItem(book).isPresent ? (
                  <FontAwesomeIcon
                    className={classes.filledHeart}
                    icon={faHeart}
                    onClick={(e) => handleRemove(e,isWishListItem(book).data?.id)}
                  />
                ) : (
                  <FontAwesomeIcon
                  className={classes.heartButton}
                  icon={faRegularHeart}
                  onClick={(e) => handleWishlistChange(e,book)}
                />
                )}
                
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
