import React from "react";
import classes from "./Review.module.css";

import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";

const person = "/Assets/Icons/person2.svg";

const Review = ({ review }) => {
  return (
    <div className={classes.reviewContainer}>
      <img
        src={review?.photoUrl || person}
        alt={review?.userName}
        className={classes.profilePicture}
      />
      <div>
        <h3>{review?.userName}</h3>
        <Stack spacing={1}>
          <Rating
            name="half-rating"
            value={review?.review?.rating}
            precision={1}
            readOnly
            classes={{
              iconFilled: classes.iconFilled,
              iconHover: classes.iconHover,
            }}
            sx={{
              "& .MuiRating-iconFilled": { color: "#FCC418" }, // Color for filled stars
              "& .MuiRating-iconEmpty": { color: "gray" }, // Color for empty stars
            }}
          />
        </Stack>
        <p className={classes.reviewText}>{review?.review?.comment}</p>
      </div>
    </div>
  );
};

export default Review;
