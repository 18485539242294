import React from "react";
import classes from "./PrimaryButton.module.css";
import { getClasses } from "../../../Util";

function PrimaryButton({ children, onClick, className, toolTip,disabled=false }) {
  return (
    <button
      className={getClasses(className, classes.primaryButton, disabled === true ?  classes.disabled : "")}
      onClick={onClick}
      title={toolTip}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default PrimaryButton;
