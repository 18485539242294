import classes from "./AddLogo.module.css";
import { useState, useEffect, useContext } from "react";
import { useFabricCanvas } from "react-fabric-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faPlusCircle, faXmark } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../Components/LogoModal/LogoModal";
import useScreenSize from "../../../../Hooks/UseScreenSize";
import ToolTip from "../../../../Components/ToolTip/ToolTip";
import { CustomizationContext } from "../../Customization";
import ColorSelector from "../../Components/ColorSelector/ColorSelector";
import { fabric } from "react-fabric-page";
import { useSelector } from "react-redux";

const fallbackImage = "/images/placeholder.png"

const AddLogo = ({ onClick, toggleDropdown, dropdownOpen, currentDropdown }) => {
  const customizationPricing = useSelector((state) => state.customizationPricing.prices?.[0]);
  const {frontCoverLogo,frontCoverLogoColor,setFrontCoverLogoColor,allFoilingColors,setFrontCoverLogo} = useContext(CustomizationContext);
  const canvas = useFabricCanvas();

  const [colors,setColors] = useState([])

    useEffect(() => {
    const allowedColors = allFoilingColors?.filter(
      (color) => color.disabled === false
    );
    setColors(allowedColors);
  }, [allFoilingColors]);

  useEffect(() => {
    setFrontCoverLogoColor(colors?.[0])
  },[colors])


  const { isMediumScreen } = useScreenSize()

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const handleClickAndSelect = () => {
    onClick();
    if (dropdownOpen && currentDropdown === 'All Logo') {
      toggleDropdown(false)
    }
    else {
      toggleDropdown(true)
    }
  };

  const handleColorChange = (color,e) => {
    e.stopPropagation()
    setFrontCoverLogoColor(color);
  }


  const changeLogoColor = () => {
    if (canvas) {
      const layer = canvas?.getObjects()?.find((o) => o.id === "contents");
      if (layer) {
        const activeObject = layer?._objects?.filter(
          (o) => o.type === "imageBox"
        )?.[0];
        if (activeObject && colors) {
          activeObject.filters[0] = new fabric.Image.filters.PatternColor({
            color: frontCoverLogoColor?.colorValue,
            convert: true,
          });
          activeObject.applyFilters();
          layer.dirty = true;
        }
      }
      canvas.renderAll();
    }
  };

  const handleRemoveLogo = () => {
    const layer = canvas?.getObjects().find(o => o.id === 'contents');
    if (layer) {
        const imageObject = layer._objects.find(obj => obj.type === 'imageBox');
        if (imageObject) {
            imageObject.filters = [];
            imageObject.updateSrc(fallbackImage).then(e => {
              layer.dirty = true;
              canvas.renderAll();
              setFrontCoverLogo(null)
            });
        }

    }
};

  useEffect(() => {
    changeLogoColor()
  },[frontCoverLogoColor,frontCoverLogo])


  return (
    <div>
      <Modal onClose={handleCloseModal} isOpen={isModalOpen} />
      {isMediumScreen ? (
        <div className={classes.colorPickerDropdown} onClick={(e) => e.stopPropagation()}>
          <h3>Upload your personalized design for Foiling.</h3>

          <div className={classes.uploadLogo}>
            {/* <div className={classes.uploadBtn}>
                                <input type="file" id="photoUpload" accept="image/*" onChange={(e) => handlePhotoUpload(e)} title="Select Photos" className={classes.photoUpload} />
                                <label htmlFor="photoUpload" className={classes.photoUploadButton}>Upload Photo</label>
                            </div> */}
            <div className={classes.uploadBtn} onClick={handleOpenModal}>
              <div className={classes.addLogo}>
                <FontAwesomeIcon icon={faPlusCircle} className={classes.addLogoIcon} />
                <p>Upload your unique design!</p>
              </div>
              <div className={classes.logoPrice}>
                +${customizationPricing?.logoFrontPrice}
              </div>
            </div>

            <div className={classes.logoColor}>
              <p>Pick Your Preferred Foil Color!</p>
              <ColorSelector availableColors={colors} selectedColor={frontCoverLogoColor} handleColorChange={handleColorChange} />
            </div>

          </div>
          
        </div>
      ) : (
        <div className={classes.ColorOption} onClick={() => handleClickAndSelect()}>
          <div className={classes.colorHeader}>
            <div className={classes.headerText}>
                <h2>Unique Design</h2>
                <ToolTip tooltipText={"Add Custom logo on front cover"} />
            </div>
            <div className={classes.dropDownImage} onClick={toggleDropdown}>
              {dropdownOpen && currentDropdown === "Add Text" ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </div>

          {dropdownOpen && currentDropdown === "Unique Design" ? (
        <div className={classes.colorPickerDropdown} onClick={(e) => e.stopPropagation()}>
        <h3>Please upload a line art of your logo</h3>

        <div className={classes.uploadLogo}>
      
          <div className={classes.uploadBtn} onClick={handleOpenModal}>
            <div className={classes.addLogo}>
              <FontAwesomeIcon icon={faPlusCircle} className={classes.addLogoIcon} />
              <p>Upload your unique design!</p>
            </div>
            <div className={classes.logoPrice}>
              +${customizationPricing?.logoFrontPrice}
            </div>
          </div>

          {frontCoverLogo && <div className={classes.uploadedImage}>
              <img src={frontCoverLogo} alt="" />
              <FontAwesomeIcon className={classes.removeIcon} icon={faXmark} onClick={handleRemoveLogo} />
          </div>}

          <div className={classes.logoColor}>
            <p>Select Logo Color for front color</p>
            <ColorSelector availableColors={colors} selectedColor={frontCoverLogoColor} handleColorChange={handleColorChange} />
          </div>

        </div>
        
      </div>
          ) : (
            <div></div>
          )}
        </div>

      )}

    </div>
  );
};

export default AddLogo;
