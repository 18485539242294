import React, { useState } from "react";
import { getClasses } from "../../../Util";
import classes from "./PrimaryNav.module.css";
import NavDropdown from "../NavDropdown/NavDropdown";
import useScreenSize from "../../../Hooks/UseScreenSize";
import ItemsNav from "../ItemsNav/ItemsNav";
import CollapsedItems from "./CollapsedItems";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import { fetchBook,fetchBookCount } from "../../../Store/actions/BookAction";
import { urls } from "../../../Routes/Urls";
import MiniCart from "../../MyCart/MiniCart/MiniCart";
const personIcon = "/Assets/Icons/person-icon.svg";
const CloseIcon = "/Assets/Icons/icon-x-grey.svg";
const menuIcon = "/Assets/Icons/icon-bars.svg";
const logoImg = "/Assets/Icons/logo.svg";
const searchIcon = "/Assets/Icons/icon-search.svg";

function PrimaryNav() {
  const { isLargeScreen } = useScreenSize();
  const [isNavBarActive, setIsNavbarActive] = useState(false);
  const [query,setQuery] = useState("");

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);

  const history = useHistory();


  const handleSearch = async (e) => {
    if(e.key === "Enter" || e.type === "click"){
      if (query === "") return;

      const searchCriteria = "all";
  
      const searchResults = await fetchBook(searchCriteria, query, dispatch);
      const searchCount = await fetchBookCount(searchCriteria, query);
  
      // Redirect to LISTING page with search results, search criteria, and query as state
      history.push({
          pathname: urls.LISTING,
          state: { 
              searchResults, 
              searchCount,
              searchCriteria,
              query
          }
      });
    }
  };

  const loginRedirect = () => {
    history.push(history.location?.state?.from || "/login");
  };

  return (
    <nav className={classes.main}>
      <div className={classes.container}>
        <div className={classes.brand}>
          <div className={classes.brandLogo} onClick={() => history.push("/")}>
            <img src={logoImg} alt="logo" />
          </div>
        </div>

        <div className={classes.nav}>
          <ul className={classes.navItems}>
            {!isLargeScreen  && <ItemsNav />}

            <li key={"searchBar"}>
              <div className={classes.searchBar}>
                <div className={classes.navSearchIcon} onClick={e => handleSearch(e)}>
                  <img src={searchIcon} alt="Search" />
                </div>
                <input
                  type="text"
                  placeholder="Search..."
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  className={classes.searchInput}
                  onKeyDown={e => handleSearch(e)}
                />
              </div>
            </li>
            
            {!isLargeScreen  && <CollapsedItems />}
            {!isLargeScreen  && currentUser.userId ? (
              <li key={"userAccount"}>
                <NavDropdown
                  options={[
                    { label: "My Account" },
                    { label: "Logout" },
                  ]}
                >
                  <div className={classes.userImage}>
                    <img src={currentUser.photoURL || personIcon} alt="" />
                  </div>
                </NavDropdown>
              </li>
            ) : (
              !isLargeScreen  && (
                <li key={"login/signUp"}>
                  <SecondaryButton
                    className={classes.navItemBtn}
                    onClick={loginRedirect}
                  >
                    Login/ SignUp
                  </SecondaryButton>
                </li>
              )
            )}
            {isLargeScreen  && (
              <li key="hamburger">
                <div
                  className={classes.navItem}
                  onClick={() => setIsNavbarActive(true)}
                >
                  <img src={menuIcon} alt="menu" />
                </div>
              </li>
            )}
          </ul>

          {isLargeScreen  && (
            <div
              className={getClasses(
                classes.offCanvas,
                isNavBarActive ? classes.activeOffCanvas : ""
              )}
            >
              <div className={classes.offCanvasContent}>
                <ul className={classes.collapsedItems}>
                  <CollapsedItems />
                </ul>

                <ItemsNav />
                <div className={classes.userProfile}>
                  {currentUser.fullName ? (
                    <NavDropdown
                      options={[
                        { label: "My Account" },
                        { label: "Logout" },
                      ]}
                    >
                      <div className={classes.userImage}>
                        <img
                          src={currentUser.photoURL || personIcon}
                          alt=""
                        />
                      </div>
                      <div className={classes.userName}>
                        {currentUser.fullName}
                      </div>
                    </NavDropdown>
                  ) : (
                    <SecondaryButton
                      className={classes.navItemBtnSmall}
                      onClick={loginRedirect}
                    >
                      Login/SignUp
                    </SecondaryButton>
                  )}
                </div>

                <div className={classes.closeNavBar}>
                  <div className={classes.brandName}>
                    <img src={logoImg} alt="RBLogo" onClick={() => history.push(urls.HOME)}/>
                  </div>
                  <div
                    className={classes.navItem}
                    onClick={() => setIsNavbarActive(false)}
                  >
                    <img src={CloseIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MiniCart/>

    </nav>
  );
}

export default PrimaryNav;
