import React from 'react';
import classes from './Card2.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as filledHeart} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { addWishlistItem, deleteWishlistItem } from '../../Store/actions/WishlistAction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { urls } from '../../Routes/Urls';

const Card = ({ image, title, author, price,bookData,altText,onClick,isBestSeller=true,isWishListItem = false,wishlistItemId }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const currentUser = useSelector((state) => state.user.currentUser )

  const handleAddWishlist = (e) => {
    e.stopPropagation()
    if(currentUser?.id){
      addWishlistItem(bookData,1,dispatch,true)
    }else{
      history.push(urls.LOGIN);
    }
  }

  const handleRedirect = () => {
    if(isBestSeller) {
      history.push({
        pathname: `${urls.BESTSELLERPDP}/${bookData.id}`,
        state: { bookData }
    });
    } else {
      history.push({
        pathname: `${urls.PRODUCTDETAIL}/${bookData.id}`,
        state: { bookData }
    });
    }
}
const handleRemove = (e) => {
  e.stopPropagation()
  deleteWishlistItem(wishlistItemId, dispatch);
};


  return (
    <div className={classes.card} onClick={handleRedirect}>
      <div className={classes.imageContainer} > 
        <img src={image} alt={altText} className={classes.image} />
        <button className={classes.heartButton} onClick={e => e.stopPropagation()}>
        {isWishListItem ? (
          <FontAwesomeIcon className={classes.filledHeart} icon={filledHeart} onClick={(e) => handleRemove(e)}/>
        ) : (
          <FontAwesomeIcon icon={faHeart} className={classes.buttonText} onClick={handleAddWishlist}/>
        )}
          
          </button>
      </div>
      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.titleContainer}>
            <h3 className={classes.title} title={title}>{title}</h3>
            <p className={classes.author} title={author}>{author}</p>
          </div>
        </div>
        <div className={classes.bottomRow}>
          <p className={classes.price}>${price}</p>
        </div>
        <div className={classes.addToCartButton}>
            <button>Preview</button>
        </div>
      </div>
    </div>
  );
};

export default Card;
