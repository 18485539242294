import React from "react";
import classes from "./SecondaryButton.module.css";
import { getClasses } from "../../../Util";

function SecondaryButton({ children, onClick, className,disabled=false }) {
  return (
    <button
      className={getClasses(className, classes.secondaryButton,disabled ? classes.disabled : "")}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default SecondaryButton;
