import { useState } from "react"
import Modal, { ModalBody } from "../../../Components/Modal/Modal"
import classes from "./LeatherType.module.css"

const image = "/Assets/Images/book5.svg"

const hexagon = "/Assets/Icons/hexagon.svg"

const tabsData = [
    {
        name: "Genuine Leather",
        value: "realLeather"
    },
    {
        name: "Vegan Leather",
        value: "veganLeather"
    }
]

const tabContent = [
    {
        type : "realLeather",
        value : "Crafted from the finest full-grain goatskin leather, this premium material is renowned for its rarity, exceptional durability, and luxurious texture. Difficult and costly to source, it transforms your book into a true collector’s piece, symbolizing refined taste and passion for literature. Perfect for discerning book enthusiasts, this elegant option not only enhances the aesthetic appeal of your books but also stands the test of time, making it a cherished addition to any library.", 
        features : ["Exceptional Durability & Timeless Elegance","Rare & Premium Material","Aesthetic and Collectible Appeal"]
    },
    {
        type : "veganLeather",
        value : "Choose our Vegan Leather option for a stylish, cruelty-free alternative that beautifully mimics the look and feel of genuine leather. Made from high-quality polyurethane (PU), this material exudes sophistication while being environmentally friendly. Ideal for conscious consumers, Vegan Leather not only offers durability and easy maintenance but also provides an elegant aesthetic that rivals traditional leather. Perfect for modern book lovers seeking a sustainable choice without sacrificing luxury, our Vegan Leather ensures your books maintain a high-end appearance while supporting ethical practices.",
        features : ["Eco-Friendly Composition","Luxurious Aesthetic","Durable and Low Maintenance"]
    }
]

const LeatherType = ({ onClose }) => {
    const [activeTab, setActiveTab] = useState(tabsData[0])
    return (
        <Modal title={"Know about leather type"} onClose={onClose}>
            <ModalBody>
                <div style={{width:"50vw",padding: "2.5rem 0",borderTop:"1px solid #D0D4D7"}} className={classes.container}>
                    <div className={classes.tabs}>
                        {tabsData.map((tab) => (
                            <div className={`${classes.tab} ${activeTab.value === tab.value ? classes.tabActive : ""}`} onClick={() => setActiveTab(tab)}>
                                {tab.name}
                            </div>
                        ))}
                    </div>

                    <div className={classes.detailSection}>
                        <div className={classes.image}>
                            <img src={image} alt="" />
                        </div>

                        <div className={classes.details}>
                            <h2>{activeTab.name}</h2>
                            <p>
                                {tabContent.find((content) => content.type === activeTab.value).value}
                            </p>
                        </div>
                    </div>

                    <div className={classes.featureSection}>
                    {tabContent.find((content) => content.type === activeTab.value)?.features.map((content) => (
                        <div className={classes.features}>
                            <div className={classes.image}>
                                <img src={hexagon} alt="" />
                            </div>
                            <div className={classes.description}>
                                <h2>{content}</h2>
                                {/* <p>Sagittis pulvinar non convallis in amet libero mattis nulla duis molestie ut nibh viverra lorem.</p> */}
                            </div>
                        </div>
                    ))}
                        
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default LeatherType;