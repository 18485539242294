import { createSlice } from "@reduxjs/toolkit";

const KeywordsSlice = createSlice({
    name:"keywords",
    initialState : {
        keywords: []
    },
    reducers : {
        updateKeywords(state,action){
            state.keywords = action.payload
        }
    }

});

export const KeywordsAction = KeywordsSlice.actions
export default KeywordsSlice.reducer