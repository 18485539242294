import React, { useEffect } from 'react';
import classes from "./SearchComponent.module.css"

import { getKeywords } from '../../../Store/actions/BookAction';
import { useDispatch} from 'react-redux';
import { fetchAllBlogs,fetchBlogs } from '../../../Store/actions/BlogsAction';

const SearchComponent = ({setBlogdata,searchQuery,setSearchQuery}) => {

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const dispatch = useDispatch()
  const handleSearch = () => {
    // const filter = `${searchCriteria}:${query}`;
    if(searchQuery !== ""){
      fetchBlogs(searchQuery,1, dispatch).then(setBlogdata)
    }
    else{
      fetchAllBlogs(dispatch)
      setBlogdata(null)
    }
  };

  useEffect(() => {
    getKeywords(dispatch);
  },[])


  return (
    <div className={classes.search}>
        {/* <div className={classes.searchBar}>
          <input type="text" placeholder="Search via author, keywords, book name, ISBN" />
          <button>Search Now</button>
        </div> */}
           <div className={classes.searchContainer}>

      {/* <FaSearch className={classes.searchIcon} /> */}
      
      <input
        type="text"
        className={classes.searchInput}
        placeholder="Search Blogs"
        value={searchQuery}
        onChange={handleInputChange}
      />
      <button className={classes.searchButton} onClick={handleSearch}>
        Search Now
      </button>
    </div>
       
        </div>
  );
};

export default SearchComponent;
