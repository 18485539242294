import React, { useEffect, useState } from 'react';
import classes from './SearchBarSmall.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter } from '@fortawesome/free-solid-svg-icons';
import { fetchBook,fetchBookCount, getSuggestionsForNormalBooks } from '../../../../Store/actions/BookAction';
import { useDispatch, useSelector } from 'react-redux';
import { urls } from '../../../../Routes/Urls';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { faSearchengin } from '@fortawesome/free-brands-svg-icons';

const SearchBarSmall = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [query,setQuery] = useState("")
  const [filter,setFilter] = useState("all")


  const history = useHistory()
  const toggleDropdown = () => {
    setIsDropdownVisible(prevState => !prevState);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
};

  const dispatch = useDispatch();
  const handleSearch = async () => {
    if (query === "") return;

    const searchResults = await fetchBook(filter, query, dispatch);
    const searchCount = await fetchBookCount(filter,query);

    // Redirect to LISTING page with search results as state
    history.push({
      pathname: urls.LISTING,
      state: { 
          searchResults, 
          searchCount,
          filter,
          query
      }
  });
  };

  const handleKeyPress = (e) => {
    if(e.key === "Enter"){
        handleSearch()
    } else {
      const disallowedCharacters = ["(", "{", "[","]","}",")"];
      if (disallowedCharacters.includes(e.key)) {
        e.preventDefault();
      }
    }
} 

const handleInputPaste = (e) => {
  const pastedText = e.clipboardData.getData("text");
  const sanitizedText = pastedText.replace(/[\(\)\{\}\[\]]/g, ""); // Remove disallowed characters
  e.preventDefault(); // Prevent the default paste action
  setQuery({ target: { value: query + sanitizedText } }); // Append sanitized text to the current query
};   

  const [allRecommendations,setAllRecommendations] = useState([])

  const [searchRecommendations,setSearchRecommedations] = useState([]);
  const [recommendationClicked,setRecommendationClicked] = useState(false);
  const [focus,setFocus] = useState(false);
  const [blurTimeout, setBlurTimeout] = useState(null);

  const handleRecommendationClick = (recommendation) => {
    const words = query.trim().split(" ");
    if (words.length > 1) {
      words[words.length - 1] = recommendation;
      setQuery(words.join(" "));
    } else {
      setQuery(recommendation);
    }
    setSearchRecommedations([]);
    setRecommendationClicked(true);
  };
  
  useEffect(() => {
    if(recommendationClicked) {
      handleSearch();
      setRecommendationClicked(false);
    }
  },[recommendationClicked])
  
  useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => {
        fetchPageData();
      },500);
    
       return () => clearTimeout(timeout);
    }
  }, [query,filter]);

  useEffect(() => {
      if (query) {
        const words = query.trim().split(/\s+/);
        setSearchRecommedations(
          allRecommendations
        );
      } else {
        setSearchRecommedations([]);
      }
  }, [query, allRecommendations]);

  const handleFocus = () => {
    if (blurTimeout) {
      clearTimeout(blurTimeout); 
    }
    setFocus(true);
  };
  
  const handleBlur = () => {
    const timeout = setTimeout(() => setFocus(false),1000);
    setBlurTimeout(timeout);
  };

  const fetchPageData = async () => {
    if (query !== "") {
        const data = await fetchBook(
          filter,
          query,
          null,
          10,
          1,
          null
        );

        const filteredRecommendations = data.data.map((book) => {
          if (book?.author?.toLowerCase().includes(query.toLowerCase())) {
            return book.author
          } else if (book?.title?.toLowerCase().includes(query.toLowerCase())) {
            return book.title;
          }
          return null;
        }).filter((item) => item !== null); // Remove null values
    
        setAllRecommendations(filteredRecommendations);

    }
  };


  return (
    <div className="container">
      <div className={classes.mainContainer}>
        <div className={classes.searchIcon}>
          <FontAwesomeIcon icon={faMagnifyingGlass} onClick={handleSearch}/>
          <input 
            type="text" 
            placeholder="Search Books" 
            value={query} 
            onChange={handleInputChange} 
            onKeyDown={(e) => handleKeyPress(e)} 
            onFocus={handleFocus}
            onBlur={handleBlur}
            onPaste={handleInputPaste}
          />
        </div>
        <div className={classes.filter} onClick={toggleDropdown}>
          <FontAwesomeIcon icon={faFilter} />
          {isDropdownVisible && (
            <div className={classes.dropdownli}>
              <ul>
                  <li value="all" onClick={() => setFilter("all")}>All</li>
                  <li value="author" onClick={() => setFilter("author")}>Author</li>
                  <li value="title" onClick={() => setFilter("title")}>Book Title</li>
              </ul>
            </div>
          )}
        </div>

      </div>
      {/* Dropdown with Recommendations */}
      {searchRecommendations.length > 0 && focus && (
        <div className={classes.dropdown}>
        {searchRecommendations.slice(0,10).map((recommendation, index) => (
          <div
            key={index}
            className={classes.dropdownItem}
            onClick={() => handleRecommendationClick(recommendation)}
          >
            <FontAwesomeIcon icon={faSearchengin}/>
            {recommendation}
          </div>
        ))}
        </div>
      )}
    </div>
  );
};

export default SearchBarSmall;
