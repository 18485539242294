import classes from "./ProductDetails.module.css"
import CounterButton from "../../../../Components/Buttons/CounterButton/CounterButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight} from "@fortawesome/free-solid-svg-icons"
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton"
import { useContext, useEffect, useState } from "react"
import { getBookPrice } from "../../../../Util"
import CustomizationDetails from "../CustomizationDetails/CustomizationDetails"
import { CustomizationContext } from "../../Customization"
import { addCartItem } from "../../../../Store/actions/CartAction"
import { useDispatch } from "react-redux"
import { calculateCustomizationCharges } from "../../../../Store/actions/CustomizationAction"
import MessageDialog from "../../../../Components/MessageDialog/MessageDialog"
import { urls } from "../../../../Routes/Urls"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const ProductDetails = ({bookData,variantType,leatherType}) => {
    const dispatch  = useDispatch();
    const history = useHistory();
    const {bookQty,setBookQty,bookCustomization,setCustomizationCharges,customizationCharges,setDesignSaveInitiated,designSaveCompleted,setDesignSaveCompleted} = useContext(CustomizationContext)
    const [showDetailModal,setShowDetailModal] = useState(false);
    const [showMessageDialog,setShowMessageDialog] = useState(false);
    const incrementCount = () => {
        setBookQty(bookQty+1)
    }

    const decrementCount = () => {
        if(bookQty === 1){
            return
        }
        setBookQty(bookQty-1)
    }

    const handleAddCart = () => {
        if(bookCustomization) {
            setShowMessageDialog(true);
            // addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization)
        }
    }

    const handleDetailsClick = async () => {
        setShowDetailModal(true);
        const res = await calculateCustomizationCharges(bookCustomization);
        setCustomizationCharges(res.data.data)
    }

    const handleMessageModalClose = () => {
        if(bookCustomization) {
            setShowMessageDialog(false);
            addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization);
            history.push(urls.CART)
        }
    }

    const handleMessageModalAccept = () => {
        if(bookCustomization) {
            setDesignSaveInitiated(true);
        }

        setTimeout(() => setDesignSaveCompleted(false),10000)
    }


    useEffect(() => {
        if(designSaveCompleted && showMessageDialog) {
            addCartItem(bookData,bookQty,bookCustomization?.variantType,bookCustomization?.leatherType,bookCustomization?.leatherColor,dispatch,bookCustomization);
            history.push(urls.CART)
            setShowMessageDialog(false);
        }
    },[designSaveCompleted])

    return (
      <div className={classes.productDetails}>
        <div className={classes.productHeaders}>
          <div>
            <h2>{bookData?.title}</h2>
            <p>{bookData?.author}</p>
          </div>
          <div className={classes.price}>
            $
            {(
              getBookPrice(bookData, leatherType, variantType) * bookQty +
              (customizationCharges?.totalCharges
                ? customizationCharges.totalCharges * bookQty
                : 0)
            ).toFixed(2)}
          </div>
        </div>
        <div className={classes.cartDetails}>
          <p onClick={handleDetailsClick}>
            View details <FontAwesomeIcon icon={faAngleRight} />
          </p>
          <div className={classes.buttonContainer}>
            <CounterButton
              count={bookQty}
              incrementer={incrementCount}
              decrementer={decrementCount}
            />
            <SecondaryButton
              className={classes.cartBtn}
              onClick={handleAddCart}
            >
              Add to cart
            </SecondaryButton>
          </div>
        </div>

        {showDetailModal && (
          <CustomizationDetails onClose={() => setShowDetailModal(false)} />
        )}
         {showMessageDialog && (
        <MessageDialog
          message={"Would you like to save this design for future use? Select 'Save' to keep it accessible anytime."}
          onCancel={handleMessageModalClose}
          isCancel
          title={"Save Customization Design"}
          closeText="Don't Save and Add to Cart"
          acceptBtnText="Save and Add To Cart"
          onAccept={handleMessageModalAccept}
        />
      )}
      </div>
    );
}

export default ProductDetails;