import React, { useState } from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import classes from './BookCustomization.module.css';
import variantFeatures from "./VariantFeatures.json"

const image = '/Assets/Images/customization_of_book.svg';

const sections = [
  {
    value:"Deluxe",
    title: "Deluxe Edition: A Blend of Marble Paper Sophistication and Leather Elegance",
    desc: "Experience timeless elegance with our Deluxe Variant—crafted to captivate with half-leather binding and vibrant marble papers."
  },
  {
    value:"Super Deluxe",
    title: "Super Deluxe Edition: Indulge in Ultimate Reading Luxury",
    desc: "You will appreciate our Super Deluxe Edition if you enjoy the ultimate level of luxury. It's the ideal balance of luxury and design, created to make reading an amazing experience. See what makes the Super Deluxe Edition unique by reading on:"
  },
];

const BookCustomization = () => {
  const [activeTab, setActiveTab] = useState(sections[0]);

  return (
    <section className={classes.bookCustomization}>
      <div className={classes.header}>
        <h2>
          Our Special Leather Book Variants

        </h2>
        <p>Choose between our Super Deluxe and Deluxe bindings, each offering unique features and exquisite craftsmanship.
        </p>
        <div className={classes.customizationTypes}>
          <div className={classes.buttonGroup}>
            <button
              className={`${classes.button} ${activeTab.value === "Super Deluxe" ? classes.claret : classes.outline}`}
              onClick={() => setActiveTab(sections[1])}
            >
              Super Deluxe Edition
            </button>
            <button
              className={`${classes.button} ${activeTab.value === "Deluxe" ? classes.claret : classes.outline}`}
              onClick={() => setActiveTab(sections[0])}
            >
              Deluxe Edition
            </button>
          </div>
        </div>
      </div>
      <div className={classes.deluxeVariant}>
        <div className={classes.imageContainer}>
          <img src={image} alt="Reading" className={classes.image} />
        </div>
        <div className={classes.content}>
          <h2>{activeTab.title}</h2>
          <p>{activeTab.desc}</p>
          <div className={classes.titles}>
            {variantFeatures[activeTab.value].map((data,index) => (
              <div key={index} className={classes.title}>
                <h3>{data.title}</h3>
                <p>{data.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className={classes.customizationOptions}>
        <div className={classes.container}>
          <h2 className={classes.heading}>Why super deluxe customization</h2>
          <p className={classes.description}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <Slider {...settings} className={classes.carousel}>
            {features.map((feature, index) => (
              <div className={classes.cardContainer} key={index}>
                <div className={classes.card}>
                  <img src={require(`../../../../Assets/Images/${feature.image}`)} alt={feature.title} className={classes.cardImage} />
                  <div className={classes.text}>
                    <h3 className={classes.cardTitle}>{feature.title}</h3>
                    <p className={classes.cardDescription}>{feature.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div> */}
    </section>
  );
};

export default BookCustomization;
