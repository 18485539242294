import React, { useState, useRef, useContext, useEffect } from 'react';
import classes from './SwipeableEdgeDrawer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faBars } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../Sidebar/Sidebar';
import { CustomizationContext } from '../../Customization';
import ToolTip from '../../../../Components/ToolTip/ToolTip';
import { calculateCustomizationCharges } from '../../../../Store/actions/CustomizationAction';

const frontCoverIcon = "/Assets/Icons/customization/frontCoverIcon.svg";
const spineIcon = "/Assets/Icons/customization/spineIcon.svg";
const gildingIcon = "/Assets/Icons/customization/gildingIcon.svg";
const endpaperIcon = "/Assets/Icons/customization/endpaperIcon.svg";
const additionalRequestIcon = "/Assets/Icons/customization/additionalRequestIcon.svg";

const leatherColorIcon = "/Assets/Icons/customization/bookColorIcon.svg";
const outerBorderIcon = "/Assets/Icons/customization/outerBorderIcon.svg";
const innerBorderIcon = "/Assets/Icons/customization/innerBorderIcon.svg";
const addTextIcon = "/Assets/Icons/customization/addTextIcon.svg";
const addLogoIcon = "/Assets/Icons/customization/addLogoIcon.svg";

function SwipeableEdgeDrawer({
  open,
  setOpen,
  Component,
  SecondaryComponent,
  visibilitySecondary,
  onNext,
  onPrev,
  activeSectionNumber,
  numberOfSections,
  setActiveSection
}) {
  const [nestedDrawerOpen, setNestedDrawerOpen] = useState(false);
  const { steps, setSteps, selectedOption, setSelectedOption, bookVariant,setCustomizationCharges,bookCustomization } = useContext(CustomizationContext);

  const startYRef = useRef(0);
  const touchTargetRef = useRef(null);
  const touchHandledRef = useRef(false);

  const smallScreenSteps = [
    {
      option: "Front Cover",
      number: "01",
      step: 1,
      icon: frontCoverIcon,
      options: bookVariant === "deluxe"
        ? [
          { name: "Leather Color", icon: leatherColorIcon },
          { name: "Marble Paper", icon: additionalRequestIcon },
          { name: "Outer Border", icon: outerBorderIcon },
          { name: "Inner Border", icon: innerBorderIcon },
        ]
        : [
          { name: "Leather Color", icon: leatherColorIcon },
          { name: "Outer Border", icon: outerBorderIcon },
          { name: "Inner Border", icon: innerBorderIcon },
          { name: "Text Boxes", icon: addTextIcon },
          { name: "Unique Design", icon: addLogoIcon },
        ]
    },
    {
      option: "Spine",
      number: "02",
      step: 2,
      icon: spineIcon,
      options: [
        { name: "RibDesign", icon: spineIcon },
        { name: "Choose Spine color", icon: frontCoverIcon },
        { name: "Add Text/Logo", icon: additionalRequestIcon },
      ],
    },
    {
      option: "Paper",
      number: "03",
      step: 3,
      icon: gildingIcon,
      options: [{ name: "Gilding", icon: gildingIcon }],
    },
    {
      option: "Endpaper",
      number: "04",
      step: 4,
      icon: endpaperIcon,
      options: [
        { name: "Endpaper", icon: endpaperIcon },
        { name: "Dedication Page", icon: gildingIcon }
      ],
    },
    {
      option: "Additional Request",
      number: "05",
      step: 5,
      icon: additionalRequestIcon,
      options: [],
    },
  ];

  const handleTouchStart = (event) => {
    startYRef.current = event.touches[0].clientY;
    touchTargetRef.current = event.target;
  };

  const handleTouchMove = (event, drawerType) => {
    event.stopPropagation();
    if (event.touches.length > 1) return;

    const currentY = event.touches[0].clientY;
    const diffY = startYRef.current - currentY;

    if (drawerType === 'primary') {
      if (diffY > 10 && !open) setOpen(true);
      if (diffY < -10 && open) setOpen(false);
    } else if (drawerType === 'nested') {
      if (diffY > 10 && !nestedDrawerOpen) {
        setNestedDrawerOpen(true);
        touchHandledRef.current = true;
      }
      if (diffY < -10 && nestedDrawerOpen) {
        setNestedDrawerOpen(false);
        touchHandledRef.current = true;
      }
    }
  };

  const handleTouchEnd = () => {
    if (touchHandledRef.current) {
      touchHandledRef.current = false;
      return;
    }
    startYRef.current = 0;
    touchTargetRef.current = null;
  };

  const handleToggleNestedDrawer = () => {
    if (touchHandledRef.current) return;
    setNestedDrawerOpen((prev) => !prev);
  };

  const handlePrevOption = () => {
    const { step, optionIndex } = getCurrentStepAndOption();
    if (step && optionIndex > 0) {
      setSelectedOption(step.options[optionIndex - 1].name);
    } else if (step && optionIndex <= 0) {
      const currentStepIndex = smallScreenSteps.findIndex(s => s.option === step.option);
      const prevStep = smallScreenSteps[currentStepIndex - 1];
      if (prevStep && prevStep.options.length > 0) {
        setSteps(steps-1)
        setSelectedOption(prevStep.options[0].name);
      }
    } else if (!step && optionIndex === -1) {
      const prevStep = smallScreenSteps[3];
      setSteps(steps-1);
      setSelectedOption(prevStep.options[0].name);
    }
  };

  const getCurrentStepAndOption = () => {
    for (let step of smallScreenSteps) {
      const optionIndex = step.options.findIndex(option => option.name === selectedOption);
      if (optionIndex !== -1) return { step, optionIndex };
    }
    return { step: null, optionIndex: -1 };
  };

  const handleNextOption = () => {
    const { step, optionIndex } = getCurrentStepAndOption();
    if (step && optionIndex < step.options.length - 1) {
      setSelectedOption(step.options[optionIndex + 1].name);
    } else if (step && optionIndex >= step.options.length - 1) {
      const currentStepIndex = smallScreenSteps.findIndex(s => s.option === step.option);
      const nextStep = smallScreenSteps[currentStepIndex + 1];
  
      if (nextStep ) {
        if (nextStep.options.length > 0) {
          setSteps(steps+1)
          setSelectedOption(nextStep.options[0].name);
        } else {
          setSteps(steps+1)
          setSelectedOption(nextStep.option);
        }
      }
    }
  };

  const getTitleText = () => {
    const { step, optionIndex } = getCurrentStepAndOption();
    if (step && optionIndex !== -1) {
      return { optionText: selectedOption, stepInfo: `${optionIndex + 1}/${step.options.length}` };
    }
    return { optionText: selectedOption, stepInfo: '' };
  };

  useEffect(() => {
      async function calculateCustomizationPrice() {
        const res = await calculateCustomizationCharges(bookCustomization);
        setCustomizationCharges(res.data.data)
      }

      calculateCustomizationPrice()
  },[steps])

  return (
    <div className={classes.container}>
      <div className={open ? classes.toggleClosed : classes.toggleButton}>
        <div
          className={classes.drawerHeader}
          onClick={() => setOpen(!open)}
          onTouchStart={handleTouchStart}
          onTouchMove={(e) => handleTouchMove(e, "primary")}
          onTouchEnd={handleTouchEnd}
        >
          <div className={classes.puller} />
        </div>
      </div>
      <div
        className={`${classes.drawer} ${open ? classes.open : ""}`}
        onTouchStart={handleTouchStart}
        onTouchMove={(e) => handleTouchMove(e, "primary")}
        onTouchEnd={handleTouchEnd}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.puller} />
        </div>
        <div className={classes.drawerContent}>
          <div className={classes.drawerTitle}>
            <FontAwesomeIcon
              icon={faBars}
              className={classes.hamburgerIcon}
              onClick={handleToggleNestedDrawer}
            />
            <div className={classes.titleText}>
              <FontAwesomeIcon onClick={handlePrevOption} icon={faAngleLeft} />
              {getTitleText().optionText && <h2>{getTitleText().optionText}</h2>}
              {getTitleText().stepInfo && <p>({getTitleText().stepInfo})</p>}
              <FontAwesomeIcon onClick={handleNextOption} icon={faAngleRight} />
            </div>
            <ToolTip tooltipText={"Sample Text Goes Here"} />
          </div>
          <div className={visibilitySecondary ? classes.inVisible : ""}>
            <Component
              onNext={onNext}
              onPrev={onPrev}
              activeSectionNumber={activeSectionNumber}
              numberOfSections={numberOfSections}
              setActiveSection={setActiveSection}
            />
          </div>
          <div className={!visibilitySecondary ? classes.inVisible : ""}>
            {SecondaryComponent && (
              <SecondaryComponent
                onNext={onNext}
                onPrev={onPrev}
                activeSectionNumber={activeSectionNumber}
                numberOfSections={numberOfSections}
                setActiveSection={setActiveSection}
              />
            )}
          </div>
        </div>
      </div>
      {nestedDrawerOpen && (
        <div
          className={`${classes.nestedDrawer} ${nestedDrawerOpen ? classes.nestedDrawerOpen : ""}`}
          onTouchStart={handleTouchStart}
          onTouchMove={(e) => handleTouchMove(e, "nested")}
          onTouchEnd={handleTouchEnd}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={classes.nestedDrawerPuller} onClick={(e) => e.stopPropagation()} />
          <div className={classes.nestedDrawerContent} onClick={(e) => e.stopPropagation()}>
            <Sidebar
              currentStep={steps}
              onClick={setSteps}
              setDrawerOpen={() => {}}
              smallScreenSteps={smallScreenSteps}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SwipeableEdgeDrawer;
