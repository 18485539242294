import { fabric } from "react-fabric-page";

export const createSpineSections = (canvas, gap = 10, lineGap = 5, ml = 5, mt = 5, mb = 5, mr = 5) => {
    return new Promise((resolve, reject) => {
        try {
            // Get all the existing ribs (sections) from the canvas (IDs starting with "sections")
            const ribs = canvas.getObjects().filter(obj => obj.id && obj.id.startsWith("sections"));

            // Sort ribs based on their 'top' property to ensure correct order
            ribs.sort((a, b) => a.top - b.top);

            const sectionWidth = ribs[0].width;
            const canvasHeight = canvas.pageHeight;

            const createLayerWithRect = (id, left, top, width, height, fill = "#fff", stroke = "#000") => {
                // 1. Create the layer first
                const newLayer = new fabric.Layer([], {
                    type: "layer",
                    originX: "left",
                    originY: "top",
                    left: left,
                    top: top,
                    width: width,
                    height: height,
                    scaleX: 1,
                    scaleY: 1,
                    selectable: true,
                    evented: true,
                    hasControls: false,
                    id: id,
                });


                const clipPath = new fabric.Rect({
                    left: 0,
                    top: 0,
                    width: width,
                    height: height,
                    fill: 'transparent', // No fill, just a boundary
                    stroke: '#fff', // Border color (change as needed)
                    strokeDashArray: [5, 5], // Creates a dotted line
                    selectable: false,
                    evented: false,
                });

                newLayer.clipPath  = clipPath ;

                // 2. Add the layer to the canvas
                canvas.add(newLayer);

                // 3. Create a rectangle to be added to the layer
                const rect = new fabric.Rect({
                    left: 0,
                    top: 0,
                    width: width,
                    height: height,
                    fill: fill,
                    evented: false,
                    selectable: false
                });

                // 4. Add the rectangle to the layer
                newLayer.add(rect);

                return newLayer;
            };

            // Check if ribs length is 2
            if (ribs.length === 2) {
                const firstRib = ribs[0];
                const lastRib = ribs[1];
                const firstRibTop = firstRib.top;
                const lastRibBottom = lastRib.top + lastRib.height;

                // 1. Create the top section (from absolute top to the first rib)
                createLayerWithRect(
                    "rib-section-top", 
                    0, 
                    0, 
                    sectionWidth, 
                    firstRibTop, 
                    "#fff"
                );

                // 2. Create Section 1 (between the first rib and the second rib)
                createLayerWithRect(
                    "rib-section-1", 
                    0, 
                    firstRibTop + firstRib.height, 
                    sectionWidth, 
                    lastRib.top - (firstRibTop + firstRib.height), 
                    "#fff"
                );

                // 3. Create the bottom section (from the last rib to the absolute bottom)
                createLayerWithRect(
                    "rib-section-bottom", 
                    0, 
                    lastRibBottom, 
                    sectionWidth, 
                    canvasHeight - lastRibBottom, 
                    "#fff"
                );

            } else {
                // Full logic for ribs length greater than 2
                // 1. Create the first section (from absolute top to the first blue line)
                createLayerWithRect("rib-section-top", 0, 0, sectionWidth, 10, "#fff");

                // 2. Create the first blue line 10 units from the top
                createLayerWithRect("rib-blue-line-1", 0, 10, sectionWidth, 2, "#00f");

                // 3. Create Section 1 (between the first blue line and the first rib)
                createLayerWithRect("rib-section-1", 0, 12, sectionWidth, ribs[0].top - 12, "#fff");

                // 4. Create middle sections between ribs dynamically
                ribs.forEach((rib, index) => {
                    if (index < ribs.length - 1) {
                        const currentRibBottom = rib.top + rib.height;
                        const nextRibTop = ribs[index + 1].top;

                        // Create section between current rib and next rib
                        createLayerWithRect(
                            `rib-section-${index + 2}`,
                            0,
                            currentRibBottom,
                            sectionWidth,
                            nextRibTop - currentRibBottom,
                            "#fff"
                        );
                    }
                });

                // 5. Create the section after the last rib to the two blue lines near the bottom
                const lastRib = ribs[ribs.length - 1];
                const lastRibBottom = lastRib.top + lastRib.height;

                createLayerWithRect(
                    `rib-section-${ribs.length + 1}`,
                    0,
                    lastRibBottom,
                    sectionWidth,
                    canvasHeight - lastRibBottom - (gap + lineGap + 10),
                    "#fff"
                );

                // 6. Add the first blue line near the bottom (10 units from the bottom)
                createLayerWithRect(
                    "rib-bottom-line-1",
                    0,
                    canvasHeight - (gap + lineGap + 12),
                    sectionWidth,
                    2,
                    "#00f"
                );

                // 7. Add the second blue line with a gap between the two lines
                createLayerWithRect(
                    "rib-bottom-line-2",
                    0,
                    canvasHeight - (gap + lineGap),
                    sectionWidth,
                    2,
                    "#00f"
                );

                // 8. Create a section between the two blue lines
                createLayerWithRect(
                    "rib-section-between-lines",
                    0,
                    canvasHeight - (gap + lineGap + 10),
                    sectionWidth,
                    gap + lineGap - 5,
                    "#fff"
                );

                // 9. Create the bottom section between the two blue lines
                createLayerWithRect(
                    "rib-section-bottom",
                    0,
                    canvasHeight - (gap + lineGap - 2),
                    sectionWidth,
                    gap + lineGap + 2,
                    "#fff"
                );
            }

            // Render the canvas after adding all sections and ribs
            canvas.renderAll();

            // Resolve the promise once everything is added
            resolve();
        } catch (error) {
            reject(error);
        }
    });
};


// export const addRibDesignToAll = async (canvas, ribImageUrl, ribImageHeight, smallGap = 5) => {
//     const ribSections = canvas?.getObjects()?.filter(obj => obj.id && obj.id.startsWith("sections"));
//     let imageIdCounter = 1; // Counter for generating unique IDs

//     // Function to remove all previous rib design images
//     const removePreviousImages = () => {
//         const previousImages = canvas?.getObjects()?.filter(obj => obj.id && obj.id.startsWith("rib-design-image-"));
//         previousImages.forEach((img) => {
//             canvas.remove(img);
//         });
//         canvas.renderAll(); // Re-render canvas after removal
//     };

//     const addImage = (url, options) => {
//         return new Promise((resolve, reject) => {
//             fabric.Image.fromURL(url, (img) => {
//                 img.set(options);
//                 canvas.add(img);
//                 resolve();
//             }, { crossOrigin: 'anonymous' });
//         });
//     };

//     // Remove previous images before adding new ones
//     removePreviousImages();

//     // Add single image above the first blue line at the top
//     if (ribSections?.length > 2) {
//         const firstSection = ribSections[0];

//         // Find the top blue line
//         const topBlueLine = canvas.getObjects().find(obj => obj.id === "rib-blue-line-1");
//         if (topBlueLine) {
//             await addImage(ribImageUrl, {
//                 left: topBlueLine.left,
//                 top: topBlueLine.top, // Position the image above the top blue line
//                 width: topBlueLine.width,
//                 height: ribImageHeight,
//                 selectable: false,
//                 evented: false,
//                 id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
//             });
//         }
//     }

//     // Process each rib section for images above and below
//     for (const ribSection of ribSections) {
//         // Load the image for above the rib section
//         await addImage(ribImageUrl, {
//             left: ribSection.left,
//             top: ribSection.top - ribImageHeight,  // Position image above the rib section
//             width: ribSection.width,
//             height: ribImageHeight,
//             selectable: false,
//             evented: false,
//             id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
//         });

//         // Load the image for below the rib section
//         await addImage(ribImageUrl, {
//             left: ribSection.left,
//             top: ribSection.top + ribSection.height,  // Position image below the rib section
//             width: ribSection.width,
//             height: ribImageHeight,
//             selectable: false,
//             evented: false,
//             id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
//         });
//     }

//     // Add images for the bottom two blue lines
//     const bottomBlueLine1 = canvas?.getObjects().find(obj => obj.id === "rib-bottom-line-1");
//     const bottomBlueLine2 = canvas?.getObjects().find(obj => obj.id === "rib-bottom-line-2");

//     // Load the image for the first blue line at the bottom
//     if (bottomBlueLine1) {
//         await addImage(ribImageUrl, {
//             left: bottomBlueLine1.left,
//             top: bottomBlueLine1.top, // Position image below the first blue line
//             width: bottomBlueLine1.width,
//             height: ribImageHeight,
//             selectable: false,
//             evented: false,
//             id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
//         });
//     }

//     // Load the image for the second blue line at the bottom
//     if (bottomBlueLine2) {
//         await addImage(ribImageUrl, {
//             left: bottomBlueLine2.left,
//             top: bottomBlueLine2.top, // Position image below the second blue line
//             width: bottomBlueLine2.width,
//             height: ribImageHeight,
//             selectable: false,
//             evented: false,
//             id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
//         });
//     }

//     canvas?.renderAll();
// };

export const addRibDesignToAll = async (canvas, ribImageUrl, ribImageHeight, smallGap = 5) => {
    const ribSections = canvas?.getObjects()?.filter(obj => obj.id && obj.id.startsWith("sections"));
    let imageIdCounter = 1; // Counter for generating unique IDs

    // Function to remove all previous rib design images
    const removePreviousImages = () => {
        const previousImages = canvas?.getObjects()?.filter(obj => obj.id && obj.id.startsWith("rib-design-image-"));
        previousImages.forEach((img) => {
            canvas.remove(img);
        });
        canvas.renderAll(); // Re-render canvas after removal
    };

    // Function to add and scale image proportionally
    const addImage = (url, options, targetWidth, targetHeight) => {
        return new Promise((resolve, reject) => {
            fabric.Image.fromURL(url, (img) => {
                // Calculate the scaling factors for width and height
                const scaleX = targetWidth / img.width;
                const scaleY = targetHeight / img.height;

                // Choose the smaller scale factor to maintain aspect ratio
                const scale = Math.min(scaleX, scaleY);

                img.set({
                    ...options,
                    scaleX: scale,
                    scaleY: scale,
                    imageUrl: ribImageUrl
                });

                canvas.add(img);
                resolve();
            }, { crossOrigin: 'anonymous' });
        });
    };

    // Remove previous images before adding new ones
    removePreviousImages();

    // Add single image above the first blue line at the top
    if (ribSections?.length > 2) {
        // Find the top blue line
        const topBlueLine = canvas.getObjects().find(obj => obj.id === "rib-blue-line-1");
        if (topBlueLine) {
            await addImage(ribImageUrl, {
                left: topBlueLine.left,
                top: topBlueLine.top, // Position the image above the top blue line
                selectable: false,
                evented: false,
                id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
            }, topBlueLine.width, ribImageHeight);
        }
    }

    // Process each rib section for images above and below
    for (const ribSection of ribSections) {
        // Load the image for above the rib section
        await addImage(ribImageUrl, {
            left: ribSection.left,
            top: ribSection.top - ribImageHeight/2,  // Position image above the rib section
            selectable: false,
            evented: false,
            id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
        }, ribSection.width, ribImageHeight);

        // Load the image for below the rib section
        await addImage(ribImageUrl, {
            left: ribSection.left,
            top: ribSection.top + ribSection.height,  // Position image below the rib section
            selectable: false,
            evented: false,
            id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
        }, ribSection.width, ribImageHeight);
    }

    // Add images for the bottom two blue lines
    const bottomBlueLine1 = canvas?.getObjects().find(obj => obj.id === "rib-bottom-line-1");
    const bottomBlueLine2 = canvas?.getObjects().find(obj => obj.id === "rib-bottom-line-2");

    // Load the image for the first blue line at the bottom
    if (bottomBlueLine1) {
        await addImage(ribImageUrl, {
            left: bottomBlueLine1.left,
            top: bottomBlueLine1.top, // Position image below the first blue line
            selectable: false,
            evented: false,
            id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
        }, bottomBlueLine1.width, ribImageHeight);
    }

    // Load the image for the second blue line at the bottom
    if (bottomBlueLine2) {
        await addImage(ribImageUrl, {
            left: bottomBlueLine2.left,
            top: bottomBlueLine2.top, // Position image below the second blue line
            selectable: false,
            evented: false,
            id: `rib-design-image-${imageIdCounter++}`, // Set unique ID
        }, bottomBlueLine2.width, ribImageHeight);
    }

    canvas?.renderAll();
};


export const changeAllSectionColors = (canvas, colorId) => {
    const sectionLayers = canvas.getObjects().filter(obj => obj.id && obj.id.startsWith("rib-section"));

    sectionLayers.forEach(sectionLayer => {
        sectionLayer.getObjects().forEach(obj => {
            if (obj.type === "rect") {
                obj.set({ fill: colorId });
            }
        });
        sectionLayer.dirty = true;
    });
    canvas.renderAll(); 
};

export const addTextureToBackground = (canvas, textureImageUrl) => {
    const layer = canvas?.getObjects()?.find((o) => o.id === "content")
    fabric.Image.fromURL(textureImageUrl, (img) => {
        img.set({
            id: 'background-texture',
            selectable: false,
            evented: false, // Ensures no interaction with the image
            hasControls: false,
            hasBorders: false,
            lockMovementX: true,
            lockMovementY: true,
            scaleX: layer.width / img.width, // Scale to fit canvas width
            scaleY: layer.height / img.height // Scale to fit canvas height
        });

        canvas.add(img);
        // canvas.sendToBack(img); // Ensure the texture is at the back
        canvas.renderAll();
    });
};


export const addTextToSection = (section) => {
    const text = new fabric.TextArea("Add Text", {
        originY:"top",
        left: 5,
        top: 5,
        fontSize: 12,
        width: section.width - 10,
        height: section.height - 10,
        textAlign: 'center',
        fill: "#000",
        fontWeight: "normal",
        fontFamily: "Lato,Hind,Emoji",
        fontStyle: "normal",
        text:"",
        placeholderText:"Add Text",
        selectable: true,
        evented: true,
        lockDeletion: true,
        layer: section,
        hasControls:false,
        allowedLeft: [
            [
                0,
                section.left + section.width
            ]
        ],
        allowedTop: [
            [
                0,
                section.height
            ]
        ]
    });
    section.add(text);
}

export const addLogoToSection = (canvas, section, contentUrl) => {
    // Remove existing logo from section
    const existingLogo = section._objects.find((obj) => obj.id === "section-logo");
    if (existingLogo) {
      section.remove(existingLogo);
    }
  
    return new Promise((acc) => {
        fabric.Image.fromURL(contentUrl, (img) => {
            const scaleX = section.width / img.width;
            const scaleY = section.height / img.height;
            const scaling = Math.min(scaleX, scaleY) * 0.7;
            img.set({
              scaleX: scaling,
              scaleY: scaling,
              left: (section.width - img.width * scaling) / 2,
              top: (section.height - img.height * scaling) / 2,
              hasControls:false,
              id: "section-logo",
            });
            acc(img);
        });
    })
    
  };
  

