import React, { useState } from "react";
import classes from "./Dropdown.module.css";
import Checkbox from "../../../../Components/Checkbox/Checkbox";

const CustomDropdown = ({
  title,
  categoryData,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCategoryChange = (category) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((item) => item !== category)
        : [...prevSelected, category]
    );
  };

  function capitalize(s) {
    return s?.[0]?.toUpperCase() + s?.slice(1);
  }

  return (
    <div className={classes.dropdownContainer}>
      <div className={classes.dropdownHeader} onClick={toggleDropdown}>
        {title}
        <span className={isOpen ? classes.arrowUp : classes.arrowDown}></span>
      </div>
      {isOpen && (
        <div className={classes.dropdownContent}>
          {categoryData?.map((cat) => (
            <div key={cat.value} className={classes.checkboxWrapper}>
              <Checkbox
                id={cat.value}
                checked={selectedOptions?.includes(cat?.value)}
                label={capitalize(cat?.value)}
                onChange={() => handleCategoryChange(cat?.value)}
                className={classes.checkbox}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
