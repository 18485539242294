import React, { useState } from "react";
import classes from './Password.module.css';
import { forwardRef } from "react";
import PrimaryInput from "../Inputs/PrimaryInput/PrimaryInput";
const eyeOutline = "/Assets/Icons/eye-outline.svg";
const eyeOffOutline = "/Assets/Icons/eye-off-outline.svg";

const Password = forwardRef(function Password(props, ref) {
    const [passwordType, setPasswordType] = useState("password");

    const myStyle = {
        "position": "absolute",
        "right": "0.5rem",
        "cursor": "pointer",
        "width": "1rem",
        "height": "100%"
    };

    function toggleHandler() {
        if (passwordType === "password") {
            setPasswordType("text");
        }
        else {
            setPasswordType("password");
        }
    }

    return <div className={classes.main}>
        <PrimaryInput
            autoComplete={props.autoComplete}
            type={passwordType}
            placeholder={props.placeholder}
            className={classes["form-field"]}
            onChange={props.onChange}
            onPaste={props.onPaste}
            autoFocus={props.autoFocus}
            style={{ "width": props.width, "height": props.height }}
            onKeyDown={(e) => e.key === 'Enter' && props.onCommit?.(e)}
            validate={props.validate}
            ref={ref}
            value={props.value}
            onKeyUp={props.onKeyUp}
            name={props.name}
        />
        {props.togglePasswordVisibility && (
            passwordType === "text" ? <img src={eyeOutline} alt="Eye-outline" onClick={toggleHandler} style={myStyle} /> : <img src={eyeOffOutline} alt="Eye-Off-outline" onClick={toggleHandler} style={myStyle} />)
        }
    </div>
})

export default Password;