import { useEffect } from 'react';

function useClickOutSide({ myRef, isActive, onClick }) {
  useEffect(() => {
    if (!myRef.current || !onClick) return;

    const handleClickOutside = (event) => {
      if (isActive && myRef.current && !myRef.current.contains(event.target)) {
        onClick();
      }
    };

    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [myRef, isActive, onClick]);

  return null;
}

export default useClickOutSide;
