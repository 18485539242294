import { combineReducers, configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./Slices/LoaderSlice";
import warningReducer from "./Slices/WarningSlice";
import usersSliceReducer from "./Slices/UsersSlice";
import TestimonialSliceReducer from "./Slices/TestimonialSlice";
import GallerySliceReducer from "./Slices/GallerySlice";
import BestSellingAuthorReducer from "./Slices/BestAuthors";
import featuredCollectionSliceReducer from "./Slices/FeaturedCollectionSlice";
import BookSliceReducer from "./Slices/BookSlice";
import KeywordsSliceReducer from "./Slices/KeywordsSlice";
import blogReducer from "./Slices/BlogsSlice";
import bannerReducer from "./Slices/BannerSlice"
import serviceVideoReducer from "./Slices/ServiceVideoSlice"
import cartReducer from "./Slices/CartSlice"
import wishlistReducer from "./Slices/WishlistSlice"
import couponReducer from "./Slices/CouponSlice"
import faqReducer from "./Slices/FaqSlice"
import processingCostReducer from "./Slices/ProcessingCostSlice"
import bookColorReducer from "./Slices/BookColorSlice"
import bestSellerBookReducer from "./Slices/BestSellerBookSlice"
import bookReviewsReducer from "./Slices/BookReviewSlice"
import customizationPricingReducer from "./Slices/CustomizationPricingSlice"
import searchRecommendationReducer from "./Slices/SearchRecommendtionSlice";
import toggleReducer from "./Slices/ToggleSlice"

const allReducers = combineReducers({
  loader: loaderReducer,
  warning: warningReducer,
  user: usersSliceReducer,
  testimonial:TestimonialSliceReducer,
  gallery:GallerySliceReducer,
  bestSellingAuthors:BestSellingAuthorReducer,
  featured:featuredCollectionSliceReducer,
  books:BookSliceReducer,
  keywords:KeywordsSliceReducer,
  blogs:blogReducer,
  banners:bannerReducer,
  serviceVideo:serviceVideoReducer,
  cart:cartReducer,
  wishlist:wishlistReducer,
  coupons:couponReducer,
  Faq:faqReducer,
  processingCost:processingCostReducer,
  bookColor:bookColorReducer,
  bestSellerBooks:bestSellerBookReducer,
  bookReviews:bookReviewsReducer,
  customizationPricing:customizationPricingReducer,
  recommendation : searchRecommendationReducer,
  toggle:toggleReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = {};
  }
  return allReducers(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
