import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questions:[],
    categories:[]
}

const FaqSlice = createSlice({
    name:"faq",
    initialState,
    reducers:{
        fetchAllFaq:(state,action) => {
            state.questions = action.payload;
        },
        fetchAllCategories:(state,action) => {
            state.categories = action.payload;
        },
        addNewFaq: (state, action) => {
            state.questions.unshift(action.payload)
        },
        deleteFaq: (state, action) => {
            state.questions = state.questions?.filter(question => question.id !== action.payload?.id)
        },
        updateFaq: (state, action) => {
            const updateFaq = action.payload;
            const idx = state.questions?.findIndex(question => question.id === updateFaq.id)
            if (idx > -1) {
                state.questions[idx] = updateFaq;
            }
        }
    }

})

export const FaqAction = FaqSlice.actions;
export default FaqSlice.reducer;