import { useDispatch, useSelector } from "react-redux";
import ExclusiveVerification from "./ExclusiveVerification";
import Footer from "../Footer/Footer";
import PrimaryNav from "../Header/PrimaryNav/PrimaryNav";
import BannerHeader from "../../Components/BannerHeader/BannerHeader";
import classes from "./RBExclusive.module.css";
import { useEffect, useState } from "react";
import {
  fetchBook,
  fetchBookCount,
  getFacets,
  getKeywords,
} from "../../Store/actions/BookAction";
import Card from "../ListingPage/Card/Card2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SearchComponent from "../BestSeller/SearchComponent/SearchComponent";
import Sidebar from "../BestSeller/Sidebar/Sidebar";
import { getRandomImageForNormalBook } from "../../Util";

const crumbs = [
  { title: "Home", path: "/" },
  { title: "RB Exclusive", path: "/rb-exclusive" },
];
const pageSize = 16;

const RBExclusive = () => {
  const dispatch = useDispatch();
  const allColorsData = useSelector((state) => state.bookColor.colors);

  const [booksData, setBooksData] = useState([]);
  const [count, setCount] = useState(0);
  const [query, setQuery] = useState("");
  const searchCriteria= "all";
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / pageSize);
  const [hasExclusiveAccess, setHasExclusiveAccess] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [allowedMinPublishingYear, setAllowedMinPublishingYear] =
    useState(null);
  const [allowedMaxPublishingYear, setAllowedMaxPublishingYear] =
    useState(null);
  const [minPublishingYear, setMinPublishingYear] = useState(null);
  const [maxPublishingYear, setMaxPublishingYear] = useState(null);
  const [allGenres, setAllGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [isPageChange, setIsPageChange] = useState(false);

  useEffect(() => {
    getFacets().then((data) => {
      setAllGenres(data?.genres);
      setAllowedMinPublishingYear(data?.publishingYear?.min);
      setAllowedMaxPublishingYear(data?.publishingYear?.max);
      getKeywords(dispatch);
    });
  }, []);

  useEffect(() => {
    setMinPublishingYear(allowedMinPublishingYear);
    setMaxPublishingYear(allowedMaxPublishingYear);
  }, [allowedMinPublishingYear, allowedMaxPublishingYear]);

  const fetchBooksData = async () => {
    const data = await fetchBook(
      searchCriteria,
      query,
      dispatch,
      pageSize,
      isPageChange ? currentPage : 1,
      sortBy,
      minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
      maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
      selectedGenres
    );
    const total = await fetchBookCount(
      searchCriteria,
      query,
      minPublishingYear !== allowedMinPublishingYear ? minPublishingYear : null,
      maxPublishingYear !== allowedMaxPublishingYear ? maxPublishingYear : null,
      selectedGenres
    ); // Removed dispatch here as fetchBookCount doesn't require it
    setBooksData(data.data); // Ensure data is correctly set
    if (count !== total) {
      setCurrentPage(1);
    }
    setCount(total); // Update total results when new data is fetched
  };

  useEffect(() => {
    fetchBooksData();
  }, [currentPage, searchCriteria, sortBy]);

  useEffect(() => {
    setTimeout(fetchBooksData, 1000);
  }, [selectedGenres, minPublishingYear, maxPublishingYear]);

  return (
    <>
      <PrimaryNav />
      {!hasExclusiveAccess ? (
        <ExclusiveVerification setHasExclusiveAccess={setHasExclusiveAccess} />
      ) : (
        <div>
          <BannerHeader crumbs={crumbs} title={"Exclusive Books"} />
          <div className={classes.nav}>
            <div className={classes.headerText}>
              <h2>Find your next book here</h2>
              {booksData.length !== 0 && <p>found {count} results</p>}
            </div>
            <div className={classes.searchComponent}>
              <div className={classes.search}>
                <SearchComponent
                  query={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onSearch={fetchBooksData}
                />
              </div>
              <div className={classes.sortBtn}>
                <select
                  name="sortBy"
                  id="sortBy"
                  onChange={(e) => setSortBy(e.target.value)}
                >
                  <option value="" defaultChecked>
                    Sort By
                  </option>
                  <option value="title ASC">A-Z</option>
                  <option value="title DESC">Z-A</option>
                </select>
              </div>
            </div>
          </div>
          <div className={classes.container}>
            <Sidebar
              allGenres={allGenres}
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              allowedMaxYear={allowedMaxPublishingYear}
              allowedMinYear={allowedMinPublishingYear}
              maxYear={maxPublishingYear}
              minYear={minPublishingYear}
              setMaxYear={setMaxPublishingYear}
              setMinYear={setMinPublishingYear}
            />
            <div className={classes.searchResult}>
              <div className={classes.cardGrid}>
                {booksData?.map((card, index) => (
                  <Card
                    key={index}
                    image={getRandomImageForNormalBook(card, allColorsData)?.image}
                    title={card.title}
                    author={card.author}
                    price={card.deluxePriceGenuine}
                    bookData={card}
                  />
                ))}
              </div>
             { booksData?.length > 0 ? <div className={classes.paginationControls}>
                <button
                  onClick={() => {setCurrentPage((prevPage) => prevPage - 1); setIsPageChange(true)}}
                  disabled={currentPage <= 1}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faArrowLeft}
                  />
                </button>
                <span>
                  Page {currentPage} of {totalPages} pages
                </span>
                <button
                  onClick={() => {setCurrentPage((prevPage) => prevPage + 1); setIsPageChange(true)}}
                  disabled={currentPage >= totalPages}
                >
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faArrowRight}
                  />
                </button>
              </div> : <></>}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default RBExclusive;
