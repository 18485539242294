import { useRef } from 'react';
import { useFabricCanvasHandler, useFabricCanvasInitalizer } from 'react-fabric-page';
import { fabric } from 'react-fabric-page';

export function customCallback(editor) {
    editor.getThumbnail = function () {
        const canvas = this.canvas;
        let side = this.pageWidth < this.pageHeight ? this.pageHeight : this.pageWidth;

        const targetCanvas = document.createElement('canvas');
        targetCanvas.width = side;
        targetCanvas.height = side;
        const ctx = targetCanvas.getContext("2d");
        const leatherColor = editor.leatherColor || "white";
        ctx.fillStyle = leatherColor;
        ctx.fillRect(0, 0, targetCanvas.width, targetCanvas.height);

        ctx.drawImage(canvas.toCanvasElement(1, {
            format: 'jpg',
            left: this.pageX,
            top: this.pageY,
            width: side,
            height: side
        }), 0, 0);
        return targetCanvas.toDataURL("image/jpeg");
    };

    editor.getThumbnailForBackCover = function () {
        const canvas = this.canvas;
        let side = this.pageWidth < this.pageHeight ? this.pageHeight : this.pageWidth;

        const targetCanvas = document.createElement('canvas');
        targetCanvas.width = side;
        targetCanvas.height = side;
        const ctx = targetCanvas.getContext("2d");

        const leatherColor = editor.leatherColor || "white";
        ctx.fillStyle = leatherColor;
        ctx.fillRect(0, 0, targetCanvas.width, targetCanvas.height);

        ctx.save();
        ctx.transform(-1, 0, 0, 1, this.pageWidth - side, 0); // Flip horizontally
        ctx.drawImage(canvas.toCanvasElement(1, {
            format: 'jpg',
            left: this.pageX,
            top: this.pageY,
            width: side,
            height: side
        }), -side, 0);
        return targetCanvas.toDataURL("image/jpeg");
    };
	fabric.Object.prototype.additionalProperties.push("imageUrl");
	fabric.Object.prototype.additionalProperties.push("controlsVisibility");

	fabric.Object.prototype.controls = fabric.Object.prototype.controlsSetting.defaultControls;
	fabric.ImageBox.prototype.controls = fabric.Object.prototype.controlsSetting.defaultControls;
}



export function FabricCanvas({ onShow, style, page, onPageMount, onPageUnMount }) {
	const canvasWrapperRef = useRef(null)
	const {wrapper, mainCanvasWrapper, editor, isEditing} = useFabricCanvasInitalizer({onShow, page, onPageMount, onPageUnMount, customCallback,});
	

	return <div tabIndex={0}
		style={{
			height: '100%',
			width: '100%',
			outline: 'none',
			position: 'relative',
			...style
		}} ref={wrapper}
	>
		<div ref={canvasWrapperRef} style={{ position: 'absolute', width: "100%", height: "100%" }}>
			{/* <button style={{position: "absolute", top: "-5rem", right: "1rem"}} onClick={toggleFullScreen}>toggle</button> */}
			<div ref={mainCanvasWrapper} className="mainCanvasWrapper">
				<canvas className="mainCanvas" />
			</div>
			<div className={`edit-svg ${isEditing ? '' : 'hidden'}`}>
				{/* <span className="hyperlink" onClick={() => editor?.canvas?.closeSVGEditing()}>Close Editing</span> */}
			</div>
		</div>
	</div>;
};