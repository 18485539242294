import React, { useState, useRef, useEffect } from "react";
import classes from "./Slider.module.css"; // Assuming you're using CSS modules

const Slider = ({
  title,
  max,
  min,
  setMax,
  setMin,
  allowedMin,
  allowedMax,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);

  // Convert to percentage
  const getPercent = (value) => {
    return Math.round(((value - allowedMin) / (allowedMax - allowedMin)) * 100);
  };

  useEffect(() => {
    setMinValue(min);
    setMaxValue(max);
    if (maxValRef.current) {
      const minPercent = getPercent(min);
      const maxPercent = getPercent(max);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [min, max]);

  const handleMinChange = (e) => {
    const value = Math.min(Number(e.target.value), max - 1);
    setMin(value);
    // setMinValue(value);
  };

  const handleMaxChange = (e) => {
    const parsedValue = parseInt(e.target.value);
    const value = Math.max(parsedValue, parseInt(min) + 1);
    setMax(value);
  };

  const handleMinChange1 = (e) => {
    const value = Number(e.target.value);
    if(value <= allowedMax){
      setMinValue(value);
    } else {
      setMinValue(allowedMax)
    }
    // handleInputDebouncedChange(minValue, true)
  };

  const handleMaxChange1 = (e) => {
    const value = Number(e.target.value);
    if(value <= allowedMax){
      setMaxValue(value);
    }  else {
      setMax(allowedMax)
    }
    // handleInputDebouncedChange(maxValue, false)
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e) => {
    if(e.key === "Enter"){
      if(minValue >= allowedMin && minValue <= max) {
          setMin(minValue)
      } else {
          setMin(allowedMin);
          setMinValue(allowedMin)
      }

      if(maxValue <= allowedMax && maxValue >= min) {
          setMax(maxValue)
      } else {
          setMax(allowedMax);
          setMaxValue(allowedMax)
      }
   }
  }

  return (
    <div className={classes.dropdownContainer}>
      <div className={classes.dropdownHeader} onClick={toggleDropdown}>
        {title}
        <span className={isOpen ? classes.arrowUp : classes.arrowDown}></span>
      </div>
      {isOpen && (
        <div className={classes.container}>
          <div className={classes.sliderContainer}>
            <input
              type="range"
              min={allowedMin}
              max={allowedMax}
              value={min}
              onChange={handleMinChange}
              className={`${classes.slider} ${classes.sliderMin}`}
              ref={minValRef}
            />
            <input
              type="range"
              min={allowedMin}
              max={allowedMax}
              value={max}
              onChange={handleMaxChange}
              className={`${classes.slider} ${classes.sliderMax}`}
              ref={maxValRef}
            />

            <div className={classes.sliderTrack}></div>
            <div ref={range} className={classes.sliderRange}></div>
            <div className={classes.sliderLeftValue}>{min}</div>
            <div className={classes.sliderRightValue}>{max}</div>
          </div>

          <div className={classes.inputWrapper}>
            <div className={classes.inputBox}>
              <label htmlFor="min">Min</label>
              <input
                type="number"
                id="min"
                value={minValue}
                onChange={handleMinChange1}
                className={classes.input}
                min={Number(allowedMin)} // Set allowedMin as minimum
                max={Number(max) - 1} // Ensure min can't exceed max - 1
                onKeyDown={handleClick}
              />
            </div>
            <div className={classes.inputBox}>
              <label htmlFor="max">Max</label>
              <input
                type="number"
                id="max"
                value={maxValue}
                onChange={handleMaxChange1}
                className={classes.input}
                min={Number(min) + 1} // Ensure max can't go below min + 1
                max={allowedMax} // Set allowedMax as maximum
                onKeyDown={handleClick}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;
