import React, { useState, useEffect } from "react";
import classes from "./AddressForm.module.css";
import PrimaryInput from "../../../../Components/Inputs/PrimaryInput/PrimaryInput";
import { addUserAddress, updateAddress } from "../../../../Store/actions/UsersActions";
import { useDispatch } from "react-redux";
import PrimaryButton from "../../../../Components/Buttons/PrimaryButton/PrimaryButton";
import { getClasses } from "../../../../Util";
import CountryDropdown from "./CountryDropdown/CountryDropdown";
import { ModalBody, ModalFooter } from "../../../../Components/Modal/Modal";
import CountryCodeList from "../../../SignUp/CountryCodeList.json"

function AddressForm({ onClose, type, initialData }) {
 
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        contactNumber: "",
        countryCode: "",
        addressLine1: "",
        addressLine2: "",
        countryName: "",
        countryNameCode:"",
        state: "",
        city: "",
        zip: "",
    });

    useEffect(() => {
        if (initialData) {
            const countryCodeMatch = initialData.phoneNo.slice(0,-10);
            const contactNumberOnly = initialData.phoneNo.slice(-10);  // Remove country code if it exists

            setFormData({
                firstName: initialData.fullName.split(" ")[0] || "",
                lastName: initialData.fullName.split(" ").slice(1).join(" ") || "",
                email: initialData.email || "",
                contactNumber: contactNumberOnly || "",  // Ensure this is set correctly
                countryCode: countryCodeMatch || "",  // Extract country code or set default
                addressLine1: initialData.addressLine1 || "",
                addressLine2: initialData.addressLine2 || "",
                countryName: initialData.country || "",
                countryNameCode: initialData.countryCode || "",
                state: initialData.state || "",
                city: initialData.city || "",
                zip: initialData.zipCode || "",
            });
        }
    }, [initialData]);
    
    const handleChange = (e) => {
        if (e.target.name === "contactNumber") {
            if(e.target.value.length <= 10){
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                });
            } 
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
       
    };

    const setCountryData = (data) => {
        setFormData((prevState) =>{
            const newState = {...prevState};
            newState.countryNameCode = data.code;
            newState.countryName = data.name;
            return newState;
        } )
    }

    const isDisabled = () => {
        const {
            firstName,
            lastName,
            email,
            contactNumber,
            countryCode,
            addressLine1,
            countryName,
            state,
            city,
            zip,
        } = formData;
    
        // Check if all required fields are filled
        if (
            firstName.trim() === "" ||
            lastName.trim() === "" ||
            email.trim() === "" ||
            contactNumber.trim() === "" ||
            contactNumber.length < 10 ||
            countryCode === "" ||
            addressLine1.trim() === "" ||
            countryName.trim() === "" ||
            state.trim() === "" ||
            city.trim() === "" ||
            zip.trim() === ""
        ) {
            return true; // Disable button
        }
    
        return false; // Enable button
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const addressData = {
            fullName: `${formData.firstName} ${formData.lastName}`,
            email: formData.email.toLowerCase(),
            phoneNo: `${formData.countryCode}${formData.contactNumber}`,
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            city: formData.city,
            zipCode: formData.zip,
            state: formData.state,
            country: formData.countryName,
            countryCode:formData.countryNameCode
        };

        if (initialData) {
            updateAddress(initialData.addressId, addressData, type, dispatch);
        } else {
            addUserAddress(addressData, type, dispatch);
        }

        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            contactNumber: "",
            countryCode: "",
            addressLine1: "",
            addressLine2: "",
            countryName: "",
            countryNameCode:"",
            state: "",
            city: "",
            zip: "",
        });
        onClose();
    };

    const inputPrevent = (e) => {
        const invalidChars = ["e", "E", "+", "-", "."];
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
    }    

    return (
        <>
        <ModalBody>
        <form className={classes.form} onSubmit={handleSubmit}>
            <div className={classes.row}>
                <div className={classes.formGroup}>
                    <PrimaryInput
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        label="First Name"
                        isRequired
                        maxLength={15}
                    />
                </div>

                <div className={classes.formGroup}>
                    <PrimaryInput
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        label="Last Name"
                        isRequired={true}
                        maxLength={15}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.formGroup}>
                    <PrimaryInput
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        label="Email"
                        isRequired={true}
                        
                    />
                </div>
            </div>

            <div className={classes.formGroup}>
                <div className={classes.contactNumber}>
                <select
                        name="countryCode"
                        id="countryCode"
                        required
                        onChange={handleChange}
                        value={formData.countryCode}
                    >
                        <option value="" disabled>Select Country Code</option>
                        {CountryCodeList?.countryCode.map((item,idx) => (
                            <option key={idx} value={item.dial_code}>
                                {item.code} {item.dial_code}
                            </option>
                        ))}
                    </select>
                    <PrimaryInput
                        type="number"
                        placeholder="Contact Number"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                        isRequired={true}
                        maxLength={12}
                        onKeyDown={(e) => inputPrevent(e)}
                    />
                </div>
            </div>

            <div className={classes.formGroup}>
                <PrimaryInput
                    type="text"
                    id="addressLine1"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleChange}
                    label="Address Line 1"
                    isRequired={true}
                    maxLength={120}
                />
            </div>

            <div className={getClasses(classes.addressLine2 ,classes.formGroup)}>
                <PrimaryInput
                    type="text"
                    id="addressLine2"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleChange}
                    label="Address Line 2 (Optional)"
                    isRequired={false}
                    maxLength={120}
                />
            </div>

            <div className={classes.row}>
                <div className={classes.selectCountry} style={{margin: "1rem 0"}}>
                <CountryDropdown countryName={formData.countryName} selectCountry={setCountryData} countryCode={formData.countryNameCode}/>
                </div>

                <div className={`${classes.selectCountry} ${classes.formGroup}`} >
                    <PrimaryInput
                        type="text"
                        id="state"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                        label="State"
                        isRequired={true}
                        maxLength={50}
                    />
                </div>
            </div>

            <div className={classes.row}>
                <div className={classes.formGroup}>
                    <PrimaryInput
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        label="City"
                        isRequired={true}
                        maxLength={50}
                    />
                </div>

                <div className={classes.formGroup}>
                    <PrimaryInput
                        type="text"
                        id="zip"
                        name="zip"
                        value={formData.zip}
                        onChange={handleChange}
                        label="ZIP Code"
                        isRequired={true}
                        maxLength={10}
                    />
                </div>
            </div>
        </form>
        </ModalBody>
        <ModalFooter>
             <PrimaryButton disabled={isDisabled()} onClick={handleSubmit}>
                {initialData ? "Update Address" : "Add Address"}
            </PrimaryButton>
        </ModalFooter>
        </>
    );
}

export default AddressForm;
