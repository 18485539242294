import { createSlice } from "@reduxjs/toolkit";

const CustomizationPricingSlice = createSlice({
    name: "customizationPricing",
    initialState: {
        prices: []
    },
    reducers: {
        setAllPrices(state, action) {
            state.prices = action.payload;
        },
    }
});

export const CustomizationPricingAction = CustomizationPricingSlice.actions;
export default CustomizationPricingSlice.reducer;